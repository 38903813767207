import React, {
  FC,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateRangeCalendar, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import styled from '@mui/material/styles/styled';
import { Box, Fade, IconButton, Popover } from '@mui/material';
import { Subject } from 'rxjs';
import equal from 'fast-deep-equal/react';
import EventIcon from '@mui/icons-material/Event';
import { SingleInputDateRangeFieldProps } from '@mui/x-date-pickers-pro/SingleInputDateRangeField/SingleInputDateRangeField.types';
import { DateRangeCalendarProps } from '@mui/x-date-pickers-pro/DateRangeCalendar/DateRangeCalendar.types';
import useConfigDateFormat from '../../hooks/useConfigDateFormat';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';

export const DateRangePicker: FC<IDateRangePicker> = forwardRef(
  (
    {
      width,
      onChange,
      onBlur,
      defaultValue,
      value,
      fieldProps = {},
      calendarProps = {},
      navigationLockFn = (handlers: () => void) => handlers(),
      keyboardOnChange,
    },
    ref,
  ) => {
    const dateRangeStream$ = useMemo(() => new Subject<IDateValue>(), []);
    const [valueDate, setValueDate] = useState(
      defaultValue || { fromDate: new Date(), toDate: null },
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [focused, setFocused] = useState(false);
    const pickerRef = useRef(null);
    const isChanged = useRef(false);
    const disabledBlur = useRef<boolean>(false);
    const calendarState = useRef<PickerSelectionState | undefined>();
    const inputEl = useRef<HTMLInputElement | null>(null);

    const calendarDateFormat = useConfigDateFormat();

    const open = Boolean(anchorEl);
    useEffect(() => {
      const dateSub = dateRangeStream$.subscribe((date) => {
        onChange?.(date);
      });
      return () => {
        dateSub.unsubscribe();
      };
    }, []);

    useLayoutEffect(() => {
      if (value) setValueDate(value);
    }, [value]);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      navigationLockFn(() => setAnchorEl(pickerRef.current));
      const el = (e.target as HTMLInputElement).closest('button');
      const input = el?.nextElementSibling as HTMLInputElement;
      inputEl.current = input;
      const timer = setTimeout(() => {
        setFocused(true);
        disabledBlur.current = true;
        input.focus();
        clearTimeout(timer);
      }, 0);
    };

    fieldProps.InputProps = useMemo(
      () => ({
        ...(fieldProps.InputProps && { ...fieldProps.InputProps }),
        startAdornment: (
          <IconButton onClick={handleClick} disabled={fieldProps?.disabled || false}>
            <EventIcon />
          </IconButton>
        ),
      }),
      [valueDate, fieldProps?.disabled],
    );

    const handleClose = () => {
      setAnchorEl(null);
      disabledBlur.current = true;
      const timer = setTimeout(() => {
        inputEl.current?.focus();
        disabledBlur.current = false;
        clearTimeout(timer);
      }, 0);
      if (calendarState.current === 'partial') {
        const { fromDate, toDate } = valueDate;
        dateRangeStream$.next({ fromDate, toDate: toDate || fromDate });
        calendarState.current = undefined;
      }
    };

    return (
      <Box ref={ref}>
        <SDatePicker ref={pickerRef} {...{ width }}>
          <SingleInputDateRangeField
            size='small'
            fullWidth
            format={calendarDateFormat}
            value={[valueDate.fromDate, valueDate.toDate || null]}
            timezone='UTC'
            onMouseEnter={() => {
              disabledBlur.current = true;
            }}
            onMouseLeave={() => {
              disabledBlur.current = false;
            }}
            onBlur={() => {
              if (isChanged.current && !disabledBlur.current) {
                dateRangeStream$.next({
                  fromDate: valueDate.fromDate,
                  toDate: valueDate?.toDate || null,
                });
                onBlur?.(valueDate);
                isChanged.current = false;
              }
              if (!open && !disabledBlur.current) {
                setFocused(false);
              }
            }}
            onChange={(date, context) => {
              const notEqual = !equal(date, valueDate);
              const isValid = !context.validationError.some((el) => el);
              if (date && isValid && notEqual) {
                setValueDate({ fromDate: date[0], toDate: date[1] });
                isChanged.current = true;
              }
            }}
            onClick={() => {
              navigationLockFn(() => setFocused(true));
            }}
            readOnly={!focused}
            {...{ focused }}
            {...fieldProps}
          />
        </SDatePicker>
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            marginTop: 1,
          }}
        >
          <DateRangeCalendar
            calendars={1}
            value={[valueDate.fromDate, valueDate.toDate || null]}
            onChange={(date, selectionState) => {
              calendarState.current = selectionState;
              const dateObj = { fromDate: date[0], toDate: date[1] };
              setValueDate(dateObj);
              if (!equal(date, valueDate) && keyboardOnChange) {
                dateRangeStream$.next(dateObj);
                isChanged.current = true;
              }
              if (selectionState === 'finish') {
                if (!equal(date, valueDate)) dateRangeStream$.next(dateObj);
                handleClose();
              }
            }}
            {...calendarProps}
          />
        </Popover>
      </Box>
    );
  },
);

const SDatePicker = styled(Box)<Pick<IDateRangePicker, 'width'>>(({ width }) => ({
  width: width || '100%',
  display: 'flex',
  '.MuiInputBase-root': {
    paddingLeft: 6,
    paddingRight: 0,
    '& > input': {
      textAlign: 'center',
      width: '76%',
    },
  },
}));

export interface IDateRangePicker {
  width?: number | string;
  onChange?: (v: IDateValue) => void;
  onBlur?: (v: IDateValue) => void;
  fieldProps?: Omit<SingleInputDateRangeFieldProps<Date>, 'defaultValue' | 'onChange' | 'ref'>;
  calendarProps?: Omit<DateRangeCalendarProps<Date>, 'defaultValue' | 'onChange'>;
  defaultValue?: IDateValue;
  value?: IDateValue;
  navigationLockFn?: (handlers: () => void) => void;
  // allow to trigger onChange by keyboard entering
  keyboardOnChange?: boolean;
}

export interface IDateValue {
  fromDate: Date | null;
  toDate?: Date | null;
}
