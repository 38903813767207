import { Button, DialogActions, Stack } from '@mui/material';
import { FC } from 'react';
import { FieldsRow, FormTextField, TextField } from '../../form';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { EmailAttachmentInput } from '../../../../graphql/generatedModel';

import AttachFileIcon from '@mui/icons-material/AttachFile';

export const EmailForm: FC<IEmailForm> = ({ attachments }) => {
  const [t] = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();

  const validateEmails = (emails: string): boolean => {
    if (!emails) {
      return false;
    }
    const regEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const arrayOfEmails = emails.replace(/\s/g, '').split(/,|;/);

    for (let i = 0; i < arrayOfEmails.length; i++) {
      if (!regEx.test(arrayOfEmails[i])) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Stack direction='column' spacing={3}>
        <FieldsRow>
          <FormTextField
            name='recipient'
            label={t('common.recipient')}
            controllerProps={{ rules: { validate: validateEmails } }}
            error={Boolean(errors['recipient'])}
            helperText={!!errors?.recipient ? t('common.valid_emails') : ''}
            width='100%'
          />
        </FieldsRow>
        <FieldsRow>
          <FormTextField name='subject' label={t('common.regarding')} width='100%' />
        </FieldsRow>
        <FieldsRow>
          <FormTextField name='text' multiline rows={6} width='100%' label={t('common.message')} />
        </FieldsRow>
        <Stack
          direction='row'
          justifyContent='flex-start'
          useFlexGap
          rowGap={1}
          columnGap={2}
          flexWrap='wrap'
        >
          {attachments &&
            attachments.map((attachedFile) => (
              <TextField
                key={`attachment${Math.round(Math.random() * 10000)}`}
                value={attachedFile.fileName}
                disabled
                width='fit-content'
                InputProps={{
                  endAdornment: <AttachFileIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
                }}
              />
            ))}
        </Stack>
      </Stack>
      <Stack>
        <DialogActions sx={{ mt: 6, p: 0 }}>
          <Button variant='contained' type='submit'>
            {t('common.send')}
          </Button>
        </DialogActions>
      </Stack>
    </>
  );
};

interface IEmailForm {
  attachments?: EmailAttachmentInput[];
}
