import { Controller, ControllerProps } from 'react-hook-form';
import { ChangeEvent, FC } from 'react';
import { ISwitchProps, Switch } from '../fields/switch.component.tsx';

export const FormSwitch: FC<IFormSwitch> = ({
  name,
  defaultChecked,
  onChange,
  onBlur,
  ...props
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultChecked}
      render={({ field: { value, onChange: onFieldChange, onBlur: onFieldBlur } }) => {
        return (
          <Switch
            checked={value}
            onChange={(e) => {
              onFieldChange(e);
              onChange?.(e);
            }}
            onBlur={(e) => {
              onFieldBlur(); // notify react-hook-form when input is touched/blur
              onBlur?.(e);
            }}
            {...props}
          />
        );
      }}
    />
  );
};

interface IFormSwitch extends Omit<ISwitchProps, 'name' | 'value'> {
  name: ControllerProps['name'];
  onChange?: (v: ChangeEvent<HTMLInputElement>) => void;
}
