import { FC, useEffect, useMemo } from 'react';
import { Button, Grid, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FormCheckbox,
  FormDatePicker,
  FormNumericField,
  FormSelect,
  FormTextField,
} from '../../../../../../../../../shared/components/form';
import { FormProvider, useForm } from 'react-hook-form';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { ISelectOption } from '../../../../../../../../../shared/components/form/fields/select.component.tsx';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { TDefaultValues } from './customerPrices.popup.tsx';
import { generalTabState } from '../../../../generalTab.state.ts';
import { C_Special_Price_Type } from '../../../../../../../../../graphql/generatedModel.ts';
import { GeneralTabOptionsRes } from '../../../../../../../services/article.service.ts';
import { getSpecialPriceType } from '../../../../../../../../customer/components/tabs/conditions/components/sections/individualPrices/popups/individualPrices/individualPrices.popup.tsx';
import { format } from 'date-fns';

export const CustomerPricesForm: FC<ICustomerPricesForm> = ({
  autoFocusField,
  defaultValues,
  customersList,
  stream,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<TDefaultValues>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      specialPriceTypeText: defaultValues.specialPriceType
        ? t(`enums.${defaultValues.specialPriceType}`)
        : null,
    },
  });
  const { watch, setValue } = formMethods;
  const { errors, dirtyFields } = formMethods.formState;

  useEffect(() => {
    const formStateSub = watch((data, { name, type }) => {
      if (
        type === 'change' &&
        (name === 'fromQuantity' ||
          name === 'specialPrice' ||
          name === 'specialDiscount' ||
          name === 'specialIsNoDiscount')
      ) {
        const specialPriceType = getSpecialPriceType(data);
        formMethods.setValue(
          'specialPriceTypeText',
          specialPriceType && t(`enums.${specialPriceType}`),
        );
        formMethods.setValue('specialPriceType', specialPriceType);
      }
    });

    return () => {
      formStateSub.unsubscribe();
    };
  }, []);

  const handleSubmit = (v: TDefaultValues) => {
    const { specialPriceTypeText: _, ...rest } = v;
    const specialPriceType = v.specialPriceType as C_Special_Price_Type;
    const values = { ...rest, specialPriceType };
    if (values.fromDate) values.fromDate = format(values.fromDate, 'yyyy-MM-dd');
    if (values.toDate) values.toDate = format(values.toDate, 'yyyy-MM-dd');
    generalTabState.pub.updatePricesByCustomerPos(values);
    stream.emit('close');
  };

  const customersOptions = useMemo<Array<ExtendedSelectOption>>(() => {
    return customersList.reduce<Array<ExtendedSelectOption>>(
      (outArr, { id, customerNo, internalOrFullName }) => {
        if (id && (customerNo || internalOrFullName)) {
          outArr.push({ id, customerNo: customerNo || '', label: internalOrFullName || '' });
        }
        return outArr;
      },
      [],
    );
  }, [customersList]);

  useBeforeClosePopup({
    stream,
    isDirty: !!Object.keys(dirtyFields).length,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormSelect
              disableClearable
              autoFocus={'customerName' === autoFocusField}
              width='100%'
              name='customerId'
              label={t('common.customer')}
              datasetattribute='customerOptions'
              options={customersOptions}
              getOptionLabel={(params) => {
                const { customerNo, label } = params as ExtendedSelectOption;
                return `${customerNo} ${label}`;
              }}
              onChange={(_, opt) => {
                const option = opt as ExtendedSelectOption | null;
                setValue('customerName', option?.label);
                setValue('customerNo', option?.customerNo);
              }}
              controllerProps={{
                rules: {
                  validate: (v) => (v ? null : t('common.not_empty')),
                },
              }}
              {...(errors.customerId && {
                error: true,
                helperText: errors.customerId.message,
              })}
            />
          </Grid>
          <Grid item xs={3}>
            <FormDatePicker
              name='fromDate'
              returnEmpty
              fieldProps={{
                label: t('common.from_date'),
                autoFocus: 'fromDate' === autoFocusField,
              }}
              controllerProps={{
                rules: {
                  deps: ['toDate'],
                  validate: (fromDate, { toDate }) => {
                    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
                      return t('common.value_less', { max: t('common.to_date') });
                    }
                    return null;
                  },
                },
              }}
              {...(errors.fromDate && {
                error: true,
                helperText: errors.fromDate.message as string,
              })}
              navigation={false}
            />
          </Grid>
          <Grid item xs={3}>
            <FormDatePicker
              name='toDate'
              returnEmpty
              fieldProps={{ label: t('common.to_date'), autoFocus: 'toDate' === autoFocusField }}
              controllerProps={{
                rules: {
                  deps: ['fromDate'],
                  validate: (toDate, { fromDate }) => {
                    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
                      return t('common.value_greater', { min: t('common.from_date') });
                    }
                    return null;
                  },
                },
              }}
              {...(errors.toDate && {
                error: true,
                helperText: errors.toDate.message as string,
              })}
              navigation={false}
            />
          </Grid>
          <Grid item xs={3}>
            <FormNumericField
              name='fromQuantity'
              autoFocus={'fromQuantity' === autoFocusField}
              precision={3}
              noTrailingZeros
              label={t('customer.fromQuantity')}
            />
          </Grid>
          <Grid item xs={3}>
            <FormNumericField
              autoFocus={'specialPrice' === autoFocusField}
              name='specialPrice'
              label={t('common.price')}
              controllerProps={{
                rules: {
                  validate: (_, { specialPriceType }) => {
                    return specialPriceType ? null : t('common.not_empty');
                  },
                  deps: ['specialDiscount'],
                },
              }}
              {...(errors.specialPrice && {
                error: true,
                helperText: errors.specialPrice.message,
              })}
            />
          </Grid>
          <Grid item xs={3}>
            <FormNumericField
              name='specialDiscount'
              autoFocus={'specialDiscount' === autoFocusField}
              precision={2}
              noTrailingZeros
              returnZero
              InputProps={{
                endAdornment: '%',
              }}
              controllerProps={{
                rules: {
                  validate: (_, { specialPriceType }) => {
                    return specialPriceType ? null : t('common.not_empty');
                  },
                  deps: ['specialPrice'],
                },
              }}
              {...(errors.specialDiscount && {
                error: true,
                helperText: errors.specialDiscount.message,
              })}
              max={100}
              label={t('common.discount')}
            />
          </Grid>
          <Grid item xs={3}>
            <FormCheckbox
              name='specialIsNoDiscount'
              inputProps={{
                autoFocus: 'specialIsNoDiscount' === autoFocusField,
              }}
              controllerProps={{
                rules: {
                  deps: ['specialPrice', 'specialDiscount'],
                },
              }}
              label={t('customer.noDiscount')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              inputProps={{ maxLength: 128 }}
              width='100%'
              label={t('customer.comment')}
              name='comment'
              autoFocus={'comment' === autoFocusField}
            />
          </Grid>
          <Grid item xs={12}>
            <SFormTextField
              width='100%'
              label={t('customer.kind')}
              name='specialPriceTypeText'
              {...(errors.specialPriceType && {
                error: true,
                helperText: t(errors['specialPriceType'].message as string),
              })}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
            <FormTextField
              name='specialPriceType'
              disabled
              hidden
              sx={{ display: 'none', visibility: 'hidden' }}
            />
          </Grid>
        </Grid>
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button type='submit' variant='contained'>
            {t('common.save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

const SFormTextField = styled(FormTextField)({
  '& .MuiInputBase-root, & input': {
    cursor: 'not-allowed',
  },
});

interface ICustomerPricesForm {
  defaultValues: TDefaultValues;
  autoFocusField: string;
  customersList: GeneralTabOptionsRes['customersList'];
  articleId: string;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  customerNo?: string;
}
