import {
  Popup,
  TPopupComponent,
} from '../../../../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import { ContentPopup } from './content.popup.tsx';
import { map } from 'rxjs';

export class DoughSplittingEditPopup extends Popup<IDoughSplittingEditPopup> {
  Component: FC<TPopupComponent>;
  constructor() {
    super();
    const stream = this.innerStream;

    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);
      const [inputParams, setInputParams] = useState<IDoughSplittingEditPopup>({
        action: 'open',
        defaultValues: {
          id: '',
          roundTo: null,
          doughNo: null,
          doughName: null,
          part1Amount: null,
          part2Amount: null,
          part3Amount: null,
          part4Amount: null,
          part5Amount: null,
          diff: null,
          isSplitted: null,
          part1Def: null,
          part2Def: null,
          faktorLtKg: null,
          date: null,
          isLiters: false,
          doughRequired: null,
          title: '',
          field: '',
        },
      });

      useEffect(() => {
        stream
          .actionListener('open')
          .pipe(
            map(({ defaultValues }) => {
              return {
                action: 'open' as TPopupDefaultActions,
                defaultValues: defaultValues,
              };
            }),
          )
          .subscribe(setInputParams);
      }, []);

      return (
        <Wrapper
          stream={stream}
          PaperProps={{ sx: { maxWidth: 712, width: '100%', maxHeight: 371, height: '100%' } }}
          fullWidth
          {...{ popupTitle: inputParams.defaultValues?.title }}
        >
          <ContentPopup
            {...{
              stream,
              defaultValues: inputParams.defaultValues,
            }}
          />
        </Wrapper>
      );
    };
  }
}

export interface IDoughSplittingEditPopup {
  action: TPopupDefaultActions | 'save';
  defaultValues: IDoughSplittingEditPopupDefaultValues;
}

export interface IDoughSplittingEditPopupDefaultValues {
  id: string;
  roundTo: number | null | undefined;
  doughNo: string | null | undefined;
  doughName: string | null | undefined;
  part1Amount: number | null | undefined;
  part2Amount: number | null | undefined;
  part3Amount: number | null | undefined;
  part4Amount: number | null | undefined;
  part5Amount: number | null | undefined;
  diff: number | null | undefined;
  isSplitted: boolean | null | undefined;
  part1Def: number | null | undefined;
  part2Def: number | null | undefined;
  faktorLtKg: number | null | undefined;
  date: Date | null | undefined;
  isLiters: boolean;
  doughRequired: number | null | undefined;
  title: string;
  field: string;
}
