import { gql } from 'graphql-request';

export const getCustomerList = gql`
  query getCustomerList($filter: WA_CustomersForNewInvoicesGridFilter!) {
    wawiAssist {
      listWA_CustomersForCreatingNewInvoices(filter: $filter) {
        customerId
        customerNo
        internalOrFullName
        isEmailService
      }
    }
  }
`;

export const getCustomerDetails = gql`
  query getCustomerDetails($getCustomerDataId: ID!) {
    wawiAssist {
      getCustomerData(id: $getCustomerDataId) {
        customerNo
        internalOrFullName
        informationTip
        deliveryAddress
        isEmailServiceForInvoice
        defaultNoteInInvoice
        emailForInvoice
        transportSectorId
        transportSector {
          isPriceRateFixed
          priceRateFixed
        }
      }
    }
  }
`;

export const getCustomersDetails = gql`
  query getCustomersDetails($ids: [ID!]!) {
    wawiAssist {
      listWA_CustomersDataForCreatingMultipleInvoices(ids: $ids) {
        customerId
        defaultNoteInInvoice
        defaultNoteInInvoice2
        emailForInvoice
      }
    }
  }
`;

export const getOrders = gql`
  query getOrders($customerId: ID!, $ordersStartDate: Date!, $ordersEndDate: Date!) {
    wawiAssist {
      listWA_OrdersForNewInvoice(
        customerId: $customerId
        ordersStartDate: $ordersStartDate
        ordersEndDate: $ordersEndDate
      ) {
        orderId
        orderDate
        orderNo
        orderTotal
        customerId
        customerNo
        internalOrFullName
      }
    }
  }
`;

export const getOrderPositions = gql`
  query getOrderPositions($orderId: ID!) {
    wawiAssist {
      listWA_OrderPositionsForNewInvoice(orderId: $orderId) {
        orderPositionId
        virtualPositionId
        articleNo
        quantity
        articleName
        price
        total
        discount
        taxName
        weight
        indTextDeliveryNote
      }
    }
  }
`;

export const createInvoice = gql`
  mutation createInvoice(
    $customerId: ID!
    $date: Date!
    $ordersStartDate: Date!
    $ordersEndDate: Date!
    $manuallySelectedOrdersIds: [ID!]
    $linkText: ID
    $note: String
    $note2: String
    $isPaidByCash: Boolean!
  ) {
    wawiAssist {
      createWA_Invoice(
        invoiceProps: {
          customerId: $customerId
          date: $date
          ordersStartDate: $ordersStartDate
          ordersEndDate: $ordersEndDate
          manuallySelectedOrdersIds: $manuallySelectedOrdersIds
          linkText: $linkText
          note: $note
          note2: $note2
          isPaidByCash: $isPaidByCash
        }
      ) {
        invoiceId
        invoiceNo
      }
    }
  }
`;
