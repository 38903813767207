import { Pub, State, Sub } from '../../../shared/state/state.abstract';
import { BehaviorSubject, Observable, map, merge, share, tap, take } from 'rxjs';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import {
  C_Group_By_Type,
  Wa_InitOrderStatisticCalcMutationVariables,
} from '../../../graphql/generatedModel';
import { C_CHART_TYPE } from '../common/enums/enums';
import { IItem as IFilterDictItem } from '../../../shared/components/filterTableWithModal/filterTableWithModal.component';
import { ISatisticDataRes, orderStatisticService } from '../services/orderStatistic.service';
import { IChartDataset } from '../../../shared/components/charts/interfaces/interfaces';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { ISelectOption } from '../../../shared/components/form/fields/select.component';
import { TFormValues } from './components/top/top.component';
import { getFirstAndLastDateOfCurrentMonth } from '../../../shared/helpers/utils/utils.helper';

export const initOrderStatisticState: IOrderStatisticState = {
  action: undefined,
  items: [],
  presentedOrderTypes: [],
  summaryData: {},
  chartsDataset: {
    keys: [],
    values: [],
  },
  groupBy: C_Group_By_Type.GBT1_ARTICLE,
  gridGrouping: [],
  datePeriod: {
    ...getFirstAndLastDateOfCurrentMonth(),
  },
  isActiveGroupMode: true,
  search: '',
  chartType: C_CHART_TYPE.CT3_COLUMN,
  profileId: undefined,
  profileOptions: [],
  filter: {
    customerList: [],
    articleList: [],
    orderTypeList: [],
    groupByForFutureCalculations: C_Group_By_Type.GBT1_ARTICLE,
  },
};

class PubImpl extends Pub<IOrderStatisticState> {
  init(params: Partial<IOrderStatisticState>) {
    this.emit('init', params);
  }
  toggleGroupMode() {
    this.emit('toggleGroupMode', {});
  }
  getData(params: TFormValues) {
    this.emit('getData', { params });
  }
  updateSomeField<Key extends keyof IOrderStatisticState>(key: Key, value: any) {
    this.emit('updateSomeField', { [key]: value });
  }
  updateFilterField<Key extends keyof IOrderStatisticState['filter']>(key: Key, value: any) {
    this.emit('updateFilterField', {
      params: {
        key,
        value,
      },
    });
  }
  changeChartType(chartType: C_CHART_TYPE) {
    this.emit('changeChartType', { chartType });
  }
  search(search: string) {
    this.emit('search', { search });
  }
  gridGrouping(gridGrouping: IOrderStatisticState['gridGrouping']) {
    this.emit('gridGrouping', { gridGrouping });
  }
  changeProfile(settings: Pick<IOrderStatisticState, 'filter' | 'gridGrouping'>) {
    this.emit('changeProfile', { ...settings });
  }
}

class SubImpl extends Sub<IOrderStatisticState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  protected actionHandlers(): Observable<IOrderStatisticState> {
    return merge(
      this.update(),
      this.toggleGroupMode(),
      this.getData(),
      this.updateFilterField(),
    ).pipe(
      map((state) => {
        const { search, items } = state;
        let updatedData = structuredClone(items || []) as IOrderStatisticState['items'];

        updatedData = dataHelper
          .data(updatedData as [])
          .search({
            search,
            fields: [
              'articleNo',
              'articleDescription',
              'articleNoAndName',
              'customerNo',
              'customerName',
              'customerNoAndName',
              'date',
              'totalQuantity',
              'netSales',
              'discountGroup',
              'marketingGroup',
              'productionGroup',
            ],
          })
          .result() as ISatisticDataRes['items'];

        this.stream$.next({ ...state, action: 'internalUpdate' });
        return { ...state, items: updatedData };
      }),
    );
  }

  loading(): Observable<boolean> {
    return this.shareLoading$;
  }

  private update(): Observable<IOrderStatisticState> {
    return this.actionListener([
      'search',
      'updateSomeField',
      'changeChartType',
      'gridGrouping',
      'changeProfile',
      'updateStatisticData',
    ]);
  }

  private toggleGroupMode(): Observable<IOrderStatisticState> {
    return this.actionListener('toggleGroupMode').pipe(
      map((oldState) => {
        const state = structuredClone(oldState);
        const oldStatus = state?.isActiveGroupMode;
        const newStatus = !oldStatus;
        state.isActiveGroupMode = newStatus;
        return state;
      }),
    );
  }

  private updateFilterField(): Observable<IOrderStatisticState> {
    return this.actionListener('updateFilterField').pipe(
      map((oldState) => {
        const state = structuredClone(oldState);
        const key = state?.params?.key;
        const newValue = state?.params?.value;
        const oldFilterValue = state.filter;
        return {
          ...state,
          filter: {
            ...oldFilterValue,
            [key]: newValue,
          },
        };
      }),
    );
  }

  private getData(): Observable<IOrderStatisticState> {
    return this.actionListener('getData').pipe(
      tap(() => {
        orderStatisticService.sub
          .getOrderStatisticData()
          .pipe(
            responseHandler<ISatisticDataRes | null>({
              customErrorHandler: () => 'order.tab_not_loaded',
              errorReturnType: null,
            }),
            take(1),
          )
          .subscribe((data) => {
            this.loading$.next(false);
            const updatedState = structuredClone(this.stream$.getValue());
            let gridGrouping = updatedState?.gridGrouping;
            const groupByForFuture = updatedState?.filter?.groupByForFutureCalculations;
            if (data) {
              gridGrouping = this.generateActiveGroupBy(groupByForFuture);
            }
            this.stream$.next({
              ...updatedState,
              ...(data || {}),
              groupBy: data ? groupByForFuture : updatedState?.groupBy,
              gridGrouping,
              action: 'updateStatisticData',
            });
            return {};
          });
      }),
      tap((state) => {
        this.loading$.next(true);
        const valuesFromForm = state?.params as TFormValues;
        const { datePeriod, filter } = valuesFromForm || {};
        const { articleList, customerList, orderTypeList, groupByForFutureCalculations } =
          filter || {};
        const orderStatisticProps: Wa_InitOrderStatisticCalcMutationVariables['orderStatisticProps'] =
          {
            dateFrom: datePeriod?.fromDate,
            dateTo: datePeriod?.toDate,
            groupBy: groupByForFutureCalculations,
          };
        if (articleList?.length) {
          orderStatisticProps.articlesList = articleList?.map(({ id }) => id);
        }
        if (customerList?.length) {
          orderStatisticProps.customersList = customerList?.map(({ id }) => id);
        }
        if (orderTypeList?.length) {
          orderStatisticProps.orderTypesList = orderTypeList?.map(({ id }) => id);
        }
        orderStatisticService.pub.getOrderStatisticData({
          orderStatisticProps,
        });
      }),
    );
  }

  private generateActiveGroupBy(
    groupByForFutureCalculations: IOrderStatisticState['filter']['groupByForFutureCalculations'],
  ): IOrderStatisticState['gridGrouping'] {
    if (groupByForFutureCalculations === C_Group_By_Type.GBT2_CUSTOMER_ARTICLE) {
      return ['customerNoAndName'];
    }
    if (
      groupByForFutureCalculations === C_Group_By_Type.GBT3_ARTICLE_CUSTOMER ||
      groupByForFutureCalculations === C_Group_By_Type.GBT5_ARTICLE_DATE
    ) {
      return ['articleNoAndName'];
    }
    if (groupByForFutureCalculations === C_Group_By_Type.GBT4_DATE_ARTICLE) {
      return ['date'];
    }
    if (groupByForFutureCalculations === C_Group_By_Type.GBT6_ARTICLE_DATE_CUSTOMER) {
      return ['articleNoAndName', 'date'];
    }
    return [];
  }
}

class OrderStatisticState extends State<IOrderStatisticState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const orderStatisticState = new OrderStatisticState(initOrderStatisticState);

export interface IOrderStatisticState {
  action:
    | 'init'
    | 'internalUpdate'
    | 'getData'
    | 'toggleGroupMode'
    | 'changeChartType'
    | 'updateSomeField'
    | 'updateFilterField'
    | 'gridGrouping'
    | 'changeProfile'
    | 'updateStatisticData'
    | 'search'
    | undefined;
  items: ISatisticDataRes['items'];
  presentedOrderTypes: ISatisticDataRes['presentedOrderTypes'];
  summaryData: ISatisticDataRes['summaryData'];
  chartsDataset: IChartDataset;
  groupBy: C_Group_By_Type;
  gridGrouping: string[];
  datePeriod: {
    fromDate: string;
    toDate: string;
  };
  isActiveGroupMode: boolean;
  search: string;
  chartType: C_CHART_TYPE;
  profileOptions: Array<IOrderExtendedOptions>;
  profileId: string | undefined;
  filter: IFilter['filter'];
  params?: any;
}

interface IFilter {
  filter: {
    customerList: IFilterDictItem[];
    articleList: IFilterDictItem[];
    orderTypeList: IFilterDictItem[];
    groupByForFutureCalculations: C_Group_By_Type;
  };
  gridGrouping: string[];
}

export interface IOrderExtendedOptions extends ISelectOption {
  settings: IFilter;
}
