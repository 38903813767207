import { FC, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model';
import { useTranslation } from 'react-i18next';
import { OverviewOrdersRes } from '../../../../services/overview.service';
import { getInitSortModel, orderOverviewState } from './ordersOverview.state';
import { storageHelper } from '../../../../../../shared/helpers/storage';
import { Column } from '../../../../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { GridAggregationFunction, GridSortModel } from '@mui/x-data-grid-premium';
import { SideControls } from './components/sideControls.component';
import { C_Order_Type } from '../../../../../../graphql/generatedModel';
import { GroupTable } from '../../../../../../shared/components/groupTable/groupTable.component';
import {
  customSumAggregation,
  renderCellForGroupTable,
} from '../../../../../../shared/components/groupTable/functions/forStyle';
import { Link } from '@mui/material';
import { navigateService } from '../../../../../../shared/services/navigate/navigate.service';
import { globalSearchBuildUrl } from '../../../../../../shared/components/globalSearch/globalSearchBuildUrl.util';

export const OrdersOverviewTab: FC = () => {
  const { defaultValues } = useLoaderData() as ITabState;
  const [data, setData] = useState<OverviewOrdersRes>(defaultValues);
  const [loading, setLoading] = useState(false);
  const initIsActive = storageHelper.local.getItem('overview.orderIsGroupModeActive') || false;
  const initSortModel = getInitSortModel();
  const [isActive, setIsActive] = useState<boolean>(initIsActive);
  const [sortModel, setSortModel] = useState<GridSortModel>(initSortModel);
  const { t } = useTranslation();

  const getAllNumberRow: GridAggregationFunction<string, string | null> = {
    apply: () => {
      const count: number = data?.length || 0;
      return `${t('order.order', { count })}: ${localeFormatterHelper.formatNumber(count, {
        precision: 0,
      })}`;
    },
    label: '',
    columnTypes: ['string'],
  };

  const formatValue = (value: number) => {
    return localeFormatterHelper.formatNumber(value, {
      returnZero: false,
    });
  };

  useEffect(() => {
    orderOverviewState.pub.init({
      orderOverview: defaultValues,
      isActiveGroupMode: initIsActive,
      sortModel: initSortModel,
    });
    const unsubData = orderOverviewState.sub
      .state()
      .subscribe(({ orderOverview, isActiveGroupMode, sortModel }) => {
        setData(orderOverview);
        setIsActive(isActiveGroupMode);
        setSortModel(sortModel);
      });
    const unsubLoading = orderOverviewState.sub.loading().subscribe(setLoading);

    return () => {
      unsubData.unsubscribe();
      unsubLoading.unsubscribe();
    };
  }, []);

  return (
    <GroupTable
      data={data as []}
      defaultSorting={sortModel}
      onSort={(sortModel) => storageHelper.local.setItem('overview.orderSort', sortModel)}
      heightOffset={isActive ? 339 : 291}
      isActive={isActive}
      loading={loading}
      topControls={() => <SideControls isActive={isActive} />}
      initialState={{
        aggregation: {
          model: {
            orderDate: 'allNumberRow',
            orderTotal: 'orderTotalSum',
            orderNetTotal: 'orderNetTotalSum',
          },
        },
        columns: storageHelper.local.getItem('overview.orderOverviewColumnModel'),
      }}
      onChangeColumnModel={(v) => {
        storageHelper.local.setItem('overview.orderOverviewColumnModel', v);
      }}
      aggregationFunctions={{
        allNumberRow: getAllNumberRow,
        orderTotalSum: customSumAggregation({ returnZero: false }),
        orderNetTotalSum: customSumAggregation({ returnZero: false }),
      }}
      replacedId='orderId'
      groupLocalStorageKey='overview.orderActiveGroup'
    >
      <Column
        field='orderDate'
        headerName={t('common.date')}
        valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
        width={107}
      />
      <Column field='customerNr' headerName={t('common.customer_no')} width={93} />
      <Column field='internalOrFullName' headerName={t('common.name')} width={185} />
      <Column
        field='orderNo'
        headerName={t('common.delivery_no')}
        width={106}
        renderCell={({ value, row }) =>
          row.orderId ? (
            <Link
              component='button'
              onClick={() =>
                navigateService.pub.navigateTo(
                  globalSearchBuildUrl('order', {
                    id: row.orderId,
                    date: row.orderDate,
                    isDeleted: false,
                    orderNo: row.orderNo,
                  }),
                )
              }
            >
              {value}
            </Link>
          ) : (
            value
          )
        }
      />
      <Column
        field='orderTotal'
        headerName={t('common.brutto')}
        align='right'
        valueFormatter={({ value }) => (value ? formatValue(value) : '')}
        headerAlign='right'
        type='number'
        width={90}
      />
      <Column
        field='orderNetTotal'
        headerName={t('common.net')}
        align='right'
        headerAlign='right'
        valueFormatter={({ value }) => (value ? formatValue(value) : '')}
        type='number'
        width={90}
      />
      <Column
        field='orderType'
        headerName={t('order.order_type')}
        width={138}
        renderCell={(params) =>
          renderCellForGroupTable({
            params,
            valueComponent: (
              <span>
                {params?.value ? t(`enums.${C_Order_Type[params?.value as C_Order_Type]}`) : null}
              </span>
            ),
          })
        }
      />
      <Column field='historyUserFullName' headerName={t('common.user')} width={184} />
      <Column
        field='deliveryNoteGroupName'
        headerName={t('order.delivery_note_group')}
        width={145}
      />
      <Column field='customerGroupName' headerName={t('order.customer_group')} width={140} />
    </GroupTable>
  );
};
