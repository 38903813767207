import { map, Observable, switchMap, zip, mergeMap } from 'rxjs';

import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  ListWa_ProductionPrintJobsQueryVariables as ProductionPrintJobsQueryVariables,
  ListWa_ProductionPrintJobsQuery as ProductionPrintJobsQuery,
  GetPrintJobsOrdersCountQueryVariables as PrintJobsOrdersCountQueryVariables,
  GetPrintJobsOrdersCountQuery as PrintJobsOrdersCountQuery,
  GetPrintJobsPreProductionOrdersCountQuery as PrintJobsPreProductionOrdersCountQuery,
  ListWa_ProductionDeliveryNotesQueryVariables as ProductionDeliveryNotesQueryVariables,
  ListWa_ProductionDeliveryNotesQuery as ProductionDeliveryNotesQuery,
  ChangeIncomingOrdersStatusMutationVariables as IncomingOrdersStatusMutationVariables,
  ChangeIncomingOrdersStatusMutation as IncomingOrdersStatusMutation,
  GetIncomingOrdersAcceptanceStatusQuery as IncomingOrdersStatusQuery,
  C_Incoming_Orders_Source,
  GetProductionReportsVersionsQueryVariables as ProductionReportsVersionsVariables,
  GetProductionReportsVersionsQuery as ProductionReportsVersionsQuery,
  CreateProductionReportsVersionMutationVariables,
  CreateProductionReportsVersionMutation,
  DeleteProductionReportsVersionMutationVariables,
  DeleteProductionReportsVersionMutation,
  GetProducedArticlesQuantityQueryVariables,
  GetProducedArticlesQuantityQuery,
  SaveProducedArticlesQuantityMutationVariables,
  SaveProducedArticlesQuantityMutation,
  GetDoughsSplittingInformationQueryVariables,
  GetDoughsSplittingInformationQuery,
  SaveDoughSplittingInformationMutationVariables,
  SaveDoughSplittingInformationMutation,
  DeleteDoughSplittingInformationMutationVariables,
  DeleteDoughSplittingInformationMutation,
} from '../../../graphql/generatedModel.ts';
import {
  getPrintJobsOrdersCount,
  getPrintJobsPreProductionOrdersCount,
  getPrintJobsProductionList,
  getPrintJobsProductionPrintDeliveryNotes,
  getIncomingOrdersStatus,
  changeIncomingOrdersStatus,
  getProductionReportsVersions,
  createProductionReportsVersion,
  deleteProductionReportsVersion,
  getProducedArticlesQuantity,
  saveProducedArticlesQuantity,
  getDoughsSplittingInformation,
  saveDoughSplittingInformation,
  deleteDoughSplittingInformation,
} from './gql/printJobs.gql.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import {
  IDeliveryNotesTabDataRes,
  IPrintJobsDetailsState,
  IProductionTabDataRes,
  PrintJobsTabs,
} from '../printJobs/states/printJobsDetails.state.ts';

type Actions =
  | 'printJobsProductionTabData'
  | 'printJobsDeliveryNotesTabData'
  | 'changeIncomingOrdersStatus'
  | 'fullPrintJobsDataDependedOnSelectedTab'
  | 'getProductionReportsVersions'
  | 'createProductionReportsVersion'
  | 'deleteProductionReportsVersion'
  | 'saveProducedArticlesQuantity'
  | 'producedArticlesList'
  | 'getDoughsSplittingInformation'
  | 'deleteDoughSplittingInformation'
  | 'saveDoughSplittingInformation';

class PubImpl extends Pub<Actions> {
  printJobsProductionTabData(
    params: ProductionPrintJobsQueryVariables & PrintJobsOrdersCountQueryVariables,
  ): void {
    this.emit('printJobsProductionTabData', params);
  }
  printJobsDeliveryNotesTabData(params: ProductionDeliveryNotesQueryVariables): void {
    this.emit('printJobsDeliveryNotesTabData', params);
  }
  changeIncomingOrdersStatus(params: IncomingOrdersStatusMutationVariables): void {
    this.emit('changeIncomingOrdersStatus', params);
  }
  fullPrintJobsDataDependedOnSelectedTab(params: FullPrintJobsDataParams): void {
    this.emit('fullPrintJobsDataDependedOnSelectedTab', params);
  }
  producedArticlesList(params: GetProducedArticlesQuantityQueryVariables): void {
    this.emit('producedArticlesList', params);
  }
  getProductionReportsVersions(params: ProductionReportsVersionsVariables): void {
    this.emit('getProductionReportsVersions', params);
  }
  createProductionReportsVersion(params: CreateProductionReportsVersionMutationVariables): void {
    this.emit('createProductionReportsVersion', params);
  }
  deleteProductionReportsVersion(params: DeleteProductionReportsVersionMutationVariables): void {
    this.emit('deleteProductionReportsVersion', params);
  }
  saveProducedArticlesQuantity(params: SaveProducedArticlesQuantityMutationVariables): void {
    this.emit('saveProducedArticlesQuantity', params);
  }
  getDoughsSplittingInformation(params: GetDoughsSplittingInformationQueryVariables): void {
    this.emit('getDoughsSplittingInformation', params);
  }
  deleteDoughSplittingInformation(params: DeleteDoughSplittingInformationMutationVariables): void {
    this.emit('deleteDoughSplittingInformation', params);
  }
  saveDoughSplittingInformation(params: SaveDoughSplittingInformationMutationVariables): void {
    this.emit('saveDoughSplittingInformation', params);
  }
}

class SubImpl extends Sub<Actions> {
  printJobsProductionTabData(): Observable<PrintJobsProductionTabRes> {
    return this.actionListener('printJobsProductionTabData').pipe(
      switchMap(({ params: { filter, fromDate, toDate } }) => {
        return zip(
          gqlClient(getPrintJobsProductionList, {
            filter,
          }) as Observable<ProductionPrintJobsQuery>,
          gqlClient(getPrintJobsOrdersCount, {
            fromDate,
            toDate,
          }) as Observable<PrintJobsOrdersCountQuery>,
          gqlClient(getPrintJobsPreProductionOrdersCount, {
            onDate: fromDate,
          }) as Observable<PrintJobsPreProductionOrdersCountQuery>,
        );
      }),
      map((data) => ({
        productionList:
          (data[0]?.wawiAssist?.listWA_ProductionPrintJobs as ProductionListRes) || [],
        countOrders: (data[1]?.wawiAssist?.getWA_OrdersCountForPrintJobs as CountOrdersRes) || 0,
        countOrderPreProduction:
          (data[2]?.wawiAssist
            ?.getWA_PreProductionOrdersCountForPrintJobs as CountOrderPreProductionRes) || 0,
      })),
    );
  }
  producedArticlesList(): Observable<ProducedArticlesRes> {
    return this.actionListener('producedArticlesList').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getProducedArticlesQuantity,
          params,
        ) as Observable<GetProducedArticlesQuantityQuery>;
      }),
      map((data: GetProducedArticlesQuantityQuery) => {
        return (data?.wawiAssist?.listWA_ProducedArticlesQuantity || []) as ProducedArticlesRes;
      }),
    );
  }
  printJobsDeliveryNotesTabData(): Observable<PrintDeliveryNotesTabRes> {
    return this.actionListener('printJobsDeliveryNotesTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getPrintJobsProductionPrintDeliveryNotes,
          params,
        ) as Observable<ProductionDeliveryNotesQuery>;
      }),
      map((data: ProductionDeliveryNotesQuery) => {
        return {
          printDeliveryNotesList: (data?.wawiAssist?.listWA_ProductionDeliveryNotes ||
            []) as ProductionPrintDeliveryNotesRes,
        };
      }),
    );
  }
  saveProducedArticlesQuantity(): Observable<SaveProducedArticlesQuantityRes> {
    return this.actionListener('saveProducedArticlesQuantity').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveProducedArticlesQuantity,
          params,
        ) as Observable<SaveProducedArticlesQuantityMutation>;
      }),
      map((data: SaveProducedArticlesQuantityMutation) => {
        return (data?.wawiAssist?.saveWA_ProducedArticlesQuantity ||
          []) as SaveProducedArticlesQuantityRes;
      }),
    );
  }
  fullPrintJobsDataDependedOnSelectedTab(): Observable<FullPrintJobsDataRes> {
    return this.actionListener('fullPrintJobsDataDependedOnSelectedTab').pipe(
      switchMap(({ params: { selectedTab, datePeriod, filter } }) => {
        const { fromDate, toDate } = datePeriod || {};
        const commonQueries = [
          gqlClient(getIncomingOrdersStatus, {
            externalSourceKind: C_Incoming_Orders_Source.IOS0_bakery2b,
            onDate: fromDate,
          }) as Observable<IncomingOrdersStatusQuery>,
          gqlClient(getIncomingOrdersStatus, {
            externalSourceKind: C_Incoming_Orders_Source.IOS1_CashAssistDirect,
            onDate: fromDate,
          }) as Observable<IncomingOrdersStatusQuery>,
        ];

        let specificQueries: GQLQuery[] = [];

        switch (selectedTab) {
          case PrintJobsTabs.production:
            specificQueries = [
              gqlClient(getPrintJobsProductionList, {
                filter,
              }) as Observable<ProductionPrintJobsQuery>,
              gqlClient(getPrintJobsOrdersCount, {
                fromDate,
                toDate,
              }) as Observable<PrintJobsOrdersCountQuery>,
              gqlClient(getPrintJobsPreProductionOrdersCount, {
                onDate: fromDate,
              }) as Observable<PrintJobsPreProductionOrdersCountQuery>,
            ];
            break;
          case PrintJobsTabs.printDeliveryNotes:
            specificQueries = [
              gqlClient(getPrintJobsProductionPrintDeliveryNotes, {
                fromDate,
                toDate,
              }) as Observable<ProductionDeliveryNotesQuery>,
            ];
            break;
          default:
            break;
        }

        return zip(...specificQueries, ...commonQueries).pipe(
          map((data) => ({ data, selectedTab })),
        );
      }),
      map(({ data, selectedTab }) => {
        const bakery2b =
          (data[data.length - 2] as IncomingOrdersStatusQuery)?.wawiAssist
            ?.getWA_IncomingOrdersAcceptanceStatus || false;
        const CAdirect =
          (data[data.length - 1] as IncomingOrdersStatusQuery)?.wawiAssist
            ?.getWA_IncomingOrdersAcceptanceStatus || false;
        const statusList = {
          bakery2b,
          CAdirect,
        };

        switch (selectedTab) {
          case PrintJobsTabs.production:
            return {
              dataTab: {
                productionList:
                  (data[0] as ProductionPrintJobsQuery)?.wawiAssist?.listWA_ProductionPrintJobs ||
                  [],
                countOrders:
                  (data[1] as PrintJobsOrdersCountQuery)?.wawiAssist
                    ?.getWA_OrdersCountForPrintJobs || 0,
                countOrderPreProduction:
                  (data[2] as PrintJobsPreProductionOrdersCountQuery)?.wawiAssist
                    ?.getWA_PreProductionOrdersCountForPrintJobs || 0,
              },
              statusList,
            };

          case PrintJobsTabs.printDeliveryNotes:
            return {
              dataTab: {
                printDeliveryNotesList:
                  (data[0] as ProductionDeliveryNotesQuery)?.wawiAssist
                    ?.listWA_ProductionDeliveryNotes || [],
              },
              statusList,
            };
          default:
            return {};
        }
      }),
    );
  }
  changeIncomingOrdersStatus(): Observable<ChangeIncomingOrdersStatusRes> {
    return this.actionListener('changeIncomingOrdersStatus').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          changeIncomingOrdersStatus,
          params,
        ) as Observable<IncomingOrdersStatusMutation>;
      }),
      map((data: IncomingOrdersStatusMutation) => {
        return data?.wawiAssist
          ?.saveIncomingOrdersAcceptanceStatus as ChangeIncomingOrdersStatusRes;
      }),
    );
  }
  getProductionReportsVersions(): Observable<ProductionReportsVersionsRes> {
    return this.actionListener('getProductionReportsVersions').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getProductionReportsVersions,
          params,
        ) as Observable<ProductionReportsVersionsQuery>;
      }),
      map((data: ProductionReportsVersionsQuery) => {
        return data.wawiAssist?.listWA_ProductionReportsVersions as ProductionReportsVersionsRes;
      }),
    );
  }
  createProductionReportsVersion(): Observable<CreateProductionReportsVersionRes> {
    return this.actionListener('createProductionReportsVersion').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          createProductionReportsVersion,
          params,
        ) as Observable<CreateProductionReportsVersionMutation>;
      }),
      map((data: CreateProductionReportsVersionMutation) => {
        return data.wawiAssist
          ?.createWA_ProductionReportsVersion as CreateProductionReportsVersionRes;
      }),
    );
  }
  deleteProductionReportsVersion(): Observable<DeleteProductionReportsVersionRes> {
    return this.actionListener('deleteProductionReportsVersion').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          deleteProductionReportsVersion,
          params,
        ) as Observable<DeleteProductionReportsVersionMutation>;
      }),
      map((data: DeleteProductionReportsVersionMutation) => {
        return data.wawiAssist
          ?.deleteWA_ProductionReportsVersion as DeleteProductionReportsVersionRes;
      }),
    );
  }
  getDoughsSplittingInformation(): Observable<DoughsSplittingInformationRes> {
    return this.actionListener('getDoughsSplittingInformation').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getDoughsSplittingInformation,
          params,
        ) as Observable<GetDoughsSplittingInformationQuery>;
      }),
      map((data: GetDoughsSplittingInformationQuery) => {
        return data.wawiAssist?.listWA_DoughsSplittingInfoOnDate as DoughsSplittingInformationRes;
      }),
    );
  }
  saveDoughSplittingInformation(): Observable<SaveDoughSplittingInformationRes> {
    return this.actionListener('saveDoughSplittingInformation').pipe(
      mergeMap(({ params }) => {
        return gqlClient(
          saveDoughSplittingInformation,
          params,
        ) as Observable<SaveDoughSplittingInformationMutation>;
      }),
      map((data: SaveDoughSplittingInformationMutation) => {
        return data.wawiAssist?.saveWA_DoughSplittingInfoOnDate as SaveDoughSplittingInformationRes;
      }),
    );
  }
  deleteDoughSplittingInformation(): Observable<DeleteDoughSplittingInformationRes> {
    return this.actionListener('deleteDoughSplittingInformation').pipe(
      mergeMap(({ params }) => {
        return gqlClient(
          deleteDoughSplittingInformation,
          params,
        ) as Observable<DeleteDoughSplittingInformationMutation>;
      }),
      map((data: DeleteDoughSplittingInformationMutation) => {
        return data.wawiAssist
          ?.deleteWA_DoughSplittingInfoOnDate as DeleteDoughSplittingInformationRes;
      }),
    );
  }
}

class PrintJobsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const printJobsService = new PrintJobsService();

type FullPrintJobsDataParams = {
  selectedTab: IPrintJobsDetailsState['selectedTab'];
  datePeriod: IPrintJobsDetailsState['datePeriod'];
  filter?: ProductionPrintJobsQueryVariables['filter'];
};
export type PrintJobsProductionTabRes = Pick<
  IProductionTabDataRes,
  'productionList' | 'countOrders' | 'countOrderPreProduction'
>;
export type PrintDeliveryNotesTabRes = Pick<IDeliveryNotesTabDataRes, 'printDeliveryNotesList'>;

type GQLQuery<T = any> = Observable<T>;

export type FullPrintJobsDataRes =
  | {
      dataTab: PrintJobsProductionTabRes | PrintDeliveryNotesTabRes;
      statusList: StatusBakery2bCAdirectRes;
    }
  | Record<string, any>;

export type StatusBakery2bCAdirectRes = {
  bakery2b: GetIncomingOrdersStatusRes;
  CAdirect: GetIncomingOrdersStatusRes;
};

export type ProductionListRes = NonNullable<
  NonNullable<ProductionPrintJobsQuery['wawiAssist']>['listWA_ProductionPrintJobs']
>;

export type CountOrdersRes = NonNullable<
  NonNullable<PrintJobsOrdersCountQuery['wawiAssist']>['getWA_OrdersCountForPrintJobs']
>;

export type CountOrderPreProductionRes = NonNullable<
  NonNullable<
    PrintJobsPreProductionOrdersCountQuery['wawiAssist']
  >['getWA_PreProductionOrdersCountForPrintJobs']
>;

export type ProductionPrintDeliveryNotesRes = NonNullable<
  NonNullable<ProductionDeliveryNotesQuery['wawiAssist']>['listWA_ProductionDeliveryNotes']
>;

export type GetIncomingOrdersStatusRes = NonNullable<
  NonNullable<IncomingOrdersStatusQuery['wawiAssist']>['getWA_IncomingOrdersAcceptanceStatus']
>;

export type ChangeIncomingOrdersStatusRes = NonNullable<
  NonNullable<IncomingOrdersStatusMutation['wawiAssist']>['saveIncomingOrdersAcceptanceStatus']
>;

export type ProductionReportsVersionsRes = NonNullable<
  NonNullable<ProductionReportsVersionsQuery>['wawiAssist']
>['listWA_ProductionReportsVersions'];

export type DoughsSplittingInformationRes = NonNullable<
  NonNullable<GetDoughsSplittingInformationQuery>['wawiAssist']
>['listWA_DoughsSplittingInfoOnDate'];

export type CreateProductionReportsVersionRes = NonNullable<
  NonNullable<CreateProductionReportsVersionMutation>['wawiAssist']
>['createWA_ProductionReportsVersion'];

export type SaveDoughSplittingInformationRes = NonNullable<
  NonNullable<SaveDoughSplittingInformationMutation>['wawiAssist']
>['saveWA_DoughSplittingInfoOnDate'];

export type DeleteDoughSplittingInformationRes = NonNullable<
  NonNullable<DeleteDoughSplittingInformationMutation>['wawiAssist']
>['deleteWA_DoughSplittingInfoOnDate'];

export type DeleteProductionReportsVersionRes = NonNullable<
  NonNullable<DeleteProductionReportsVersionMutation>['wawiAssist']
>['deleteWA_ProductionReportsVersion'];

export type ProducedArticlesRes = NonNullable<
  NonNullable<GetProducedArticlesQuantityQuery['wawiAssist']>['listWA_ProducedArticlesQuantity']
>;

export type SaveProducedArticlesQuantityRes = NonNullable<
  NonNullable<SaveProducedArticlesQuantityMutation>['wawiAssist']
>['saveWA_ProducedArticlesQuantity'];
