import { FC, useEffect, useMemo, useState } from 'react';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component.tsx';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Content as Wrapper } from '../../../../../../../shared/components/content/content.component.tsx';
import { Subscription, take } from 'rxjs';

import {
  dictionaryAdditionalService,
  DictSingleBankRes,
} from '../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service.ts';
import {
  FieldsSection,
  FormSelect,
  FormTextField,
} from '../../../../../../../shared/components/form';
import { C_Booking_Type } from '../../../../../../../graphql/generatedModel.ts';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema } from './validation/bankSettings.schema.ts';
import { dictionaryState } from '../../../../../../../shared/components/dictionary/states/dictionary.state.ts';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler.ts';

const validationSchema = createValidationSchema();

export const ContentPopup: FC<IContent> = ({ stream }) => {
  const formMethods = useForm<DictSingleBankRes>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });
  const {
    formState: { errors, isValid, isDirty },
  } = formMethods;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let singleBankSub: Subscription;
    const openSub = stream
      .actionListener('open')
      .pipe(take(1))
      .subscribe((v) => {
        const id = v?.id;
        if (id) {
          singleBankSub = dictionaryAdditionalService.sub
            .getDictSingleBank()
            .pipe(
              responseHandler<DictSingleBankRes>({
                errorReturnType: {
                  id: 'errorId',
                  flagBookingAfter: C_Booking_Type.BO1_BOOKING_DATE_CREDIT,
                },
              }),
            )
            .subscribe((data) => {
              formMethods.reset(data);
              setLoading(false);
            });
          dictionaryAdditionalService.pub.getDictSingleBank({ id });
        }
      });
    return () => {
      openSub.unsubscribe();
      singleBankSub && singleBankSub?.unsubscribe();
    };
  }, []);

  const recipeBookOptions = useMemo(
    () => [
      {
        id: C_Booking_Type.BO1_BOOKING_DATE_CREDIT,
        label: t(`enums.${C_Booking_Type.BO1_BOOKING_DATE_CREDIT}`),
      },
      {
        id: C_Booking_Type.BO2_BOOKING_PROCESSING_DATE,
        label: t(`enums.${C_Booking_Type.BO2_BOOKING_PROCESSING_DATE}`),
      },
    ],
    [t],
  );

  const handleConfirm = (formData: DictSingleBankRes) => {
    const { internalNameOrName: _, ...rest } = formData;
    if (isDirty && isValid) {
      dictionaryState.pub.updateCell(
        {
          id: formData.id,
          field: 'virtualField',
          value: rest,
        },
        true,
      );
    }

    stream.emit('close');
  };

  useBeforeClosePopup({
    stream,
    isDirty,
  });

  return (
    <Wrapper type='box' {...{ loading }}>
      <FormProvider {...formMethods}>
        <form
          style={{ opacity: loading ? '0' : '1', transition: '0.3s' }}
          noValidate
          onSubmit={formMethods.handleSubmit(handleConfirm)}
        >
          <Stack direction='row' justifyContent='space-around'>
            <FieldsSection nomt title={t('settings.section_bank')}>
              <Stack direction='column' spacing={3} minWidth='350px'>
                <FormTextField
                  inputProps={{ maxLength: 40 }}
                  name='internalName'
                  label={t('settings.internal_bank_description')}
                />
                <FormTextField
                  inputProps={{ maxLength: 40 }}
                  name='name'
                  label={t('common.name')}
                  required
                  {...(errors.name && {
                    error: true,
                    helperText: t(errors['name'].message as string),
                  })}
                />
                <FormTextField name='bankLocation' label={t('settings.zip_code')} />
                <FormTextField
                  inputProps={{ maxLength: 16 }}
                  name='pcAccount'
                  label={t('settings.subscriber_num')}
                  required
                  {...(errors.pcAccount && {
                    error: true,
                    helperText: t(errors['pcAccount'].message as string),
                  })}
                />
                <FormTextField
                  inputProps={{ maxLength: 21 }}
                  name='qrIBAN'
                  label={t('settings.qr_iban')}
                  {...(errors.qrIBAN && {
                    error: true,
                    helperText: t(errors['qrIBAN'].message as string),
                  })}
                />
                <FormTextField
                  inputProps={{ maxLength: 32 }}
                  name='bankBIC'
                  label={t('settings.company.bic.label')}
                  {...(errors.bankBIC && {
                    error: true,
                    helperText: t(errors['bankBIC'].message as string),
                  })}
                />
                <FormTextField
                  inputProps={{ maxLength: 16 }}
                  name='memberNo'
                  label={t('settings.customer_identification')}
                  {...(errors.memberNo && {
                    error: true,
                    helperText: t(errors['memberNo'].message as string),
                  })}
                />
                <FormTextField
                  inputProps={{ maxLength: 10 }}
                  name='fibuAccount'
                  label={t('settings.financial_accounting')}
                />
              </Stack>
            </FieldsSection>
            <FieldsSection nomt title={t('settings.section_customer')}>
              <Stack direction='column' spacing={3} minWidth='300px'>
                <FormTextField
                  inputProps={{ maxLength: 40 }}
                  name='customerName1'
                  label={t('settings.first_name')}
                />
                <FormTextField
                  inputProps={{ maxLength: 40 }}
                  name='customerName2'
                  label={t('settings.second_name')}
                />
                <FormTextField
                  inputProps={{ maxLength: 40 }}
                  name='customerStreet'
                  label={t('settings.street')}
                />
                <FormTextField
                  inputProps={{ maxLength: 40 }}
                  name='customerLocation'
                  label={t('settings.zip_code')}
                />
                <FormSelect
                  name='flagBookingAfter'
                  options={recipeBookOptions}
                  label={t('settings.recipe_book')}
                  disableClearable
                />
              </Stack>
            </FieldsSection>
          </Stack>
          <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
            <Button type='submit' variant='contained'>
              {t('common.save')}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Wrapper>
  );
};

interface IContent {
  stream: IPopupWrapper['stream'];
}
