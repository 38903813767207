import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { BehaviorSubject, map, merge, Observable, share, switchMap, tap } from 'rxjs';

import { transitionService } from '../../../shared/components/loading/service/transition.service.ts';
import { dataHelper } from '../../../shared/helpers/data/data.helper.ts';
import { storageHelper } from '../../../shared/helpers/storage';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import {
  DictArticleListsRes,
  DictAssortmentGroupsRes,
  DictCashAssistProductGroupsRes,
  DictCashAssistTemplatesRes,
  DictDiscountGroupsRes,
  DictMarketingGroupsRes,
  DictProductionGroupsRes,
  DictShippingPointsRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { navigateService } from '../../../shared/services/navigate/navigate.service.ts';
import { Pub, State, Sub } from '../../../shared/state/state.abstract.ts';
import { articleTabLoadingService } from '../components/tabs/articleTababLoading.service.ts';
import { articleService, ArticlesListRes } from '../services/article.service.ts';
import { ArticleTabs } from './tabState.model.ts';

export const initArticleListState: IArticleListState = {
  action: undefined,
  articleList: [],
  selectedArticle: undefined,
  search: '',
  sortModel: [{ field: 'articleNo', sort: 'asc' }],
  filter: {
    isActive: true,
  },
  filterOptionData: {
    articleLists: [],
    assortmentGroups: [],
    cashAssistProductGroups: [],
    cashAssistTemplates: [],
    discountGroups: [],
    marketingGroups: [],
    productionGroups: [],
    shippingPoints: [],
  },
  allArticleListLength: 0,
  articleTitle: '',
  clearCacheFlag: false,
  params: {},
};

const tabsDisabledByIsDough = [
  ArticleTabs.availability,
  ArticleTabs.cashAssist,
  ArticleTabs.label,
  ArticleTabs.defaultOrder,
];

class PubImpl extends Pub<IArticleListState> {
  init(params: Partial<IArticleListState>) {
    this.emit('init', params);
  }
  search(search: string) {
    this.emit('search', { search });
  }
  sort(sortModel: IArticleListState['sortModel']) {
    this.emit('sort', { sortModel });
    storageHelper.local.setItem('article.articlesListSort', sortModel);
  }
  selectArticle(selectedArticle: ArticlesListRes[number]) {
    this.emit('selectArticle', { selectedArticle });
    storageHelper.session.setItem('article.selectedArticle', selectedArticle);
  }
  refreshArticleData(): void {
    this.emit('refreshArticleData', {});
  }
  selectDoughArticle(selectedArticle: ArticlesListRes[number]) {
    storageHelper.session.setItem('article.selectedArticle', selectedArticle);
    this.emit('selectDoughArticle', { selectedArticle });
  }
  unselectArticle() {
    this.emit('unselectArticle', { selectedArticle: undefined });
  }
  articleList() {
    const { filter } = this.stream$.getValue();
    this.emit('articleList', { filter, params: { cache: false } });
  }
  filter(filter: IArticleListState['filter']) {
    this.emit('filter', { filter });
  }
  addArticleToList(record: NonNullable<IArticleListState['selectedArticle']>) {
    const streamV = this.stream$.getValue();
    this.stream$.next({ ...streamV, articleList: [...streamV.articleList, record] });
    resetArticleDeps();
    this.selectArticle(record);
  }
  updateArticle(article: ArticlesListRes[number]) {
    this.emit('updateArticle', { params: article });
  }
  clearCacheFlag(clearCacheFlag: boolean) {
    this.emit('updateArticle', { clearCacheFlag });
  }
}

class SubImpl extends Sub<IArticleListState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());

  shareDataForTabLoader(): IArticleListState {
    return this.stream$.getValue();
  }

  protected actionHandlers(): Observable<IArticleListState> {
    return merge(
      this.articleList(),
      this.updateArticleList(),
      this.updateArticle(),
      this.selectDoughArticle(),
    ).pipe(
      map(
        ({
          search,
          articleList,
          allArticleListLength,
          sortModel,
          selectedArticle,
          filterOptionData,
          ...rest
        }) => {
          /*** Prevent routing when the user manually changes the URL to tabs blocked by isDough value ***/
          const currentPath = window.location.pathname;
          if (selectedArticle?.isDough && rest.action === 'init') {
            for (const tab of tabsDisabledByIsDough) {
              if (currentPath === `/article/${tab}`) {
                navigateService.pub.navigateTo('/article/general');
              }
            }
          }
          /*** */
          let sortedList = [...(articleList || [])];
          allArticleListLength = articleList?.length || 0;

          articleList = dataHelper
            .data(articleList as [])
            .sort({
              sortModel,
              callback: (sorted) => (sortedList = sorted as []),
            })
            .search({
              search,
              fields: ['articleNo', 'description'],
            })
            .result() as ArticlesListRes;
          if (rest.action === 'articleList' || rest.action === 'filter') {
            const isSelectedArticleInList = sortedList.some(
              (item) => item?.id === selectedArticle?.id,
            );
            if (!isSelectedArticleInList) {
              selectedArticle = sortedList[0] || null;
              articleListState.pub.selectArticle(selectedArticle);
              storageHelper.session.setItem('article.selectedArticle', selectedArticle);
            }
          }
          const newState = {
            ...rest,
            search,
            articleList,
            sortModel,
            allArticleListLength,
            selectedArticle,
            filterOptionData,
          };
          this.stream$.next({ ...newState, articleList: sortedList, action: 'internalUpdate' });
          return newState;
        },
      ),
      tap(() => this.loading$.next(false)),
      this.articleTitlePipe,
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  private updateArticleList(): Observable<IArticleListState> {
    return this.actionListener([
      'init',
      'search',
      'sort',
      'unselectArticle',
      'selectArticle',
      'refreshArticleData',
    ]);
  }
  private selectDoughArticle(): Observable<IArticleListState> {
    return this.actionListener('selectDoughArticle').pipe(
      map((state) => {
        transitionService.pub.off();
        articleTabLoadingService.pub.loading(true);
        navigateService.pub.navigateTo('/article/general');
        return state;
      }),
    );
  }
  private articleList(): Observable<IArticleListState> {
    return this.actionListener(['articleList', 'filter']).pipe(
      tap(() => this.loading$.next(true)),
      tap(({ filter, params: { cache } }) => articleService.pub.articlesList({ filter, cache })),
      switchMap((state) => {
        return articleService.sub.articleList().pipe(
          responseHandler<ArticlesListRes>({
            customErrorHandler() {
              return 'article.article_list_not_loaded';
            },
            errorReturnType: [],
          }),
          map((articleList) => {
            this.stream$.getValue().articleList = [...articleList];
            return { ...state, articleList };
          }),
        );
      }),
    );
  }

  private updateArticle(): Observable<IArticleListState> {
    return this.actionListener('updateArticle').pipe(
      map((state) => {
        const { params } = state;
        if (params) {
          const itemIdx = state.articleList.findIndex((item) => item.id === params.id);
          if (itemIdx !== -1) {
            const temp = [...state.articleList];
            temp.splice(itemIdx, 1, params);
            storageHelper.memory.setItem('article.list', temp);
            state.articleList = temp;
          }
        }

        return state;
      }),
    );
  }

  private articleTitlePipe(src: Observable<IArticleListState>) {
    return src.pipe(
      map<IArticleListState, IArticleListState>((state) => {
        const { action, params, selectedArticle } = state;
        const {
          articleNo: paramArticleNo,
          description: paramDescription,
          id: paramId,
        } = params || {};
        const {
          articleNo: selectedArticleNo,
          description: selectedDescription,
          id: selectedId,
        } = selectedArticle || {};

        const articleNo =
          action === 'updateArticle' &&
          paramArticleNo !== selectedArticleNo &&
          paramId === selectedId
            ? paramArticleNo
            : selectedArticleNo;

        const description =
          action === 'updateArticle' &&
          paramDescription !== selectedDescription &&
          paramId === selectedId
            ? paramDescription
            : selectedDescription;

        state.articleTitle =
          articleNo && description ? `${articleNo} ${description}` : articleNo || description || '';
        return state;
      }),
    );
  }
}

class ArticleListState extends State<IArticleListState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const articleListState = new ArticleListState(initArticleListState);

export const resetArticleDeps = (resetOnlyDictArticles = false) => {
  const { memory } = storageHelper;
  memory.removeItem('configsData.dictArticles');
  if (!resetOnlyDictArticles) {
    memory.removeItem('configsData.articlesForOrderPositionList');
  }
  articleListState.pub.clearCacheFlag(true);
};

export interface IArticleListState {
  action:
    | 'init'
    | 'search'
    | 'sort'
    | 'selectArticle'
    | 'selectDoughArticle'
    | 'articleList'
    | 'filter'
    | 'loader'
    | 'updateArticle'
    | 'unselectArticle'
    | 'refreshArticleData'
    | 'internalUpdate'
    | undefined;
  articleList: ArticlesListRes;
  selectedArticle?: ArticlesListRes[number];
  search: string;
  filter: IFilter;
  filterOptionData: ArticleFilterDataRes;
  allArticleListLength: number;
  articleTitle: string;
  sortModel: GridSortModel;
  clearCacheFlag: boolean;
  params?: any;
}

export interface IFilter {
  isActive?: boolean;
  discountGroupId?: string;
  articleListId?: string;
  assortimentGroupId?: string;
  marketingGroupId?: string;
  productionGroupId?: string;
  cashAssistTemplateId?: string;
  cashAssistArticleGroupId?: string;
  shippingPointId?: string;
}

export type ArticleFilterDataRes = {
  articleLists: DictArticleListsRes;
  discountGroups: DictDiscountGroupsRes;
  productionGroups: DictProductionGroupsRes;
  marketingGroups: DictMarketingGroupsRes;
  shippingPoints: DictShippingPointsRes;
  assortmentGroups: DictAssortmentGroupsRes;
  cashAssistTemplates: DictCashAssistTemplatesRes;
  cashAssistProductGroups: DictCashAssistProductGroupsRes;
};
