import {
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  repeat,
  share,
  switchMap,
  take,
  takeWhile,
  tap,
  zip,
} from 'rxjs';

import { format } from 'date-fns';
import equal from 'fast-deep-equal/react';
import {
  ListWa_ArticlesQuery as ArticlesQuery,
  ListWa_ArticlesQueryVariables as ArticlesQueryVariables,
  C_Win_Report_Task_Status,
  GetArticleProductionCalculationDependenciesQueryVariables as CalculationDependenciesVariables,
  GetArticleCashAssitTabDataQuery as CashAssistTabQuery,
  GetArticleCashAssitTabDataQueryVariables as CashAssistTabVariables,
  CopyArticleMutation,
  CopyArticleMutationVariables,
  Create_R36_ArticleListAllPriceCategoriesReportMutation,
  Create_R36_ArticleListAllPriceCategoriesReportMutationVariables,
  Create_R50_ArticleListWithImageReportMutation,
  Create_R50_ArticleListWithImageReportMutationVariables,
  Create_R6_ArticleListReportMutation,
  Create_R6_ArticleListReportMutationVariables,
  CreateArticleMutation,
  CreateArticleSpecialPricesMutation,
  CreateArticleSpecialPricesMutationVariables,
  CustomersListQuery,
  CustomersListQueryVariables,
  GetArticleGeneralTabDataQuery as GeneralTabQuery,
  GetArticleGeneralTabDataQueryVariables as GeneralTabVariables,
  GetArticleAvailabilityTabDataQuery,
  GetArticleAvailabilityTabDataQueryVariables,
  GetArticleLabelDataFromRaQuery,
  GetArticleLabelDataFromRaQueryVariables,
  GetArticleLabelTabDataQuery,
  GetArticleLabelTabDataQueryVariables,
  GetArticlePricesByCustomerQuery,
  GetArticlePricesByCustomerQueryVariables,
  GetArticleProductionCalculationDependenciesQuery,
  GetArticleSpecialPricesQuery,
  GetArticleSpecialPricesQueryVariables,
  GetDataForNewDefaultOrderPositionByArticleQuery,
  GetDataForNewDefaultOrderPositionByArticleQueryVariables as GetDataForNewDefaultOrderPositionByArticleVariables,
  GetDefaultOrderTabDataQuery,
  GetDefaultOrderTabDataQueryVariables,
  GetDeliverySplittingPartsCalculationQuery,
  GetTasksStatusQuery,
  GetTasksStatusQueryVariables,
  GetWa_ArticleDoughDataFromRaQuery,
  GetWa_ArticleDoughDataFromRaQueryVariables,
  GetWeeklyDefaultOrderByArticleFromDateQuery,
  GetWeeklyDefaultOrderByArticleFromDateQueryVariables,
  ListArticlesWithSpecialOrRegularPricesQuery,
  ListArticlesWithSpecialOrRegularPricesQueryVariables,
  GetArticleListsTabDataQuery as ListsTabQuery,
  GetArticleListsTabDataQueryVariables as ListsTabVariables,
  GetArticlePostTabDataQuery as PostTabQuery,
  GetArticlePostTabDataQueryVariables as PostTabVariables,
  GetArticleProductionTabDataQuery as ProductionTabQuery,
  SaveArticleDataMutation,
  SaveArticleDataMutationVariables,
  SaveArticleProductionCalculationMutation,
  SaveArticleProductionCalculationMutationVariables,
  ValidateWa_ArticleFieldsQuery as ValidateArticleFieldsQuery,
  ValidateWa_ArticleFieldsQueryVariables as ValidateArticleFieldsVariables,
} from '../../../graphql/generatedModel.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { ISelectOption } from '../../../shared/components/form/fields/select.component.tsx';
import { storageHelper } from '../../../shared/helpers/storage';
import {
  companyConfigService,
  TCompanyConfigs,
} from '../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  configsData,
  DictArticlesRes,
  DictCashAssistTemplatesRes,
  DictCustomersRes,
  DictDiscountGroupsRes,
  DictMarketingGroupsRes,
  DictProductionGroupsRes,
  DictRecipesWithConnectedArticlesRes,
  DictShippingPointsRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { modeService } from '../../../shared/services/mode/mode.service.ts';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract.ts';
import {
  GetDeliverySplittingPartsCalculationQueryVariablesExtended,
  GetDeliverySplittingPartsCalculationRes,
} from '../../customer/services/customer.service.ts';
import { getDeliverySplittingPartsCalculation } from '../../customer/services/gql/customer.gql.ts';
import { getTasksStatus } from '../../production/services/gql/winReports.gql.ts';
import { IArticleReport } from '../components/popups/report/content.popup.tsx';
import { articleListState, IArticleListState } from '../states/articleList.state.ts';
import {
  articleReportAllPriceCategoriesPreview,
  articleReportPreview,
  articleReportWithImagePreview,
  copyArticle,
  createArticle,
  createArticleSpecialPrices,
  customersList,
  getArticleAvailabilityTabData,
  getArticleCashAssitTabData,
  getArticleDoughDataFromRA,
  getArticleGeneralTabData,
  getArticleLabelDataFromRA,
  getArticleLabelTabData,
  getArticleListsTabData,
  getArticlePostTabData,
  getArticlePricesByCustomer,
  getArticleProductionCalculationDependencies,
  getArticleProductionTabData,
  getArticlesList,
  getArticleSpecialPrices,
  getDataForNewDefaultOrderPositionByArticle,
  getDefaultOrderTabData,
  getWeeklyDefaultOrderByArticleFromDate,
  listArticlesWithSpecialOrRegularPrices,
  saveArticleData,
  saveArticleProductionCalculation,
  validateArticleFieldsOnServer,
} from './gql/article.gql.ts';

type Action =
  | undefined
  | 'articlesList'
  | 'articleGeneralTabData'
  | 'generalTabOptions'
  | 'articleListsTabData'
  | 'articleFilterData'
  | 'filterArticleLists'
  | 'createArticle'
  | 'articleAvailabilityTabData'
  | 'copyArticle'
  | 'editArticle'
  | 'discountGroups'
  | 'validateArticleFieldsOnServer'
  | 'validateProductionRecipeIdOnServer'
  | 'articleProductionTabData'
  | 'articleLabelTabData'
  | 'articleLabelDataFromRa'
  | 'articleCashAssistTabData'
  | 'articlePostTabData'
  | 'defaultOrderTabData'
  | 'getWeeklyDefaultOrderByArticleFromDate'
  | 'articleDoughDataFromRA'
  | 'getArticleProductionCalculationDependencies'
  | 'getArticleSpecialPrices'
  | 'getArticlePricesByCustomer'
  | 'saveArticleProductionCalculation'
  | 'getDataForNewDefaultOrderPositionByArticle'
  | 'getDeliverySplittingPartsCalculation'
  | 'listArticlesWithSpecialOrRegularPrices'
  | 'createArticleSpecialPrices'
  | 'customersList'
  | 'reportPreview';

class PubImpl extends Pub<Action> {
  articlesList(params?: ArticlesQueryVariables & { cache: boolean }): void {
    this.emit('articlesList', params);
  }
  articleGeneralTabData(params: GeneralTabVariables): void {
    this.emit('articleGeneralTabData', params);
  }
  articleListsTabData(params: ListsTabVariables): void {
    this.emit('articleListsTabData', params);
  }
  articleCashAssistTabData(params: CashAssistTabVariables): void {
    this.emit('articleCashAssistTabData', params);
  }
  articleProductionTabData(params: GeneralTabVariables): void {
    this.emit('articleProductionTabData', params);
  }
  articlePostTabData(params: PostTabVariables): void {
    this.emit('articlePostTabData', params);
  }
  defaultOrderTabData(params: GetDefaultOrderTabDataQueryVariables): void {
    this.emit('defaultOrderTabData', params);
  }
  getWeeklyDefaultOrderByArticleFromDate(
    params: GetWeeklyDefaultOrderByArticleFromDateQueryVariables,
  ): void {
    this.emit('getWeeklyDefaultOrderByArticleFromDate', params);
  }
  getDataForNewDefaultOrderPositionByArticle(
    params: GetDataForNewDefaultOrderPositionByArticleVariables,
  ): void {
    this.emit('getDataForNewDefaultOrderPositionByArticle', params);
  }
  getDeliverySplittingPartsCalculation(
    params: GetDeliverySplittingPartsCalculationQueryVariablesExtended,
  ) {
    this.emit('getDeliverySplittingPartsCalculation', params);
  }
  articleLabelTabData(params: GetArticleLabelTabDataQueryVariables): void {
    this.emit('articleLabelTabData', params);
  }
  articleLabelDataFromRa(params: GetArticleLabelDataFromRaQueryVariables): void {
    this.emit('articleLabelDataFromRa', params);
  }
  generalTabOptions(): void {
    this.emit('generalTabOptions');
  }
  createArticle(params: ArticleGeneralTabDataRes): void {
    this.emit('createArticle', params);
  }
  copyArticle(params: CopyArticleMutationVariables) {
    this.emit('copyArticle', params);
  }
  editArticle(params: SaveArticleDataMutationVariables): void {
    this.emit('editArticle', params);
  }
  saveArticleProductionCalculation(
    params: SaveArticleProductionCalculationMutationVariables,
  ): void {
    this.emit('saveArticleProductionCalculation', params);
  }
  articleAvailabilityTabData(params: GetArticleAvailabilityTabDataQueryVariables): void {
    this.emit('articleAvailabilityTabData', params);
  }
  validateArticleFieldsOnServer(
    params: ValidateArticleFieldsVariables & {
      context: any;
    },
  ): void {
    this.emit('validateArticleFieldsOnServer', params);
  }
  validateProductionRecipeIdOnServer(
    params: ValidateArticleFieldsVariables & { context: any },
  ): void {
    this.emit('validateProductionRecipeIdOnServer', params);
  }
  articleDoughDataFromRA(params: GetWa_ArticleDoughDataFromRaQueryVariables): void {
    this.emit('articleDoughDataFromRA', params);
  }
  getArticleProductionCalculationDependencies(params: CalculationDependenciesVariables): void {
    this.emit('getArticleProductionCalculationDependencies', params);
  }
  getArticleSpecialPrices(params: GetArticleSpecialPricesQueryVariables): void {
    this.emit('getArticleSpecialPrices', params);
  }
  getArticlePricesByCustomer(params: GetArticlePricesByCustomerQueryVariables): void {
    this.emit('getArticlePricesByCustomer', params);
  }
  listArticlesWithSpecialOrRegularPrices(
    params: ListArticlesWithSpecialOrRegularPricesQueryVariables,
  ): void {
    this.emit('listArticlesWithSpecialOrRegularPrices', params);
  }
  createArticleSpecialPrices(params: CreateArticleSpecialPricesMutationVariables): void {
    this.emit('createArticleSpecialPrices', params);
  }
  customersList(): void {
    this.emit('customersList');
  }
  reportPreview(params: IArticleReport): void {
    this.emit('reportPreview', params);
  }
}
class SubImpl extends Sub<Action> {
  private _validateArticleNo = () => {
    return this.actionListener('validateArticleFieldsOnServer').pipe(
      distinctUntilChanged((prev, curr) => {
        const prevValue = prev.params.fieldForCheck.articleNo;
        const currValue = curr.params.fieldForCheck.articleNo;
        return prevValue === currValue;
      }),
      debounceTime(700),
      tap(({ params: { context } }) => context.setLoading(true)),
      switchMap(
        ({ params: { context, ...variables } }) =>
          gqlClient(validateArticleFieldsOnServer, variables).pipe(
            tap(() => context.setLoading(false)) as any,
          ) as Observable<ValidateArticleFieldsQuery>,
      ),
      map((v) => v.wawiAssist?.validateWA_ArticleFields as ValidateArticleFieldsRes),
      share(),
    );
  };
  private _validateProductionRecipeId = () => {
    return this.actionListener('validateProductionRecipeIdOnServer').pipe(
      tap(({ params: { context } }) => context.setLoading(true)),
      switchMap(({ params: { context, ...rest } }) => {
        if (rest.fieldForCheck.productionRecipeId) {
          return gqlClient(validateArticleFieldsOnServer, rest).pipe(
            tap(() => {
              context.setLoading(false);
            }) as any,
            map((data: ValidateArticleFieldsQuery) => {
              return data.wawiAssist?.validateWA_ArticleFields as ValidateArticleFieldsRes;
            }),
          ) as Observable<ValidateArticleFieldsQuery>;
        }
        context.setLoading(false);
        return of({ productionRecipeId: true });
      }),
      share(),
    );
  };
  private validateArticleNo$ = this._validateArticleNo();
  private validateProductionRecipeId$ = this._validateProductionRecipeId();

  reportPreview(): Observable<
    ReportPreviewRes | ReportAllPriceCategoriesPreviewRes | ReportWithImagePreviewRes
  > {
    return this.actionListener('reportPreview').pipe(
      switchMap(({ params }: { params: IArticleReport }) => {
        switch (params.reportId) {
          case 'R6_ARTICLE_LIST': {
            const { articleList, priceValidOn, priceMode, customerId, priceCategory } = params;
            const variables: Create_R6_ArticleListReportMutationVariables = {
              params: {
                articleList,
                priceValidOn: format(new Date(priceValidOn), 'yyyy-MM-dd'),
              },
            };

            if (priceMode === 'byCustomer') {
              variables.params.customerId = customerId;
            }
            if (priceMode === 'byPriceCategory') {
              variables.params.priceCategory = priceCategory;
            }
            return gqlClient(articleReportPreview, variables).pipe(
              map((res: Create_R6_ArticleListReportMutation) => {
                return res.wawiAssist?.create_r6_ArticleListReport as ReportPreviewRes;
              }),
            );
          }

          case 'R50_ARTICLE_LIST_WITH_IMAGES': {
            const { articleList, priceValidOn, priceMode, customerId, priceCategory } = params;
            const variables: Create_R50_ArticleListWithImageReportMutationVariables = {
              params: {
                articleList,
                priceValidOn: format(new Date(priceValidOn), 'yyyy-MM-dd'),
              },
            };

            if (priceMode === 'byCustomer') {
              variables.params.customerId = customerId;
            }
            if (priceMode === 'byPriceCategory') {
              variables.params.priceCategory = priceCategory;
            }
            return gqlClient(articleReportWithImagePreview, variables).pipe(
              map((res: Create_R50_ArticleListWithImageReportMutation) => {
                return res.wawiAssist
                  ?.create_r50_ArticleListWithImageReport as ReportWithImagePreviewRes;
              }),
            );
          }

          case 'R36_ARTICLE_LIST_ALL_PRICE_CATEGORIES': {
            const { articleList, priceValidOn } = params;
            const variables: Create_R36_ArticleListAllPriceCategoriesReportMutationVariables = {
              params: {
                articleList,
                priceValidOn: format(new Date(priceValidOn), 'yyyy-MM-dd'),
              },
            };
            return gqlClient(articleReportAllPriceCategoriesPreview, variables).pipe(
              map((res: Create_R36_ArticleListAllPriceCategoriesReportMutation) => {
                return res.wawiAssist
                  ?.create_r36_ArticleListAllPriceCategoriesReport as ReportAllPriceCategoriesPreviewRes;
              }),
            );
          }
          default:
            return EMPTY;
        }
      }),
      switchMap((reportTask) => {
        const { taskId } = reportTask;
        const variables: GetTasksStatusQueryVariables = { tasksId: [taskId] };
        return gqlClient(getTasksStatus, variables).pipe(
          map((res: GetTasksStatusQuery) => {
            return res.wawiAssist?.getWinReportTasksStatuses;
          }),
          repeat({ count: 20, delay: 2000 }),
          takeWhile((report) => {
            return report?.every((item) => {
              return (
                item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
                item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED
              );
            }) as boolean;
          }, true),
          filter((report) => {
            const [item] = report!;
            return (
              item.status === C_Win_Report_Task_Status.WRTS2_READY ||
              item.status === C_Win_Report_Task_Status.WRTS15_NO_DATA_FOR_REPORT
            );
          }),
          map((report) => {
            const [item] = report!;
            reportTask.status = item.status;
            return reportTask;
          }),
        );
      }),
    );
  }

  customersList(): Observable<ISelectOption[]> {
    return this.actionListener('customersList').pipe(
      switchMap(() => {
        const list = storageHelper.memory.getItem('article.customersList');
        const variables: CustomersListQueryVariables = { filter: { isActive: true } };
        if (list) {
          return of(list);
        }
        return gqlClient(customersList, { ...variables }).pipe(
          map<CustomersListQuery, ISelectOption[]>((res) => {
            const values = res.wawiAssist?.listWA_Customers;
            const list = values?.map(({ id, customerNo, internalOrFullName }) => ({
              id: id,
              label: internalOrFullName,
              customerNo,
            }));
            storageHelper.memory.setItem('article.customersList', list);
            return list as ISelectOption[];
          }),
        );
      }),
    );
  }

  articleList(): Observable<ArticlesListRes> {
    return this.actionListener('articlesList').pipe(
      switchMap(({ params: { filter, cache } }) => {
        const savedFilter = storageHelper.memory.getItem('article.filter');
        if (cache && equal(filter, savedFilter)) {
          const list = storageHelper.memory.getItem('article.list');
          if (list) {
            return of(list);
          }
        }

        return gqlClient(getArticlesList, { filter }).pipe(
          map((data) => {
            const list = data.wawiAssist?.listWA_Articles;
            storageHelper.memory.setItem('article.filter', filter);
            storageHelper.memory.setItem('article.list', list);
            return list;
          }),
        ) as Observable<ArticlesQuery>;
      }),
    );
  }
  articleGeneralTabData(): Observable<ArticleGeneralTabDataRes> {
    return this.actionListener('articleGeneralTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleGeneralTabData, params) as Observable<GeneralTabQuery>;
      }),
      map((data: GeneralTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleGeneralTabDataRes;
      }),
    );
  }

  articleListsTabData(): Observable<ArticleListsTabDataRes> {
    return this.actionListener('articleListsTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleListsTabData, params) as Observable<ListsTabQuery>;
      }),
      map((data: ListsTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleListsTabDataRes;
      }),
    );
  }
  articleCashAssistTabData(): Observable<ArticleCashAssistTabDataRes> {
    return this.actionListener('articleCashAssistTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleCashAssitTabData, params) as Observable<CashAssistTabQuery>;
      }),
      map((data: CashAssistTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleCashAssistTabDataRes;
      }),
    );
  }
  articleProductionTabData(): Observable<ArticleProductionTabDataRes> {
    return this.actionListener('articleProductionTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleProductionTabData, params) as Observable<ProductionTabQuery>;
      }),
      map((data: ProductionTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticleProductionTabDataRes;
      }),
    );
  }
  defaultOrderTabData(): Observable<GetDefaultOrderTabDataResExtended> {
    return this.actionListener('defaultOrderTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getDefaultOrderTabData, params) as Observable<GetDefaultOrderTabDataQuery>;
      }),
      map((data) => {
        const res = data.wawiAssist?.getArticleData as GetDefaultOrderTabDataRes;
        if (res?.weeklyDefaultOrderByArticle && res.weeklyDefaultOrderByArticle.length) {
          res.weeklyDefaultOrderByArticle = this.addReorderField(res.weeklyDefaultOrderByArticle);
        }
        return (res || {}) as GetDefaultOrderTabDataResExtended;
      }),
    );
  }
  getWeeklyDefaultOrderByArticleFromDate(): Observable<GetWeeklyDefaultOrderByArticleFromDateResExtended> {
    return this.actionListener('getWeeklyDefaultOrderByArticleFromDate').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getWeeklyDefaultOrderByArticleFromDate,
          params,
        ) as Observable<GetWeeklyDefaultOrderByArticleFromDateQuery>;
      }),
      map((data) => {
        const res = data.wawiAssist?.getArticleData as GetWeeklyDefaultOrderByArticleFromDateRes;
        if (res?.weeklyDefaultOrderByArticle && res.weeklyDefaultOrderByArticle.length) {
          res.weeklyDefaultOrderByArticle = this.addReorderField(res.weeklyDefaultOrderByArticle);
        }
        return (res || {}) as GetWeeklyDefaultOrderByArticleFromDateResExtended;
      }),
    );
  }

  getDataForNewDefaultOrderPositionByArticle(): Observable<GetDataForNewDefaultOrderPositionByArticleRes> {
    return this.actionListener('getDataForNewDefaultOrderPositionByArticle').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getDataForNewDefaultOrderPositionByArticle,
          params,
        ) as Observable<GetDataForNewDefaultOrderPositionByArticleQuery>;
      }),
      map((data) => {
        return data.wawiAssist
          ?.getWA_DataForNewDefaultOrderPositionByArticle as GetDataForNewDefaultOrderPositionByArticleRes;
      }),
    );
  }

  getDeliverySplittingPartsCalculation(): Observable<GetDeliverySplittingPartsCalculationRes> {
    return this.actionListener('getDeliverySplittingPartsCalculation').pipe(
      switchMap(({ params }) => {
        const { id, ...args } = params;
        return zip(
          gqlClient(
            getDeliverySplittingPartsCalculation,
            args,
          ) as Observable<GetDeliverySplittingPartsCalculationQuery>,
          of(id),
        );
      }),
      map(([data, id]) => {
        const res = data?.wawiAssist?.getWA_DeliverySplittingPartsCalculation;
        return { ...res, id } as GetDeliverySplittingPartsCalculationRes;
      }),
    );
  }

  getArticleProductionCalculationDependencies(): Observable<GetArticleProductionCalculationDependenciesRes> {
    return this.actionListener('getArticleProductionCalculationDependencies').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleProductionCalculationDependencies, params);
      }),
      map((data: GetArticleProductionCalculationDependenciesQuery) => {
        return data.wawiAssist?.getArticleData
          .articleProductionCalculationDependencies as GetArticleProductionCalculationDependenciesRes;
      }),
    );
  }

  getArticleSpecialPrices(): Observable<GetArticleSpecialPricesRes> {
    return this.actionListener('getArticleSpecialPrices').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleSpecialPrices,
          params,
        ) as Observable<GetArticleSpecialPricesQuery>;
      }),
      map((data) => {
        return data.wawiAssist?.getArticleData?.articleSpecialPrices as GetArticleSpecialPricesRes;
      }),
    );
  }

  getArticlePricesByCustomer(): Observable<GetArticlePricesByCustomerRes> {
    return this.actionListener('getArticlePricesByCustomer').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticlePricesByCustomer,
          params,
        ) as Observable<GetArticlePricesByCustomerQuery>;
      }),
      map((data) => {
        return data.wawiAssist?.getArticleData
          ?.articlePricesByCustomers as GetArticlePricesByCustomerRes;
      }),
    );
  }

  articleAvailabilityTabData(): Observable<ArticleAvailabilityTabDataRes> {
    return this.actionListener('articleAvailabilityTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleAvailabilityTabData,
          params,
        ) as Observable<GetArticleAvailabilityTabDataQuery>;
      }),
      map((data: GetArticleAvailabilityTabDataQuery) => {
        const {
          articleAssortimentGroupsCheckedState,
          availabilityInProcurementKindId,
          articleDeliveryPeriodsSettings,
          ...weekDays
        } = data.wawiAssist?.getArticleData || {};
        return {
          articleAssortimentGroupsCheckedState,
          availabilityInProcurementKindId,
          periods: articleDeliveryPeriodsSettings?.articleRegularDeliveryPeriods,
          periodsWithCustomWeekDays:
            articleDeliveryPeriodsSettings?.articleCustomWeekDaysAndDeliveryPeriods?.map(
              ({ id, fromDate, toDate, description, isRepeatYearly, ...weekDays }) => ({
                period: {
                  fromDate,
                  toDate,
                  isRepeatYearly,
                },
                weekDays,
                description,
                id,
              }),
            ),
          vacations: articleDeliveryPeriodsSettings?.articleVacations,
          weekDays,
        } as ArticleAvailabilityTabDataRes;
      }),
    );
  }

  articlePostTabData(): Observable<ArticlePostTabDataRes> {
    return this.actionListener('articlePostTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticlePostTabData, params) as Observable<PostTabQuery>;
      }),
      map((data: PostTabQuery) => {
        return data.wawiAssist?.getArticleData as ArticlePostTabDataRes;
      }),
    );
  }

  articleLabelTabData(): Observable<ArticleLabelTabDataRes> {
    return this.actionListener('articleLabelTabData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getArticleLabelTabData, params) as Observable<GetArticleLabelTabDataQuery>;
      }),
      map((data: GetArticleLabelTabDataQuery) => {
        const mainData = data.wawiAssist?.getArticleData;
        return {
          ...mainData,
        } as ArticleLabelTabDataRes;
      }),
    );
  }

  articleLabelDataFromRa(): Observable<ArticleLabelDataFromRaRes> {
    return this.actionListener('articleLabelDataFromRa').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleLabelDataFromRA,
          params,
        ) as Observable<GetArticleLabelDataFromRaQuery>;
      }),
      map((data: GetArticleLabelDataFromRaQuery) => {
        return data.wawiAssist?.getWA_ArticleLabelDataFromRA as ArticleLabelDataFromRaRes;
      }),
    );
  }

  articleDoughDataFromRA(): Observable<ArticleDoughDataFromRARes> {
    return this.actionListener('articleDoughDataFromRA').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getArticleDoughDataFromRA,
          params,
        ) as Observable<GetWa_ArticleDoughDataFromRaQuery>;
      }),
      map((data: GetWa_ArticleDoughDataFromRaQuery) => {
        return data.wawiAssist?.getWA_ArticleDoughDataFromRA as ArticleDoughDataFromRARes;
      }),
    );
  }

  generalTabOptions(): Observable<GeneralTabOptionsRes> {
    return this.actionListener('generalTabOptions').pipe(
      switchMap(() => {
        const discountGroups = storageHelper.memory.getItem('configsData.dictDiscountGroups');
        const marketingGroups = storageHelper.memory.getItem('configsData.dictMarketingGroups');
        const productionGroups = storageHelper.memory.getItem('configsData.dictProductionGroups');
        const shippingPoints = storageHelper.memory.getItem('configsData.dictShippingPoints');
        const customersList = storageHelper.memory.getItem('configsData.dictCustomers');
        const companyConfig = storageHelper.session.getItem('companyConfig');
        if (
          Array.isArray(discountGroups) &&
          Array.isArray(marketingGroups) &&
          Array.isArray(productionGroups) &&
          Array.isArray(shippingPoints) &&
          Array.isArray(customersList) &&
          companyConfig
        ) {
          return of({
            discountGroups,
            marketingGroups,
            productionGroups,
            shippingPoints,
            customersList,
            isShowShippingPointsInArticle: companyConfig?.isShowShippingPointsInArticle,
          });
        }
        configsData.pub.common([
          'dictDiscountGroups',
          'dictMarketingGroups',
          'dictProductionGroups',
          'dictCustomers',
          'dictShippingPoints',
        ]);
        return zip(
          configsData.sub.dictDiscountGroups(),
          configsData.sub.dictProductionGroups(),
          configsData.sub.dictMarketingGroups(),
          configsData.sub.dictShippingPoints(),
          configsData.sub.dictCustomers(),
          companyConfigService.getConfigs().pipe(take(1)),
        );
      }),
      map((v) => {
        // v is from zip operator (fetched data)
        if (Array.isArray(v)) {
          return {
            discountGroups: v[0] || [],
            productionGroups: v[1] || [],
            marketingGroups: v[2] || [],
            shippingPoints: v[3] || [],
            customersList: v[4] || [],
            isShowShippingPointsInArticle: v[5]?.isShowShippingPointsInArticle,
          } as GeneralTabOptionsRes;
        }
        // v is from of operator (cached data)
        return v;
      }),
    );
  }
  createArticle(): Observable<CreateOrCopyArticleRes> {
    return this.actionListener('createArticle').pipe(
      switchMap(({ params: paramsDirty }) => {
        const { id: _id, ...params } = paramsDirty || {};
        return gqlClient(createArticle, { data: params }) as Observable<CreateArticleMutation>;
      }),
      map((res: CreateArticleMutation) => {
        const data = res.wawiAssist?.createWA_Article;
        this.switchToEditModeAndAddArticle(data as CreateOrCopyArticleRes);
        return data as CreateOrCopyArticleRes;
      }),
    );
  }

  copyArticle(): Observable<CreateOrCopyArticleRes> {
    return this.actionListener('copyArticle').pipe(
      switchMap(({ params }) => {
        const { originalId, data } = params as CopyArticleMutationVariables;

        return gqlClient(copyArticle, {
          originalId,
          data,
        }) as Observable<CopyArticleMutation>;
      }),
      map((res: CopyArticleMutation) => {
        const data = res.wawiAssist?.copyWA_Article;
        this.switchToEditModeAndAddArticle(data as CreateOrCopyArticleRes);
        return data as CreateOrCopyArticleRes;
      }),
    );
  }

  editArticle(): Observable<SaveArticleDataRes> {
    return this.actionListener('editArticle').pipe(
      switchMap(({ params }) => {
        return gqlClient(saveArticleData, params) as Observable<SaveArticleDataMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveArticleData as SaveArticleDataRes;
      }),
    );
  }

  saveArticleProductionCalculation(): Observable<SaveArticleProductionCalculationRes> {
    return this.actionListener('saveArticleProductionCalculation').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          saveArticleProductionCalculation,
          params,
        ) as Observable<SaveArticleProductionCalculationMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.saveArticleData as SaveArticleProductionCalculationRes;
      }),
    );
  }

  listArticlesWithSpecialOrRegularPrices(): Observable<ListArticlesWithSpecialOrRegularPricesRes> {
    return this.actionListener('listArticlesWithSpecialOrRegularPrices').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          listArticlesWithSpecialOrRegularPrices,
          params,
        ) as Observable<ListArticlesWithSpecialOrRegularPricesQuery>;
      }),
      map((data) => {
        return data.wawiAssist
          ?.listWA_ArticlesWithSpecialOrRegularPrices as ListArticlesWithSpecialOrRegularPricesRes;
      }),
    );
  }

  createArticleSpecialPrices(): Observable<CreateArticleSpecialPricesRes> {
    return this.actionListener('createArticleSpecialPrices').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          createArticleSpecialPrices,
          params,
        ) as Observable<CreateArticleSpecialPricesMutation>;
      }),
      map((data) => {
        return data.wawiAssist?.createWA_ArticleSpecialPrices as CreateArticleSpecialPricesRes;
      }),
    );
  }

  validateArticleFieldsOnServer(): Observable<ValidateArticleFieldsRes> {
    return this.validateArticleNo$ as Observable<ValidateArticleFieldsRes>;
  }
  validateProductionRecipeIdOnServer(): Observable<ValidateArticleFieldsRes> {
    return this.validateProductionRecipeId$ as Observable<ValidateArticleFieldsRes>;
  }

  private switchToEditModeAndAddArticle(data: CreateOrCopyArticleRes) {
    modeService.pub.mode('edit');
    modeService.sub
      .mode()
      .pipe(
        filter((v) => v === 'edit'),
        take(1),
      )
      .subscribe((v) => {
        if (v === 'edit') articleListState.pub.addArticleToList(data);
      });
  }
  private addReorderField(
    array: GetDefaultOrderTabDataRes['weeklyDefaultOrderByArticle'],
  ): ExtendedDefaultOrderGridDataPos[] {
    return array.map((el, i) => ({
      ...el,
      id: Date.now() + i,
      __reorder__: `${el?.customerNr ? '(' + el?.customerNr + ')' : ''} ${
        el?.internalOrFullName || ''
      }`,
    }));
  }
}

class ArticleService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const articleService = new ArticleService();

export type ArticlesListRes = NonNullable<
  NonNullable<ArticlesQuery['wawiAssist']>['listWA_Articles']
>;

export type ArticleGeneralTabDataRes = NonNullable<
  NonNullable<GeneralTabQuery['wawiAssist']>['getArticleData']
>;

export type ArticleProductionTabDataRes = NonNullable<
  NonNullable<ProductionTabQuery>['wawiAssist']
>['getArticleData'];

export type ArticleCashAssistTabDataRes = NonNullable<
  NonNullable<CashAssistTabQuery['wawiAssist']>['getArticleData'] & { id: string }
>;

export type ArticlePostTabDataRes = NonNullable<
  NonNullable<PostTabQuery['wawiAssist']>['getArticleData']
>;

export type ArticleLabelTabDataRes = NonNullable<
  NonNullable<GetArticleLabelTabDataQuery['wawiAssist']>['getArticleData']
> & {
  id?: string;
};

export type GetDefaultOrderTabDataRes = NonNullable<
  NonNullable<GetDefaultOrderTabDataQuery['wawiAssist']>['getArticleData']
>;

export type ExtendedDefaultOrderGridDataPos =
  GetDefaultOrderTabDataRes['weeklyDefaultOrderByArticle'][number] & {
    id: number;
    __reorder__: string;
  };

export interface GetDefaultOrderTabDataResExtended
  extends Omit<GetDefaultOrderTabDataRes, 'weeklyDefaultOrderByArticle'> {
  weeklyDefaultOrderByArticle: ExtendedDefaultOrderGridDataPos[];
}

export type GetWeeklyDefaultOrderByArticleFromDateRes = NonNullable<
  NonNullable<GetWeeklyDefaultOrderByArticleFromDateQuery['wawiAssist']>['getArticleData']
>;

export interface GetWeeklyDefaultOrderByArticleFromDateResExtended
  extends Omit<GetWeeklyDefaultOrderByArticleFromDateRes, 'weeklyDefaultOrderByArticle'> {
  weeklyDefaultOrderByArticle: ExtendedDefaultOrderGridDataPos[];
}

export type GetDataForNewDefaultOrderPositionByArticleRes = NonNullable<
  NonNullable<
    GetDataForNewDefaultOrderPositionByArticleQuery['wawiAssist']
  >['getWA_DataForNewDefaultOrderPositionByArticle']
>;

export type ArticleLabelDataFromRaRes = NonNullable<
  NonNullable<GetArticleLabelDataFromRaQuery['wawiAssist']>['getWA_ArticleLabelDataFromRA']
>;

export type ArticleListsTabDataRes = NonNullable<
  NonNullable<ListsTabQuery['wawiAssist']>['getArticleData']
>;

export type ArticleDoughDataFromRARes = NonNullable<
  NonNullable<GetWa_ArticleDoughDataFromRaQuery['wawiAssist']>['getWA_ArticleDoughDataFromRA']
>;

export type ArticleAvailabilityTabDataFromServer = NonNullable<
  NonNullable<GetArticleAvailabilityTabDataQuery['wawiAssist']>['getArticleData']
>;

export enum WeekDays {
  isDeliveredMon = 'isDeliveredMon',
  isDeliveredTue = 'isDeliveredTue',
  isDeliveredWed = 'isDeliveredWed',
  isDeliveredThu = 'isDeliveredThu',
  isDeliveredFri = 'isDeliveredFri',
  isDeliveredSat = 'isDeliveredSat',
  isDeliveredSun = 'isDeliveredSun',
}

export type WeekDaysFlags = {
  [key in WeekDays]: boolean;
};

export type PeriodsWithCustomWeekDaysType =
  ArticleAvailabilityTabDataFromServer['articleDeliveryPeriodsSettings']['articleCustomWeekDaysAndDeliveryPeriods'][number];

export type PeriodWithCustomWeekDays = Pick<PeriodsWithCustomWeekDaysType, 'id' | 'description'> & {
  period: {
    fromDate: PeriodsWithCustomWeekDaysType['fromDate'];
    toDate: PeriodsWithCustomWeekDaysType['toDate'];
    isRepeatYearly: PeriodsWithCustomWeekDaysType['isRepeatYearly'];
  };
  weekDays: WeekDaysFlags;
};

export type PeriodConditions = {
  periods: ArticleAvailabilityTabDataFromServer['articleDeliveryPeriodsSettings']['articleRegularDeliveryPeriods'];
  periodsWithCustomWeekDays: PeriodWithCustomWeekDays[];
  vacations: ArticleAvailabilityTabDataFromServer['articleDeliveryPeriodsSettings']['articleVacations'];
  weekDays: WeekDaysFlags;
};

export type ArticleAvailabilityTabDataRes = Pick<
  ArticleAvailabilityTabDataFromServer,
  'articleAssortimentGroupsCheckedState' | 'availabilityInProcurementKindId'
> &
  PeriodConditions & { id?: string };

export type ValidateArticleFieldsRes = NonNullable<
  NonNullable<ValidateArticleFieldsQuery['wawiAssist']>['validateWA_ArticleFields']
>;
export type SaveArticleDataRes = NonNullable<
  NonNullable<NonNullable<SaveArticleDataMutation['wawiAssist']>['saveArticleData']>
>;
export type SaveArticleProductionCalculationRes = NonNullable<
  NonNullable<SaveArticleProductionCalculationMutation['wawiAssist']>['saveArticleData']
>;
export type GetArticleSpecialPricesRes = NonNullable<
  NonNullable<GetArticleSpecialPricesQuery['wawiAssist']>['getArticleData']
>['articleSpecialPrices'];
export type GetArticlePricesByCustomerRes = NonNullable<
  NonNullable<GetArticlePricesByCustomerQuery['wawiAssist']>['getArticleData']
>['articlePricesByCustomers'];

export interface GeneralTabOptionsRes {
  discountGroups: DictDiscountGroupsRes;
  productionGroups: DictProductionGroupsRes;
  marketingGroups: DictMarketingGroupsRes;
  shippingPoints: DictShippingPointsRes;
  isShowShippingPointsInArticle: TCompanyConfigs['isShowShippingPointsInArticle'];
  customersList: DictCustomersRes;
}

export interface CashAssistTabOptionsRes {
  productionGroups: DictProductionGroupsRes;
  cashAssistTemplates: DictCashAssistTemplatesRes;
}

export interface ProductionTabOptionsRes {
  dictArticles: DictArticlesRes;
  dictRecipesWithConnectedArticles: DictRecipesWithConnectedArticlesRes;
}

export type CreateOrCopyArticleRes = NonNullable<
  NonNullable<CreateArticleMutation['wawiAssist']>['createWA_Article']
>;
export interface ICopyArticleMutation {
  articleId: NonNullable<IArticleListState['selectedArticle']>['id'];
  generalTab: ArticleGeneralTabDataRes;
}
export type GetArticleProductionCalculationDependenciesRes = NonNullable<
  NonNullable<
    NonNullable<GetArticleProductionCalculationDependenciesQuery['wawiAssist']>['getArticleData']
  >['articleProductionCalculationDependencies']
>;

export type ListArticlesWithSpecialOrRegularPricesRes = NonNullable<
  NonNullable<
    ListArticlesWithSpecialOrRegularPricesQuery['wawiAssist']
  >['listWA_ArticlesWithSpecialOrRegularPrices']
>;

export type CreateArticleSpecialPricesRes = NonNullable<
  NonNullable<CreateArticleSpecialPricesMutation['wawiAssist']>['createWA_ArticleSpecialPrices']
>;

export type ReportPreviewRes = NonNullable<
  NonNullable<Create_R6_ArticleListReportMutation['wawiAssist']>['create_r6_ArticleListReport'] & {
    status: C_Win_Report_Task_Status;
  }
>;
export type ReportAllPriceCategoriesPreviewRes = NonNullable<
  NonNullable<
    Create_R36_ArticleListAllPriceCategoriesReportMutation['wawiAssist']
  >['create_r36_ArticleListAllPriceCategoriesReport'] & { status: C_Win_Report_Task_Status }
>;
export type ReportWithImagePreviewRes = NonNullable<
  NonNullable<
    Create_R50_ArticleListWithImageReportMutation['wawiAssist']
  >['create_r50_ArticleListWithImageReport'] & { status: C_Win_Report_Task_Status }
>;
