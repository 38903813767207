import React, {
  FC,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateCalendar, DateField } from '@mui/x-date-pickers-pro';
import styled from '@mui/material/styles/styled';
import { Box, Button, Fade, IconButton, Popover, SxProps, Theme } from '@mui/material';
import PrevDayIcon from '@mui/icons-material/KeyboardArrowLeft';
import NextDayIcon from '@mui/icons-material/KeyboardArrowRight';
import { Subject } from 'rxjs';
import EventIcon from '@mui/icons-material/Event';
import { format } from 'date-fns';
import { DateFieldProps } from '@mui/x-date-pickers/DateField/DateField.types';
import { DateCalendarProps } from '@mui/x-date-pickers/DateCalendar/DateCalendar.types';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import { useTranslation } from 'react-i18next';
import useConfigDateFormat from '../../hooks/useConfigDateFormat';
import { ControllerProps } from 'react-hook-form';
import equal from 'fast-deep-equal/react';

export const DatePicker: FC<IDatePicker> = forwardRef(
  (
    {
      navigation = true,
      width,
      onChange,
      onBlur,
      defaultValue,
      value,
      fieldProps = {},
      calendarProps = {},
      weekDay,
      onChangeDependencies = [],
      navigationLockFn = (handlers: () => void) => handlers(),
      mode = 'onBlur',
      returnEmpty,
      error,
      helperText,
      sx = {},
    },
    ref,
  ) => {
    const { disabled = false } = fieldProps;

    const dateStream$ = useMemo(() => new Subject<Date | null>(), []);
    const [valueDate, setValueDate] = useState<Date | null>(defaultValue || null);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [focused, setFocused] = useState(fieldProps.autoFocus || false);
    const pickerRef = useRef(null);
    const isChanged = useRef(false);
    const disabledBlur = useRef<boolean>(false);
    const inputEl = useRef<HTMLInputElement | null>(null);

    const calendarDateFormat = useConfigDateFormat();

    const { i18n } = useTranslation();
    const open = Boolean(anchorEl);

    useEffect(() => {
      const dateSub = dateStream$.subscribe((date) => {
        onChange?.(date);
      });
      return () => {
        dateSub.unsubscribe();
      };
    }, onChangeDependencies);

    useLayoutEffect(() => {
      if (value || value === null) setValueDate(value);
    }, [value]);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      navigationLockFn(() => setAnchorEl(pickerRef.current));
      const el = (e.target as HTMLInputElement).closest('button');
      const input = el?.nextElementSibling as HTMLInputElement;
      inputEl.current = input;
      const timer = setTimeout(() => {
        setFocused(true);
        disabledBlur.current = true;
        input.focus();
        clearTimeout(timer);
      }, 0);
    };

    const locale = useMemo(() => {
      switch (i18n.language) {
        case 'de':
          return de;
        case 'en':
          return en;
        case 'fr':
          return fr;
        case 'it':
          return it;
        default:
          return de;
      }
    }, [i18n.language]);

    fieldProps.InputProps = useMemo(
      () => ({
        ...(fieldProps.InputProps && { ...fieldProps.InputProps }),
        startAdornment: (
          <IconButton onClick={handleClick} disabled={disabled} onFocus={() => setFocused(true)}>
            <EventIcon />
          </IconButton>
        ),
        ...(weekDay &&
          valueDate && { endAdornment: <span>{format(valueDate, 'EEEE', { locale })}</span> }),
      }),
      [valueDate, locale, disabled],
    );

    const handleClose = () => {
      setAnchorEl(null);
      disabledBlur.current = true;
      const timer = setTimeout(() => {
        inputEl.current?.focus();
        disabledBlur.current = false;
        clearTimeout(timer);
      }, 0);
    };

    const navigationChangeDate = (dayNumber: number) => {
      if (valueDate) {
        const updatedDate = new Date(valueDate.setDate(dayNumber));
        setValueDate(updatedDate);
        dateStream$.next(updatedDate);
      }
    };
    return (
      <Box {...{ ref, sx }}>
        <SDatePicker ref={pickerRef} {...{ width, navigation, weekDay }}>
          {navigation && (
            <SNavigationBtn
              onClick={() => {
                if (valueDate)
                  navigationLockFn(() => navigationChangeDate(valueDate.getDate() - 1));
              }}
              purpose='prev'
              color='secondary'
              startIcon={<PrevDayIcon />}
            />
          )}
          <DateField
            size='small'
            fullWidth
            format={calendarDateFormat}
            InputProps={{
              startAdornment: <span>{valueDate && format(valueDate, 'EE')}</span>,
              endAdornment: (
                <IconButton onClick={handleClick}>
                  <EventIcon />
                </IconButton>
              ),
            }}
            value={valueDate && new Date(valueDate)}
            timezone='UTC'
            slotProps={{
              textField: { error, helperText },
            }}
            onBlur={() => {
              if (isChanged.current && !disabledBlur.current) {
                dateStream$.next(valueDate);
                onBlur?.(valueDate);
                isChanged.current = false;
              }
              if (!open && !disabledBlur.current) {
                setFocused(false);
              }
            }}
            onChange={(date, context) => {
              const notEqual = !equal(date, valueDate);
              if ((returnEmpty || date) && !context.validationError && notEqual) {
                setValueDate(date && new Date(date));
                //add row below by specifc props
                if (returnEmpty || (typeof onBlur === 'function' && mode === 'onBlur')) {
                  dateStream$.next(date);
                }
                isChanged.current = true;
              }
            }}
            onMouseEnter={() => {
              disabledBlur.current = true;
            }}
            onMouseLeave={() => {
              disabledBlur.current = false;
            }}
            onClick={() => {
              navigationLockFn(() => setFocused(true));
            }}
            inputProps={{
              ...fieldProps.inputProps,
              onFocus: () => setFocused(true),
              onBlur: () => !open && setFocused(false),
            }}
            // readOnly={!focused}
            {...{ focused }}
            {...fieldProps}
          />

          {navigation && (
            <SNavigationBtn
              onClick={() => {
                if (valueDate)
                  navigationLockFn(() => navigationChangeDate(valueDate.getDate() + 1));
              }}
              purpose='next'
              color='secondary'
              startIcon={<NextDayIcon />}
            />
          )}
        </SDatePicker>
        <Popover
          anchorEl={anchorEl}
          open={open}
          keepMounted
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            marginTop: 1,
          }}
        >
          <DateCalendar
            value={valueDate}
            onChange={(date, selectionState) => {
              date && setValueDate(date);
              if (selectionState === 'finish' && date) {
                if (!equal(date, valueDate)) {
                  dateStream$.next(date);
                  if (typeof onBlur === 'function' && mode === 'onBlur') isChanged.current = true;
                }
                handleClose();
              }
            }}
            {...calendarProps}
          />
        </Popover>
      </Box>
    );
  },
);

const SDatePicker = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'navigation' && prop !== 'weekDay',
})<TDatePickerWrap>(({ width, navigation, weekDay }) => ({
  width: width || '100%',
  display: 'flex',
  '.MuiInputBase-root': {
    ...(navigation && { borderRadius: 0 }),
    paddingLeft: 6,
    '& > input': {
      ...(!weekDay && { textAlign: 'center', width: '67%' }),
      ...(weekDay && { width: 'initial' }),
    },
  },
  '& .MuiFormHelperText-contained': {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const SNavigationBtn = styled(Button)<{ purpose: 'prev' | 'next' }>(({ purpose }) => ({
  minWidth: 40,
  padding: 8,
  border: '1px solid rgba(0, 0, 0, 0.23)',
  '&:hover': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  ...(purpose === 'prev' && {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  }),
  '.MuiButton-startIcon': {
    margin: 0,
    color: 'rgb(120, 120, 120)',
  },
  ...(purpose === 'next' && { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: 0 }),
}));
export interface IDatePicker {
  width?: number | string;
  onBlur?: (v: Date | null) => void;
  onChange?: (v: Date | null) => void;
  fieldProps?: Omit<DateFieldProps<Date>, 'defaultValue' | 'onChange'>;
  calendarProps?: Omit<DateCalendarProps<Date>, 'defaultValue' | 'onChange'>;
  defaultValue?: Date;
  navigation?: boolean;
  weekDay?: boolean;
  value?: Date;
  navigationLockFn?: (handlers: () => void) => void;
  onChangeDependencies?: any[];
  error?: boolean;
  helperText?: string;
  /* todo The mode prop corrects the problem of updating the value when changing the date from the DateCalendar component.
      Use only with the FormDatePicker component, since the simple DatePicker component determines automatically depending on the event received.
      The default value is "onBlur". */
  mode?: 'onChange' | 'onBlur';
  name?: ControllerProps['name'];
  returnEmpty?: boolean;
  sx?: SxProps<Theme>;
}

type TDatePickerWrap = Pick<IDatePicker, 'width' | 'navigation' | 'weekDay'>;
