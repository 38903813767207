import { Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';

import { ContentLoading } from '../../../../shared/components/loading/loading.component';
import { transitionService } from '../../../../shared/components/loading/service/transition.service';
import { TabPanel, Tabs } from '../../../../shared/components/tabs';
import { TabContentContainer } from '../../../../shared/components/tabs/tabsContainer.component.tsx';
import { companyConfigService } from '../../../../shared/services/companyConfig/companyConfig.service';
import { TMode, modeService } from '../../../../shared/services/mode/mode.service';
import { navigateService } from '../../../../shared/services/navigate/navigate.service';
import { ArticleTabs, ITabState } from '../../states/tabState.model';
import { articleTabLoadingService } from './articleTababLoading.service';
import { AvailabilityTab } from './availability/availability.tab';
import { CashAssistTab } from './cashassist/cashassist.tab';
import { LabelTab } from './label/label.tab.tsx';
import { ListsTab } from './lists/lists.tab';
import { PostTab } from './post/post.tab';
import { ProductionTab } from './production/production.tab';
import { DefaultOrderTab } from './defaultOrder/defaultOrder.tab.tsx';
import { isDoughService } from './articleIsDough.service.ts';
import { GeneralModeContainer } from './general/generalModeContainer.tsx';

const ArticleDetails: FC = () => {
  const { tab } = useParams() as { tab: ArticleTabs };
  const { t } = useTranslation();
  const { isDough: initIsDough } = useLoaderData() as ITabState;

  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<TMode>('edit');
  const [isDough, setIsDough] = useState<boolean>(initIsDough);

  const disableTab = mode !== 'edit';
  const { isShippingFunctionActivated, isCADBAvailable } =
    companyConfigService.getCachedConfigs() || {};

  const handleActiveTab: THandleActiveTab = (_, tab) => {
    if (!loading) {
      transitionService.pub.off();
      articleTabLoadingService.pub.loading(true);
      navigateService.pub.navigateTo(`/article/${tab}`);
    }
  };

  useEffect(() => {
    const unsubLoading = articleTabLoadingService.sub.loading().subscribe(setLoading);
    const unsubMode = modeService.sub.mode().subscribe(setMode);
    const unsubIsDough = isDoughService.sub.isDough().subscribe(setIsDough);
    return () => {
      modeService.pub.mode('edit');
      isDoughService.pub.clean();
      unsubMode.unsubscribe();
      unsubLoading.unsubscribe();
      unsubIsDough.unsubscribe();
    };
  }, []);

  return (
    <>
      <Tabs value={tab} onChange={handleActiveTab}>
        <Tab label={t('article.general')} value={ArticleTabs.general} />
        <Tab disabled={disableTab} label={t('article.lists')} value={ArticleTabs.lists} />
        <Tab
          disabled={disableTab || isDough}
          label={t('article.availability')}
          value={ArticleTabs.availability}
        />
        <Tab disabled={disableTab} label={t('article.production')} value={ArticleTabs.production} />
        <Tab
          disabled={disableTab || !isCADBAvailable || isDough}
          label={t('article.cashassist')}
          value={ArticleTabs.cashAssist}
        />
        <Tab
          disabled={disableTab || isDough}
          label={t('article.default_order')}
          value={ArticleTabs.defaultOrder}
        />
        <Tab
          disabled={disableTab || !isShippingFunctionActivated}
          label={t('article.post')}
          value={ArticleTabs.post}
        />
        <Tab
          disabled={disableTab || isDough}
          label={t('article.label')}
          value={ArticleTabs.label}
        />
      </Tabs>
      <TabContentContainer>
        <ContentLoading loading={loading}>
          {tab === ArticleTabs.general && (
            <GeneralModeContainer mode={mode} tab={tab} isDough={isDough} />
          )}

          {mode === 'edit' && (
            <>
              <TabPanel value={ArticleTabs.lists} activeValue={tab}>
                <ListsTab />
              </TabPanel>
              <TabPanel value={ArticleTabs.availability} activeValue={tab}>
                <AvailabilityTab />
              </TabPanel>
              <TabPanel value={ArticleTabs.production} activeValue={tab}>
                <ProductionTab />
              </TabPanel>
              <TabPanel value={ArticleTabs.cashAssist} activeValue={tab}>
                <CashAssistTab />
              </TabPanel>
              <TabPanel value={ArticleTabs.post} activeValue={tab}>
                <PostTab />
              </TabPanel>
              <TabPanel value={ArticleTabs.label} activeValue={tab}>
                <LabelTab />
              </TabPanel>
              <TabPanel value={ArticleTabs.defaultOrder} activeValue={tab}>
                <DefaultOrderTab />
              </TabPanel>
            </>
          )}
        </ContentLoading>
      </TabContentContainer>
    </>
  );
};
export default ArticleDetails;

type THandleActiveTab = (evt: SyntheticEvent<Element, Event>, tab: ArticleTabs) => void;
