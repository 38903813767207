import { Pub, State } from '../../../../../shared/state/state.abstract.ts';
import { BehaviorSubject } from 'rxjs';
import {
  createOrderService,
  CustomerInfoRes,
  INewOrderDataRes,
  IPosition,
  OrderDataParams,
  TOrderData,
} from '../../../services/createOrder.service.ts';
import { IFilteredProductDataRes, TaxesByDateRes } from '../../../services/order.service.ts';
import { ReactNode } from 'react';
import { BoxProps } from '@mui/material';
import { SubImpl } from './sub.state.ts';
import { IOrderTypeDescription } from '../../../edit/states/orderDetails/orderDetails.state.ts';

export const initOrderDetailsState: IOrderDetailsState = {
  action: undefined,
  orderData: {
    orderSourceInfo: {},
  },
  orderDataBackup: {
    orderSourceInfo: {},
  },
  dirty: false,
  customerInfo: {},
  noData: true,
  selectedPos: null,
};
export const additionalOptions: IAdditionalOptions = {
  productData: {
    productList: [],
    listSpecialAvailabilityStateForArticles: undefined,
    listSpecialAssortmentGroups: undefined,
  },
  taxes: [],
};
export const additionalOptions$ = new BehaviorSubject<IAdditionalOptions>(additionalOptions);
class PubImpl extends Pub<IOrderDetailsState> {
  init(params: IOrderDetailsState) {
    this.emit('init', params);
  }
  initOptions(params: IAdditionalOptions) {
    additionalOptions$.next(params);
  }
  updateOrderData(orderData: IOrderDetailsState['orderData']) {
    this.emit('updateOrderData', { orderData });
  }
  revertOrderData() {
    this.emit('revertOrderData', {});
  }
  resetOrderDataChanges() {
    const streamV = this.stream$.getValue();
    this.emit('resetOrderDataChanges', { orderData: streamV.orderDataBackup });
  }
  replaceOrderData(params: OrderDataParams) {
    createOrderService.pub.orderData(params);
    this.emit('replaceOrderData', {});
  }
  selectPosition(selectedPos: IOrderDetailsState['selectedPos']) {
    this.emit('selectPosition', { selectedPos });
  }
  addPosition(args: AddPositionArgs) {
    this.emit(args.type, args.type === 'addPosition' ? { selectedPos: args.position } : {});
  }
  updatePosition(position?: IOrderDetailsState['selectedPos']) {
    this.emit('updatePosition', { selectedPos: position });
  }
  updatePositions(positions: IOrderDetailsState['orderData']['positions']) {
    const { orderData } = this.stream$.getValue();
    this.emit('updateOrderData', { orderData: { ...orderData, positions } });
  }
  deletePosition(args: DeletePositionArgs) {
    const { selectedPos, orderData } = this.stream$.getValue();
    if (args.type === 'deletePosition' && args.position?.id !== selectedPos?.id) {
      const positions = orderData.positions?.filter((el) => el.id !== args.position?.id);
      this.updatePositions(positions);
    } else {
      this.emit(args.type, args.type === 'deletePosition' ? { selectedPos: args.position } : {});
    }
  }
  updateCell(selectedPos: IOrderDetailsState['selectedPos'], type: TypeCell) {
    this.emit(type, { selectedPos });
  }
}

class OrderDetailsState extends State<IOrderDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const orderDetailsState = new OrderDetailsState(initOrderDetailsState);
export interface IOrderDetailsState {
  action:
    | 'init'
    | 'updateState'
    | 'selectOrder'
    | 'queryData'
    | 'errorData'
    | 'selectPosition'
    | 'updatePosition'
    | 'updateOrderData'
    | 'replaceOrderData'
    | 'revertOrderData'
    | 'resetOrderDataChanges'
    | 'emptyData'
    | 'loader'
    | 'internalUpdate'
    | TypeAddPosition
    | TypeDeletePosition
    | TypeCell
    | undefined;
  customerInfo: ICustomerInfo;
  orderData: IOrderData;
  orderDataBackup: IOrderData;
  dirty: boolean;
  noData: boolean;
  selectedPos: IPosition | null;
}

export interface IOrderData extends Omit<INewOrderDataRes, 'deliveryTime'> {
  deliveryTime?: TOrderData['deliveryTime'] | Date;
}

export interface ICustomerInfo extends Partial<Pick<CustomerInfoRes, TSeparateCustomerInfoFields>> {
  contacts?: Omit<CustomerInfoRes, TSeparateCustomerInfoFields>;
  transportSector?: CustomerInfoRes['transportSector'];
  transportSectorId?: CustomerInfoRes['transportSectorId'];
  isEmailServiceForInvoice?: boolean;
  readOnlyAddress?: boolean;
  orderType?: IOrderTypeDescription;
  rightColumn?: ReactNode;
  rightColumnProps?: BoxProps;
}

export interface IAdditionalOptions {
  productData: IFilteredProductDataRes;
  taxes: TaxesByDateRes;
}

type TSeparateCustomerInfoFields =
  | 'customerNo'
  | 'internalOrFullName'
  | 'informationTip'
  | 'deliveryAddress'
  | 'discountKindId'
  | 'isDeliverySplitting'
  | 'deliverySplittingPartsCount'
  | 'id';
type TypeCell =
  | 'priceCell'
  | 'descriptionCell'
  | 'vatCodeCell'
  | 'discountCell'
  | 'quantityCell'
  | 'splittingCell';

/* TYPES TO ADD POSITION start */
type TypeAddPosition = IAddPosition['type'] | IAddDeliveryPosition['type'];
interface IAddPosition {
  type: 'addPosition';
  position?: IOrderDetailsState['selectedPos'];
}
interface IAddDeliveryPosition {
  type: 'addDeliveryPosition';
  position?: never;
}
type AddPositionArgs = IAddPosition | IAddDeliveryPosition;

/* TYPES TO ADD POSITION end */
/* TYPES TO DELETE POSITION start */
type TypeDeletePosition = IDeletePosition['type'] | IDeleteDeliveryPosition['type'];
interface IDeletePosition {
  type: 'deletePosition';
  position: IOrderDetailsState['selectedPos'];
}
interface IDeleteDeliveryPosition {
  type: 'deleteDeliveryPosition';
  position?: never;
}
type DeletePositionArgs = IDeletePosition | IDeleteDeliveryPosition;
/* TYPES TO DELETE POSITION end */
