import { gql } from 'graphql-request';

export const getCreatedOrdersForCustomer = gql`
  query listWA_CreatedOrders($filter: WA_CreatedOrdersGridFilter!) {
    wawiAssist {
      listWA_CreatedOrders(filter: $filter) {
        orderId
        orderDate
        orderNo
        orderTotal
        orderType
        caOrderType
        customerId
        customerNo
        internalOrFullName
        isDeleted
        isInvoiced
        emailAddress
      }
    }
  }
`;

export const deleteOrder = gql`
  mutation deleteWA_Order($id: ID!) {
    wawiAssist {
      deleteWA_Order(id: $id)
    }
  }
`;

export const getOrderDataOfCreatedOrder = gql`
  query getWA_OrderDataOfCreatedOrder($orderId: ID!) {
    wawiAssist {
      getWA_OrderDataOfCreatedOrder(orderId: $orderId) {
        orderNo
        orderDate
        orderType
        customerId
        specialAddress
        referenceOrderNo
        deliveryTime
        linkText
        note
        note2
        notePlainText
        isEditable
        datetimeOfLastEmail
        caInfo {
          cashRegisterNo
          receiptNo
          deliveryTime
          deliveryMethod
          userName
        }
        positions {
          virtualPositionId
          articleId
          articleNo
          description
          quantity
          price
          total
          discount
          vatCode
          indTextDeliveryNote
          indTextProduction
          splittingPartsPreset
          quantityPerLot
          minQuantity
          maxQuantity
          saleUnit
          weight
          isChangeable
        }
      }
    }
  }
`;

export const getCustomerInformation = gql`
  query getCustomerInformation($getCustomerDataId: ID!) {
    wawiAssist {
      getCustomerData(id: $getCustomerDataId) {
        id
        customerNo
        internalOrFullName
        phoneBusiness
        phoneBusiness2
        phoneMobile
        phonePrivate
        fax
        email
        deliveryAddress
        informationTip
        discountKindId
        isDeliverySplitting
        deliverySplittingPartsCount
        transportSectorId
        transportSector {
          isPriceRateFixed
          priceRateFixed
        }
      }
    }
  }
`;

export const saveOrder = gql`
  mutation saveOrder($orderProps: WA_SaveOrderInputs!) {
    wawiAssist {
      saveWA_Order(orderProps: $orderProps) {
        orderNo
        customerNo
        orderId
        orderDate
        orderTotal
        internalOrFullName
      }
    }
  }
`;
