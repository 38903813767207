import { FC, ReactElement, ReactNode, useEffect } from 'react';
import styled from '@mui/material/styles/styled';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { topbarService } from '../navigation/components/topBar/services/topBar.service';

export const Page: FC<IPage> = ({ title, withBorder, children, topContent, sxBox = {} }) => {
  const renderedTopContent = topContent?.();
  useEffect(() => {
    topbarService.pub.title(title);
  }, [title]);
  return (
    <SPage direction='column'>
      {topContent && <Stack marginBottom='24px'>{renderedTopContent}</Stack>}
      <SBox flexGrow={1} sx={{ minHeight: 0, ...sxBox }} {...{ withBorder }}>
        {children}
      </SBox>
    </SPage>
  );
};
const SPage = styled(Stack)(({ theme }) => ({
  height: `calc(100vh - 62px)`,
  padding: theme.page.padding,
  overflow: 'auto',
  flexGrow: 1,
  paddingTop: '16px',
}));
export interface IPage {
  title: string;
  withBorder?: boolean;
  topContent?: () => ReactElement;
  children: ReactNode;
  sxBox?: SxProps<Theme>;
}

const SBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'withBorder',
})<{ withBorder?: boolean; sxBox?: SxProps<Theme> }>(({ theme, withBorder }) => ({
  ...(withBorder && {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    padding: '20px 16px 16px',
  }),
}));
