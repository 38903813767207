import { delay, from, map, Observable, retry, switchMap, zip, of, forkJoin } from 'rxjs';
import { ClientError } from 'graphql-request';
import { TFunction } from 'i18next';
import {
  C_Email_Msg_Doctype,
  C_Global_Error_Code,
  C_History_Pdf_Doctype,
  C_Report,
  GetWa_PdfHistoryReportQuery,
  GetWa_PdfHistoryReportQueryVariables,
  ListWa_PdfHistoryReportsQuery,
  ListWa_PdfHistoryReportsQueryVariables,
  SendEmailWithDocumentMutation,
  SendEmailWithDocumentMutationVariables,
  ExtractXmlFromPdfOfInvoicesMutation,
  ExtractXmlFromPdfOfInvoicesMutationVariables,
  Wa_PdfFileOfInvoiceInput,
  ListWa_DataForMultiEmailsQueryVariables,
  ListWa_DataForMultiEmailsQuery,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import {
  getWAPdfHistoryReport,
  listWAPdfHistoryReports,
  sendEmailWithDocument,
  extractXmlFromPdfOfInvoices,
  getDataForMultiEmails,
} from './gql/reports.gql';
import { companyConfigService } from '../../services/companyConfig/companyConfig.service';
import { localeFormatterHelper } from '../../../shared/helpers/formatter/localeFormatter.helper';

type Actions =
  | 'getPdfHistoryReport'
  | 'listPdfHistoryReports'
  | 'sendEmailWithDocument'
  | 'sendMultiEmailsWithDocument'
  | 'extractXmlFromPdfOfInvoices'
  | 'getDataForMultiEmails'
  | undefined;

class PubImpl extends Pub<Actions> {
  getPdfHistoryReport(params: GetWa_PdfHistoryReportQueryVariables) {
    this.emit('getPdfHistoryReport', params);
  }

  listPdfHistoryReports(params: ListWa_PdfHistoryReportsQueryVariables) {
    this.emit('listPdfHistoryReports', params);
  }

  sendEmailWithDocument(params: SendEmailWithDocumentMutationVariables): void {
    this.emit('sendEmailWithDocument', params);
  }

  sendMultiEmailsWithDocument(params: SendEmailWithDocumentMutationVariables[]): void {
    this.emit('sendMultiEmailsWithDocument', params);
  }

  extractXmlFromPdfOfInvoices(params: ExtractXmlFromPdfOfInvoicesMutationVariables) {
    this.emit('extractXmlFromPdfOfInvoices', params);
  }

  getDataForMultiEmails(params: ListWa_DataForMultiEmailsQueryVariables) {
    this.emit('getDataForMultiEmails', params);
  }
}

class SubImpl extends Sub<Actions> {
  getPdfHistoryReport(): Observable<GetWAPdfHistoryReportRes> {
    return this.actionListener('getPdfHistoryReport').pipe(
      switchMap(({ params }) => {
        return gqlClient(getWAPdfHistoryReport, params) as Observable<GetWa_PdfHistoryReportQuery>;
      }),
      map((data) => data?.wawiAssist?.getWA_PdfHistoryReport as GetWAPdfHistoryReportRes),
    );
  }
  waitAndGetPdfHistoryReport(): Observable<GetWAPdfHistoryReportRes> {
    return this.getPdfHistoryReport().pipe(
      this._retryer({
        retryCondition: ({ response }) =>
          response.errors?.[0].extensions.code === C_Global_Error_Code.GEC20_NO_DATA,
      }),
    );
  }

  listPdfHistoryReports(): Observable<ListWAPdfHistoryReportRes> {
    return this.actionListener('listPdfHistoryReports').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          listWAPdfHistoryReports,
          params,
        ) as Observable<ListWa_PdfHistoryReportsQuery>;
      }),
      map((data) => data?.wawiAssist?.listWA_PdfHistoryReports as ListWAPdfHistoryReportRes),
    );
  }
  waitAndListPdfHistoryReports(): Observable<ListWAPdfHistoryReportRes> {
    return this.listPdfHistoryReports().pipe(
      this._retryer({
        retryCondition: ({ response }) =>
          response.errors?.[0].extensions.code === C_Global_Error_Code.GEC20_NO_DATA,
      }),
    );
  }

  private _retryer<T>(options: IRetryerArgs) {
    const { retryCondition, attempts = 10, interval = 1000 } = options || {};
    return (stream: Observable<T>) => {
      return stream.pipe(
        retry({
          count: attempts,
          delay: (v: ClientError) => {
            const cond = retryCondition(v);
            if (cond) {
              return from([v]).pipe(delay(interval));
            }
            throw v;
          },
        }),
      );
    };
  }

  sendEmailWithDocument(): Observable<SendEmailWithDocumentPlusParamsRes> {
    return this.actionListener('sendEmailWithDocument').pipe(
      switchMap(({ params }: { params: SendEmailWithDocumentMutationVariables }) => {
        const { docType, documentId } = params;
        return zip(
          gqlClient(sendEmailWithDocument, params) as Observable<SendEmailWithDocumentMutation>,
          of({ docType, documentId }),
        );
      }),
      map(([res, variables]) => {
        return {
          ...res.wawiAssist?.sendEmailWithDocument,
          ...variables,
        } as SendEmailWithDocumentPlusParamsRes;
      }),
    );
  }

  sendMultiEmailsWithDocument(): Observable<SendEmailWithDocumentPlusParamsRes[]> {
    return this.actionListener('sendMultiEmailsWithDocument').pipe(
      switchMap(({ params }: { params: SendEmailWithDocumentMutationVariables[] }) => {
        const emailsRequests = params.map((variables) =>
          gqlClient(sendEmailWithDocument, { ...variables }).pipe(
            map((res: SendEmailWithDocumentMutation) => ({ res, variables })),
          ),
        );
        return forkJoin(emailsRequests).pipe(
          map((responses) => {
            return responses.map(({ res, variables }) => {
              const response = res.wawiAssist?.sendEmailWithDocument as SendEmailWithDocumentRes;
              const { docType, documentId } = variables;
              return {
                ...response,
                docType,
                documentId,
              } as SendEmailWithDocumentPlusParamsRes;
            });
          }),
        );
      }),
    );
  }

  extractXmlFromPdfOfInvoices(): Observable<ExtractXmlFromPdfOfInvoicesRes> {
    return this.actionListener('extractXmlFromPdfOfInvoices').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          extractXmlFromPdfOfInvoices,
          params,
        ) as Observable<ExtractXmlFromPdfOfInvoicesMutation>;
      }),
      map(
        (data) => data?.wawiAssist?.extractXmlFromPdfOfInvoices as ExtractXmlFromPdfOfInvoicesRes,
      ),
    );
  }

  getDataForMultiEmails(): Observable<DataForMultiEmailsRes> {
    return this.actionListener('getDataForMultiEmails').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getDataForMultiEmails,
          params,
        ) as Observable<ListWa_DataForMultiEmailsQuery>;
      }),
      map((data) => data.wawiAssist?.listWA_DataForMultiEmails as DataForMultiEmailsRes),
    );
  }
}

class ReportsService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const reportsService = new ReportsService();

interface IRetryerArgs {
  retryCondition: (err: ClientError) => boolean;
  attempts?: number;
  interval?: number;
}

export type GetWAPdfHistoryReportRes = NonNullable<
  NonNullable<GetWa_PdfHistoryReportQuery['wawiAssist']>['getWA_PdfHistoryReport']
>;
export type ListWAPdfHistoryReportRes = NonNullable<
  NonNullable<ListWa_PdfHistoryReportsQuery['wawiAssist']>['listWA_PdfHistoryReports']
>;
export type SendEmailWithDocumentRes = NonNullable<
  NonNullable<SendEmailWithDocumentMutation['wawiAssist']>['sendEmailWithDocument']
>;
export interface SendEmailWithDocumentPlusParamsRes extends SendEmailWithDocumentRes {
  docType: C_Email_Msg_Doctype;
  documentId: number | string;
}
export type ExtractXmlFromPdfOfInvoicesRes = NonNullable<
  NonNullable<ExtractXmlFromPdfOfInvoicesMutation['wawiAssist']>['extractXmlFromPdfOfInvoices']
>;

export type DataForMultiEmailsRes = NonNullable<
  NonNullable<ListWa_DataForMultiEmailsQuery['wawiAssist']>['listWA_DataForMultiEmails']
>;

export const getPdfDocTypeFromEmailDocType = (
  emailDocType: C_Email_Msg_Doctype,
): C_History_Pdf_Doctype | undefined => {
  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_INVOICE:
      return C_History_Pdf_Doctype.PDF_INVOICE;

    case C_Email_Msg_Doctype.EML_ORDER:
      return C_History_Pdf_Doctype.PDF_ORDER;

    case C_Email_Msg_Doctype.EML_OFFER:
      return C_History_Pdf_Doctype.PDF_OFFER;

    default:
      return undefined;
  }
};

export const getFileNameForEmailWithDocument = (
  t: TFunction,
  emailDocType: C_Email_Msg_Doctype,
  documentNo: number,
  reportId: C_Report,
): string => {
  let attachmentFileName: string;

  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_ORDER:
      attachmentFileName = `${t('common.delivery_note')}_${documentNo}.pdf`;
      break;

    case C_Email_Msg_Doctype.EML_OFFER:
      attachmentFileName = `${t('offer.offer')}_${documentNo}.pdf`;
      break;

    case C_Email_Msg_Doctype.EML_INVOICE:
      switch (reportId) {
        case C_Report.R9_INVOICE_A4:
        case C_Report.R19_INVOICE_A4_DETAILED:
        case C_Report.R31_INVOICE_A4_ARTICLE_GROUPING:
        case C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING:
          attachmentFileName = `${t('invoice.invoice')}_${documentNo}.pdf`;
          break;

        case C_Report.R18_INVOICE_PAYMENTSLIP:
        case C_Report.R59_INVOICE_PAYMENTSLIP_WITH_QR:
          attachmentFileName = `${t('invoice.payment_slip')}_${documentNo}.pdf`;
          break;

        case C_Report.R17_INVOICE_SUMMARY:
        case C_Report.R52_DELIVERY_OVERVIEW:
          attachmentFileName = `${t('invoice.invoice_summary')}_${documentNo}.pdf`;
          break;

        default:
          attachmentFileName = `${t('common.report')}.pdf`;
      }
      break;

    default:
      attachmentFileName = `${t('common.report')}.pdf`;
  }

  return attachmentFileName;
};

export const getFileNameForInvoiceXml = (documentNo: number): string => {
  return `xrechnung_${documentNo}.xml`;
};

export const getTextForEmailWithDocument = (
  t: TFunction,
  emailDocType: C_Email_Msg_Doctype,
  documentNoReplacement?: number,
): string => {
  const configs = companyConfigService.getCachedConfigs();
  let emailText = '';

  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_INVOICE:
      emailText = configs?.isDefaultEmailTextForInvoice
        ? t('invoice.email_message')
        : configs?.customEmailTextForInvoice ?? '';
      if (documentNoReplacement !== null) {
        emailText = emailText.replace('[FakturaNr]', String(documentNoReplacement));
      }
      break;

    case C_Email_Msg_Doctype.EML_ORDER:
      emailText = configs?.isDefaultEmailTextForOrder
        ? t('order.email_message')
        : configs?.customEmailTextForOrder ?? '';
      if (documentNoReplacement !== null) {
        emailText = emailText.replace('[LieferscheinNr]', String(documentNoReplacement));
      }
      break;

    case C_Email_Msg_Doctype.EML_OFFER:
      emailText = configs?.isDefaultEmailTextForOffer
        ? t('offer.email_message')
        : configs?.customEmailTextForOffer ?? '';
      if (documentNoReplacement !== null) {
        emailText = emailText.replace('[OfferNr]', String(documentNoReplacement));
      }
      break;

    case C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE:
      emailText = configs?.isDefaultEmailTextForDebtor
        ? t('debitor.emailMessage')
        : configs?.customEmailTextForDebtor ?? '';
      break;
  }

  return emailText;
};

export const getSubjectForEmailWithDocument = (
  t: TFunction,
  emailDocType: C_Email_Msg_Doctype,
  documentNo?: number,
  documentDate?: Date,
): string => {
  let subject = '';

  switch (emailDocType) {
    case C_Email_Msg_Doctype.EML_INVOICE:
      subject =
        documentNo !== undefined
          ? `${t('common.invoice')} ${documentNo}` +
            (documentDate !== undefined
              ? ` ${t('common.for').toLowerCase()} ${localeFormatterHelper.formatDate(
                  documentDate,
                )}`
              : '')
          : t('common.invoice');
      break;

    case C_Email_Msg_Doctype.EML_ORDER:
      subject =
        documentNo !== undefined
          ? `${t('common.order')} ${documentNo}` +
            (documentDate !== undefined
              ? ` ${t('common.for').toLowerCase()} ${localeFormatterHelper.formatDate(
                  documentDate,
                )}`
              : '')
          : t('common.order');
      break;

    case C_Email_Msg_Doctype.EML_OFFER:
      subject =
        documentNo !== undefined
          ? `${t('common.offer')} ${documentNo}` +
            (documentDate !== undefined
              ? ` ${t('common.for').toLowerCase()} ${localeFormatterHelper.formatDate(
                  documentDate,
                )}`
              : '')
          : t('common.offer');
      break;

    case C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE:
      subject = t('debitor.reminder');
      break;
  }

  return subject;
};

export const getFilesDataToExtractXmlFromPdfOfInvoices = (
  reportsData: ListWAPdfHistoryReportRes['reports'],
): Array<Wa_PdfFileOfInvoiceInput> => {
  const justInvoicePdfsData = reportsData.filter(
    (pdfFileData) =>
      pdfFileData.reportId === C_Report.R9_INVOICE_A4 ||
      pdfFileData.reportId === C_Report.R19_INVOICE_A4_DETAILED ||
      pdfFileData.reportId === C_Report.R31_INVOICE_A4_ARTICLE_GROUPING ||
      pdfFileData.reportId === C_Report.R39_INVOICE_A4_CUSTOMER_GROUPING,
  );

  return justInvoicePdfsData.map((invoicePdfData) => {
    return {
      invoiceId: invoicePdfData.documentId,
      pdfFile: {
        s3Bucket: invoicePdfData.bucket,
        s3Key: invoicePdfData.s3Key ?? '',
      },
    };
  });
};
