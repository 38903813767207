import { Box, styled } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from '../shared/components/navigation/components/navLink.component';
import { Nav } from '../shared/components/navigation/nav.component';
// Date-picker
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import { take } from 'rxjs';
// Nav icons
// import ProcurementIco from '@mui/icons-material/AddShoppingCart';
// import OfferIco from '@mui/icons-material/BusinessCenter';
import ProductionIco from '@mui/icons-material/Factory';
import InvoiceIco from '@mui/icons-material/LocalAtm';
import BarChartIcon from '@mui/icons-material/BarChart';
import DebitorIco from '@mui/icons-material/PendingActions';
import CustomerIco from '@mui/icons-material/Person';
import SettingsIco from '@mui/icons-material/Settings';
import OrderIco from '@mui/icons-material/ShoppingCartCheckout';
import ArticleIco from '@mui/icons-material/ViewInAr';
import { BusinessCenter } from '@mui/icons-material';
import { Outlet, useNavigation } from 'react-router-dom';

import { environment } from '../environment.ts';
import { C_Email_Msg_Doctype, C_Language } from '../graphql/generatedModel.ts';
import { invoiceDetailsState } from '../pages/invoice/list/states/invoiceDetails.state.ts';
import { orderDetailsState } from '../pages/order/edit/states/orderDetails/orderDetails.state.ts';
import { Guard } from '../shared/components/guard/guard.component.tsx';
import { transitionService } from '../shared/components/loading/service/transition.service.ts';
import { Transition } from '../shared/components/loading/transition.component';
import { SubNavLink } from '../shared/components/navigation/components/subNavLink.component.tsx';
import { TopBar } from '../shared/components/navigation/components/topBar/topBar.component.tsx';
import { snackbarService } from '../shared/components/snackbar/service/snackbar.service.ts';
import { localeFormatterHelper } from '../shared/helpers/formatter/localeFormatter.helper.ts';
import { storageHelper } from '../shared/helpers/storage';
import { loggedOutPopup, useGlobalState } from '../shared/hooks/useGlobalState.tsx';
import useLanguage from '../shared/hooks/useLanguage.tsx';
import { responseHandler } from '../shared/responseHandler/responseHandler.ts';
import { companyConfigService } from '../shared/services/companyConfig/companyConfig.service.ts';
import { Navigate } from '../shared/services/navigate/components/navigate.component.tsx';
import {
  reportsService,
  SendEmailWithDocumentPlusParamsRes,
} from '../shared/services/reports/reports.service.ts';
import { offerDetailsState } from '../pages/offer/states/offerDetails/offerDetails.state.ts';

const RootLayout: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { changeLanguageHandler } = useLanguage();
  const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
  const { state } = useNavigation();
  const { i18n, t } = useTranslation();

  const localeFns = useMemo(() => {
    switch (i18n.language) {
      case 'de':
        return de;
      case 'en':
        return en;
      case 'fr':
        return fr;
      case 'it':
        return it;
      default:
        return de;
    }
  }, [i18n.language]);

  useEffect(() => {
    companyConfigService
      .getConfigs()
      .pipe(take(1))
      .subscribe((v) => {
        localeFormatterHelper.setCompanyConfig(v);
        let language: C_Language = C_Language.L2_GERMAN;

        if (appEnv === 'production') {
          language = v.currentUser.userLanguageId || C_Language.L2_GERMAN;
        } else if (appEnv === 'development') {
          language =
            storageHelper.local.getItem('sys_data.language') ||
            v.currentUser.userLanguageId ||
            C_Language.L2_GERMAN;
        }
        changeLanguageHandler(language);
      });

    const emailSendingNotificationsSub = reportsService.sub
      .sendEmailWithDocument()
      .pipe(
        responseHandler<SendEmailWithDocumentPlusParamsRes>({
          customErrorHandler: () => 'common.problem_while_sending_email',
          errorReturnType: {
            sentMessages: [],
            unsentMessages: [],
            documentId: '',
            docType: C_Email_Msg_Doctype.EML_ORDER,
          },
          quite: true,
        }),
      )
      .subscribe((res) => {
        let upgradableService;
        const { documentId, docType } = res;
        switch (docType) {
          case C_Email_Msg_Doctype.EML_ORDER:
            upgradableService = orderDetailsState;
            break;
          case C_Email_Msg_Doctype.EML_INVOICE:
            upgradableService = invoiceDetailsState;
            break;
          case C_Email_Msg_Doctype.EML_OFFER:
            upgradableService = offerDetailsState;
            break;
          default:
            upgradableService = null;
        }
        snackbarService.pub.hide('emailWithReportLoading');
        if (res.sentMessages?.length && !res.unsentMessages?.length) {
          snackbarService.pub.show({
            content: t('common.email_sent_successfully', { count: res.sentMessages.length }),
            type: 'success',
          });
          if (upgradableService && documentId) {
            const datetimeOfLastEmail = res.sentMessages[res.sentMessages.length - 1].timestamp;
            upgradableService.pub.updateLastEmailDetails(datetimeOfLastEmail, String(documentId));
          }
        }
        if (res.sentMessages?.length && res.unsentMessages?.length) {
          snackbarService.pub.show({
            content: `${t('common.emails_sent_successfully', {
              successCount: res.sentMessages?.length,
            })}\n${t('common.emails_failed_to_send', {
              failureCount: res.unsentMessages?.length,
            })}`,
            type: 'warning',
          });
          if (upgradableService && documentId) {
            const datetimeOfLastEmail = res.sentMessages[res.sentMessages.length - 1].timestamp;
            upgradableService.pub.updateLastEmailDetails(datetimeOfLastEmail, String(documentId));
          }
        }
        if (!res.sentMessages?.length && res.unsentMessages?.length) {
          snackbarService.pub.show({
            content: t('common.problem_while_sending_email', { count: res.unsentMessages.length }),
            type: 'error',
          });
        }
      });

    return () => {
      emailSendingNotificationsSub.unsubscribe();
    };
  }, []);

  useGlobalState();

  useEffect(() => {
    if (state === 'idle') {
      setLoading(false);
      transitionService.pub.on();
    }
    if (state === 'loading') {
      setLoading(true);
    }
  }, [state]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeFns}>
      <Navigate />
      <SRootLayout>
        <Nav>
          <Box sx={{ overflow: 'hidden auto' }}>
            <Guard module='customer'>
              <NavLink to='/customer/general' icon={CustomerIco} label={t('customer.customer')} />
            </Guard>
            <Guard module='article'>
              <NavLink to='/article/general' icon={ArticleIco} label={t('article.article')} />
            </Guard>
            <Guard module='offer'>
              <NavLink to='/offer' icon={BusinessCenter} label={t('offer.offer')} />
            </Guard>
            <Guard module='order'>
              <NavLink to='order' icon={OrderIco} label={t('order.order')}>
                <Guard module='order' componentName='createOrder'>
                  <SubNavLink to='/order/create' label={t('order.create_order')} />
                </Guard>
                <Guard module='order' componentName='editOrder'>
                  <SubNavLink to='/order/edit' label={t('order.edit_order')} />
                </Guard>
                <Guard module='order' componentName='orderOverview'>
                  <SubNavLink to='/order/overview' label={t('order.overview')} />
                </Guard>
              </NavLink>
            </Guard>
            <Guard module='production'>
              <NavLink to='production' icon={ProductionIco} label={t('production.production')}>
                <Guard module='production' componentName='printJobs'>
                  <SubNavLink
                    to='/production/print-jobs/production'
                    label={t('production.print_jobs')}
                  />
                </Guard>
                <>
                  <Guard module='production' componentName='editPrintList'>
                    <SubNavLink
                      to='/production/edit-print-list/general'
                      label={t('production.edit_print_list')}
                    />
                  </Guard>
                  <Guard module='production' componentName='stockOfFreezer'>
                    <SubNavLink
                      to='/production/stock-of-freezer'
                      label={t('production.stock_of_freezer')}
                    />
                  </Guard>
                </>
              </NavLink>
            </Guard>
            <Guard module='invoice'>
              <NavLink to='invoice' icon={InvoiceIco} label={t('invoice.invoice')}>
                <Guard module='invoice' componentName='createInvoice'>
                  <SubNavLink to='/invoice/create-invoice' label={t('invoice.create_invoice')} />
                </Guard>
                <Guard module='invoice' componentName='invoiceList'>
                  <SubNavLink to='/invoice/invoices-list' label={t('invoice.invoices_list')} />
                </Guard>
              </NavLink>
            </Guard>
            <Guard module='debitor'>
              <NavLink to='debitor' icon={DebitorIco} label={t('debitor.debitor')}>
                <Guard module='debitor' componentName='openDebts'>
                  <SubNavLink to='/debitor/open-debts' label={t('debitor.openDebts')} />
                </Guard>
                <Guard module='debitor' componentName='payments'>
                  <SubNavLink to='/debitor/payments' label={t('debitor.payments')} />
                </Guard>
                <Guard module='debitor' componentName='importIso'>
                  <SubNavLink to='/debitor/import-iso' label={t('debitor.importIso')} />
                </Guard>
              </NavLink>
            </Guard>
            <Guard module='statistic'>
              <NavLink to='statistic' icon={BarChartIcon} label={t('statistic.statistic')}>
                <Guard module='statistic' componentName='orderStatistic'>
                  <SubNavLink
                    to='/statistic/order-statistic'
                    label={t('statistic.order_statistic')}
                  />
                </Guard>
                <Guard module='statistic' componentName='productionStatistic'>
                  <SubNavLink
                    to='/statistic/production-statistic'
                    label={t('statistic.production_statistic')}
                  />
                </Guard>
              </NavLink>
            </Guard>
            <Guard module='settings'>
              <NavLink
                to='/settings/company/company'
                icon={SettingsIco}
                label={t('common.settings')}
              />
            </Guard>
          </Box>
        </Nav>
        <Box
          sx={{
            pl: (theme) => theme.nav.width,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <TopBar />
          <Transition show={loading}>
            <Outlet />
          </Transition>
        </Box>
      </SRootLayout>
      <loggedOutPopup.Component />
    </LocalizationProvider>
  );
};
export default RootLayout;

const SRootLayout = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  top: 0,
  left: 0,
  opacity: 1,
  transition: 'opacity 1s',
  maxWidth: '1980px',
  minWidth: '1366px',
  width: '100vw',
  height: '100vh',
  margin: '0 auto',
  borderLeft: `1px solid ${theme.palette.divider}`,
  borderRight: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden',
}));
