import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';

import { Popup, TPopupComponent } from '../../popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../popup/services/popup.service.ts';

export class WarningPopup extends Popup<IWarningPopup> {
  Component: FC<TPopupComponent>;

  constructor(params?: { width?: number; closureOverlay?: boolean }) {
    super();
    const stream = this.innerStream;
    const width = params?.width || 500;
    const closureOverlay = params?.closureOverlay;
    this.Component = () => {
      const Wrapper = useMemo(() => this.Wrapper, []);

      const { t } = useTranslation();
      const [message, setMessage] = useState<string>('');
      const handleApply = useRef<() => void | undefined>();
      useEffect(() => {
        stream.actionListener('open').subscribe(({ onApply, message }) => {
          setMessage(message);
          handleApply.current = onApply;
        });
      }, []);
      const onApply = () => {
        handleApply.current?.();
        stream.emit('close');
      };

      return (
        <Wrapper
          popupTitle={t('common.warning')}
          PaperProps={{ sx: { maxWidth: width } }}
          fullWidth
          {...{ closureOverlay, stream }}
        >
          <Typography>{message}</Typography>
          <Stack mt={3} direction='row' justifyContent='end'>
            <Button onClick={onApply} variant='contained'>
              {t('common.ok')}
            </Button>
          </Stack>
        </Wrapper>
      );
    };
  }
}

interface IWarningPopup {
  action: TPopupDefaultActions;
  onApply?: () => void;
  message: string;
}
