import { FC, useLayoutEffect, ReactNode } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import styled from '@mui/material/styles/styled';
import {
  TooltipProps,
  Tooltip,
  tooltipClasses,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Theme,
} from '@mui/material';
import { TypeColors } from '@mui/material/styles/createPalette';
import { AddInfoIcon } from '../../../svg/custom.svg.tsx';
import { FormTextField } from '../form';
import { useForm, FormProvider } from 'react-hook-form';
import { customerService } from '../../../pages/customer/services/customer.service.ts';
import { take } from 'rxjs';
import { snackbarService } from '../snackbar/service/snackbar.service.ts';
import { notificationCenter } from '../../notificationCenter/notificationCenter.ts';
import { useTranslation } from 'react-i18next';
import { responseHandler } from '../../responseHandler/responseHandler.ts';

export const InfoTipByCustomer: FC<IInfoTip> = ({
  readonly = false,
  text = '',
  children,
  sxSvgProps,
  customerId,
  hidden = false,
  successMessage,
}) => {
  const { t } = useTranslation();
  const formMethods = useForm<Pick<IInfoTip, 'text'>>({
    defaultValues: {
      text,
    },
    mode: 'onBlur',
  });
  const {
    reset,
    watch,
    formState: { isDirty },
    getValues,
  } = formMethods;

  useLayoutEffect(() => {
    const clearedInfoTip =
      text
        ?.replace(/<head>[\s\S]*<\/head>|<[^>]+>/g, '')
        .trim()
        .replace(/\n\s*\n+/g, '\n') || '';
    reset({ text: clearedInfoTip });
  }, [text]);

  const handleClose = () => {
    if (isDirty && customerId) {
      notificationCenter.notify(customerService.sub.editCustomerData(), {
        type: undefined,
        handler: (stream) => {
          stream.pipe(take(1), responseHandler()).subscribe((res) => {
            if (!!res) {
              snackbarService.pub.show({
                type: 'success',
                content: successMessage || t('customer.note_saved'),
              });
            }
            reset({ text: getValues('text') });
          });
        },
      });
      customerService.pub.editCustomerData({
        data: {
          id: customerId,
          informationTip: getValues('text'),
        },
      });
    }
  };
  return (
    <FormProvider {...formMethods}>
      <Stack direction='row' alignItems='center' spacing='6px'>
        {children}
        {!hidden && (watch('text') || !readonly) && (
          <STooltipWrapper
            onClose={handleClose}
            title={
              <SInfoContent>
                <SFormTextField
                  fullWidth
                  width='auto'
                  disabled={readonly}
                  readOnlyDesign={readonly}
                  name='text'
                  multiline
                  {...(!readonly && {
                    label: t('common.note'),
                  })}
                />
                <STextHelper>{watch('text')}</STextHelper>
              </SInfoContent>
            }
            placement='right'
          >
            <SIconButton>
              {!watch('text') && !readonly ? (
                <AddInfoIcon sx={sxSvgProps} />
              ) : (
                <InfoIcon sx={sxSvgProps} />
              )}
            </SIconButton>
          </STooltipWrapper>
        )}
      </Stack>
    </FormProvider>
  );
};

const SInfoContent = styled(Paper)(() => ({
  padding: 10,
  fontSize: 14,
  display: 'flex',
  flexDirection: 'column',
}));

const STextHelper = styled('pre')(({ theme }) => ({
  display: 'block',
  visibility: 'visible',
  maxWidth: 500,
  paddingLeft: '14px',
  paddingRight: '14px',
  overflow: 'hidden',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  margin: 0,
  height: 0,
}));

const STooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    maxWidth: '500px',
  },
}));

const SFormTextField = styled(FormTextField, {
  shouldForwardProp: (prop) => prop !== 'readOnlyDesign',
})<{ readOnlyDesign: boolean }>(({ readOnlyDesign }) => ({
  ...(readOnlyDesign && {
    '& div': {
      padding: 0,
    },
    '& .Mui-disabled': {
      color: 'inherit !important',
      textFillColor: 'inherit !important',
    },
    '& fieldset': {
      borderWidth: 0,
    },
  }),
}));

const SIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'colorBtn',
})<{ colorBtn?: TypeColors }>(({ theme, colorBtn }) => ({
  padding: 4,
  position: 'relative',
  color: `${theme.palette[colorBtn || 'lightBlue'].main}90`,
  '&:hover': {
    backgroundColor: `${theme.palette[colorBtn || 'lightBlue'].main}10`,
  },
}));

interface IInfoTip {
  text?: null | string;
  readonly?: boolean;
  customerId?: string;
  sxSvgProps?: SxProps<Theme>;
  children?: ReactNode;
  hidden?: boolean;
  successMessage?: string;
}
