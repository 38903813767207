import { articleDe, articleEn, articleFr, articleIt } from './pages/article/article.i18n';
import { customerDe, customerEn, customerFr, customerIt } from './pages/customer/customer.i18n.ts';
import {
  productionDe,
  productionEn,
  productionFr,
  productionIt,
} from './pages/production/production.i18n.ts';
import { commonDe, commonEn, commonFr, commonIt } from './shared/i18n/common.i18n.ts';
import { enumsDe, enumsEn, enumsFr, enumsIt } from './shared/i18n/enums.i18n.ts';
import { orderDe, orderEn, orderFr, orderIt } from './pages/order/order.i18n';
import { invoiceDe, invoiceEn, invoiceFr, invoiceIt } from './pages/invoice/invoice.i18n.ts';
import { errorsDe, errorsEn, errorsFr, errorsIt } from './shared/i18n/errors.i18n.ts';
import { settingsDe, settingsEn, settingsFr, settingsIt } from './pages/settings/settings.i18n.ts';
import { debitorDe, debitorEn, debitorFr, debitorIt } from './pages/debitor/debitor.i18n.ts';
import { offerDe, offerEn, offerFr, offerIt } from './pages/offer/offer.i18n.ts';
import {
  statisticDe,
  statisticEn,
  statisticFr,
  statisticIt,
} from './pages/statistic/statistic.i18n.ts';

const de = {
  ...commonDe,
  ...enumsDe,
  ...articleDe,
  ...orderDe,
  ...customerDe,
  ...productionDe,
  ...invoiceDe,
  ...debitorDe,
  ...errorsDe,
  ...settingsDe,
  ...offerDe,
  ...statisticDe,
};

const en = {
  ...commonEn,
  ...enumsEn,
  ...articleEn,
  ...orderEn,
  ...customerEn,
  ...productionEn,
  ...invoiceEn,
  ...debitorEn,
  ...errorsEn,
  ...settingsEn,
  ...offerEn,
  ...statisticEn,
};

const fr = {
  ...commonFr,
  ...enumsFr,
  ...articleFr,
  ...orderFr,
  ...customerFr,
  ...productionFr,
  ...invoiceFr,
  ...debitorFr,
  ...errorsFr,
  ...settingsFr,
  ...offerFr,
  ...statisticFr,
};

const it = {
  ...commonIt,
  ...enumsIt,
  ...articleIt,
  ...orderIt,
  ...customerIt,
  ...productionIt,
  ...invoiceIt,
  ...debitorIt,
  ...errorsIt,
  ...settingsIt,
  ...offerIt,
  ...statisticIt,
};

export const i18nResorces = {
  de: { translation: de },
  en: { translation: en },
  fr: { translation: fr },
  it: { translation: it },
};
