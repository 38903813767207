import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldsRow,
  FormSelect,
  FormTextField,
  FormTimePicker,
  SelectMenuWithFooter,
} from '../../../../../../shared/components/form';
import { NoteButton } from '../../../../../../shared/components/form/buttons/noteButton.component';
import { DictTextBlocksRes } from '../../../../../../shared/services/configsData/configsData.service';
import { useVisibilityGUID } from '../../../../../../shared/hooks/useVisibilityGUID';
import { additionalNoteCompanies } from '../../../../../customer/components/tabs/forms/components/formsForm.component.tsx';
import { useFormContext } from 'react-hook-form';
import { NotePopup } from '../../../../common/popups/note.popup';
import { valueHelper } from '../../../../../../shared/helpers/valueProcessor/value.helper';
import { IconButton } from '../../../../common/iconButton/iconButton.component.tsx';
import TuneIcon from '@mui/icons-material/Tune';
import { ICustomer } from '../../../../loaders/editOrder.loader.ts';
import { C_Order_Type } from '../../../../../../graphql/generatedModel.ts';
import { isChashAssistOrder } from '../../orderList/orderList.row.ts';
import { MoreControlsPopup } from './popups/moreControls.popup.tsx';
import { Badge, styled } from '@mui/material';

export const SideControls: FC<ISideControls> = ({
  linkTextOptions,
  errorText,
  customerList,
  orderType,
  isEditable,
}) => {
  const { t } = useTranslation();
  const showAdditionalNote = useVisibilityGUID(additionalNoteCompanies);
  const { getValues } = useFormContext();
  const notePopup = useMemo(
    () => new NotePopup({ isEditable, showAdditionalNote }),
    [showAdditionalNote, isEditable],
  );

  const isNote = useMemo(
    () =>
      valueHelper.checkValueInHtml(getValues('note')) ||
      (showAdditionalNote && valueHelper.checkValueInHtml(getValues('note2'))),
    [getValues(), showAdditionalNote],
  );
  const isCashAssist = isChashAssistOrder(orderType);
  const moreControlsPopup = useMemo(
    () => new MoreControlsPopup({ customerList, isCashAssist, isEditable }),
    [isCashAssist, customerList, isEditable],
  );

  return (
    <FieldsRow direction={'row'} spacing={2} alignItems='start'>
      <FormTextField
        disabled={!isEditable}
        inputProps={{ maxLength: 32 }}
        name='referenceOrderNo'
        width='220px'
        label={t('order.reference')}
      />
      <FormTimePicker
        name='deliveryTime'
        label={t('order.delivery_time')}
        width={150}
        fieldProps={{ disabled: !isEditable, ...(errorText && { color: 'error' }) }}
        errorText={errorText && `${t('order.invalid_value')}: ${errorText}`}
      />
      <FormSelect
        disabled={!isEditable}
        name='linkText'
        options={linkTextOptions as []}
        label={t('order.text')}
        width='220px'
        PaperComponent={(props) => (
          <SelectMenuWithFooter {...props} href='/settings/print-forms/general' />
        )}
      />
      <NoteButton
        badged={isNote}
        title={t('common.note')}
        view='small'
        style={{ minWidth: 'fit-content' }}
        onClick={() =>
          notePopup.stream.emit('open', {
            values: {
              main: getValues('note'),
              additional: getValues('note2'),
            },
          })
        }
      />
      <IconButton
        title={t('order.more_settings')}
        onClick={() =>
          moreControlsPopup.stream.emit('open', {
            values: {
              customerId: getValues('customerId'),
              orderDate: getValues('orderDate'),
              caCashRegisterNo: getValues('caCashRegisterNo'),
              caDeliveryMethod: getValues('caDeliveryMethod'),
              caDeliveryTime: getValues('caDeliveryTime'),
              caReceiptNo: getValues('caReceiptNo'),
              caUserName: getValues('caUserName'),
            },
          })
        }
      >
        {isCashAssist && <SBadge variant='dot' color='primary' />}
        <TuneIcon />
      </IconButton>
      <moreControlsPopup.Component />
      <notePopup.Component />
    </FieldsRow>
  );
};

const SBadge = styled(Badge)({
  position: 'absolute',
  top: '7px',
  right: '7px',
});

interface ISideControls {
  errorText: undefined | string;
  linkTextOptions: DictTextBlocksRes;
  customerList: ICustomer[];
  orderType: C_Order_Type;
  isEditable: boolean;
}
