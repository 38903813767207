import { FC, useEffect, useState, useMemo } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Badge, BadgeProps, Box, Stack, styled } from '@mui/material';
import { IDeliverySplittingState } from '../../deliverySplitting.state.ts';

export const SplittingCell: FC<ISplittingCell> = ({ params, calculatedSplitting }) => {
  const { id, value, field, row } = params;
  const [calculatedVal, setCalculatedVal] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<'success' | 'error'>('success');

  useEffect(() => {
    if (id === calculatedSplitting.id) {
      const fieldName = field.split('.')[0];
      const quantity = row[fieldName].quantity;
      if (quantity) {
        const day = fieldName.split('Settings')[0];
        const index = field.split('_')[1];
        const calculatedDay =
          calculatedSplitting[`${day}Parts` as keyof typeof calculatedSplitting];
        let sumCalculatedDay: number | undefined = undefined;
        if (Array.isArray(calculatedDay) && calculatedDay.length) {
          sumCalculatedDay = calculatedDay.reduce((acc: number, item, i) => {
            if (i === Number(index)) setCalculatedVal(item);
            acc += Number(item);
            return acc;
          }, 0);
        } else if (typeof calculatedDay === 'number') {
          setCalculatedVal(String(calculatedDay));
          sumCalculatedDay = calculatedDay;
        }
        setStatus(Number(quantity) === sumCalculatedDay ? 'success' : 'error');
      }
    }
  }, [row, calculatedSplitting, id]);

  const isOpacityCell = useMemo(() => {
    const partNumber = Number(field.split('_')[1] || 0) + 1;
    const parts = Number(row.deliverySplittingPartsCount || 0);
    const isDeliverySplitting = row.isDeliverySplitting;
    return !isDeliverySplitting || partNumber > parts;
  }, [row, field]);

  return (
    <Stack flexDirection='row' alignItems='center' height={'100%'}>
      <StyledBadge
        badgeContent={calculatedVal}
        invisible={!(id === calculatedSplitting.id && calculatedVal !== undefined)}
        color={status}
        max={100000}
      />
      <Box
        sx={{ opacity: isOpacityCell ? 0.5 : 1 }}
        marginLeft={`${typeof value === 'string' && value.length ? '6px' : '0px'}`}
      >
        {value}
      </Box>
    </Stack>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    position: 'initial',
    padding: '0 3px',
    transform: 'initial',
    fontSize: 10,
    fontWeight: 'bold',
    minWidth: 15,
    height: 15,
    transition: 'initial',
  },
}));
interface ISplittingCell {
  params: GridRenderCellParams;
  calculatedSplitting: IDeliverySplittingState['calculatedSplitting'];
}
