import { FC, useState, useLayoutEffect, useMemo, useEffect } from 'react';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { useTranslation } from 'react-i18next';
import { IDefaultOrderGridData, defaultOrderState } from '../../../../defaultOrder.state.ts';
import {
  weekDays,
  formatValue,
} from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';
import { ToggleButton, styled, Stack, Button, Box } from '@mui/material';
import { FormToggleButtonGroup } from '../../../../../../../../../shared/components/form/formConnected/formToggleButtonGroup.tsx';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import equal from 'fast-deep-equal/react';
import { Table } from '../../../../../../../../../shared/components/table/table.component.tsx';
import { storageHelper } from '../../../../../../../../../shared/helpers/storage';
import { useFormContext } from 'react-hook-form';
import {
  attachSplittingsToQuantity,
  generateSplittingColumns,
  clearDataFromVirtualKeys,
} from './cells/additionalFunctions.tsx';
import { Column } from '../../../../../../../../../shared/components/table/components/common.components.tsx';
import { CustomerCell } from './cells/customer.cell.tsx';
import { deliverySplittingState, IDeliverySplittingState } from './deliverySplitting.state.ts';
import { Checkbox } from '../../../../../../../../../shared/components/form';
import { viewCells } from '../../gridItems/views/viewCells.ts';
import { viewRows } from '../../gridItems/views/viewRows.ts';

export const ContentPopup: FC<IContentPopup> = ({ stream }) => {
  const { t } = useTranslation();
  const [initTableData, setInitTableData] = useState<IDefaultOrderGridData>([]);
  const [tableData, setTableData] = useState<IDefaultOrderGridData>([]);
  const [customerCellLoading, setCustomerCellLoading] = useState<number | null>(null);
  const { watch } = useFormContext();
  const [calculatePositionSplitting, setCalculatePositionSplitting] = useState<
    IDeliverySplittingState['calculatedSplitting']
  >({});
  const [selectedPos, setSelectedPos] = useState<IDeliverySplittingState['selectedPos']>();
  const isOnlyOneTurnedOnSplitting: boolean = useMemo(() => {
    if (initTableData.length) {
      return !!initTableData.find((pos) => pos.isDeliverySplitting);
    }
    return false;
  }, [initTableData]);
  const maxCountOfSplittingParts: number = useMemo(() => {
    return initTableData.reduce(
      (acc, pos) => Math.max(acc, pos?.deliverySplittingPartsCount || 0),
      0,
    );
  }, [initTableData]);

  useLayoutEffect(() => {
    stream.actionListener('open').subscribe(({ tableData }) => {
      setTableData(tableData);
      setInitTableData(structuredClone(tableData));
      deliverySplittingState.pub.initGridData(tableData);
    });
  }, []);

  useEffect(() => {
    const deliverySplittingStateSub = deliverySplittingState.sub
      .state()
      .subscribe(({ deliverySplittingGridData, selectedPos, calculatedSplitting }) => {
        setCalculatePositionSplitting(calculatedSplitting);
        setTableData(deliverySplittingGridData);
        setSelectedPos(selectedPos);
      });
    const customerCellsLoadingSub = deliverySplittingState.sub
      .customerCellLoading()
      .subscribe(setCustomerCellLoading);
    return () => {
      deliverySplittingStateSub.unsubscribe();
      customerCellsLoadingSub.unsubscribe();
    };
  }, []);

  const handleApply = () => {
    defaultOrderState.pub.updateGridData(clearDataFromVirtualKeys(tableData));
    stream.emit('close');
  };

  const weekDayButtons = useMemo(
    () =>
      weekDays.map((day, i) => (
        <ToggleButton
          key={i}
          title={t(`common.${day}`)}
          value={day}
          size='small'
          sx={{ flex: 1, height: '40px', maxWidth: 140, minWidth: 30 }}
        >
          <SButtonName>{t(`common.${day}`)}</SButtonName>
        </ToggleButton>
      )),
    [t],
  );

  useBeforeClosePopup({
    stream,
    isDirty: !equal(tableData, initTableData),
  });
  return (
    <Box>
      <Stack direction='row' mb={3} mt={2}>
        <SFormToggleButtonGroup
          disabled={!isOnlyOneTurnedOnSplitting}
          name='enabledDays'
          sx={{ width: '100%', display: 'flex' }}
        >
          {weekDayButtons}
        </SFormToggleButtonGroup>
      </Stack>
      <STable
        data={tableData}
        heightOffset={310}
        initialState={{
          columns: storageHelper.local.getItem('article.defaultOrder.splittingGridColumnModel'),
        }}
        onChangeColumnModel={(v) =>
          storageHelper.local.setItem('article.defaultOrder.splittingGridColumnModel', v)
        }
        columnVisibilityModel={{
          'group_mondaySettings.splittingPartsPreset':
            isOnlyOneTurnedOnSplitting && watch('enabledDays').includes('monday'),
          'group_tuesdaySettings.splittingPartsPreset':
            isOnlyOneTurnedOnSplitting && watch('enabledDays').includes('tuesday'),
          'group_wednesdaySettings.splittingPartsPreset':
            isOnlyOneTurnedOnSplitting && watch('enabledDays').includes('wednesday'),
          'group_thursdaySettings.splittingPartsPreset':
            isOnlyOneTurnedOnSplitting && watch('enabledDays').includes('thursday'),
          'group_fridaySettings.splittingPartsPreset':
            isOnlyOneTurnedOnSplitting && watch('enabledDays').includes('friday'),
          'group_saturdaySettings.splittingPartsPreset':
            isOnlyOneTurnedOnSplitting && watch('enabledDays').includes('saturday'),
          'group_sundaySettings.splittingPartsPreset':
            isOnlyOneTurnedOnSplitting && watch('enabledDays').includes('sunday'),
        }}
        hidingColumnModel={{
          generatedModelFn: attachSplittingsToQuantity,
        }}
        onRowClick={({ row }) => {
          if (row.id !== selectedPos?.id) deliverySplittingState.pub.selectPosition(row);
        }}
        rowSelectionModel={selectedPos?.id}
        onCellEditStop={(v, e) => {
          if (v.reason === 'enterKeyDown') {
            e.defaultMuiPrevented = true;
          }
        }}
        isCellEditable={({ row, field }) => {
          const isSplittingPartsPreset = field.includes('splittingPartsPreset');
          if (isSplittingPartsPreset) {
            const parts = Number(row.deliverySplittingPartsCount || 0);
            const partNumber = Number(field.split('_')[1] || 0) + 1;
            const isDeliverySplitting = row.isDeliverySplitting;
            if (!isDeliverySplitting || partNumber > parts) return false;
          }
          return true;
        }}
        focusTabProps={{ enabled: true }}
        {...{ viewCells, viewRows }}
      >
        <Column sortable={false} headerName={t('common.number')} field='customerNr' width={100} />
        <Column
          sortable={false}
          headerName={t('common.name')}
          renderCell={(params) => <CustomerCell {...{ params, customerCellLoading }} />}
          width={300}
          field='internalOrFullName'
        />
        <Column
          headerName={t('article.enablePartialDelivery')}
          field='isDeliverySplitting'
          sortable={false}
          width={202}
          renderCell={({ value }) => (
            <SCheckboxWrapper>
              <Checkbox size='small' checked={value} readOnly />
            </SCheckboxWrapper>
          )}
          align='center'
          headerAlign='center'
        />
        <Column
          headerName={t('article.numberOfPieces')}
          field='deliverySplittingPartsCount'
          sortable={false}
          width={152}
          align='center'
          headerAlign='center'
        />
        <Column
          sortable={false}
          headerName={t('common.mon')}
          field='mondaySettings.quantity'
          valueGetter={({ row }) => row.mondaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          maxCountOfSplittingParts,
          'mondaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.tue')}
          field='tuesdaySettings.quantity'
          valueGetter={({ row }) => row.tuesdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          maxCountOfSplittingParts,
          'tuesdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.wed')}
          field='wednesdaySettings.quantity'
          valueGetter={({ row }) => row.wednesdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          maxCountOfSplittingParts,
          'wednesdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.thu')}
          field='thursdaySettings.quantity'
          valueGetter={({ row }) => row.thursdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          maxCountOfSplittingParts,
          'thursdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.fri')}
          field='fridaySettings.quantity'
          valueGetter={({ row }) => row.fridaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          maxCountOfSplittingParts,
          'fridaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.sat')}
          field='saturdaySettings.quantity'
          valueGetter={({ row }) => row.saturdaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          maxCountOfSplittingParts,
          'saturdaySettings',
          calculatePositionSplitting,
        )}
        <Column
          sortable={false}
          headerName={t('common.sun')}
          field='sundaySettings.quantity'
          valueGetter={({ row }) => row.sundaySettings.quantity}
          valueFormatter={({ value }) => formatValue(value)}
          width={90}
          headerAlign='right'
          align='right'
        />
        {generateSplittingColumns(
          maxCountOfSplittingParts,
          'sundaySettings',
          calculatePositionSplitting,
        )}
      </STable>
      <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
        <Button onClick={handleApply} variant='contained'>
          {t('common.apply')}
        </Button>
      </Stack>
    </Box>
  );
};
const SCheckboxWrapper = styled(Box)({
  '& label': {
    margin: 0,
  },
});

const SButtonName = styled('span')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const SFormToggleButtonGroup = styled(FormToggleButtonGroup)(({ disabled }) => ({
  ...(disabled && {
    '& .Mui-selected': {
      color: 'rgba(0, 0, 0, 0.26)!important',
      border: '1px solid rgba(0, 0, 0, 0.12)!important',
      backgroundColor: 'transparent',
    },
    '.MuiToggleButtonGroup-middleButton': {
      borderLeft: '1px solid transparent!important',
    },
  }),
}));

const STable = styled(Table)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders [data-field*="splittingPartsPreset"]': {
    backgroundColor: theme.palette.grey['50'],
  },
}));

interface IContentPopup {
  stream: IPopupWrapper['stream'];
}
