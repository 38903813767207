import { Observable, map, of, switchMap, zip } from 'rxjs';
import {
  ListWa_CreatedInvoicesQuery as CreatedInvoicesQuery,
  ListWa_CreatedInvoicesQueryVariables as CreatedInvoicesVariables,
  GetCustomerDetailsQuery,
  GetInvoiceDataQuery,
  GetOrderPositionsForCreatedInvoiceQuery,
  GetOrderPositionsForCreatedInvoiceQueryVariables,
  GetOrdersForCreatedInvoiceQuery,
  GetWa_InvoiceDeletingInfoQueryVariables as GetInvoiceDeletingInfoQueryVariables,
  GetWa_InvoiceDeletingInfoQuery as GetInvoiceDeletingInfoQuery,
  DeleteWa_InvoiceMutation as DeleteInvoiceMutation,
  DeleteWa_InvoiceMutationVariables as DeleteInvoiceMutationVariables,
} from '../../../graphql/generatedModel';
import {
  getCreatedInvoices,
  getInvoiceData,
  getOrderPositionsForCreatedInvoice,
  getOrdersForCreatedInvoice,
  deleteInvoice,
  getInvoiceDeletingInfo,
} from './gql/invoiceList.gql';
import { getCustomerDetails } from './gql/createInvoice.gql';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { CustomerDataRes, transformOrderDataIfMultipayCustomer } from './createInvoice.service';
import { IInvoiceDetailsState } from '../list/states/invoiceDetails.state';
import { Pub, Sub, Service } from '../../../shared/services/service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { addTotalWeight } from '../loaders/createInvoice.loader';

type Actions =
  | 'createdInvoicesList'
  | 'queryData'
  | 'positions'
  | 'getInvoiceDeletingInfo'
  | 'deleteInvoice';
class PubImpl extends Pub<Actions> {
  createdInvoicesList(params: CreatedInvoicesVariables) {
    this.emit('createdInvoicesList', params);
  }
  queryData(params: QueryProps) {
    this.emit('queryData', params);
  }
  positions(params: GetOrderPositionsForCreatedInvoiceQueryVariables) {
    this.emit('positions', params);
  }
  getInvoiceDeletingInfo(params: GetInvoiceDeletingInfoQueryVariables): void {
    this.emit('getInvoiceDeletingInfo', params);
  }
  deleteInvoice(params: DeleteInvoiceMutationVariables): void {
    this.emit('deleteInvoice', params);
  }
}

class SubImpl extends Sub<Actions> {
  createdInvoicesList(): Observable<CreatedInvoicesRes> {
    return this.actionListener('createdInvoicesList').pipe(
      switchMap(({ params }) => {
        return gqlClient(getCreatedInvoices, params) as Observable<CreatedInvoicesQuery>;
      }),
      map((data: CreatedInvoicesQuery) => {
        return (data.wawiAssist?.listWA_CreatedInvoices || []) as CreatedInvoicesRes;
      }),
    );
  }

  queryData(): Observable<QueryDataRes> {
    return this.actionListener('queryData').pipe(
      switchMap(({ params }) => {
        const { customerId, invoiceId, orderSort, isDeleted } = params || {};
        return zip(
          gqlClient(getCustomerDetails, {
            getCustomerDataId: customerId,
          }) as Observable<GetCustomerDetailsQuery>,
          gqlClient(getInvoiceData, { id: invoiceId }) as Observable<GetInvoiceDataQuery>,
          gqlClient(getOrdersForCreatedInvoice, {
            id: invoiceId,
          }) as Observable<GetOrdersForCreatedInvoiceQuery>,
          of(orderSort),
          of(isDeleted),
          of(customerId),
        );
      }),
      map(([customerInfo, invoiceInfo, orders, orderSort, isDeleted, selectedCustomerId]) => {
        const sortModel = orderSort as GridSortModel;
        const sortedOrders = dataHelper
          .data(orders.wawiAssist?.listWA_OrdersForCreatedInvoice as [])
          .sort({ sortModel })
          .result();
        const { isMultipayCustomer, transformedOrders } = transformOrderDataIfMultipayCustomer({
          id: selectedCustomerId,
          orders: sortedOrders as OrdersRes,
        });
        const selectedOrder = transformedOrders?.[0];
        return {
          customerInfo: (customerInfo.wawiAssist?.getCustomerData || {}) as CustomerDataRes,
          orders: transformedOrders as IInvoiceDetailsState['orders'],
          selectedOrder,
          orderPositions: [] as PositionsRes,
          invoiceInfo: invoiceInfo?.wawiAssist?.getInvoiceData,
          isDeleted,
          isMultipayCustomer,
        } as QueryDataRes & { isDeleted: boolean };
      }),
      switchMap((data) => {
        const { isDeleted, ...restData } = data;
        const { orders, selectedOrder } = restData;
        if (!orders?.length || !selectedOrder?.orderId) {
          return zip(of(restData));
        }

        return zip(
          of(restData),
          gqlClient(getOrderPositionsForCreatedInvoice, {
            orderId: selectedOrder.orderId,
            isInvoiceDeleted: isDeleted,
          }) as Observable<GetOrderPositionsForCreatedInvoiceQuery>,
        );
      }),
      map(([data, orderPositionsRes]) => {
        if (!orderPositionsRes) {
          return data as QueryDataRes;
        }
        data.orderPositions = addTotalWeight(
          orderPositionsRes.wawiAssist?.listWA_OrderPositionsForCreatedInvoice || [],
        ) as PositionsRes;
        return data as QueryDataRes;
      }),
    );
  }

  positions(): Observable<PositionsRes> {
    return this.actionListener('positions').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          getOrderPositionsForCreatedInvoice,
          params,
        ) as Observable<GetOrderPositionsForCreatedInvoiceQuery>;
      }),
      map((data: GetOrderPositionsForCreatedInvoiceQuery) => {
        return addTotalWeight(
          data.wawiAssist?.listWA_OrderPositionsForCreatedInvoice || [],
        ) as PositionsRes;
      }),
    );
  }

  getInvoiceDeletingInfo(): Observable<GetInvoiceDeletingInfoRes> {
    return this.actionListener('getInvoiceDeletingInfo').pipe(
      switchMap(({ params }) => {
        return gqlClient(getInvoiceDeletingInfo, params) as Observable<GetInvoiceDeletingInfoQuery>;
      }),
      map((data: GetInvoiceDeletingInfoQuery) => {
        return data.wawiAssist?.getWA_InvoiceDeletingInfo as GetInvoiceDeletingInfoRes;
      }),
    );
  }

  deleteInvoice(): Observable<DeleteInvoiceRes> {
    return this.actionListener('deleteInvoice').pipe(
      switchMap(({ params }) => {
        return gqlClient(deleteInvoice, params) as Observable<DeleteInvoiceMutation>;
      }),
      map((data: DeleteInvoiceMutation) => {
        return data.wawiAssist?.deleteWA_Invoice as DeleteInvoiceRes;
      }),
    );
  }
}

class InvoiceListService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const invoiceListService = new InvoiceListService();

export type CreatedInvoicesRes = NonNullable<
  NonNullable<CreatedInvoicesQuery['wawiAssist']>['listWA_CreatedInvoices']
>;

export type InvoiceDataRes = NonNullable<
  NonNullable<GetInvoiceDataQuery['wawiAssist']>['getInvoiceData']
>;

export type OrdersRes = NonNullable<
  NonNullable<GetOrdersForCreatedInvoiceQuery['wawiAssist']>['listWA_OrdersForCreatedInvoice']
>;

export type PositionsRes = NonNullable<
  NonNullable<
    GetOrderPositionsForCreatedInvoiceQuery['wawiAssist']
  >['listWA_OrderPositionsForCreatedInvoice']
>;

export interface QueryProps {
  customerId: string;
  invoiceId: string;
  orderSort: GridSortModel;
  isDeleted: boolean;
}

export type QueryDataRes = Required<
  Pick<
    IInvoiceDetailsState,
    | 'customerInfo'
    | 'orders'
    | 'selectedOrder'
    | 'orderPositions'
    | 'invoiceInfo'
    | 'isMultipayCustomer'
  >
>;
export type GetInvoiceDeletingInfoRes = NonNullable<
  NonNullable<GetInvoiceDeletingInfoQuery['wawiAssist']>['getWA_InvoiceDeletingInfo']
>;

export type DeleteInvoiceRes = NonNullable<
  NonNullable<DeleteInvoiceMutation['wawiAssist']>['deleteWA_Invoice']
>;
