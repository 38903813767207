import equal from 'fast-deep-equal/react';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
  zip,
  zipWith,
} from 'rxjs';
import {
  ArticleStdPriceQuery,
  ArticleStdPriceQueryVariables,
  CopyWa_CustomerMutation,
  CopyWa_CustomerMutationVariables,
  CreateWa_CustomerMutation,
  CreateWa_CustomerMutationVariables,
  GetCustomerAvailabilityDataQuery,
  GetCustomerAvailabilityDataQueryVariables,
  GetCustomerCashAssistQuery,
  GetCustomerCashAssistQueryVariables,
  GetCustomerConditionsQuery,
  GetCustomerConditionsQueryVariables,
  GetCustomerDataQuery,
  GetCustomerDataQueryVariables,
  GetCustomerDefaultOrderDataQuery,
  GetCustomerDefaultOrderDataQueryVariables,
  GetCustomerDefaultOrderFromDateQuery,
  GetCustomerDefaultOrderFromDateQueryVariables,
  GetCustomerEmailsDataQuery,
  GetCustomerEmailsDataQueryVariables,
  GetCustomerFormsDataQuery,
  GetCustomerFormsDataQueryVariables,
  GetCustomerIndividualPricesQuery,
  GetCustomerIndividualPricesQueryVariables,
  GetCustomerListsDataQuery,
  GetCustomerListsDataQueryVariables,
  GetCustomerTourQuery,
  GetCustomerTourQueryVariables,
  GetDataForExistingDefaultOrderPositionQuery,
  GetDataForExistingDefaultOrderPositionQueryVariables,
  GetDataForNewDefaultOrderPositionQuery,
  GetDataForNewDefaultOrderPositionQueryVariables,
  GetDeliverySplittingPartsCalculationQuery,
  GetDeliverySplittingPartsCalculationQueryVariables,
  ListCustomersQuery,
  ListCustomersQueryVariables,
  ReCalcWa_OrdersMutation,
  ReCalcWa_OrdersMutationVariables,
  SaveCustomerDataMutation,
  SaveCustomerDataMutationVariables,
  ValidateWa_CustomerFieldsQuery,
  ValidateWa_CustomerFieldsQueryVariables,
} from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { storageHelper } from '../../../shared/helpers/storage';
import {
  configsData,
  DictCustomerGroupsRes,
  DictDeliveryNoteGroupsRes,
  DictTourplansRes,
} from '../../../shared/services/configsData/configsData.service';
import { modeService } from '../../../shared/services/mode/mode.service.ts';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import { customersListState, resetCustomerDeps } from '../states/customersList.state.ts';
import {
  articleStdPrice,
  copyCustomer,
  createCustomer,
  editCustomer,
  getCustomerAvailability,
  getCustomerCashAssist,
  getCustomerConditions,
  getCustomerDefaultOrderData,
  getCustomerDefaultOrderFromDate,
  getCustomerEmails,
  getCustomerForms,
  getCustomerGeneral,
  getCustomerIndividualPrices,
  getCustomerLists,
  getCustomerTour,
  getDataForExistingDefaultOrderPosition,
  getDataForNewDefaultOrderPosition,
  getDeliverySplittingPartsCalculation,
  listCustomers,
  reCalcOrders,
  validateCustomerFields,
} from './gql/customer.gql';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';

type Action =
  | 'listGridCustomers'
  | 'customerGeneralData'
  | 'generalTabUtilityData'
  | 'generalTabFullData'
  | 'customerFormsData'
  | 'customerListsData'
  | 'customerAvailabilityData'
  | 'customerCashAssistData'
  | 'listTourPlans'
  | 'editCustomerData'
  | 'createCustomer'
  | 'reCalcOrders'
  | 'copyCustomer'
  | 'validateCustomerFields'
  | 'customerEmailsData'
  | 'getCustomerDefaultOrderData'
  | 'getCustomerDefaultOrderFromDate'
  | 'getDataForExistingDefaultOrderPosition'
  | 'getDataForNewDefaultOrderPosition'
  | 'getCustomerConditions'
  | 'getCustomerIndividualPrices'
  | 'customerTourData'
  | 'getDeliverySplittingPartsCalculation'
  | 'articleStdPrice';

class PubImpl extends Pub<Action> {
  listGridCustomers(params: ListCustomersQueryVariables & { cache: boolean }) {
    this.emit('listGridCustomers', params);
  }
  customerGeneralData(params: GetCustomerDataQueryVariables) {
    this.emit('customerGeneralData', params);
  }
  generalTabUtilityData() {
    this.emit('generalTabUtilityData');
  }
  generalTabFullData(params: GetCustomerDataQueryVariables) {
    this.emit('generalTabFullData', params);
  }
  customerFormsData(params: GetCustomerFormsDataQueryVariables) {
    this.emit('customerFormsData', params);
  }
  customerListsData(params: GetCustomerListsDataQueryVariables) {
    this.emit('customerListsData', params);
  }
  customerAvailabilityData(params: GetCustomerAvailabilityDataQueryVariables) {
    this.emit('customerAvailabilityData', params);
  }
  customerCashAssistData(params: GetCustomerCashAssistQueryVariables) {
    this.emit('customerCashAssistData', params);
  }
  customerTourData(params: GetCustomerTourQueryVariables) {
    this.emit('customerTourData', params);
  }
  editCustomerData(params: SaveCustomerDataMutationVariables) {
    this.emit('editCustomerData', params);
  }
  createCustomer(params: CreateWa_CustomerMutationVariables) {
    this.emit('createCustomer', params);
  }
  reCalcOrders(params: ReCalcWa_OrdersMutationVariables) {
    this.emit('reCalcOrders', params);
  }
  copyCustomer(params: CopyWa_CustomerMutationVariables) {
    this.emit('copyCustomer', params);
  }
  validateCustomerFields(
    params: ValidateWa_CustomerFieldsQueryVariables & {
      context: any;
    },
  ): void {
    this.emit('validateCustomerFields', params);
  }
  customerEmailsData(params: GetCustomerEmailsDataQueryVariables) {
    this.emit('customerEmailsData', params);
  }
  getCustomerDefaultOrderData(params: GetCustomerDefaultOrderDataQueryVariables) {
    this.emit('getCustomerDefaultOrderData', params);
  }
  getCustomerDefaultOrderFromDate(params: GetCustomerDefaultOrderFromDateQueryVariables) {
    this.emit('getCustomerDefaultOrderFromDate', params);
  }
  getDataForExistingDefaultOrderPosition(
    params: GetDataForExistingDefaultOrderPositionQueryVariablesExtended,
  ) {
    this.emit('getDataForExistingDefaultOrderPosition', params);
  }
  getDataForNewDefaultOrderPosition(
    params: GetDataForNewDefaultOrderPositionQueryVariablesExtended,
  ) {
    this.emit('getDataForNewDefaultOrderPosition', params);
  }
  getCustomerConditions(params: GetCustomerConditionsQueryVariables) {
    this.emit('getCustomerConditions', params);
  }
  getCustomerIndividualPrices(params: GetCustomerIndividualPricesQueryVariables) {
    this.emit('getCustomerIndividualPrices', params);
  }
  getDeliverySplittingPartsCalculation(
    params: GetDeliverySplittingPartsCalculationQueryVariablesExtended,
  ) {
    this.emit('getDeliverySplittingPartsCalculation', params);
  }
  articleStdPrice(params: ArticleStdPriceQueryVariables) {
    this.emit('articleStdPrice', params);
  }
}

export enum CustomerNumber {
  valid = 'valid',
  invalid = 'invalid',
}
class SubImpl extends Sub<Action> {
  private _validateCustomerNo(): Observable<ValidateCustomerFieldsRes> {
    return this.actionListener('validateCustomerFields').pipe(
      distinctUntilChanged((prev, curr) => {
        const prevValue = prev.params.fieldsForCheck.customerNo;
        const currValue = curr.params.fieldsForCheck.customerNo;
        return prevValue === currValue;
      }),
      debounceTime(700),
      tap(({ params: { context } }) => context.setLoading(true)),
      switchMap(
        ({ params: { context, ...variables } }) =>
          gqlClient(validateCustomerFields, variables).pipe(
            tap(() => context.setLoading(false)) as any,
          ) as Observable<ValidateWa_CustomerFieldsQuery>,
      ),
      map((v) => v.wawiAssist?.validateWA_CustomerFields as ValidateCustomerFieldsRes),
      share(),
    );
  }
  private _validateBank1MandatNo = () => {
    return this.actionListener('validateCustomerFields').pipe(
      distinctUntilChanged((prev, curr) => {
        return (
          prev.params.fieldsForCheck.bank1MandatNo === curr.params.fieldsForCheck.bank1MandatNo
        );
      }),
      debounceTime(700),
      switchMap(({ params: { context, ...rest } }) => {
        context.setLoading(true);
        return gqlClient(validateCustomerFields, rest).pipe(
          map((data: ValidateWa_CustomerFieldsQuery) => {
            context.setLoading(false);
            return data.wawiAssist?.validateWA_CustomerFields.bank1MandatNo
              ? CustomerNumber.valid
              : CustomerNumber.invalid;
          }),
        ) as Observable<CustomerNumber>;
      }),
      share(),
    );
  };
  private _validateBank2MandatNo = () => {
    return this.actionListener('validateCustomerFields').pipe(
      distinctUntilChanged((prev, curr) => {
        return (
          prev.params.fieldsForCheck.bank2MandatNo === curr.params.fieldsForCheck.bank2MandatNo
        );
      }),
      debounceTime(700),
      switchMap(({ params: { context, ...rest } }) => {
        context.setLoading(true);
        return gqlClient(validateCustomerFields, rest).pipe(
          map((data: ValidateWa_CustomerFieldsQuery) => {
            context.setLoading(false);
            return data.wawiAssist?.validateWA_CustomerFields.bank2MandatNo
              ? CustomerNumber.valid
              : CustomerNumber.invalid;
          }),
        ) as Observable<CustomerNumber>;
      }),
      share(),
    );
  };
  private validateCustomerNo$ = this._validateCustomerNo();
  private validateBank1MandatNo$ = this._validateBank1MandatNo();
  private validateBank2MandatNo$ = this._validateBank2MandatNo();

  listGridCustomers(): Observable<ListCustomersRes> {
    return this.actionListener('listGridCustomers').pipe(
      switchMap(({ params: { filter, cache } }) => {
        const savedFilter = storageHelper.memory.getItem('customer.filter');
        if (cache && equal(filter, savedFilter)) {
          const list = storageHelper.memory.getItem('customer.list');
          if (list) {
            return of(list);
          }
        }
        return gqlClient(listCustomers, { filter }).pipe<ListCustomersQuery>(
          map((res) => {
            const list = res.wawiAssist?.listWA_Customers || [];
            storageHelper.memory.setItem('customer.filter', filter);
            storageHelper.memory.setItem('customer.list', [...list]);
            return list;
          }),
        ) as Observable<ListCustomersQuery>;
      }),
    );
  }

  customerGeneralData(): Observable<CustomerGeneralDataRes> {
    return this.stream$.pipe(
      filter((v) => v.action === 'customerGeneralData' || v.action === 'generalTabFullData'),
      switchMap(({ params }) => {
        return gqlClient(getCustomerGeneral, params) as Observable<GetCustomerDataQuery>;
      }),
      map((res) => {
        return (res?.wawiAssist?.getCustomerData || {}) as CustomerGeneralDataRes;
      }),
    );
  }

  generalTabUtilityData(): Observable<GeneralTabUtilityData> {
    return this.stream$.pipe(
      filter((v) => v.action === 'generalTabUtilityData' || v.action === 'generalTabFullData'),
      tap(() => {
        configsData.pub.common(['dictCustomerGroups', 'dictDeliveryNoteGroups']);
      }),
      switchMap(() => {
        return configsData.sub.dictCustomerGroups().pipe(
          zipWith(configsData.sub.dictDeliveryNoteGroups()),
          responseHandler<[DictCustomerGroupsRes, DictDeliveryNoteGroupsRes]>({
            errorReturnType: [[], []],
          }),
          take(1),
        );
      }),
      map(([customerGroups, deliveryNoteGroups]) => ({ customerGroups, deliveryNoteGroups })),
    );
  }

  generalTabFullData(): Observable<GeneralTabFullData> {
    return this.actionListener('generalTabFullData').pipe(
      switchMap(() => this.customerGeneralData().pipe(zipWith(this.generalTabUtilityData()))),
      map(([tabData, utilityData]) => ({ tabData, utilityData })),
    );
  }

  customerFormsData(): Observable<CustomerFormsDataRes> {
    return this.actionListener('customerFormsData').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(getCustomerForms, params) as Observable<GetCustomerFormsDataQuery>,
      ),
      map((v) => v.wawiAssist?.getCustomerData as CustomerFormsDataRes),
    );
  }

  customerListsData(): Observable<CustomerListsDataRes> {
    return this.actionListener('customerListsData').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(getCustomerLists, params) as Observable<GetCustomerListsDataQuery>,
      ),
      map((v) => v.wawiAssist?.getCustomerData as CustomerListsDataRes),
    );
  }

  customerAvailabilityData(): Observable<CustomerAvailabilityRes> {
    return this.actionListener('customerAvailabilityData').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(
            getCustomerAvailability,
            params,
          ) as Observable<GetCustomerAvailabilityDataQuery>,
      ),
      map((v) => {
        const {
          id,
          customerAssortimentGroupsCheckedState,
          informationTip,
          customerDeliveryPeriodsSettings,
          ...weekDays
        } = (v.wawiAssist?.getCustomerData || {}) as CustomerAvailabilityTabDataFromServer;
        return {
          id,
          customerAssortimentGroupsCheckedState,
          informationTip,
          periods: customerDeliveryPeriodsSettings?.customerRegularDeliveryPeriods,
          periodsWithCustomWeekDays:
            customerDeliveryPeriodsSettings?.customerCustomWeekDaysAndDeliveryPeriods?.map(
              ({ id, fromDate, toDate, description, isRepeatYearly, ...weekDays }) => ({
                period: {
                  fromDate,
                  toDate,
                  isRepeatYearly,
                },
                weekDays,
                description,
                id,
              }),
            ),
          vacations: customerDeliveryPeriodsSettings?.customerVacations,
          weekDays,
        } as CustomerAvailabilityRes;
      }),
    );
  }

  customerCashAssistData(): Observable<CustomerCashAssistDataRes> {
    return this.actionListener('customerCashAssistData').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(getCustomerCashAssist, params) as Observable<GetCustomerCashAssistQuery>,
      ),
      map((v) => v.wawiAssist?.getCustomerData as CustomerCashAssistDataRes),
    );
  }

  customerTourData(): Observable<CustomerTourDataRes> {
    return this.actionListener('customerTourData').pipe(
      switchMap(
        ({ params }) => gqlClient(getCustomerTour, params) as Observable<GetCustomerTourQuery>,
      ),
      map((v) => v.wawiAssist?.getCustomerData as CustomerTourDataRes),
    );
  }

  editCustomerData(): Observable<SaveCustomerDataRes> {
    return this.actionListener('editCustomerData').pipe(
      switchMap(
        ({ params }) => gqlClient(editCustomer, params) as Observable<SaveCustomerDataMutation>,
      ),
      map((v) => v.wawiAssist?.saveCustomerData as SaveCustomerDataRes),
    );
  }

  createCustomer(): Observable<CreateCustomerDataRes> {
    return this.actionListener('createCustomer').pipe(
      switchMap(
        ({ params }) => gqlClient(createCustomer, params) as Observable<CreateWa_CustomerMutation>,
      ),
      map((res) => {
        const data = res.wawiAssist?.createWA_Customer;
        resetCustomerDeps();
        this.switchToEditModeAndAddCustomer(data as CreateCustomerDataRes);
        return data as CreateCustomerDataRes;
      }),
    );
  }

  reCalcOrders(): Observable<ReCalcOrdersRes> {
    return this.actionListener('reCalcOrders').pipe(
      switchMap(
        ({ params }) => gqlClient(reCalcOrders, params) as Observable<ReCalcWa_OrdersMutation>,
      ),
      map((v) => v.wawiAssist?.reCalcWA_Orders as ReCalcOrdersRes),
    );
  }

  copyCustomer(): Observable<CopyCustomerDataRes> {
    return this.actionListener('copyCustomer').pipe(
      switchMap(
        ({ params }) => gqlClient(copyCustomer, params) as Observable<CopyWa_CustomerMutation>,
      ),
      map((res) => {
        const data = res.wawiAssist?.copyWA_Customer;
        resetCustomerDeps();
        this.switchToEditModeAndAddCustomer(data as CopyCustomerDataRes);
        return data as CopyCustomerDataRes;
      }),
    );
  }

  validateCustomerNo(): Observable<ValidateCustomerFieldsRes> {
    return this.validateCustomerNo$;
  }
  validateBank1MandatNo(): Observable<CustomerNumber> {
    return this.validateBank1MandatNo$;
  }
  validateBank2MandatNo(): Observable<CustomerNumber> {
    return this.validateBank2MandatNo$;
  }

  customerEmailsData(): Observable<CustomerEmailsDataRes> {
    return this.stream$.pipe(
      filter((v) => v.action === 'customerEmailsData'),
      switchMap(({ params }) => {
        return gqlClient(getCustomerEmails, params) as Observable<GetCustomerEmailsDataQuery>;
      }),
      map((res) => {
        return (res.wawiAssist?.getCustomerData || {}) as CustomerEmailsDataRes;
      }),
    );
  }
  getCustomerDefaultOrderData(): Observable<GetCustomerDefaultOrderDataResExtended> {
    return this.actionListener('getCustomerDefaultOrderData').pipe(
      switchMap(({ params }) => {
        return gqlClient(getCustomerDefaultOrderData, params);
      }),
      map((data: GetCustomerDefaultOrderDataQuery) => {
        const res = data.wawiAssist?.getCustomerData as GetCustomerDefaultOrderDataResExtended;
        if (res?.customerWeeklyDefaultOrder && res.customerWeeklyDefaultOrder.length) {
          res.customerWeeklyDefaultOrder = res.customerWeeklyDefaultOrder.map((el, i) => ({
            ...el,
            id: Date.now() + i,
            __reorder__: `${el?.articleNo ? '(' + el?.articleNo + ')' : ''} ${
              el?.description || ''
            }`,
          })) as [];
        }
        return res || {};
      }),
    );
  }
  getCustomerDefaultOrderFromDate(): Observable<GetCustomerDefaultOrderFromDateResExtended> {
    return this.actionListener('getCustomerDefaultOrderFromDate').pipe(
      switchMap(({ params }) => {
        return gqlClient(getCustomerDefaultOrderFromDate, params);
      }),
      map((data: GetCustomerDefaultOrderFromDateQuery) => {
        const res = data.wawiAssist?.getCustomerData as GetCustomerDefaultOrderFromDateResExtended;
        if (res?.customerWeeklyDefaultOrder && res.customerWeeklyDefaultOrder.length) {
          res.customerWeeklyDefaultOrder = res.customerWeeklyDefaultOrder.map((el, i) => ({
            ...el,
            id: Date.now() + i,
            __reorder__: `${el?.articleNo ? '(' + el?.articleNo + ')' : ''} ${
              el?.description || ''
            }`,
          })) as [];
        }
        return res || [];
      }),
    );
  }
  getDataForExistingDefaultOrderPosition(): Observable<GetDataForExistingDefaultOrderPositionRes> {
    return this.actionListener('getDataForExistingDefaultOrderPosition').pipe(
      switchMap(({ params }) => {
        const { id, ...args } = params;
        return zip(
          gqlClient(
            getDataForExistingDefaultOrderPosition,
            args,
          ) as Observable<GetDataForExistingDefaultOrderPositionQuery>,
          of(id),
        );
      }),
      map(([data, id]) => {
        const res = data.wawiAssist?.getWA_DataForNewDefaultOrderPosition;
        return { ...res, id } as GetDataForExistingDefaultOrderPositionRes;
      }),
    );
  }
  getDataForNewDefaultOrderPosition(): Observable<GetDataForNewDefaultOrderPositionRes> {
    return this.actionListener('getDataForNewDefaultOrderPosition').pipe(
      switchMap(({ params }) => {
        const { id, ...args } = params;
        return zip(
          gqlClient(
            getDataForNewDefaultOrderPosition,
            args,
          ) as Observable<GetDataForNewDefaultOrderPositionQuery>,
          of(id),
        );
      }),
      map(([data, id]) => {
        const res = data.wawiAssist?.getWA_DataForNewDefaultOrderPosition;
        return { ...res, id } as GetDataForNewDefaultOrderPositionRes;
      }),
    );
  }
  getCustomerConditions(): Observable<CustomerConditionsRes> {
    return this.actionListener('getCustomerConditions').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(getCustomerConditions, params) as Observable<GetCustomerConditionsQuery>,
      ),
      map((v) => v.wawiAssist?.getCustomerData as CustomerConditionsRes),
    );
  }
  getCustomerIndividualPrices(): Observable<IndividualPricesRes> {
    return this.actionListener('getCustomerIndividualPrices').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(
            getCustomerIndividualPrices,
            params,
          ) as Observable<GetCustomerIndividualPricesQuery>,
      ),
      map((v) => v.wawiAssist?.getCustomerData?.customerSpecialPrices as IndividualPricesRes),
    );
  }
  getDeliverySplittingPartsCalculation(): Observable<GetDeliverySplittingPartsCalculationRes> {
    return this.actionListener('getDeliverySplittingPartsCalculation').pipe(
      switchMap(({ params }) => {
        const { id, ...args } = params;
        return zip(
          gqlClient(
            getDeliverySplittingPartsCalculation,
            args,
          ) as Observable<GetDeliverySplittingPartsCalculationQuery>,
          of(id),
        );
      }),
      map(([data, id]) => {
        const res = data?.wawiAssist?.getWA_DeliverySplittingPartsCalculation;
        return { ...res, id } as GetDeliverySplittingPartsCalculationRes;
      }),
    );
  }
  articleStdPrice(): Observable<ArticleStdPriceQueryRes> {
    return this.actionListener('articleStdPrice').pipe(
      switchMap(({ params }) => {
        return gqlClient(articleStdPrice, params) as Observable<ArticleStdPriceQuery>;
      }),
      map((data) => data.wawiAssist?.getWA_ArticleStdPrice as ArticleStdPriceQueryRes),
    );
  }

  private switchToEditModeAndAddCustomer(data: CreateCustomerDataRes | CopyCustomerDataRes) {
    modeService.pub.mode('edit');
    modeService.sub
      .mode()
      .pipe(
        filter((v) => v === 'edit'),
        take(1),
      )
      .subscribe((v) => {
        if (v === 'edit') {
          const { id, customerNo, internalOrFullName } = data;
          customersListState.pub.addRecordToList({
            id,
            customerNo,
            internalOrFullName,
          });
        }
      });
  }
}

class CustomerService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const customerService = new CustomerService();

export type CustomerConditionsRes = NonNullable<
  NonNullable<GetCustomerConditionsQuery['wawiAssist']>['getCustomerData']
>;

export type IndividualPricesRes = NonNullable<
  NonNullable<GetCustomerIndividualPricesQuery['wawiAssist']>['getCustomerData']
>['customerSpecialPrices'];

export type ListCustomersRes = NonNullable<
  NonNullable<ListCustomersQuery['wawiAssist']>['listWA_Customers']
>;

export type CustomerGeneralDataRes = NonNullable<
  NonNullable<GetCustomerDataQuery['wawiAssist']>['getCustomerData']
>;

export type GeneralTabUtilityData = {
  customerGroups?: DictCustomerGroupsRes;
  deliveryNoteGroups?: DictDeliveryNoteGroupsRes;
};

export interface IUtilityData extends GeneralTabUtilityData {
  tourPlans?: DictTourplansRes;
}

export interface GeneralTabFullData {
  tabData: CustomerGeneralDataRes;
  utilityData: IUtilityData;
}

export type CustomerFormsDataRes = NonNullable<
  NonNullable<GetCustomerFormsDataQuery['wawiAssist']>['getCustomerData']
>;

export type CustomerListsDataRes = NonNullable<
  NonNullable<GetCustomerListsDataQuery['wawiAssist']>['getCustomerData']
>;

export type CustomerAvailabilityTabDataFromServer = NonNullable<
  NonNullable<GetCustomerAvailabilityDataQuery['wawiAssist']>['getCustomerData']
>;

export enum WeekDays {
  isDeliveredMon = 'isDeliveredMon',
  isDeliveredTue = 'isDeliveredTue',
  isDeliveredWed = 'isDeliveredWed',
  isDeliveredThu = 'isDeliveredThu',
  isDeliveredFri = 'isDeliveredFri',
  isDeliveredSat = 'isDeliveredSat',
  isDeliveredSun = 'isDeliveredSun',
}

export type WeekDaysFlags = {
  [key in WeekDays]: boolean;
};

export type PeriodsWithCustomWeekDaysType =
  CustomerAvailabilityTabDataFromServer['customerDeliveryPeriodsSettings']['customerCustomWeekDaysAndDeliveryPeriods'][number];

export type PeriodWithCustomWeekDays = Pick<PeriodsWithCustomWeekDaysType, 'id' | 'description'> & {
  period: {
    fromDate: PeriodsWithCustomWeekDaysType['fromDate'];
    toDate: PeriodsWithCustomWeekDaysType['toDate'];
    isRepeatYearly: PeriodsWithCustomWeekDaysType['isRepeatYearly'];
  };
  weekDays: WeekDaysFlags;
};

export type PeriodConditions = {
  periods: CustomerAvailabilityTabDataFromServer['customerDeliveryPeriodsSettings']['customerRegularDeliveryPeriods'];
  periodsWithCustomWeekDays: PeriodWithCustomWeekDays[];
  vacations: CustomerAvailabilityTabDataFromServer['customerDeliveryPeriodsSettings']['customerVacations'];
  weekDays: WeekDaysFlags;
};

export type CustomerAvailabilityRes = Pick<
  CustomerAvailabilityTabDataFromServer,
  'id' | 'customerAssortimentGroupsCheckedState' | 'informationTip'
> &
  PeriodConditions;

export type CustomerCashAssistDataRes = NonNullable<
  NonNullable<GetCustomerCashAssistQuery['wawiAssist']>['getCustomerData']
>;

export type CustomerTourDataRes = NonNullable<
  NonNullable<GetCustomerTourQuery['wawiAssist']>['getCustomerData']
>;

export type SaveCustomerDataRes = NonNullable<
  NonNullable<SaveCustomerDataMutation['wawiAssist']>['saveCustomerData']
>;

export type CreateCustomerDataRes = NonNullable<
  NonNullable<CreateWa_CustomerMutation['wawiAssist']>['createWA_Customer']
>;

export type ReCalcOrdersRes = NonNullable<
  NonNullable<ReCalcWa_OrdersMutation['wawiAssist']>['reCalcWA_Orders']
>;

export type CopyCustomerDataRes = NonNullable<
  NonNullable<CopyWa_CustomerMutation['wawiAssist']>['copyWA_Customer']
>;

export type ValidateCustomerFieldsRes = NonNullable<
  NonNullable<ValidateWa_CustomerFieldsQuery['wawiAssist']>['validateWA_CustomerFields']
>;

export type CustomerEmailsDataRes = NonNullable<
  NonNullable<GetCustomerEmailsDataQuery['wawiAssist']>['getCustomerData']
>;
export type GetCustomerDefaultOrderDataRes = NonNullable<
  NonNullable<GetCustomerDefaultOrderDataQuery['wawiAssist']>['getCustomerData']
>;
export type ArticleStdPriceQueryRes = NonNullable<
  NonNullable<ArticleStdPriceQuery['wawiAssist']>['getWA_ArticleStdPrice']
>;

export type GetDeliverySplittingPartsCalculationRes = NonNullable<
  NonNullable<
    GetDeliverySplittingPartsCalculationQuery['wawiAssist']
  >['getWA_DeliverySplittingPartsCalculation']
> & { id: number };
export interface GetCustomerDefaultOrderDataResExtended
  extends Omit<GetCustomerDefaultOrderDataRes, 'customerWeeklyDefaultOrder'> {
  customerWeeklyDefaultOrder: ExtendedDefaultOrderGridDataPos[];
}

export type ExtendedDefaultOrderGridDataPos =
  GetCustomerDefaultOrderDataRes['customerWeeklyDefaultOrder'][number] & {
    id: number;
    __reorder__: string;
  };
export type GetCustomerDefaultOrderFromDateRes = NonNullable<
  NonNullable<GetCustomerDefaultOrderFromDateQuery['wawiAssist']>['getCustomerData']
>;
export type GetCustomerDefaultOrderFromDatePos =
  GetCustomerDefaultOrderFromDateRes['customerWeeklyDefaultOrder'][number] & {
    id: number;
    __reorder__: string;
  };

export type GetDataForExistingDefaultOrderPositionRes = NonNullable<
  NonNullable<
    GetDataForExistingDefaultOrderPositionQuery['wawiAssist']
  >['getWA_DataForNewDefaultOrderPosition']
> & { id: number };
export type GetDataForNewDefaultOrderPositionRes = NonNullable<
  NonNullable<
    GetDataForNewDefaultOrderPositionQuery['wawiAssist']
  >['getWA_DataForNewDefaultOrderPosition']
> & { id: number };
export interface GetCustomerDefaultOrderFromDateResExtended
  extends Omit<GetCustomerDefaultOrderFromDateRes, 'customerWeeklyDefaultOrder'> {
  customerWeeklyDefaultOrder: GetCustomerDefaultOrderFromDatePos[];
}
/* PARAMS TYPES start */
interface GetDataForExistingDefaultOrderPositionQueryVariablesExtended
  extends GetDataForExistingDefaultOrderPositionQueryVariables {
  id: number;
}
interface GetDataForNewDefaultOrderPositionQueryVariablesExtended
  extends GetDataForNewDefaultOrderPositionQueryVariables {
  id: number;
}
export interface GetDeliverySplittingPartsCalculationQueryVariablesExtended
  extends GetDeliverySplittingPartsCalculationQueryVariables {
  id: number;
}
/* PARAMS TYPES end */
