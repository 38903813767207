import { Pub, Service, Sub } from '../../../services/service.abstract.ts';
import {
  EmailTrackingByDocumentQuery,
  EmailTrackingByDocumentQueryVariables,
} from '../../../../graphql/generatedModel.ts';
import { map, Observable, switchMap } from 'rxjs';
import { gqlClient } from '../../../../graphql/graphqlRequest.ts';
import { emailTrackingByDocument } from './gql/emailTracking.gql.ts';

type Action = 'emailTrackingByDocument' | undefined;

class PubImpl extends Pub<Action> {
  emailTrackingByDocument(params: EmailTrackingByDocumentQueryVariables) {
    this.emit('emailTrackingByDocument', params);
  }
}

class SubImpl extends Sub<Action> {
  emailTrackingByDocument(): Observable<EmailTrackingByDocumentRes> {
    return this.actionListener('emailTrackingByDocument').pipe(
      switchMap(({ params }) => gqlClient(emailTrackingByDocument, params)),
      map((data: EmailTrackingByDocumentQuery) => {
        return data.wawiAssist?.getWA_EmailTrackingByDocument as EmailTrackingByDocumentRes;
      }),
    );
  }
}

class EmailService extends Service<Action> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const emailService = new EmailService();

export type EmailTrackingByDocumentRes = NonNullable<
  NonNullable<EmailTrackingByDocumentQuery['wawiAssist']>['getWA_EmailTrackingByDocument']
>;
