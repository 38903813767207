/* Icons */
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

import { FC, useEffect, useMemo, useState } from 'react';
import { IconButton, MenuItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SplitButton } from '../../../../../../shared/components/splitButton/splitButton.component.tsx';
import { Radio } from '../../../../../../shared/components/form';
import styled from '@mui/material/styles/styled';
import {
  CreationType,
  CreationWarnings,
  initOrderCreationState,
  IOrderCreation,
  orderCreationState,
} from '../../../states/orderDetails/orderCreation.state.ts';
import { snackbarService } from '../../../../../../shared/components/snackbar/service/snackbar.service.ts';
import { ConfirmCreationPopup } from '../../../../common/popups/confirm.popup.tsx';
import { localeFormatterHelper } from '../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import {
  CreateInvoiceRes,
  createInvoiceService,
} from '../../../../../invoice/services/createInvoice.service.ts';
import { customerListState, ICustomerListState } from '../../../states/customerList.state.ts';
import { PreviewPopup } from '../../../../../../shared/components/previewPopup/preview.popup.tsx';
import { C_Order_Error_Code } from '../../../../../../graphql/generatedModel.ts';
import { responseHandler } from '../../../../../../shared/responseHandler/responseHandler.ts';

export const OrderActions: FC<IOrderActions> = ({ selectedCustomer }) => {
  const [creationState, setCreationState] = useState<IOrderCreation>(initOrderCreationState);
  const [loading, setLoading] = useState<boolean>(false);
  const [noOrderBtnDisabled, setNoOrderBtnDisabled] = useState(false);
  const confirmCreationModal = useMemo(() => new ConfirmCreationPopup(), []);
  const orderPreviewModal = useMemo(() => new PreviewPopup(), []);
  const { t } = useTranslation();

  const handleNoOrder = () => {
    customerListState.pub.changeNoOrderStatus();
  };
  useEffect(() => {
    snackbarService.sub.show().subscribe(({ params: { id } }) => {
      setNoOrderBtnDisabled(id === 'noOrderStatusLoading');
    });
  }, []);

  useEffect(() => {
    const orderCreationSub = orderCreationState.sub.state().subscribe((v) => {
      setCreationState(v);
      const { warnings, existingOrdersOnDate } = v;
      switch (warnings) {
        case C_Order_Error_Code.OEC2_EMPTY_POSITIONS: {
          snackbarService.pub.show({
            type: 'error',
            content: t('order.empty_positions_create_warning'),
          });
          break;
        }
        case CreationWarnings['existingOrders']: {
          if (existingOrdersOnDate.length) {
            const content = (
              <>
                <p>{t('order.existing_orders_warning')}</p>
                <ul>
                  {existingOrdersOnDate.map((item) => {
                    if (!item) return null;
                    const { orderNo, userName, createDateTime } = item;
                    return (
                      <li key={orderNo}>
                        {t('common.delivery_no')} {orderNo} {userName} (
                        {localeFormatterHelper.formatDate(createDateTime)})
                      </li>
                    );
                  })}
                </ul>
              </>
            );
            confirmCreationModal.stream.emit('open', {
              content,
              onConfirm: () =>
                orderCreationState.pub.createOrder({
                  ignoreMinMaxProblems: false,
                  ignoreQuantityPerLotProblems: false,
                  ignoreSplittingProblems: false,
                  ignoreDaysToProduce: false,
                }),
            });
          }
          break;
        }
        case C_Order_Error_Code.OEC6_NOT_ENOUGH_DAYS_TO_PRODUCE: {
          const content = t('order.not_enough_days_to_produce');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderCreationState.pub.createOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: false,
                ignoreMinMaxProblems: false,
                ignoreSplittingProblems: false,
              });
            },
          });
          break;
        }
        case C_Order_Error_Code.OEC3_QUANTITY_PER_LOT: {
          const content = t('order.positions_lot_quantity_warning');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderCreationState.pub.createOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: true,
                ignoreMinMaxProblems: false,
                ignoreSplittingProblems: false,
              });
            },
          });
          break;
        }
        case C_Order_Error_Code.OEC4_MIN_MAX: {
          const content = t('order.positions_min_max_quantity_warning');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderCreationState.pub.createOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: true,
                ignoreMinMaxProblems: true,
                ignoreSplittingProblems: false,
              });
            },
          });
          break;
        }
        case C_Order_Error_Code.OEC5_SPLITTING: {
          const content = t('order.delivery_splitting_calculations_warning');
          confirmCreationModal.stream.emit('open', {
            content,
            onConfirm: () => {
              orderCreationState.pub.createOrder({
                ignoreDaysToProduce: true,
                ignoreQuantityPerLotProblems: true,
                ignoreMinMaxProblems: true,
                ignoreSplittingProblems: true,
              });
            },
          });
          break;
        }
        case CreationWarnings['unknown']: {
          snackbarService.pub.show({
            type: 'error',
            content: t('common.server_error'),
          });
          break;
        }
        default:
      }
    });

    const orderCreatedSub = orderCreationState.sub
      .createdOrder()
      .subscribe(({ createdOrder /*, creationSettings*/ }) => {
        if (createdOrder?.orderNo) {
          snackbarService.pub.show({
            type: 'success',
            content: `${t('common.delivery_no')} ${createdOrder.orderNo}`,
          });
        }
      });

    const invoiceCreatedSub = createInvoiceService.sub
      .createInvoice()
      .pipe(
        responseHandler<CreateInvoiceRes>({
          customErrorHandler: () => {
            return 'invoice.invoice_not_saved';
          },
          errorReturnType: undefined,
        }),
      )
      .subscribe((res) => {
        if (res?.invoiceNo) {
          snackbarService.pub.show({
            type: 'success',
            content: `${t('invoice.invoice_no')} ${res.invoiceNo}`,
          });
        }
        createInvoiceService.pub.clearStream();
      });

    const loadingSub = orderCreationState.sub.loading().subscribe((isLoading) => {
      setLoading(isLoading);
      if (isLoading) {
        snackbarService.pub.show({
          id: 'orderCreateLoading',
          type: 'loading',
          content: t('order.create_order'),
          noAutoHide: true,
        });
      } else {
        snackbarService.pub.hide('orderCreateLoading');
      }
    });

    return () => {
      orderCreationSub.unsubscribe();
      orderCreatedSub.unsubscribe();
      invoiceCreatedSub.unsubscribe();
      loadingSub.unsubscribe();
      confirmCreationModal.stream.unsubscribe();
      orderCreationState.pub.resetState();
      orderPreviewModal.stream.unsubscribe();
    };
  }, []);

  const creationTitle = useMemo(() => {
    switch (creationState.creationSettings.type) {
      case 'withInvoice':
        return t('order.create_with_invoice');
      case 'payByCash':
        return t('order.pay_by_cash');
      default:
        return t('order.create_order');
    }
  }, [creationState.creationSettings.type, t]);

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <IconButton
        onClick={handleNoOrder}
        disabled={noOrderBtnDisabled}
        color={selectedCustomer?.isNoOrderOnDate ? 'info' : 'error'}
        size='large'
        title={selectedCustomer?.isNoOrderOnDate ? t('order.restore') : t('order.no_order')}
      >
        {selectedCustomer?.isNoOrderOnDate ? <ReplayCircleFilledIcon /> : <NotInterestedIcon />}
      </IconButton>
      <SplitButton
        title={creationTitle}
        onClick={() => orderCreationState.pub.initCreateOrder()}
        disabled={loading}
      >
        <SMenuItem>
          <Radio
            label={t('order.create_with_invoice')}
            value={'withInvoice'}
            checked={creationState.creationSettings.type === 'withInvoice'}
            onChange={(e) =>
              orderCreationState.pub.setCreationOption({ type: e.target.value as CreationType })
            }
            onClick={() =>
              creationState.creationSettings.type === 'withInvoice'
                ? orderCreationState.pub.setCreationOption({ type: undefined })
                : null
            }
          />
        </SMenuItem>
        <SMenuItem>
          <Radio
            label={t('order.pay_by_cash')}
            value={'payByCash'}
            checked={creationState.creationSettings.type === 'payByCash'}
            onChange={(e) =>
              orderCreationState.pub.setCreationOption({ type: e.target.value as CreationType })
            }
            onClick={() =>
              creationState.creationSettings.type === 'payByCash'
                ? orderCreationState.pub.setCreationOption({ type: undefined })
                : null
            }
          />
        </SMenuItem>
        {/* <SMenuItem>
          <Checkbox
            checked={creationState.creationSettings.previewOnCreated}
            onChange={(e) =>
              orderCreationState.pub.setCreationOption({ previewOnCreated: e.target.checked })
            }
            label={t('order.preview_automatically')}
          />
        </SMenuItem> */}
      </SplitButton>
      <confirmCreationModal.Component />
      <orderPreviewModal.Component />
    </Stack>
  );
};

const SMenuItem = styled(MenuItem)({
  '& label > span': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& label': {
    width: '100%',
    paddingTop: 2,
    paddingBottom: 2,
  },
});

interface IOrderActions {
  selectedCustomer?: ICustomerListState['selectedCustomer'];
}
