import { FC, useMemo, useCallback } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import {
  Select,
  SelectMultiple,
  SelectFreeSolo,
  ISelectProps,
  ISelectOption,
  IFreeSoloOption,
  SelectWithImages,
} from '../fields/select.component.tsx';

export const FormSelect: FC<IFormSelectProps> = ({
  name,
  onChange,
  onBlur,
  controllerProps,
  disabled,
  withImage,
  ...selectProps
}) => {
  const SelectComponent = useMemo(() => {
    if (selectProps.multiple) {
      return SelectMultiple;
    } else if (selectProps.freeSolo) {
      return SelectFreeSolo;
    } else if (withImage) {
      return SelectWithImages;
    } else {
      return Select;
    }
  }, [selectProps.multiple, selectProps.freeSolo, withImage]);
  const getOption = useCallback<
    (id: ISelectOption['id'], options: readonly ISelectOption[]) => ISelectOption | undefined
  >((id, options) => {
    if (!id) return undefined;
    return options.find((item) => item.id?.toString() === id?.toString());
  }, []);

  return (
    <Controller
      {...controllerProps}
      name={name}
      render={({
        field: {
          onChange: onFieldChange,
          onBlur: onFieldBlur,
          value,
          disabled: fieldDisabled,
          ...restField
        },
      }) => (
        <SelectComponent
          {...selectProps}
          value={
            selectProps.multiple
              ? value?.reduce((outArr: ISelectOption[], id: ISelectOption['id']) => {
                  const option: ISelectOption | undefined = getOption(id, selectProps.options);
                  if (option) outArr.push(option);
                  return outArr;
                }, []) || []
              : getOption(value, selectProps.options) || null
          }
          onChange={(_, v) => {
            let handlerValue;
            if (selectProps.freeSolo && !Array.isArray(v)) {
              const val = v as IFreeSoloOption;
              handlerValue = val?.inputValue ? val?.inputValue : v?.id || null;
            } else {
              handlerValue = Array.isArray(v) ? v.map((v) => v?.id) : v?.id || null;
            }
            onFieldChange(handlerValue); // send value to react-hook-form
            onChange?.(handlerValue, v);
          }}
          onBlur={(e) => {
            onFieldBlur(); // notify react-hook-form when input is touched/blur
            onBlur?.(e);
          }}
          disabled={typeof disabled === 'boolean' ? disabled : fieldDisabled}
          {...restField}
        />
      )}
    />
  );
};

interface IFormSelectProps extends Omit<ISelectProps, 'value' | 'onChange' | 'defaultValue'> {
  name: ControllerProps['name'];
  onChange?: (
    v: ISelectOption['id'] | null | ISelectOption['id'][],
    option: ISelectOption | null | ISelectOption[],
  ) => void;
  multiple?: boolean;
  withImage?: boolean;
  controllerProps?: Omit<ControllerProps, 'name' | 'render'>;
  disabled?: boolean;
}
