import { FC, useMemo } from 'react';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component.tsx';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Content as Wrapper } from '../../../../../../../shared/components/content/content.component.tsx';
import { FormSwitch } from '../../../../../../../shared/components/form/index.ts';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { FormNumericField } from '../../../../../../../shared/components/form/index.ts';
import { FieldsRow } from '../../../../../../../shared/components/form/index.ts';
import { Theme } from '../../../../../../../shared/styles/theme/theme.ts';
import { IDoughSplittingEditPopupDefaultValues } from './doughSplittingEdit.popup.tsx';
import { printJobsService } from '../../../../../services/printJobs.service.ts';
import { format } from 'date-fns';
import { localeFormatterHelper } from '../../../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { WarningPopup } from '../../../../../../../shared/components/popupTemplates/warningPopup/warning.popup.tsx';
import { useBeforeClosePopup } from '../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export const ContentPopup: FC<IContent> = ({ stream, defaultValues }) => {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues });
  const {
    formState: { dirtyFields },
    watch,
    getValues,
  } = formMethods;
  const warningPopup = useMemo(() => new WarningPopup({ closureOverlay: false }), []);

  const handleSubmit = () => {
    const kgLtConvert = (amount: number) =>
      values.isLiters ? (amount / 1000) * (values.faktorLtKg || 1) : amount;
    const values = getValues();

    if (values.isSplitted) {
      const notEmptyParts =
        (values.part1Amount ? 1 : 0) +
        (values.part2Amount ? 1 : 0) +
        (values.part3Amount ? 1 : 0) +
        (values.part4Amount ? 1 : 0) +
        (values.part5Amount ? 1 : 0);

      if (notEmptyParts < 2) {
        warningPopup.stream.emit('open', {
          message: t(
            `production.for_dough_splitting_you_need_to_have_more_then_one_not_empty_splitting_parts`,
          ),
        });
      } else {
        printJobsService.pub.saveDoughSplittingInformation({
          // Mutation anyway needs parts weight values in kilogramms, so if we in liter mode we need to convert
          dataToSave: {
            id: values.id,
            part1: kgLtConvert(values.part1Amount || 0),
            part2: kgLtConvert(values.part2Amount || 0),
            part3: kgLtConvert(values.part3Amount || 0),
            part4: kgLtConvert(values.part4Amount || 0),
            part5: kgLtConvert(values.part5Amount || 0),
            onDate: format(values.date || new Date(), 'yyyy-MM-dd'),
          },
        });

        stream.emit('save');
        stream.emit('close');
      }
    } else {
      printJobsService.pub.deleteDoughSplittingInformation({
        id: values.id,
        onDate: format(values.date || new Date(), 'yyyy-MM-dd').split('T')[0],
      });

      stream.emit('save');
      stream.emit('close');
    }
  };

  useBeforeClosePopup({
    stream,
    isDirty: !!Object.keys(dirtyFields).length,
  });

  return (
    <Wrapper type='box'>
      <FormProvider {...formMethods}>
        <Stack mt={2}>
          <Typography variant='h5'>{getValues().doughNo + ' ' + getValues().doughName}</Typography>
        </Stack>
        <Stack direction='row' mt={2}>
          <Stack>
            <Typography variant='caption'>
              {t('article.dough') + (getValues().isLiters ? ' (lt)' : ' (kg)')}
            </Typography>
            <Typography sx={{ color: Theme.palette.success.main }} variant='body1'>
              {getValues().doughRequired || 0}
            </Typography>
          </Stack>
          <Stack>
            <Typography sx={{ ml: 8 }} variant='caption'>
              {t('production.difference') + (getValues().isLiters ? ' (lt)' : ' (kg)')}
            </Typography>
            <Typography sx={{ ml: 8, color: Theme.palette.error.main }} variant='body1'>
              {localeFormatterHelper.formatNumber(
                (getValues().doughRequired || 0) -
                  (watch('part1Amount') || 0) -
                  (watch('part2Amount') || 0) -
                  (watch('part3Amount') || 0) -
                  (watch('part4Amount') || 0) -
                  (watch('part5Amount') || 0),
                { returnZero: true },
              )}
            </Typography>
          </Stack>
        </Stack>
        <FieldsRow mt={4}>
          <FormNumericField
            controls
            precision={2}
            width='120px'
            label={t('production.part_n_m', {
              number: 1,
              measurement: getValues().isLiters ? 'lt' : 'kg',
            })}
            name='part1Amount'
            autoFocus={
              getValues().field === 'resPart1LtAmount' || getValues().field === 'resPart1Amount'
            }
          />
          <FormNumericField
            controls
            precision={2}
            width='120px'
            label={t('production.part_n_m', {
              number: 2,
              measurement: getValues().isLiters ? 'lt' : 'kg',
            })}
            name='part2Amount'
            autoFocus={
              getValues().field === 'resPart2LtAmount' || getValues().field === 'resPart2Amount'
            }
          />
          <FormNumericField
            controls
            precision={2}
            width='120px'
            label={t('production.part_n_m', {
              number: 3,
              measurement: getValues().isLiters ? 'lt' : 'kg',
            })}
            name='part3Amount'
            autoFocus={
              getValues().field === 'resPart3LtAmount' || getValues().field === 'resPart3Amount'
            }
          />
          <FormNumericField
            controls
            precision={2}
            width='120px'
            label={t('production.part_n_m', {
              number: 4,
              measurement: getValues().isLiters ? 'lt' : 'kg',
            })}
            name='part4Amount'
            autoFocus={
              getValues().field === 'resPart4LtAmount' || getValues().field === 'resPart4Amount'
            }
          />
          <FormNumericField
            controls
            precision={2}
            width='120px'
            label={t('production.part_n_m', {
              number: 5,
              measurement: getValues().isLiters ? 'lt' : 'kg',
            })}
            name='part5Amount'
            autoFocus={
              getValues().field === 'resPart5LtAmount' || getValues().field === 'resPart5Amount'
            }
          />
        </FieldsRow>
        <Stack direction='row' justifyContent='space-between' mt={5}>
          <FormSwitch name='isSplitted' label={t('production.split_in_report')} />
          <Button
            onClick={handleSubmit}
            variant='contained'
            color='primary'
            size='medium'
            disabled={
              // We can change splitting settings if
              // 1. Splitting parts values changed with toggle isSplitted turned on
              // 2. Toggle isSplitted was changed
              !(
                dirtyFields?.isSplitted ||
                (watch('isSplitted') === true &&
                  (dirtyFields?.part1Amount ||
                    dirtyFields?.part2Amount ||
                    dirtyFields?.part3Amount ||
                    dirtyFields?.part4Amount ||
                    dirtyFields?.part5Amount))
              )
            }
            sx={{ textTransform: 'uppercase' }}
          >
            {t('common.save')}
          </Button>
        </Stack>
      </FormProvider>
      <warningPopup.Component />
    </Wrapper>
  );
};

interface IContent {
  stream: IPopupWrapper['stream'];
  defaultValues: IDoughSplittingEditPopupDefaultValues;
}
