import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take, zip, switchMap, of, map } from 'rxjs';
import { IPopupWrapper } from '../../../../../../../shared/components/popup/components/popup.component';
import { IMultiEmail } from './sendMultipleEmails.popup';
import { responseHandler } from '../../../../../../../shared/responseHandler/responseHandler';
import { snackbarService } from '../../../../../../../shared/components/snackbar/service/snackbar.service';
import {
  C_Email_Msg_Doctype,
  SendReminderOfInvoiceEmailMutationVariables,
  EmailAttachmentInput,
  Wa_PdfFileOfInvoiceInput,
  C_E_Invoice_Format,
} from '../../../../../../../graphql/generatedModel';
import {
  IPopupMarkupProps,
  PopupMarkup,
} from '../../../../../../../shared/components/multiEmailPopup/popupMarkup.component';
import { notificationCenter } from '../../../../../../../shared/notificationCenter/notificationCenter';
import {
  openDebtsService,
  SendReminderOfInvoiceWithIds,
} from '../../../../../services/openDebts.service.ts';
import { openDebtsState } from '../../../../states/openDebts.state.ts';
import {
  IGetMultipleR10NotifierFilesRes,
  IGetMultipleR64NotifierFilesRes,
  IGetR64NotifierFilesRes,
} from '../../../../../services/debtReports.service.ts';
import {
  reportsService,
  getSubjectForEmailWithDocument,
  getTextForEmailWithDocument,
  getFilesDataToExtractXmlFromPdfOfInvoices,
  getFileNameForEmailWithDocument,
  ExtractXmlFromPdfOfInvoicesRes,
  ListWAPdfHistoryReportRes,
  getFileNameForInvoiceXml,
  DataForMultiEmailsRes,
} from '../../../../../../../shared/services/reports/reports.service';

export const MultiEmailContent: FC<IEmailContent> = ({ stream, params }) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<IPopupMarkupProps['tableData']>([]);
  const attachmentsOfEachCustomer = useRef<IAttachmentsOfCustomer[]>([]);
  const { t } = useTranslation();
  const { getMultiNotifierReportFilesPub, getMultiNotifierReportFilesSub, customerInvoices } =
    params || {};
  const ID_SEPARATOR = ',';

  useEffect(() => {
    const reportsServiceSub = zip(
      reportsService.sub.getDataForMultiEmails(),
      getMultiNotifierReportFilesSub(),
    )
      .pipe(
        responseHandler<
          [DataForMultiEmailsRes, IGetMultipleR10NotifierFilesRes | IGetMultipleR64NotifierFilesRes]
        >({
          customErrorHandler: () => {
            setLoading(false);
            stream.emit('close');
            return undefined;
          },
          errorReturnType: [[], []],
        }),
        switchMap(
          (
            v: [
              DataForMultiEmailsRes,
              IGetMultipleR10NotifierFilesRes | IGetMultipleR64NotifierFilesRes,
            ],
          ) => {
            // this function extracts XML files from invoices (if needed) and passes to next function
            const [emailData, reportData] = v;

            const testElement = reportData[0];
            const maybeR64NotifierFiles = (testElement as IGetR64NotifierFilesRes).invoicesPdfs
              ? (reportData as IGetMultipleR64NotifierFilesRes)
              : [];

            let invoicePdfsToExtractXml: Array<Wa_PdfFileOfInvoiceInput> = [];
            emailData.forEach(({ customerId, eInvoiceFormat }) => {
              if (eInvoiceFormat === C_E_Invoice_Format.EIF2_XRECHNUNG) {
                const filesOfCustomer = maybeR64NotifierFiles.filter(
                  (customerFilesData) => customerFilesData.customerId === customerId,
                );

                filesOfCustomer.forEach((customerFilesData) => {
                  const filesToExtractFrom = getFilesDataToExtractXmlFromPdfOfInvoices(
                    customerFilesData.invoicesPdfs,
                  );
                  invoicePdfsToExtractXml = invoicePdfsToExtractXml.concat(filesToExtractFrom);
                });
              }
            });

            if (invoicePdfsToExtractXml.length > 0) {
              reportsService.pub.extractXmlFromPdfOfInvoices({
                invoices: invoicePdfsToExtractXml,
              });
              return reportsService.sub
                .extractXmlFromPdfOfInvoices()
                .pipe(
                  map(
                    (extractedXmlWithEInvoice) =>
                      [emailData, reportData, extractedXmlWithEInvoice] as [
                        DataForMultiEmailsRes,
                        IGetMultipleR10NotifierFilesRes | IGetMultipleR64NotifierFilesRes,
                        ExtractXmlFromPdfOfInvoicesRes,
                      ],
                  ),
                );
            } else {
              return of([emailData, reportData, []] as [
                DataForMultiEmailsRes,
                IGetMultipleR10NotifierFilesRes | IGetMultipleR64NotifierFilesRes,
                ExtractXmlFromPdfOfInvoicesRes,
              ]);
            }
          },
        ),
      )
      .subscribe(
        (
          v: [
            DataForMultiEmailsRes,
            IGetMultipleR10NotifierFilesRes | IGetMultipleR64NotifierFilesRes,
            ExtractXmlFromPdfOfInvoicesRes,
          ],
        ) => {
          // prepare attachments for each customer. if e-invoice XML is provided, ignore invoice's PDF files
          const [emailData, reportData, extractedXmlWithEInvoice] = v;

          if (emailData) {
            const mergedData = emailData.map((item) => {
              const documentId: string =
                customerInvoices?.[item.customerId].invoiceIds.join(ID_SEPARATOR) || '';
              return { ...item, documentId };
            });
            setTableData(mergedData);
          }

          if (reportData.length > 0) {
            const testElement = reportData[0];
            const maybeR10NotifierFiles = (testElement as IGetR64NotifierFilesRes).invoicesPdfs
              ? []
              : (reportData as IGetMultipleR10NotifierFilesRes);
            const maybeR64NotifierFiles = (testElement as IGetR64NotifierFilesRes).invoicesPdfs
              ? (reportData as IGetMultipleR64NotifierFilesRes)
              : [];

            if (maybeR10NotifierFiles.length > 0) {
              emailData.forEach(({ customerId }) => {
                const filesOfCustomer = maybeR10NotifierFiles.filter(
                  (filesOfCustomer) => filesOfCustomer.customerId === customerId,
                );
                const reminderAttachments: Array<EmailAttachmentInput> = filesOfCustomer.map(
                  (el) => ({
                    url: el.url ?? '',
                    fileName: `${t('debitor.reminder')}.pdf`,
                  }),
                );

                attachmentsOfEachCustomer.current.push({
                  customerId: customerId,
                  attachments: reminderAttachments,
                });
              });
            } else if (maybeR64NotifierFiles.length > 0) {
              emailData.forEach(({ customerId }) => {
                const filesOfCustomer = maybeR64NotifierFiles.filter(
                  (filesOfCustomer) => filesOfCustomer.customerId === customerId,
                );

                const reminderAttachments: Array<EmailAttachmentInput> = filesOfCustomer.map(
                  (el) => ({
                    url: el.notifierPdfUrl,
                    fileName: `${t('debitor.reminder')}.pdf`,
                  }),
                );

                let invoiceAttachments: Array<EmailAttachmentInput> = [];

                const pdfFilesOfCustomer = filesOfCustomer.reduce(
                  (acc: ListWAPdfHistoryReportRes['reports'], { invoicesPdfs }) => {
                    acc = acc.concat(invoicesPdfs);
                    return acc;
                  },
                  [],
                );
                pdfFilesOfCustomer.forEach((reportFile) => {
                  const eInvoiceXmlFileOfDocument = extractedXmlWithEInvoice.find(
                    (xmlFileData) => xmlFileData.invoiceId === reportFile.documentId,
                  );

                  if (eInvoiceXmlFileOfDocument) {
                    const existingXmlAttachment = invoiceAttachments.find(
                      ({ url }) => url === eInvoiceXmlFileOfDocument.xmlFile.url,
                    );
                    if (!existingXmlAttachment) {
                      invoiceAttachments.push({
                        url: eInvoiceXmlFileOfDocument.xmlFile.url,
                        fileName: getFileNameForInvoiceXml(reportFile.documentNo),
                      });
                    }
                  } else {
                    invoiceAttachments.push({
                      url: reportFile.url ?? '',
                      fileName: getFileNameForEmailWithDocument(
                        t,
                        C_Email_Msg_Doctype.EML_INVOICE,
                        reportFile.documentNo,
                        reportFile.reportId,
                      ),
                    });
                  }
                });

                attachmentsOfEachCustomer.current.push({
                  customerId: customerId,
                  attachments: reminderAttachments.concat(invoiceAttachments),
                });
              });
            }
          }

          setLoading(false);
        },
      );

    const invoicesIds = Object.values(customerInvoices || {}).map((item) => item.invoiceIds[0]);
    reportsService.pub.getDataForMultiEmails({
      emailsDocType: C_Email_Msg_Doctype.EML_INVOICE,
      documentsIds: invoicesIds,
    });
    getMultiNotifierReportFilesPub();

    return () => {
      reportsServiceSub.unsubscribe();
    };
  }, []);

  const handleSendMultiEmail = () => {
    notificationCenter.notify<SendReminderOfInvoiceWithIds[]>(
      openDebtsService.sub.sendMultipleRemindersOfInvoiceEmail().pipe(
        responseHandler<SendReminderOfInvoiceWithIds[]>({
          errorReturnType: [],
        }),
      ),
      {
        type: 'snackbar',
        handler(src, target) {
          src.pipe(take(1)).subscribe({
            next: (data) => {
              const resultData = data as SendReminderOfInvoiceWithIds[];
              snackbarService.pub.hide('snackbarLoading');
              let successCount = 0;
              let failureCount = 0;
              resultData.forEach((res) => {
                if (res?.invoiceIds?.length) {
                  openDebtsState.pub.updateReminderCount(res.invoiceIds);
                }
                if (res.sentMessages?.length) {
                  successCount += res.sentMessages.length;
                }
                if (res.unsentMessages?.length) {
                  failureCount += res.unsentMessages?.length;
                }
              });
              if (failureCount === 0 && successCount > 0) {
                snackbarService.pub.show({
                  type: 'success',
                  content: t('common.emails_sent_successfully', { successCount }),
                });
              } else if (failureCount > 0 && successCount > 0) {
                snackbarService.pub.show({
                  type: 'warning',
                  content: `${t('common.emails_sent_successfully', { successCount })}\n${t(
                    'common.emails_failed_to_send',
                    { failureCount },
                  )}`,
                });
              } else {
                snackbarService.pub.show({
                  type: 'error',
                  content: t('common.emails_failed_to_send', { failureCount }),
                });
              }
              target.next(data);
            },
            error: (error) => {
              console.error('Error occurred while sending emails:', error);
              snackbarService.pub.hide('snackbarLoading');
              snackbarService.pub.show({
                type: 'error',
                content: t('common.error_while_sending_emails'),
              });
              target.error(error);
            },
          });
        },
      },
    );
    const emailsParams: Array<SendReminderOfInvoiceEmailMutationVariables> = tableData?.map(
      ({ email, customerId, documentId }) => {
        const attachments = attachmentsOfEachCustomer.current.find(
          (item) => item.customerId === customerId,
        )?.attachments;
        return {
          emailParams: {
            to: email ? email.replace(/\s/g, '').split(/,|;/) : [],
            subject: getSubjectForEmailWithDocument(t, C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE),
            body: getTextForEmailWithDocument(t, C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE),
            attachments: attachments,
          },
          invoiceIds: documentId.split(ID_SEPARATOR),
        };
      },
    );
    openDebtsService.pub.sendMultipleRemindersOfInvoiceEmail(emailsParams);

    stream.emit('close');
  };

  return (
    <PopupMarkup
      tableData={tableData}
      loading={loading}
      onCancel={() => stream.emit('close')}
      onConfirm={handleSendMultiEmail}
      defaultEmailText={getTextForEmailWithDocument(t, C_Email_Msg_Doctype.EML_REMINDER_OF_INVOICE)}
    />
  );
};

interface IEmailContent {
  stream: IPopupWrapper['stream'];
  params: IMultiEmail['params'];
}

interface IAttachmentsOfCustomer {
  customerId: string;
  attachments: Array<EmailAttachmentInput>;
}
