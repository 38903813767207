import { gql } from 'graphql-request';

export const listCustomers = gql`
  query listCustomers($filter: WA_CustomerFilter) {
    wawiAssist {
      listWA_Customers(filter: $filter) {
        id
        internalOrFullName
        customerNo
      }
    }
  }
`;

export const getCustomerGeneral = gql`
  query getCustomerData($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        informationTip
        id
        customerNo
        isActive
        customerAddressKindId
        customerGroupId
        deliveryNoteGroupId
        salutation
        name
        surname
        internalName
        addition
        street
        postBox
        zipCode
        city
        country
        deliveryAddressSalutation
        deliveryAddressName
        deliveryAddressAddition
        deliveryAddressStreet
        deliveryAddressPostBox
        deliveryAddressZipCode
        deliveryAddressCity
        deliveryAddressCountry
        contactPerson
        phoneBusiness
        phoneBusiness2
        phonePrivate
        phoneMobile
        fax
        email
        emailForInvoice
        website
      }
    }
  }
`;

export const getCustomerForms = gql`
  query getCustomerFormsData($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        id
        informationTip
        correspondenceLanguage
        invoicePaymentSlipReportId
        invoiceReportId
        invoiceSummaryReportId
        orderReportId
        offerReportId
        deliveryNoteCopies
        invoiceCopies
        eBillAccount
        defaultNoteInOrder
        defaultNoteInOrder2
        defaultNoteInInvoice
        defaultNoteInInvoice2
      }
    }
  }
`;

export const editCustomer = gql`
  mutation saveCustomerData($data: WA_SaveCustomerInput!) {
    wawiAssist {
      saveCustomerData(dataToSave: $data) {
        status
        updatedGridItem {
          id
          internalOrFullName
          customerNo
        }
      }
    }
  }
`;

export const reCalcOrders = gql`
  mutation ReCalcWA_Orders($params: WA_ReCalcOrdersInputs!) {
    wawiAssist {
      reCalcWA_Orders(params: $params)
    }
  }
`;

export const createCustomer = gql`
  mutation createWA_Customer($data: WA_CreateCustomerInput!, $customerProfileId: ID) {
    wawiAssist {
      createWA_Customer(data: $data, customerProfileId: $customerProfileId) {
        id
        internalOrFullName
        customerNo
      }
    }
  }
`;

export const copyCustomer = gql`
  mutation copyWA_Customer($originalId: ID!, $data: WA_CreateCustomerInput!) {
    wawiAssist {
      copyWA_Customer(originalId: $originalId, data: $data) {
        id
        internalOrFullName
        customerNo
      }
    }
  }
`;

export const getCustomerLists = gql`
  query getCustomerListsData($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        id
        informationTip
        customerListsCheckedState {
          id
          label: name
          checked
        }
      }
    }
  }
`;

export const getCustomerAvailability = gql`
  query getCustomerAvailabilityData($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        id
        informationTip
        isDeliveredSun
        isDeliveredMon
        isDeliveredTue
        isDeliveredWed
        isDeliveredThu
        isDeliveredFri
        isDeliveredSat
        customerAssortimentGroupsCheckedState {
          id
          label: name
          checked
        }
        customerDeliveryPeriodsSettings {
          customerRegularDeliveryPeriods {
            id
            fromDate
            toDate
            description
            isRepeatYearly
          }
          customerCustomWeekDaysAndDeliveryPeriods {
            id
            fromDate
            toDate
            description
            isDeliveredMon
            isDeliveredThu
            isDeliveredSun
            isDeliveredSat
            isDeliveredFri
            isDeliveredTue
            isDeliveredWed
            isRepeatYearly
          }
          customerVacations {
            id
            fromDate
            toDate
            description
          }
        }
      }
    }
  }
`;

export const getCustomerCashAssist = gql`
  query getCustomerCashAssist($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        informationTip
        id
        caPriceAndDiscountSourceId
        isCashAssistCreditCustomer
        caSpecialDiscount
      }
    }
  }
`;

export const getCustomerTour = gql`
  query getCustomerTour($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        id
        informationTip
        tourplanNote
        tourplanId
        tourPreparationArea
        tourDepartureTime
        tourPosition
        tourplanSatId
        tourSatPreparationArea
        tourSatDepartureTime
        tourSatPosition
        tourplanSunId
        tourSunPreparationArea
        tourSunDepartureTime
        tourSunPosition
        isAlwaysInTourplan
        transportSectorId
      }
    }
  }
`;

export const validateCustomerFields = gql`
  query validateWA_CustomerFields($id: ID, $fieldsForCheck: WA_CustomerFieldsForCheck!) {
    wawiAssist {
      validateWA_CustomerFields(id: $id, fieldsForCheck: $fieldsForCheck) {
        customerNo
        bank1MandatNo
        bank2MandatNo
      }
    }
  }
`;

export const getCustomerEmails = gql`
  query getCustomerEmailsData($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        id
        email
        emailForInvoice
        eInvoiceFormat
      }
    }
  }
`;

export const getCustomerDefaultOrderData = gql`
  query GetCustomerDefaultOrderData($customerId: ID!) {
    wawiAssist {
      getCustomerData(id: $customerId) {
        informationTip
        id
        isDeliverySplitting
        customerWeeklyDefaultOrder {
          isActive
          articleId
          description
          articleNo
          itemSortPos
          isMatchAssortmentGroup
          canChangeInOrders
          articleInformation
          mondaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          tuesdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          wednesdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          thursdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          fridaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          saturdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          sundaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
        }
        defaultOrderOverrideFromCustomerId
        defaultOrderSourceId
        isAllowedAutoOrder
        deliverySplittingPartsCount
        isFlexibleOrders
        distributionLowerLimit
        distributionHigherLimit
      }
    }
  }
`;

export const getCustomerDefaultOrderFromDate = gql`
  query GetCustomerDefaultOrderFromDate($customerId: ID!, $date: Date) {
    wawiAssist {
      getCustomerData(id: $customerId) {
        id
        customerWeeklyDefaultOrder(date: $date) {
          isActive
          articleId
          description
          articleNo
          itemSortPos
          isMatchAssortmentGroup
          canChangeInOrders
          articleInformation
          mondaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          tuesdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          wednesdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          thursdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          fridaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          saturdaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
          sundaySettings {
            isArticleAvailable
            date
            quantity
            quantityMin
            quantityMax
            splittingPartsPreset
          }
        }
        defaultOrderSourceId
        isAllowedAutoOrder
        deliverySplittingPartsCount
      }
    }
  }
`;

export const getDataForExistingDefaultOrderPosition = gql`
  query GetDataForExistingDefaultOrderPosition($articleId: ID!, $customerId: ID!, $date: Date!) {
    wawiAssist {
      getWA_DataForNewDefaultOrderPosition(
        articleId: $articleId
        customerId: $customerId
        date: $date
      ) {
        isActive
        articleId
        description
        articleNo
        canChangeInOrders
        isMatchAssortmentGroup
        mondaySettings {
          isArticleAvailable
        }
        tuesdaySettings {
          isArticleAvailable
        }
        wednesdaySettings {
          isArticleAvailable
        }
        thursdaySettings {
          isArticleAvailable
        }
        fridaySettings {
          isArticleAvailable
        }
        saturdaySettings {
          isArticleAvailable
        }
        sundaySettings {
          isArticleAvailable
        }
      }
    }
  }
`;

export const getDataForNewDefaultOrderPosition = gql`
  query GetDataForNewDefaultOrderPosition($articleId: ID!, $customerId: ID!, $date: Date!) {
    wawiAssist {
      getWA_DataForNewDefaultOrderPosition(
        articleId: $articleId
        customerId: $customerId
        date: $date
      ) {
        isActive
        articleId
        description
        articleNo
        itemSortPos
        isMatchAssortmentGroup
        canChangeInOrders
        articleInformation
        mondaySettings {
          isArticleAvailable
          date
          quantity
          quantityMin
          quantityMax
          splittingPartsPreset
        }
        tuesdaySettings {
          isArticleAvailable
          date
          quantity
          quantityMin
          quantityMax
          splittingPartsPreset
        }
        wednesdaySettings {
          isArticleAvailable
          date
          quantity
          quantityMin
          quantityMax
          splittingPartsPreset
        }
        thursdaySettings {
          isArticleAvailable
          date
          quantity
          quantityMin
          quantityMax
          splittingPartsPreset
        }
        fridaySettings {
          isArticleAvailable
          date
          quantity
          quantityMin
          quantityMax
          splittingPartsPreset
        }
        saturdaySettings {
          isArticleAvailable
          date
          quantity
          quantityMin
          quantityMax
          splittingPartsPreset
        }
        sundaySettings {
          isArticleAvailable
          date
          quantity
          quantityMin
          quantityMax
          splittingPartsPreset
        }
      }
    }
  }
`;

export const getCustomerIndividualPrices = gql`
  query getCustomerIndividualPrices($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        customerSpecialPrices {
          fromDate
          toDate
          articleNo
          articleId
          description
          regularPrice
          specialPrice
          specialDiscount
          specialIsNoDiscount
          fromQuantity
          specialPriceType
          comment
          id
        }
      }
    }
  }
`;

export const getCustomerConditions = gql`
  query GetCustomerConditions($id: ID!) {
    wawiAssist {
      getCustomerData(id: $id) {
        informationTip
        id
        customerSpecialPrices {
          fromDate
          toDate
          articleNo
          articleId
          description
          regularPrice
          specialPrice
          specialDiscount
          specialIsNoDiscount
          fromQuantity
          specialPriceType
          comment
          id
        }
        customerNo
        priceCategoryId
        taxSystemId
        discountKindId
        quantityDiscountSettingId
        discountOnGroup1
        discountOnGroup2
        discountOnGroup3
        discountOnGroup4
        discountOnGroup5
        discountOnGroup6
        discountOnGroup7
        discountOnGroup8
        discountOnGroup9
        discountOnGroup10
        discountOnGroup11
        discountOnGroup12
        discountOnGroup13
        discountOnGroup14
        discountOnGroup15
        discountOnGroup16
        discountOnGroup17
        discountOnGroup18
        discountOnGroup19
        discountOnGroup20
        invoicingIntervalId
        debitorStatus
        collectiveInvoicingToCustomerId
        returnLimit
        eInvoiceFormat
        paymentPeriod
        individualPaymentTerms
        skonto
        skontoPeriod
        besrBankId
        debtorAccount
        slipReferenceNo
        directDebitBankId
        bank1Name
        bank1BIN
        bank1AccountNo
        bank1IBAN
        bank1BIC
        bank1MandatNo
        bank1MandatSignatureDate
        bank2Name
        bank2BIN
        bank2AccountNo
        bank2IBAN
        bank2BIC
        bank2MandatNo
        bank2MandatSignatureDate
      }
    }
  }
`;

export const getDeliverySplittingPartsCalculation = gql`
  query GetDeliverySplittingPartsCalculation(
    $position: WA_DefaultOrderPositionSplittingPresetInput!
  ) {
    wawiAssist {
      getWA_DeliverySplittingPartsCalculation(position: $position) {
        mondayParts
        tuesdayParts
        wednesdayParts
        thursdayParts
        fridayParts
        saturdayParts
        sundayParts
      }
    }
  }
`;

export const articleStdPrice = gql`
  query ArticleStdPrice($stdPriceInput: WA_ArticleStdPriceInput) {
    wawiAssist {
      getWA_ArticleStdPrice(stdPriceInput: $stdPriceInput)
    }
  }
`;
