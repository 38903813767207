import {
  BehaviorSubject,
  filter,
  finalize,
  map,
  merge,
  Observable,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs';

import {
  C_Save_Operation_Status,
  SaveCustomerDataMutationVariables,
  Wa_CustomerSpecialPricesGridItemInput,
} from '../../../../../graphql/generatedModel';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import { Pub, State, Sub } from '../../../../../shared/state/state.abstract';
import {
  CustomerConditionsRes,
  customerService,
  IndividualPricesRes,
  SaveCustomerDataRes,
} from '../../../services/customer.service';
import { ITabState } from '../../../states/tabState.model';
import { customersListState } from '../../../states/customersList.state';
import { customerTabLoadingService } from '../customerTabLoading.service';
import { formatDate } from '../../../../../shared/helpers/utils/utils.helper';
import { defaultCustomerConditionsData } from '../../../loaders/conditionsTab.resolver';

class PubImpl extends Pub<IConditionsTabState> {
  initCustomerSpecialPrices(customerSpecialPrices: IConditionsTabState['customerSpecialPrices']) {
    this.emit('initCustomerSpecialPrices', {
      customerSpecialPrices,
    });
  }
  recordData(dataToSave: IConditionsTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save(dataToSave?: IConditionsTabState['dataToSave']) {
    if (dataToSave) {
      this.emit('save', { dataToSave });
    } else {
      this.emit('save', {});
    }
  }
  selectSpecialPricesPos(
    selectedSpecialPricesPos: IConditionsTabState['selectedSpecialPricesPos'],
  ) {
    this.emit('selectSpecialPricesPos', { selectedSpecialPricesPos });
  }
  deleteSpecialPricesPos() {
    this.emit('deleteSpecialPricesPos', {});
  }
  updateSpecialPricesPos(
    selectedSpecialPricesPos: IConditionsTabState['selectedSpecialPricesPos'],
  ) {
    this.emit('updateSpecialPricesPos', { selectedSpecialPricesPos });
  }
  saveSpecialPricesGrid(customerId: IConditionsTabState['customerId']) {
    this.emit('saveSpecialPricesGrid', { customerId });
  }
  copyIndividualPrices(customerIdForCopy: string) {
    customerService.pub.getCustomerIndividualPrices({ id: customerIdForCopy });
    this.emit('copyIndividualPrices', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends Sub<IConditionsTabState> {
  private individualPricesGridLoading$ = new BehaviorSubject<boolean>(false);
  private shareIndividualPricesGridLoading$: Observable<boolean> =
    this.individualPricesGridLoading$.pipe(share());
  protected actionHandlers(): Observable<IConditionsTabState> {
    return merge(
      this.selectedCustomer(),
      this.deleteSpecialPricesPos(),
      this.updateSpecialPricesPos(),
      this.saveSpecialPricesGrid(),
      this.save(),
      this.copyIndividualPrices(),
      this.updateState(),
    ).pipe(
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }
  public individualPricesGridLoading(): Observable<boolean> {
    return this.shareIndividualPricesGridLoading$;
  }
  private deleteSpecialPricesPos(): Observable<IConditionsTabState> {
    return this.actionListener('deleteSpecialPricesPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        let selectedPos: IConditionsTabState['selectedSpecialPricesPos'] = null;
        updatedState.customerSpecialPrices = updatedState.customerSpecialPrices?.filter(
          (pos, i, arr) => {
            if (pos?.id === state.selectedSpecialPricesPos?.id) {
              const nextPos = arr?.[i + 1];
              const prevPos = arr?.[i - 1];
              if (i === 0 && arr!.length > 1) {
                selectedPos = nextPos;
              }
              if (i !== 0) {
                selectedPos = nextPos || prevPos || null;
              }
              return false;
            } else return true;
          },
        );
        updatedState.selectedSpecialPricesPos = selectedPos;
        return updatedState;
      }),
    );
  }

  private updateSpecialPricesPos(): Observable<IConditionsTabState> {
    return this.actionListener('updateSpecialPricesPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        const foundedIdx = updatedState.customerSpecialPrices.findIndex(
          (item) => item.id === updatedState.selectedSpecialPricesPos?.id,
        );
        if (updatedState.selectedSpecialPricesPos) {
          if (foundedIdx !== -1 && updatedState.selectedSpecialPricesPos) {
            updatedState.customerSpecialPrices.splice(
              foundedIdx,
              1,
              updatedState.selectedSpecialPricesPos,
            );
          } else updatedState.customerSpecialPrices.push(updatedState.selectedSpecialPricesPos);
        }
        return updatedState;
      }),
    );
  }

  saveSpecialPricesGrid(): Observable<IConditionsTabState> {
    return this.actionListener('saveSpecialPricesGrid').pipe(
      tap((state) => {
        const updatedState = structuredClone(state);
        const { customerSpecialPrices, customerId } = updatedState;
        customerService.sub
          .editCustomerData()
          .pipe(
            responseHandler<SaveCustomerDataRes | undefined>({
              success: () => 'customer.customer_saved',
              customErrorHandler: () => 'common.error_chnages_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              customersListState.pub.updateCustomer(updatedGridItem!);
            }
          });
        const preparedData = customerSpecialPrices.reduce(
          (acc: Wa_CustomerSpecialPricesGridItemInput[], item) => {
            if (item.articleId) {
              const pos = {
                articleId: item.articleId,
                comment: item.comment,
                fromDate: item.fromDate,
                fromQuantity: item.fromQuantity,
                specialDiscount: item.specialDiscount,
                specialIsNoDiscount: item.specialIsNoDiscount,
                specialPrice: item.specialPrice,
                specialPriceType: item.specialPriceType,
                toDate: item.toDate,
                ...(!item.id.includes('new_') && { id: item.id }),
              } as Wa_CustomerSpecialPricesGridItemInput;
              acc.push(pos);
            }
            return acc;
          },
          [],
        );
        customerService.pub.editCustomerData({
          data: { id: customerId, customerSpecialPrices: preparedData },
        } as SaveCustomerDataMutationVariables);
        this.stream$.next({
          ...updatedState,
          action: 'internalUpdate',
        });
      }),
      finalize(() => conditionsTabState.pub.clearStream()),
      filter(() => false),
    );
  }

  private save(): Observable<IConditionsTabState> {
    return this.actionListener('save').pipe(
      tap(({ dataToSave }) => {
        customerService.sub
          .editCustomerData()
          .pipe(
            responseHandler<SaveCustomerDataRes | undefined>({
              success: () => 'customer.customer_saved',
              customErrorHandler: () => 'common.error_chnages_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              customersListState.pub.updateCustomer(updatedGridItem!);
            }
          });

        customerService.pub.editCustomerData({
          data: dataToSave,
        } as SaveCustomerDataMutationVariables);
      }),
      finalize(() => conditionsTabState.pub.clearStream()),
    );
  }

  private copyIndividualPrices(): Observable<IConditionsTabState> {
    return this.actionListener('copyIndividualPrices').pipe(
      tap(() => this.individualPricesGridLoading$.next(true)),
      switchMap((state) => {
        return customerService.sub.getCustomerIndividualPrices().pipe(
          responseHandler<IndividualPricesRes>({
            errorReturnType: state.customerSpecialPrices as IndividualPricesRes,
          }),
          map((customerSpecialPrices) => {
            const updatedState = structuredClone(state);
            updatedState.customerSpecialPrices = this.addPrefix(customerSpecialPrices);
            updatedState.selectedSpecialPricesPos = null;
            this.individualPricesGridLoading$.next(false);
            return updatedState;
          }),
          filter(({ action }) => action !== 'list.selectedRecord'),
        );
      }),
    );
  }

  private addPrefix(customerSpecialPrices: IndividualPricesRes): IndividualPricesRes {
    const noData = !customerSpecialPrices || !customerSpecialPrices?.length;
    if (noData) {
      return customerSpecialPrices;
    }
    return customerSpecialPrices.map(({ id, ...rest }) => ({
      ...rest,
      id: `new_${id}`,
    }));
  }

  private selectedCustomer(): Observable<IConditionsTabState> {
    return customersListState.sub.state().pipe(
      filter(({ action }) => action === 'selectRecord'),
      filter(({ selectedRecord }) => typeof selectedRecord?.id === 'string'),
      switchMap(({ selectedRecord }) => {
        customerTabLoadingService.pub.loading(true);
        const id = selectedRecord!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedRecord';
        const details = customerService.sub.getCustomerConditions().pipe(
          responseHandler<CustomerConditionsRes>({
            errorReturnType: defaultCustomerConditionsData,
          }),
          map(
            ({
              bank1MandatSignatureDate,
              bank2MandatSignatureDate,
              customerSpecialPrices,
              ...rest
            }) => {
              const formattedDates = {
                bank1MandatSignatureDate: formatDate(bank1MandatSignatureDate),
                bank2MandatSignatureDate: formatDate(bank2MandatSignatureDate),
              };
              state.defaultValues = { ...rest, ...formattedDates };
              state.selectedSpecialPricesPos = null;
              state.customerSpecialPrices = customerSpecialPrices;
              customerTabLoadingService.pub.loading(false);
              customersListState.pub.updateInfoTip(rest.informationTip);
              return state;
            },
          ),
        );
        customerService.pub.getCustomerConditions({ id });

        return details;
      }),
    );
  }
  private updateState(): Observable<IConditionsTabState> {
    return this.actionListener(['selectSpecialPricesPos']);
  }
}

class ConditionsTabState extends State<IConditionsTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const conditionsTabState = new ConditionsTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
  customerSpecialPrices: [],
  selectedSpecialPricesPos: null,
  customerId: null,
});

export interface IConditionsTabState
  extends Pick<ITabState, 'defaultValues' | 'customerSpecialPrices'> {
  action:
    | undefined
    | 'list.selectedRecord'
    | 'initCustomerSpecialPrices'
    | 'selectSpecialPricesPos'
    | 'addSpecialPricesPos'
    | 'deleteSpecialPricesPos'
    | 'updateSpecialPricesPos'
    | 'saveSpecialPricesGrid'
    | 'recordData'
    | 'copyIndividualPrices'
    | 'internalUpdate'
    | 'save';
  dataToSave: CustomerConditionsRes | Record<string, any>;
  customerId: CustomerConditionsRes['id'] | null;
  customerSpecialPrices: ITabState['customerSpecialPrices'];
  selectedSpecialPricesPos: ITabState['customerSpecialPrices'][number] | null;
}
