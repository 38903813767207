import { LoaderFunction } from 'react-router-dom';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { map, take, zipWith } from 'rxjs';
import { C_Order_Type } from '../../../graphql/generatedModel';
import {
  DictOneTimeCustomersQueryRes,
  createOrderService,
} from '../../order/services/createOrder.service';
import { ListCustomersRes, customerService } from '../../customer/services/customer.service';
import { ArticlesListRes, articleService } from '../../article/services/article.service';
import { IItem as IFilterDictItem } from '../../../shared/components/filterTableWithModal/filterTableWithModal.component';
import { t } from 'i18next';
import { orderStatisticService } from '../services/orderStatistic.service';
import { storageHelper } from '../../../shared/helpers/storage';
import { IOrderStatisticState, initOrderStatisticState } from '../order/orderStatistic.state';

export const orderStatisticLoader: LoaderFunction = async (): Promise<IOrderStatisticLoader> => {
  const initData = structuredClone(initOrderStatisticState);

  initData.profileOptions = storageHelper.local.getItem('orderStatistic.profiles') || [];
  initData.profileId = storageHelper.local.getItem('orderStatistic.selectedProfileId') || undefined;
  if (initData.profileId) {
    const sessionProfileFilter = initData?.profileOptions?.find(
      (el) => el.id === initData.profileId,
    );
    if (sessionProfileFilter) {
      initData.filter = sessionProfileFilter.settings.filter;
      initData.groupBy = initData.filter.groupByForFutureCalculations;
      initData.gridGrouping = sessionProfileFilter.settings.gridGrouping || [];
    }
  }

  const { oneTimeCustomers, customers, articles } =
    await orderStatisticService.globHelpers.streamToPromise(
      createOrderService.sub.oneTimeCustomerList().pipe(
        zipWith(customerService.sub.listGridCustomers(), articleService.sub.articleList()),
        responseHandler<[DictOneTimeCustomersQueryRes, ListCustomersRes, ArticlesListRes]>({
          errorReturnType: [[], [], []],
        }),
        take(1),
        map(([oneTimeCustomersRaw, customersRaw, articlesRaw]) => {
          const oneTimeCustomers = oneTimeCustomersRaw.map(({ id, label, customerNo }) => ({
            id,
            number: customerNo,
            description: label ?? '',
          }));

          const customers = customersRaw.map(({ id, customerNo, internalOrFullName }) => ({
            id,
            number: customerNo,
            description: internalOrFullName ?? '',
          }));

          const articles = articlesRaw.map(({ id, articleNo, description }) => ({
            id,
            number: articleNo,
            description: description ?? '',
          }));

          return { oneTimeCustomers, customers, articles };
        }),
      ),
      () => {
        createOrderService.pub.oneTimeCustomerList();
        customerService.pub.listGridCustomers({ filter: { isActive: true }, cache: false });
        articleService.pub.articlesList({ filter: { isActive: true }, cache: false });
      },
    );

  const allOrderTypes = Object.values(C_Order_Type);
  const orderTypes = allOrderTypes.map((typeValue) => ({
    id: typeValue,
    description: t(`enums.${typeValue}`),
  }));

  return {
    initData,
    dicts: {
      oneTimeCustomers,
      customers,
      articles,
      orderTypes,
    },
  };
};

export interface IDict {
  oneTimeCustomers: IFilterDictItem[];
  customers: IFilterDictItem[];
  articles: IFilterDictItem[];
  orderTypes: IFilterDictItem[];
}

export interface IOrderStatisticLoader {
  initData: IOrderStatisticState;
  dicts: IDict;
}
