import { gql } from 'graphql-request';

export const getWAPdfHistoryReport = gql`
  query getWA_PdfHistoryReport($params: WA_GetReportInput!) {
    wawiAssist {
      getWA_PdfHistoryReport(params: $params) {
        documentNo
        reportId
        url
        s3Key
        bucket
      }
    }
  }
`;

export const listWAPdfHistoryReports = gql`
  query listWA_PdfHistoryReports($params: WA_ListReportsInput!) {
    wawiAssist {
      listWA_PdfHistoryReports(params: $params) {
        mergedFile {
          url
          s3Key
          bucket
        }
        reports {
          documentId
          documentNo
          reportId
          url
          s3Key
          bucket
        }
      }
    }
  }
`;

export const sendEmailWithDocument = gql`
  mutation sendEmailWithDocument(
    $messageParams: SendMultipleEmailsInput!
    $docType: C_EMAIL_MSG_DOCTYPE!
    $documentId: ID!
  ) {
    wawiAssist {
      sendEmailWithDocument(
        messageParams: $messageParams
        docType: $docType
        documentId: $documentId
      ) {
        sentMessages {
          messageId
          timestamp
        }
        unsentMessages {
          recipient
        }
      }
    }
  }
`;

export const extractXmlFromPdfOfInvoices = gql`
  mutation extractXmlFromPdfOfInvoices($invoices: [WA_PdfFileOfInvoiceInput!]!) {
    wawiAssist {
      extractXmlFromPdfOfInvoices(invoices: $invoices) {
        invoiceId
        xmlFile {
          s3Bucket
          s3Key
          url
        }
      }
    }
  }
`;

export const getDataForMultiEmails = gql`
  query ListWA_DataForMultiEmails($emailsDocType: C_EMAIL_MSG_DOCTYPE!, $documentsIds: [ID!]!) {
    wawiAssist {
      listWA_DataForMultiEmails(emailsDocType: $emailsDocType, documentsIds: $documentsIds) {
        documentId
        documentNo
        documentDate
        customerId
        customerNo
        internalOrFullName
        email
        eInvoiceFormat
      }
    }
  }
`;
