import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC } from 'react';
import { environment } from '../../../../../../../environment';
import { C_Language } from '../../../../../../../graphql/generatedModel';
import useLanguage from '../../../../../../hooks/useLanguage';
import {
  FranceFlagIcon,
  GermanyFlagIcon,
  ItalyFlagIcon,
  UKFlagIcon,
} from '../../../../../../../svg/custom.svg';

export const Language: FC = () => {
  const appEnv = import.meta.env.VITE_REACT_APP_ENV as keyof typeof environment;
  const { currentLanguage, changeLanguageHandler } = useLanguage();

  return (
    <>
      {appEnv === 'development' && (
        <Select
          data-testid='language'
          value={currentLanguage}
          onChange={(e: SelectChangeEvent) => {
            changeLanguageHandler(e.target.value as C_Language);
          }}
          size='small'
          autoWidth
          sx={{
            '& .MuiSelect-select': {
              display: 'flex',
            },
          }}
        >
          <MenuItem value={C_Language.L2_GERMAN}>
            <GermanyFlagIcon />
            <Typography ml='10px'>de</Typography>
          </MenuItem>
          <MenuItem value={C_Language.L1_ENGLISH}>
            <UKFlagIcon />
            <Typography ml='10px'>en</Typography>
          </MenuItem>
          <MenuItem value={C_Language.L3_FRENCH}>
            <FranceFlagIcon />
            <Typography ml='10px'>fr</Typography>
          </MenuItem>
          <MenuItem value={C_Language.L4_ITALIAN}>
            <ItalyFlagIcon />
            <Typography ml='10px'>it</Typography>
          </MenuItem>
        </Select>
      )}
    </>
  );
};
