import { Pub, State, Sub } from '../../../../../shared/state/state.abstract.ts';
import { ITabState, tabStateModel } from '../../../states/tabState.model.ts';
import {
  customerService,
  CustomerTourDataRes,
  SaveCustomerDataRes,
} from '../../../services/customer.service.ts';
import { filter, finalize, map, merge, Observable, switchMap, take, tap } from 'rxjs';
import { customersListState } from '../../../states/customersList.state.ts';
import { customerTabLoadingService } from '../customerTabLoading.service.ts';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler.ts';
import {
  C_Save_Operation_Status,
  SaveCustomerDataMutationVariables,
} from '../../../../../graphql/generatedModel.ts';
import { defaultCustomerTourData } from '../../../loaders/tourTab.resolver.ts';

class PubImpl extends Pub<ITourTabState> {
  save(dataToSave: SaveCustomerDataMutationVariables) {
    this.emit('save', { dataToSave });
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<ITourTabState> {
  protected actionHandlers(): Observable<ITourTabState> {
    return merge(this.selectedCustomer(), this.save()).pipe();
  }
  private save(): Observable<ITourTabState> {
    return this.actionListener('save').pipe(
      tap(({ dataToSave }) => {
        if (dataToSave) {
          customerService.sub
            .editCustomerData()
            .pipe(
              responseHandler<SaveCustomerDataRes | undefined>({
                success: () => 'customer.customer_saved',
                customErrorHandler: () => 'common.error_chnages_not_saved',
              }),
              filter((v) => v !== undefined),
              take(1),
            )
            .subscribe((res) => {
              if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
                const { updatedGridItem } = res;
                customersListState.pub.updateCustomer(updatedGridItem!);
              }
            });
          customerService.pub.editCustomerData(dataToSave);
        }
      }),
      finalize(() => tourTabState.pub.clearStream()),
    );
  }
  private selectedCustomer(): Observable<ITourTabState> {
    return customersListState.sub.state().pipe(
      filter(({ action }) => action === 'selectRecord'),
      filter(({ selectedRecord }) => typeof selectedRecord?.id === 'string'),
      switchMap(({ selectedRecord }) => {
        customerTabLoadingService.pub.loading(true);
        const id = selectedRecord!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedRecord';

        const details = customerService.sub.customerTourData().pipe(
          responseHandler<CustomerTourDataRes>({ errorReturnType: defaultCustomerTourData }),
          map((data) => {
            state.defaultValues = data;
            customersListState.pub.updateInfoTip(data.informationTip);
            customerTabLoadingService.pub.loading(false);
            return state;
          }),
        );
        customerService.pub.customerTourData({ id });
        return details;
      }),
    );
  }
}

class TourTabState extends State<ITourTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const tourTabState = new TourTabState({
  ...tabStateModel,
  action: undefined,
  dataToSave: undefined,
});
export interface ITourTabState extends Pick<ITabState, 'defaultValues'> {
  action: undefined | 'list.selectedRecord' | 'save';
  dataToSave: SaveCustomerDataMutationVariables | undefined;
}
