import { TypeTable } from '../../../../../../../../shared/components/table/table.component.tsx';
import { viewCells } from './viewCells.ts';

export const viewRows: TypeTable['viewRows'] = {
  action: () => {
    return 'row';
  },
  styles: [
    {
      className: 'row',
      rowStyles: {
        '&.MuiDataGrid-row.Mui-selected': {
          ...(viewCells?.styles &&
            viewCells.styles.reduce((acc: Record<string, any>, el) => {
              let tag: any = el.className;
              acc[`& > .${tag}`] = Object.getOwnPropertyDescriptor(el.cellStyles, '&:hover')?.value;
              return acc;
            }, {})),
        },
      },
    },
  ],
};
