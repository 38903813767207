import {
  filter,
  finalize,
  map,
  merge,
  Observable,
  switchMap,
  take,
  tap,
  zip,
  BehaviorSubject,
  share,
} from 'rxjs';

import {
  C_Sale_Unit,
  C_Save_Operation_Status,
  C_Vat_Code,
  SaveArticleDataMutationVariables,
  Wa_ArticleSpecialPricesGridItemInput,
} from '../../../../../graphql/generatedModel';
import { storageHelper } from '../../../../../shared/helpers/storage';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import {
  configsData,
  DictPriceCategoriesRes,
} from '../../../../../shared/services/configsData/configsData.service';
import { Pub, State } from '../../../../../shared/state/state.abstract';
import {
  ArticleGeneralTabDataRes,
  articleService,
  SaveArticleDataRes,
  GetArticleSpecialPricesRes,
  GetArticlePricesByCustomerRes,
} from '../../../services/article.service';
import { articleListState } from '../../../states/articleList.state';
import { ITabState } from '../../../states/tabState.model';
import { articleTabLoadingService } from '../articleTababLoading.service';
import { TArticleSpecialPrices } from './components/specialPrices/specialPrices.component';
import { generateSpecialPricesWithOnlyUsablePrices } from '../../../loaders/generalTab.resolver';
import { resetTimeInDate } from '../../../../../shared/helpers/utils/utils.helper';
import { modeService } from '../../../../../shared/services/mode/mode.service';
import { artcileTabsSub } from '../articleCommonTabs.sub';

export const defaultCreateModeValues: Partial<ArticleGeneralTabDataRes> = {
  id: 'testId',
  articleNo: null,
  description: null,
  saleUnitId: C_Sale_Unit.SU1_PIECE,
  productionGroupId: null,
  marketingGroupId: null,
  shippingPointId: null,
  price1: null,
  price2: null,
  price3: null,
  price4: null,
  price5: null,
  price6: null,
  price7: null,
  price8: null,
  price9: null,
  price10: null,
  isNoDiscount: false,
  note: null,
  isNotifyOnOrderStage: false,
  quantityPerLot: null,
  isActive: true,
  vatCode: C_Vat_Code.VT2_REDUCED,
};

class PubImpl extends Pub<IGeneralTabState> {
  recordData(dataToSave: IGeneralTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save(dataToSave?: IGeneralTabState['dataToSave']) {
    if (dataToSave) {
      this.emit('save', { dataToSave });
    } else {
      this.emit('save', {});
    }
  }
  initArticleSpecialPrices(articleSpecialPrices: IGeneralTabState['articleSpecialPrices']) {
    this.emit('initArticleSpecialPrices', {
      articleSpecialPrices,
      selectedSpecialPricesPos: articleSpecialPrices?.[0],
    });
  }
  selectSpecialPricesPos(selectedSpecialPricesPos: IGeneralTabState['selectedSpecialPricesPos']) {
    this.emit('selectSpecialPricesPos', { selectedSpecialPricesPos });
  }
  updateSpecialPricesPos(selectedSpecialPricesPos: IGeneralTabState['selectedSpecialPricesPos']) {
    this.emit('updateSpecialPricesPos', { selectedSpecialPricesPos });
  }
  deleteSpecialPricesPos() {
    this.emit('deleteSpecialPricesPos', {});
  }
  saveSpecialPricesGrid(articleId: IGeneralTabState['articleId']) {
    this.emit('saveSpecialPricesGrid', { articleId });
  }
  copySpecialPrices(articleIdForCopy: NonNullable<IGeneralTabState['articleId']>): void {
    articleService.pub.getArticleSpecialPrices({ id: articleIdForCopy });
    this.emit('copySpecialPrices', {});
  }
  initPricesByCustomer(articlePricesByCustomers: IGeneralTabState['articlePricesByCustomers']) {
    this.emit('initPricesByCustomerPos', {
      articlePricesByCustomers,
      selectedPricesByCustomerPos: articlePricesByCustomers?.[0],
    });
  }
  selectPricesByCustomerPos(
    selectedPricesByCustomerPos: IGeneralTabState['selectedPricesByCustomerPos'],
  ) {
    this.emit('selectPricesByCustomerPos', { selectedPricesByCustomerPos });
  }
  updatePricesByCustomerPos(
    selectedPricesByCustomerPos: IGeneralTabState['selectedPricesByCustomerPos'],
  ) {
    this.emit('updatePricesByCustomerPos', { selectedPricesByCustomerPos });
  }
  copyPricesByCustomer(articleIdForCopy: string): void {
    articleService.pub.getArticlePricesByCustomer({ id: articleIdForCopy });
    this.emit('copyPricesByCustomer', {});
  }
  savePricesByCustomerGrid(articleId: IGeneralTabState['articleId']) {
    this.emit('savePricesByCustomerGrid', { articleId });
  }
  deletePricesByCustomerPos() {
    this.emit('deletePricesByCustomerPos', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends artcileTabsSub<IGeneralTabState>() {
  private specialPricesGridLoading$ = new BehaviorSubject<boolean>(false);
  private shareSpecialPricesGridLoading$: Observable<boolean> = this.specialPricesGridLoading$.pipe(
    share(),
  );
  protected actionHandlers(): Observable<IGeneralTabState> {
    return merge(
      this.selectedArticle(),
      this.save(),
      this.saveSpecialPricesGrid(),
      this.copySpecialPrices(),
      this.updateState(),
      this.updateSpecialPricesPos(),
      this.deleteSpecialPricesPos(),
      this.copyPricesByCustomer(),
      this.savePricesByCustomerGrid(),
      this.updatePricesByCustomerPos(),
      this.deletePricesByCustomerPos(),
    ).pipe(
      tap((state) => {
        this.stream$.next({ ...state, action: 'internalUpdate' });
      }),
    );
  }
  public specialPricesGridLoading(): Observable<boolean> {
    return this.shareSpecialPricesGridLoading$;
  }
  private save(): Observable<IGeneralTabState> {
    return this.actionListener('save').pipe(
      filter(({ dataToSave }) => {
        return Boolean(Object.keys(dataToSave).length);
      }),
      tap(({ dataToSave }) => {
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
            storageHelper.memory.removeItem('configsData.articlesForOrderPositionList');
          });
        articleService.pub.editArticle({
          dataToSave,
        } as SaveArticleDataMutationVariables);
      }),
      finalize(() => generalTabState.pub.clearStream()),
    );
  }

  private updateSpecialPricesPos(): Observable<IGeneralTabState> {
    return this.actionListener('updateSpecialPricesPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        const foundedIdx = updatedState.articleSpecialPrices.findIndex(
          (item) => item.id === updatedState.selectedSpecialPricesPos?.id,
        );
        if (updatedState.selectedSpecialPricesPos) {
          if (foundedIdx !== -1) {
            updatedState.articleSpecialPrices.splice(
              foundedIdx,
              1,
              updatedState.selectedSpecialPricesPos,
            );
          } else updatedState.articleSpecialPrices.push(updatedState.selectedSpecialPricesPos);
          updatedState.articleSpecialPrices = this.findActualSpecialPrice(
            updatedState.articleSpecialPrices,
          );
        }
        return updatedState;
      }),
    );
  }

  private updatePricesByCustomerPos(): Observable<IGeneralTabState> {
    return this.actionListener('updatePricesByCustomerPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        const foundedIdx = updatedState.articlePricesByCustomers.findIndex(
          (item) => item.id === updatedState.selectedPricesByCustomerPos?.id,
        );
        if (updatedState.selectedPricesByCustomerPos) {
          if (foundedIdx !== -1) {
            updatedState.articlePricesByCustomers.splice(
              foundedIdx,
              1,
              updatedState.selectedPricesByCustomerPos,
            );
          } else
            updatedState.articlePricesByCustomers.push(updatedState.selectedPricesByCustomerPos);
        }
        return updatedState;
      }),
    );
  }

  findActualSpecialPrice(articleSpecialPrices: TArticleSpecialPrices): TArticleSpecialPrices {
    const actualPrices = articleSpecialPrices?.filter((row) =>
      this.isSpecialPriceRowCanBeActual(row),
    );
    if (actualPrices?.length) {
      if (actualPrices?.length === 1) {
        const actual = actualPrices[0];
        return this.setActualToRow(articleSpecialPrices, actual?.id);
      }
      const maxFromDate = actualPrices.reduce((res, { fromDate }) => {
        if (!fromDate) {
          return res;
        }
        const from = resetTimeInDate(new Date(fromDate))?.getTime();
        if (from > res) {
          return from;
        }
        return res;
      }, 0);

      const caunterRowsWithMaxFromDate = actualPrices.reduce((caunter, { fromDate }) => {
        if (!fromDate) {
          return caunter;
        }
        const from = resetTimeInDate(new Date(fromDate))?.getTime();
        if (from === maxFromDate) {
          return caunter + 1;
        }
        return caunter;
      }, 0);

      if (caunterRowsWithMaxFromDate === 1) {
        const actual = actualPrices.find(({ fromDate }) => {
          if (fromDate) {
            const from = resetTimeInDate(new Date(fromDate))?.getTime();
            return from === maxFromDate;
          }
        });
        return actual?.id
          ? this.setActualToRow(articleSpecialPrices, actual.id as string)
          : articleSpecialPrices;
      }

      const theBiggestId = actualPrices.reduce((initId, { id: idForCheck, fromDate }) => {
        const from = resetTimeInDate(new Date(fromDate))?.getTime();
        if (from !== maxFromDate) {
          return initId;
        }
        const id = this.deletePrefixNew(initId);
        const numberId = this.deletePrefixNew(idForCheck);
        if (id > numberId) {
          return initId;
        } else {
          return idForCheck;
        }
      }, '0');

      return theBiggestId
        ? this.setActualToRow(articleSpecialPrices, String(theBiggestId))
        : articleSpecialPrices;
    }

    return articleSpecialPrices;
  }

  private deletePrefixNew(initId: number | string): number {
    const stringId = String(initId);
    if (stringId?.startsWith('new_')) {
      const numberId = Number(stringId?.replace('new_', ''));
      return numberId;
    }
    const numberId = Number(initId);
    return numberId;
  }

  private isSpecialPriceRowCanBeActual(row: TArticleSpecialPrices[number]): boolean {
    const { fromDate, toDate } = row;
    const current = resetTimeInDate(new Date())?.getTime();
    let from: number | null = null;
    let to: number | null = null;
    if (fromDate) {
      from = resetTimeInDate(new Date(fromDate))?.getTime();
    }
    if (toDate) {
      to = new Date(toDate)?.setHours(23, 59, 59, 999);
    }
    if (!from && !to) {
      return false;
    }
    if (from && to) {
      return current > from && current < to;
    }
    if (!from) {
      return current < (to as number);
    }
    // case when  to ===  null
    return current > from;
  }

  private setActualToRow(
    articleSpecialPrices: TArticleSpecialPrices,
    id: string,
  ): TArticleSpecialPrices {
    return articleSpecialPrices?.map((v) => {
      const { id: idForCheck } = v;
      if (idForCheck === id) {
        return {
          ...v,
          isActual: true,
        };
      } else {
        return {
          ...v,
          isActual: false,
        };
      }
    });
  }

  private deleteSpecialPricesPos(): Observable<IGeneralTabState> {
    return this.actionListener('deleteSpecialPricesPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        let selectedPos: IGeneralTabState['selectedSpecialPricesPos'] = null;
        const updatedSpecialPrices = updatedState.articleSpecialPrices?.filter((pos, i, arr) => {
          if (pos?.id === state.selectedSpecialPricesPos?.id) {
            const nextPos = arr?.[i + 1];
            const prevPos = arr?.[i - 1];
            if (i === 0 && arr!.length > 1) {
              selectedPos = nextPos;
            }
            if (i !== 0) {
              selectedPos = nextPos || prevPos || null;
            }
            return false;
          } else return true;
        });
        if (updatedSpecialPrices?.length) {
          updatedState.articleSpecialPrices = this.findActualSpecialPrice(updatedSpecialPrices);
        } else {
          updatedState.articleSpecialPrices = updatedSpecialPrices;
        }
        updatedState.selectedSpecialPricesPos = selectedPos;
        return updatedState;
      }),
    );
  }

  private deletePricesByCustomerPos(): Observable<IGeneralTabState> {
    return this.actionListener('deletePricesByCustomerPos').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        let updatedSelectedPos: IGeneralTabState['selectedPricesByCustomerPos'] = null;
        const updatedPrices: IGeneralTabState['articlePricesByCustomers'] =
          updatedState.articlePricesByCustomers.filter((item, i, arr) => {
            if (item.id === state.selectedPricesByCustomerPos?.id) {
              updatedSelectedPos = arr[i + 1] || arr[i - 1] || null;
              return false;
            }
            return true;
          });
        updatedState.articlePricesByCustomers = updatedPrices;
        updatedState.selectedPricesByCustomerPos = updatedSelectedPos;
        return updatedState;
      }),
    );
  }

  saveSpecialPricesGrid(): Observable<IGeneralTabState> {
    return this.actionListener('saveSpecialPricesGrid').pipe(
      tap((state) => {
        const updatedState = structuredClone(state);
        const { articleSpecialPrices, articleId } = updatedState;
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
          });
        const preparedData = articleSpecialPrices
          .filter(({ fromDate, toDate }) => fromDate || toDate)
          .map(({ id, isActual: _, ...rest }) => ({
            ...rest,
            ...(!id.includes('new_') && { id }),
          })) as Wa_ArticleSpecialPricesGridItemInput[];
        articleService.pub.editArticle({
          dataToSave: { id: articleId, articleSpecialPrices: preparedData },
        } as SaveArticleDataMutationVariables);
        this.stream$.next({
          ...updatedState,
          action: 'internalUpdate',
        });
      }),
      finalize(() => generalTabState.pub.clearStream()),
      filter(() => false),
    );
  }

  savePricesByCustomerGrid(): Observable<IGeneralTabState> {
    return this.actionListener('savePricesByCustomerGrid').pipe(
      tap((state) => {
        const { articlePricesByCustomers, articleId } = state;
        articleService.sub
          .editArticle()
          .pipe(
            responseHandler<SaveArticleDataRes | undefined>({
              success: () => 'article.article_saved',
              customErrorHandler: () => 'article.article_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              articleListState.pub.updateArticle(updatedGridItem!);
            }
          });
        const pricesToSave = articlePricesByCustomers.map((item) => {
          const { customerNo: _, customerName: __, ...restData } = item;
          return restData;
        });
        articleService.pub.editArticle({
          dataToSave: { id: articleId as string, articlePricesByCustomers: pricesToSave },
        });
      }),
      finalize(() => generalTabState.pub.clearStream()),
      filter(() => false),
    );
  }

  private copySpecialPrices(): Observable<IGeneralTabState> {
    return this.actionListener('copySpecialPrices').pipe(
      tap(() => this.specialPricesGridLoading$.next(true)),
      switchMap((state) => {
        return articleService.sub.getArticleSpecialPrices().pipe(
          responseHandler<GetArticleSpecialPricesRes>({
            errorReturnType: state.articleSpecialPrices,
          }),
          map((articleSpecialPrices) => {
            const updatedState = structuredClone(state);
            updatedState.articleSpecialPrices = this.addPrefix(articleSpecialPrices);
            updatedState.selectedSpecialPricesPos = null;
            this.specialPricesGridLoading$.next(false);
            return updatedState;
          }),
          filter(({ action }) => action !== 'list.selectedArticle'),
        );
      }),
    );
  }

  private copyPricesByCustomer(): Observable<IGeneralTabState> {
    return this.actionListener('copyPricesByCustomer').pipe(
      switchMap((state) => {
        return articleService.sub.getArticlePricesByCustomer().pipe(
          responseHandler<GetArticlePricesByCustomerRes>({
            errorReturnType: state.articlePricesByCustomers,
          }),
          map((articlePricesByCustomer) => {
            const updatedState = structuredClone(state);
            updatedState.articlePricesByCustomers = this.addPrefix(articlePricesByCustomer);
            updatedState.selectedPricesByCustomerPos = null;
            return updatedState;
          }),
          filter(({ action }) => action !== 'list.selectedArticle'),
        );
      }),
    );
  }

  private addPrefix<T extends { id: string }>(itemsArr: Array<T>): Array<T> {
    const noData = !itemsArr || !itemsArr?.length;
    if (noData) {
      return itemsArr;
    }
    return itemsArr.map(
      ({ id, ...rest }) =>
        ({
          ...rest,
          id: `new_${id}`,
        } as T),
    );
  }

  private selectedArticle(): Observable<IGeneralTabState> {
    return articleListState.sub.state().pipe(
      filter(({ action }) => {
        return (
          action === 'selectArticle' ||
          action === 'articleList' ||
          action === 'filter' ||
          action === 'refreshArticleData'
        );
      }),
      filter(({ selectedArticle, action }) => {
        const state = this.stream$.getValue();
        if (!selectedArticle || !selectedArticle?.id) {
          state.defaultValues = {};
          this.articleIdNotExist$.next(true);
        }
        return (
          typeof selectedArticle?.id === 'string' &&
          (state.defaultValues.id !== selectedArticle?.id || action === 'refreshArticleData')
        );
      }),
      tap(() => modeService.pub.mode('edit')),
      switchMap(({ selectedArticle }) => {
        articleTabLoadingService.pub.loading(true);
        const id = selectedArticle!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedArticle';
        const details = zip(
          articleService.sub.articleGeneralTabData(),
          configsData.sub.dictPriceCategories(),
        ).pipe(
          responseHandler<GeneralAndPriceCategoriesRes>({
            customErrorHandler: () => 'article.tab_not_loaded',
            errorReturnType: [{ ...defaultCreateModeValues }, []],
          }),
          take(1),
          map(
            ([
              { articleSpecialPrices: asp = [], articlePricesByCustomers, ...rest },
              priceCategories,
            ]) => {
              const articleSpecialPrices = generateSpecialPricesWithOnlyUsablePrices(
                asp,
                priceCategories,
              );
              state.defaultValues = rest;
              state.selectedSpecialPricesPos = null;
              state.articleSpecialPrices = articleSpecialPrices;
              state.selectedSpecialPricesPos = articleSpecialPrices?.[0];
              articleTabLoadingService.pub.loading(false);
              this.specialPricesGridLoading$.next(false);
              state.articlePricesByCustomers = articlePricesByCustomers || [];
              return state;
            },
          ),
        );
        articleService.pub.articleGeneralTabData({ id });
        configsData.pub.common(['dictPriceCategories']);
        return details;
      }),
    );
  }

  private updateState(): Observable<IGeneralTabState> {
    return this.actionListener(['selectSpecialPricesPos', 'selectPricesByCustomerPos']);
  }
}

class GeneralTabState extends State<IGeneralTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const initSpecialPricePosition = {
  fromDate: null,
  toDate: null,
  isActual: false,
  price1: null,
  price2: null,
  price3: null,
  price5: null,
  price4: null,
  price6: null,
  price7: null,
  price8: null,
  price9: null,
  price10: null,
  description: '',
  id: null,
};

export const generalTabState = new GeneralTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
  articleSpecialPrices: [],
  selectedSpecialPricesPos: null,
  articlePricesByCustomers: [],
  selectedPricesByCustomerPos: null,
  articleId: null,
});

export interface IGeneralTabState
  extends Pick<ITabState, 'defaultValues' | 'articleSpecialPrices' | 'articlePricesByCustomers'> {
  action:
    | undefined
    | 'list.selectedArticle'
    | 'recordData'
    | 'save'
    | 'saveSpecialPricesGrid'
    | 'copySpecialPrices'
    | 'initArticleSpecialPrices'
    | 'selectSpecialPricesPos'
    | 'deleteSpecialPricesPos'
    | 'updateSpecialPricesPos'
    | 'initPricesByCustomerPos'
    | 'selectPricesByCustomerPos'
    | 'updatePricesByCustomerPos'
    | 'copyPricesByCustomer'
    | 'savePricesByCustomerGrid'
    | 'deletePricesByCustomerPos'
    | 'internalUpdate';
  selectedSpecialPricesPos: TArticleSpecialPrices[number] | null;
  dataToSave: ArticleGeneralTabDataRes | Record<string, any>;
  articleId: ArticleGeneralTabDataRes['id'] | null;
  selectedPricesByCustomerPos: ITabState['articlePricesByCustomers'][number] | null;
}

type GeneralAndPriceCategoriesRes = [Partial<ArticleGeneralTabDataRes>, DictPriceCategoriesRes];
