/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Observable,
  merge,
  tap,
  BehaviorSubject,
  share,
  pairwise,
  filter,
  switchMap,
  of,
  zip,
  map,
  mergeMap,
  take,
  delay,
} from 'rxjs';
import equal from 'fast-deep-equal/react';
import i18n from 'i18next';
import { Sub } from '../../../../shared/state/state.abstract.ts';
import {
  IOfferDetailsState,
  initOfferDetailsState,
  IAdditionalOptions,
  additionalOptions$,
  IOfferPrepareArgsToSave,
  offerSelectionPopup,
  offerDetailsState,
} from './offerDetails.state.ts';

import { customerListState } from '../customerList.state.ts';
import {
  offerService,
  OfferDetailsRes,
  ProductDataRes,
  IPosition,
  ProductPriceAndDiscountRes,
  TaxesByDateRes,
  CreateDraftOfferExtendedRes,
  INewOfferDataExtendedRes,
  DeleteDraftOfferRes,
  SaveOfferRes,
} from '../../services/offer.service.ts';
import { responseHandler } from '../../../../shared/responseHandler/responseHandler.ts';
import {
  C_Sale_Unit,
  C_Kg_Representation,
  C_Discount_Kind,
  CreateWa_DraftOfferMutationVariables,
  Wa_OfferPositionInputs,
  C_Vat_Code,
  C_Virtual_Position,
  SaveWa_OfferMutationVariables,
  C_Offer_Error_Code,
} from '../../../../graphql/generatedModel.ts';
import { companyConfigService } from '../../../../shared/services/companyConfig/companyConfig.service.ts';
import { notificationCenter } from '../../../../shared/notificationCenter/notificationCenter.ts';
import { snackbarService } from '../../../../shared/components/snackbar/service/snackbar.service.ts';
import { navBlocker } from '../../../../shared/components/navigation/blocker/nav.blocker.ts';
import { format } from 'date-fns';
import { IDateSettingsFormData } from '../../components/offerDetails/popups/dateSettings/content.popup.tsx';
import { IOfferActions } from './offerActions.state.ts';

export class SubImpl extends Sub<IOfferDetailsState> {
  private loading$ = new BehaviorSubject<boolean>(false);
  private shareLoading$: Observable<boolean> = this.loading$.pipe(share());
  private offerGridLoading$ = new BehaviorSubject<boolean>(false);
  private saveLoading$ = new BehaviorSubject<boolean>(false);
  private shareSaveLoading$: Observable<boolean> = this.saveLoading$.pipe(share());
  private warnings$ = new BehaviorSubject<IOfferActions['warnings'] | undefined>(undefined);
  private shareWarnings$: Observable<IOfferActions['warnings'] | undefined> = this.warnings$.pipe(
    share(),
  );
  private shareOfferGridLoading$: Observable<boolean> = this.offerGridLoading$.pipe(share());
  private cellsLoading$ = new BehaviorSubject<string[]>([]); // Loading by cells
  private shareCellsLoading$ = this.cellsLoading$.pipe(share());
  private calcPriceError$ = new BehaviorSubject<number[]>([]);
  private shareCalcPriceError$ = this.calcPriceError$.pipe(share());
  private editedCells: Set<string> = new Set(); // Cells edited manually (format - 'idPosition_cellName')
  protected actionHandlers(): Observable<IOfferDetailsState> {
    return merge(
      this.addPosition(),
      this.deletePosition(),
      this.queryData(),
      this.simpleCell(),
      this.productCell(),
      this.quantityCell(),
      this.vatCell(),
      this.updatePosition(),
      this.replaceOfferData(),
      this.revertOrderData(),
      this.updateState(),
      this.updateDateSettings(),
      this.selectOffer(),
      this.saveOfferData(),
    ).pipe(
      switchMap((state) => {
        return zip(of(state), customerListState.sub.sharedValues());
      }),
      map(([state, { customer }]) => {
        state.dirty =
          state.action !== 'emptyData' && !equal(state.offerDataBackup, state.offerData);
        const isCreatedOffer =
          !customer?.isAccepted &&
          !customer?.isDeclined &&
          customer?.offerId !== '0' &&
          !!customer?.offerId;
        navBlocker.watch(state.dirty && isCreatedOffer);
        this.stream$.next({ ...state, action: 'internalUpdate' });
        return state;
      }),
      tap(({ action }) => action !== 'saveOfferData' && this.loading$.next(false)),
    );
  }
  loading(): Observable<boolean> {
    return this.shareLoading$;
  }
  saveLoading(): Observable<boolean> {
    return this.shareSaveLoading$;
  }
  warnings(): Observable<IOfferActions['warnings'] | undefined> {
    return this.shareWarnings$;
  }
  cellsLoading(): Observable<string[]> {
    return this.shareCellsLoading$;
  }
  offerGridLoading(): Observable<boolean> {
    return this.shareOfferGridLoading$;
  }
  calculationPriceError(): Observable<number[]> {
    return this.shareCalcPriceError$;
  }
  /* METHODS OF SECONDARY OPTIONS start */
  additionalOptions(): Observable<IAdditionalOptions> {
    return additionalOptions$.pipe(switchMap(() => merge(this.productList(), this.taxesList())));
  }
  private productList(): Observable<IAdditionalOptions> {
    return offerService.sub.filteredOfferProductData().pipe(
      responseHandler<IAdditionalOptions['productData']>({
        errorReturnType: {
          productList: [],
          listSpecialAvailabilityStateForArticles: undefined,
          listSpecialAssortmentGroups: undefined,
        },
        customErrorHandler: () => 'errors.unknownWithReload',
      }),
      map((productData) => {
        const additionalOptionsState = additionalOptions$.getValue();
        additionalOptionsState.productData = productData;
        return additionalOptionsState;
      }),
    );
  }
  private taxesList(): Observable<IAdditionalOptions> {
    return offerService.sub.taxes().pipe(
      responseHandler<TaxesByDateRes>({
        errorReturnType: [],
      }),
      map((taxes) => {
        const additionalOptionsState = additionalOptions$.getValue();
        additionalOptionsState.taxes = taxes;
        return additionalOptionsState;
      }),
    );
  }

  draftOfferSaved({ id }: { id: string }): Observable<void> {
    return offerService.sub.createDraftOffer().pipe(
      responseHandler<CreateDraftOfferExtendedRes>({
        errorReturnType: {
          draftOfferId: '',
          customerId: '',
        },
      }),
      filter(({ draftOfferId }) => !!draftOfferId),
      map(({ draftOfferId }) => {
        customerListState.pub.updateDraftStatus({ id, draftOfferId });
      }),
    );
  }

  sharedValues(): Observable<ISharedValues> {
    // if you need current values of this state in other places
    const { offerData } = this.stream$.getValue();
    return of({
      offerData,
    });
  }
  /* METHODS OF SECONDARY OPTIONS end */
  /* MASTER DATA METHODS start */
  private selectOffer(): Observable<IOfferDetailsState> {
    return offerSelectionPopup.stream.state().pipe(
      filter(({ action }) => action === 'confirm'),
      switchMap((value) => {
        const zipStream = zip(of(value), this.stream$);
        const prevOfferNo = this.stream$.value.offerData.offerSourceInfo.offerNo;
        const currOfferNo = value.confirmedOffer?.offerSourceInfo.offerNo;
        if (prevOfferNo !== currOfferNo) {
          return zipStream.pipe(
            tap(() => this.loading$.next(true)),
            delay(500),
          );
        }
        return zipStream;
      }),
      map(([{ confirmedOffer = {} }, state]) => {
        return {
          ...state,
          offerData: confirmedOffer as IOfferDetailsState['offerData'],
          action: 'selectOffer',
        };
      }),
    );
  }
  private queryData(): Observable<IOfferDetailsState> {
    return customerListState.sub.state().pipe(
      pairwise(),
      filter(
        ([prevV, currV]) =>
          (currV.action === 'selectCustomer' || currV.action === 'filter') &&
          (prevV.filter.date !== currV.filter.date ||
            prevV.selectedCustomer?.id !== currV.selectedCustomer?.id),
      ),
      switchMap(([prevListState, listState]) => {
        const { customerId, draftOfferId, id, offerId } = prevListState.selectedCustomer || {};
        const runDraftOfferCondition = offerId === '0' || !offerId;
        if (customerId && id && runDraftOfferCondition) {
          this.runSaveDraftOffer({
            id,
            customerId,
            draftOfferId,
            offerForDate: prevListState.filter.date,
          });
        }
        if (!listState.selectedCustomer) {
          return of({
            ...initOfferDetailsState,
            action: 'emptyData',
          }) as Observable<IOfferDetailsState>;
        }
        return zip(this.stream$, of(listState)).pipe(
          tap(([_, listState]) => {
            this.loading$.next(true);
            const {
              productData: { listSpecialAvailabilityStateForArticles, listSpecialAssortmentGroups },
            } = additionalOptions$.getValue();
            const prevDate =
              prevListState.selectedCustomer?.offerForDate || prevListState.filter.date;
            const date = listState.selectedCustomer?.offerForDate || listState.filter.date;
            const customerId = listState.selectedCustomer?.customerId as string;
            const prevCustomerId = prevListState.selectedCustomer?.customerId as string;
            const isDateChanged = prevDate !== date || !listSpecialAvailabilityStateForArticles;
            const isCustomerChanged = customerId !== prevCustomerId;
            offerService.pub.filteredOfferProductData({
              ...((isCustomerChanged || !listSpecialAssortmentGroups) && { customerId }),
              isDateChanged,
              date,
              listSpecialAssortmentGroups,
              listSpecialAvailabilityStateForArticles,
            });
            const isTaxes = Boolean(additionalOptions$.getValue().taxes);
            if (prevDate !== date || !isTaxes) {
              offerService.pub.taxes({
                date,
              });
            }
            offerService.pub.offerDetails({
              ...listState.selectedCustomer,
              offerForDate: listState.filter.date,
            });
          }),
          switchMap(([state]) => {
            return offerService.sub.offerDetails().pipe(
              responseHandler<OfferDetailsRes>({
                errorReturnType: {
                  offerData: [],
                  customerInfo: {},
                },
              }),
              map(({ customerInfo, offerData }) => {
                if (!Object.keys(customerInfo).length || !offerData.length) {
                  // in case of error
                  const emptyState = structuredClone(initOfferDetailsState);
                  emptyState.action = 'errorData';
                  emptyState.offerDataBackup = structuredClone(emptyState.offerData);
                  return emptyState;
                }
                const {
                  customerNo,
                  internalOrFullName,
                  informationTip,
                  deliveryAddress,
                  discountKindId,
                  isDeliverySplitting,
                  deliverySplittingPartsCount,
                  transportSectorId,
                  transportSector,
                  id,
                  ...contacts
                } = customerInfo;
                return {
                  ...state,
                  customerInfo: {
                    customerNo,
                    internalOrFullName,
                    informationTip,
                    deliveryAddress,
                    discountKindId,
                    isDeliverySplitting,
                    deliverySplittingPartsCount,
                    transportSector,
                    id,
                    contacts,
                  },
                  offerData: offerData[0] || {},
                  offerDataBackup: structuredClone(offerData[0] || {}),
                  selectedPos: null,
                  noData: false,
                  action: 'queryData',
                } as IOfferDetailsState;
              }),
            );
          }),
        );
      }),
    );
  }

  private updatePosition(): Observable<IOfferDetailsState> {
    return this.actionListener('updatePosition').pipe(
      map((state) => {
        const updatedState = structuredClone(state);
        const { selectedPos } = updatedState;
        updatedState.offerData.positions = updatedState.offerData.positions?.map((pos) => {
          if (pos?.id === selectedPos?.id) return selectedPos;
          return pos;
        });
        return updatedState;
      }),
    );
  }

  saveOfferData(): Observable<IOfferDetailsState> {
    return this.actionListener('saveOfferData').pipe(
      tap(() => {
        this.saveLoading$.next(true);
      }),
      switchMap((state) => {
        return zip(customerListState.sub.sharedValues(), of(state));
      }),
      tap(([{ customer }, state]) => {
        const args = this.prepareArgsToSave({
          state,
          offerId: customer?.offerId as string,
          modificationOptions: state.modificationOptions,
        });
        if (args?.offerProps?.offerForDate) {
          offerService.pub.saveOffer(args);
        }
      }),
      switchMap(([{ customer, lastCustomerInTable }, state]) => {
        return offerService.sub.saveOffer().pipe(
          responseHandler<SaveOfferRes>({
            customErrorHandler: ({ code }) => {
              this.saveLoading$.next(false);
              const needSecondRequest = code === C_Offer_Error_Code.OEC3_QUANTITY_PER_LOT;
              const emptyPositionsError = code === C_Offer_Error_Code.OEC2_EMPTY_POSITIONS;
              if (needSecondRequest) {
                this.warnings$.next(code);
              } else if (emptyPositionsError) {
                snackbarService.pub.show({
                  type: 'error',
                  content: i18n.t('offer.empty_positions_create_warning'),
                });
              } else {
                snackbarService.pub.show({
                  type: 'error',
                  content: i18n.t('common.server_error'),
                });
              }
              return undefined;
            },
            errorReturnType: undefined,
            quite: true,
          }),
          filter((data) => {
            return !!data;
          }),
          map((result) => {
            this.saveLoading$.next(false);
            snackbarService.pub.show({
              type: 'success',
              content: i18n.t('common.successfully_saved'),
            });
            const isLeftGridDataChanged =
              state?.customerInfo?.customerNo !== result?.customerNr ||
              customer?.offerTotal !== result?.offerTotal ||
              state?.offerDataBackup?.offerForDate !== result?.offerForDate;

            if (lastCustomerInTable) {
              return { ...initOfferDetailsState, action: 'emptyData' } as IOfferDetailsState;
            } else if (isLeftGridDataChanged) {
              customerListState.pub.updateSelectedCustomerInfo(result, true);
            }
            return {
              ...state,
              offerDataBackup: structuredClone(state.offerData),
              dirty: false,
            };
          }),
        );
      }),
    );
  }

  private replaceOfferData(): Observable<IOfferDetailsState> {
    return this.actionListener('replaceOfferData').pipe(
      tap(() => this.offerGridLoading$.next(true)),
      switchMap((state) => {
        const updatedState = structuredClone(state);
        return offerService.sub.offerData().pipe(
          responseHandler<INewOfferDataExtendedRes>({
            errorReturnType: [this.stream$.getValue().offerData],
          }),
          tap((offerData) => {
            if (offerData.length > 1) {
              offerSelectionPopup.stream.emit('open', { offers: offerData });
            }
          }),
          map((offerData) => {
            updatedState.offerData = offerData[0] || {};
            this.offerGridLoading$.next(false);
            return updatedState;
          }),
        );
      }),
    );
  }

  private prepareArgsToSave({
    state,
    offerId,
    modificationOptions,
  }: IOfferPrepareArgsToSave): SaveWa_OfferMutationVariables {
    const {
      offerData: { positions = [], note, linkText, offerForDate, validUntilDate },
    } = state || {};

    const formattedPositions = positions.map((position) => ({
      virtualPositionId: position?.virtualPositionId,
      articleId: position?.articleId,
      quantity: position?.quantity || 0,
      price: position?.price,
      total: position?.total,
      discount: position?.discount,
      vatCode: position?.vatCode,
      indTextDeliveryNote:
        position.virtualPositionId === C_Virtual_Position.VP3_GROUP_HEADER
          ? position.description
          : position?.indTextDeliveryNote,
      indTextProduction: position?.indTextProduction,
    })) as Wa_OfferPositionInputs[];

    const offerProps = {
      offerId,
      linkText,
      modificationOptions,
      note,
      positions: formattedPositions,
      offerForDate,
      validUntilDate,
    } as SaveWa_OfferMutationVariables['offerProps'];

    return {
      offerProps,
    };
  }

  revertOrderData(): Observable<IOfferDetailsState> {
    return this.actionListener('revertOrderData').pipe(
      delay(50), // fixes the problem if the cell is in edit mode
      switchMap((state) => {
        return zip(of(state), customerListState.sub.sharedValues()).pipe(
          tap(([_, { customer }]) => {
            if (Number(customer?.draftOfferId)) {
              offerService.pub.deleteDraftOffer({
                deleteWaDraftOfferId: customer?.draftOfferId!,
              });
              this.offerGridLoading$.next(true);
            }
          }),
          switchMap(([state, { customer, date }]) => {
            if (Number(customer?.draftOfferId)) {
              return offerService.sub.deleteDraftOffer().pipe(
                responseHandler<DeleteDraftOfferRes>({ errorReturnType: false }),
                tap((response) => {
                  if (!response) this.offerGridLoading$.next(false);
                }),
                filter((response) => response),
                tap(() => {
                  offerService.pub.offerData({
                    ...customer,
                    draftOfferId: null,
                    offerForDate: date,
                  });
                }),
                switchMap(() => {
                  return offerService.sub.offerData().pipe(
                    responseHandler<INewOfferDataExtendedRes>({
                      errorReturnType: [this.stream$.getValue().offerData],
                    }),
                    map((data) => {
                      state.offerDataBackup = structuredClone(data[0] || {});
                      this.offerGridLoading$.next(false);
                      customerListState.pub.updateDraftStatus({
                        id: customer?.id!,
                        draftOfferId: null,
                      });
                      return { ...state };
                    }),
                  );
                }),
              );
            } else return of(state);
          }),
          map((state) => {
            state.offerData = structuredClone(state.offerDataBackup);
            this.calcPriceError$.next([]);
            return { ...state };
          }),
        );
      }),
    );
  }

  addPosition(): Observable<IOfferDetailsState> {
    return this.actionListener(['addPosition', 'addHeaderPosition', 'addPageBreakPosition']).pipe(
      switchMap((state) => {
        const updatedState = structuredClone(state);
        if (updatedState.action === 'addHeaderPosition') {
          return this.addVirtualPosition({
            state,
            virtualPositionId: C_Virtual_Position.VP3_GROUP_HEADER,
          });
        } else if (updatedState.action === 'addPageBreakPosition') {
          return this.addVirtualPosition({
            state,
            virtualPositionId: C_Virtual_Position.VP1_PAGE_BREAK,
          });
        } else {
          if (updatedState.selectedPos) {
            updatedState.offerData.positions?.push(updatedState.selectedPos);
          } else {
            const newPosition = {
              virtualPositionId: null,
              articleId: null,
              articleNo: null,
              description: null,
              quantity: null,
              price: 0,
              total: 0,
              discount: 0,
              vatCode: C_Vat_Code.VT5_ZERO,
              indTextDeliveryNote: null,
              indTextProduction: null,
              quantityPerLot: null,
              saleUnit: null,
              id: Date.now(),
              __reorder__: i18n.t('offer.new_position'),
            };
            const virtualPositionId = updatedState.offerData.positions?.findIndex(
              (el) => el?.virtualPositionId,
            );
            if (typeof virtualPositionId === 'number' && virtualPositionId !== -1) {
              updatedState.offerData.positions?.splice(virtualPositionId, 0, newPosition);
            } else updatedState.offerData.positions?.push(newPosition);
            updatedState.selectedPos = newPosition;
          }
          return of(updatedState);
        }
      }),
    );
  }
  deletePosition(): Observable<IOfferDetailsState> {
    return this.actionListener(['deletePosition']).pipe(
      delay(50), // fixes the problem if the cell is in edit mode
      map((state) => {
        const updatedState = structuredClone(state);
        let selectedPos: IOfferDetailsState['selectedPos'] = null;
        updatedState.offerData.positions = updatedState.offerData.positions?.filter(
          (pos, i, arr) => {
            if (pos?.id === state.selectedPos?.id) {
              const nextPos = arr?.[i + 1];
              const prevPos = arr?.[i - 1];
              if (i === 0 && arr!.length > 1) {
                selectedPos = !nextPos?.virtualPositionId ? nextPos : null;
              }
              if (i !== 0) {
                selectedPos = (!nextPos?.virtualPositionId && nextPos) || prevPos || null;
              }
              return false;
            } else return true;
          },
        );
        updatedState.selectedPos = selectedPos;

        return updatedState;
      }),
    );
  }
  private updateState(): Observable<IOfferDetailsState> {
    return this.actionListener(['updateState', 'selectPosition', 'updateOfferData']);
  }
  private updateDateSettings(): Observable<IOfferDetailsState> {
    return this.actionListener('updateDateSettings').pipe(
      map((state) => {
        const dateSettings = state?.params as IDateSettingsFormData;

        return {
          ...state,
          offerData: {
            ...state?.offerData,
            offerForDate: format(dateSettings.offerForDate as Date, 'yyyy-MM-dd'),
            validUntilDate: format(dateSettings.validUntilDate as Date, 'yyyy-MM-dd'),
          },
        };
      }),
    );
  }
  /* MASTER DATA METHODS end */
  /* OFFER CELL METHODS start */
  private simpleCell(): Observable<IOfferDetailsState> {
    return this.actionListener(['priceCell', 'discountCell']).pipe(
      map(({ selectedPos, action }) => {
        const updatedPos = structuredClone(selectedPos!);
        if (action === 'priceCell') {
          this.calcPriceError$.next(
            this.calcPriceError$.value.filter((item) => item !== updatedPos?.id),
          );
          updatedPos.total = this.calculateTotalDependsOnSaleUnit(updatedPos);
        }
        const state = structuredClone(this.stream$.getValue());
        state.offerData.positions = state.offerData.positions?.map((el) =>
          el?.id === updatedPos?.id ? updatedPos : el,
        );
        state.selectedPos = updatedPos;
        this.editedCells.add(`${updatedPos?.id}_${action}`);
        return state;
      }),
    );
  }
  private productCell(): Observable<IOfferDetailsState> {
    return this.actionListener('descriptionCell').pipe(
      mergeMap(({ selectedPos, action }) => {
        this.preUpdateCell(selectedPos);
        return zip(of(selectedPos), customerListState.sub.sharedValues()).pipe(
          tap(([editablePos, leftListData]) => {
            const position = this.stream$.value.selectedPos;
            offerService.pub.productData({
              orderAndArticleProps: {
                orderDate: leftListData.date,
                customerId: leftListData.customer?.customerId!,
                articleId: editablePos?.articleId!,
                vatCode: position?.vatCode!,
                quantity: Number(position?.quantity),
              },
            });
            this.cellsLoading$.next([
              ...this.cellsLoading$.value,
              `${editablePos?.id}_description`,
            ]);
          }),
          switchMap(([editablePos]) => {
            return offerService.sub.productData().pipe(
              responseHandler<ProductDataRes | 'serverError'>({ errorReturnType: 'serverError' }),
              filter(() => {
                const state = this.stream$.getValue();
                this.cellsLoading$.next(
                  this.cellsLoading$.value.filter(
                    (item) => item !== `${editablePos?.id}_description`,
                  ),
                );
                return !!state.offerData.positions?.some((el) => el?.id === editablePos?.id);
              }), // if position is deleted
              map((values) => {
                const state = structuredClone(this.stream$.getValue());
                let updatedPos =
                  state.offerData.positions?.find((pos) => pos?.id === editablePos?.id) ||
                  ({} as IPosition);
                if (values === 'serverError' && editablePos?.id) {
                  updatedPos.price = 0;
                  this.calcPriceError$.next([...this.calcPriceError$.value, editablePos?.id]);
                } else if (typeof values === 'object' && editablePos?.id) {
                  updatedPos = { ...updatedPos, ...values } as IPosition;
                  this.calcPriceError$.next(
                    this.calcPriceError$.value.filter((item) => item !== editablePos?.id),
                  );
                }
                updatedPos.total = this.calculateTotalDependsOnSaleUnit(updatedPos);
                state.offerData.positions = state.offerData.positions?.map((el) =>
                  el?.id === updatedPos?.id ? updatedPos : el,
                );
                this.editedCells.add(`${editablePos?.id}_${action}`);
                this.editedCells.delete(`${editablePos?.id}_priceCell`);
                this.editedCells.delete(`${editablePos?.id}_discountCell`);
                this.editedCells.delete(`${editablePos?.id}_vatCell`);
                return state;
              }),
            );
          }),
        );
      }),
    );
  }
  private quantityCell(): Observable<IOfferDetailsState> {
    return this.actionListener('quantityCell').pipe(
      mergeMap(({ selectedPos, action, customerInfo }) => {
        this.preUpdateCell(selectedPos);
        const isNotEditableDiscount =
          customerInfo?.discountKindId === C_Discount_Kind.DK5_QUANTITY_DISCOUNT ||
          customerInfo?.discountKindId === C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS;
        const isNeededRequest = Boolean(
          selectedPos?.articleNo &&
            !(
              this.editedCells.has(`${selectedPos?.id}_priceCell`) &&
              (isNotEditableDiscount || this.editedCells.has(`${selectedPos?.id}_discountCell`))
            ),
        );
        return zip(of(selectedPos), customerListState.sub.sharedValues()).pipe(
          tap(([editablePos, { customer, date }]) => {
            if (isNeededRequest) {
              offerService.pub.productPriceAndDiscount({
                orderAndArticleProps: {
                  articleId: editablePos?.articleId!,
                  customerId: customer?.customerId!,
                  orderDate: date,
                  quantity: Number(editablePos?.quantity!),
                  vatCode: editablePos?.vatCode!,
                },
              });
              this.cellsLoading$.next([...this.cellsLoading$.value, `${editablePos?.id}_quantity`]);
            }
          }),
          switchMap(([editablePos]) => {
            if (isNeededRequest) {
              return offerService.sub.productPriceAndDiscount().pipe(
                responseHandler<ProductPriceAndDiscountRes | 'serverError'>({
                  errorReturnType: 'serverError',
                }),
                map((values) => {
                  const state = structuredClone(this.stream$.getValue());
                  const updatedPos = state.offerData.positions?.find(
                    (pos) => pos.id === editablePos?.id,
                  );
                  this.cellsLoading$.next(
                    this.cellsLoading$.value.filter(
                      (item) => item !== `${editablePos?.id}_quantity`,
                    ),
                  );
                  if (!updatedPos) return;
                  if (!this.editedCells.has(`${updatedPos?.id}_priceCell`)) {
                    if (values === 'serverError' && editablePos?.id) {
                      updatedPos.price = 0;
                      this.calcPriceError$.next([...this.calcPriceError$.value, editablePos?.id]);
                      return updatedPos;
                    } else if (typeof values === 'object' && editablePos?.id) {
                      updatedPos.price = values.price;
                      this.calcPriceError$.next(
                        this.calcPriceError$.value.filter((item) => item !== editablePos?.id),
                      );
                    }
                  }
                  if (
                    !this.editedCells.has(`${updatedPos?.id}_discountCell`) &&
                    typeof values === 'object'
                  ) {
                    updatedPos.discount = values.discount;
                  }
                  return updatedPos;
                }),
              );
            }
            return of(editablePos);
          }),
          filter((updatedPos) => !!updatedPos), // if position is deleted
          map((updatedPos) => {
            const state = structuredClone(this.stream$.getValue());
            updatedPos!.total = this.calculateTotalDependsOnSaleUnit(updatedPos!);
            state.offerData.positions = state.offerData.positions?.map((el) =>
              el?.id === updatedPos?.id ? updatedPos : el,
            );
            this.editedCells.add(`${updatedPos?.id}_${action}`);
            return state;
          }),
        );
      }),
    );
  }
  private vatCell(): Observable<IOfferDetailsState> {
    return this.actionListener('vatCodeCell').pipe(
      mergeMap(({ selectedPos, action, customerInfo }) => {
        this.preUpdateCell(selectedPos);
        const isNotEditableDiscount =
          customerInfo?.discountKindId === C_Discount_Kind.DK5_QUANTITY_DISCOUNT ||
          customerInfo?.discountKindId === C_Discount_Kind.DK6_QUANTITY_DISCOUNT_GROUPS;
        const isNeededRequest = Boolean(
          selectedPos?.articleNo &&
            !(
              this.editedCells.has(`${selectedPos?.id}_priceCell`) &&
              (isNotEditableDiscount || this.editedCells.has(`${selectedPos?.id}_discountCell`))
            ),
        );
        return zip(of(selectedPos), customerListState.sub.sharedValues()).pipe(
          tap(([editablePos, { customer, date }]) => {
            if (isNeededRequest) {
              offerService.pub.productPriceAndDiscount({
                orderAndArticleProps: {
                  articleId: editablePos?.articleId!,
                  customerId: customer?.customerId!,
                  orderDate: date,
                  quantity: Number(editablePos?.quantity!),
                  vatCode: editablePos?.vatCode!,
                },
              });
              this.cellsLoading$.next([...this.cellsLoading$.value, `${editablePos?.id}_vatCode`]);
            }
          }),
          switchMap(([editablePos]) => {
            if (isNeededRequest) {
              return offerService.sub.productPriceAndDiscount().pipe(
                responseHandler<ProductPriceAndDiscountRes | 'serverError'>({
                  errorReturnType: 'serverError',
                }),
                map((values) => {
                  const state = structuredClone(this.stream$.getValue());
                  const updatedPos = state.offerData.positions?.find(
                    (pos) => pos.id === editablePos?.id,
                  )!;
                  this.cellsLoading$.next(
                    this.cellsLoading$.value.filter(
                      (item) => item !== `${editablePos?.id}_vatCode`,
                    ),
                  );
                  if (!updatedPos) return;
                  if (!this.editedCells.has(`${updatedPos?.id}_priceCell`)) {
                    if (values === 'serverError' && editablePos?.id) {
                      updatedPos.price = 0;
                      updatedPos.total = 0;
                      this.calcPriceError$.next([...this.calcPriceError$.value, editablePos?.id]);
                      return updatedPos;
                    } else if (typeof values === 'object' && editablePos?.id) {
                      updatedPos.price = values.price;
                      updatedPos.total = this.calculateTotalDependsOnSaleUnit(updatedPos);
                      this.calcPriceError$.next(
                        this.calcPriceError$.value.filter((item) => item !== editablePos?.id),
                      );
                    }
                  }
                  if (
                    !this.editedCells.has(`${updatedPos?.id}_discountCell`) &&
                    typeof values === 'object'
                  ) {
                    updatedPos.discount = values.discount;
                  }
                  return updatedPos;
                }),
              );
            }
            return of(editablePos);
          }),
          filter((updatedPos) => !!updatedPos), // if position is deleted
          map((updatedPos) => {
            const state = structuredClone(this.stream$.getValue());
            state.offerData.positions = state.offerData.positions?.map((el) =>
              el?.id === updatedPos?.id ? updatedPos : el,
            );
            this.editedCells.add(`${updatedPos?.id}_${action}`);
            return state;
          }),
        );
      }),
    );
  }
  /* OFFER CELL METHODS end */
  /* UTILITY start */
  async runSaveDraftOffer(params?: IRunSaveDraftOfferArgs): Promise<void> {
    const { id } = params || {};
    let { customerId, draftOfferId, offerForDate } = params || {};
    const { customer, date: listFilterDate } = await offerService.globHelpers.streamToPromise(
      customerListState.sub.sharedValues(),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
    );
    if (!params) {
      customerId = customer?.customerId;
      draftOfferId = customer?.draftOfferId || undefined;
      offerForDate = listFilterDate;
    }
    const { dirty, offerData } = this.stream$.getValue();
    const { positions = [], linkText, note } = offerData;
    const saveCondition = dirty && customerId && id;
    if (saveCondition) {
      const formattedPositions = this.formatPositionsToMutationArgument(positions, true);
      offerService.pub.createDraftOffer({
        draftOfferProps: {
          customerId: customerId as string,
          ...(draftOfferId !== '0' && draftOfferId && { draftOfferId }),
          linkText,
          note,
          offerForDate,
          positions: formattedPositions,
        },
      });
      notificationCenter.notify(offerDetailsState.sub.draftOfferSaved({ id }), {
        type: undefined,
        handler: (stream) => {
          stream.pipe(take(1)).subscribe(() => {
            console.log('Draft offer saved');
          });
        },
      });
    }
  }

  private defaultRound(v: number): number {
    const precision = companyConfigService.getCachedConfigs()?.decimalPlacesCount || 2;
    const multiplier = Math.pow(10, precision);
    return Math.round(v * multiplier) / multiplier;
  }

  formatPositionsToMutationArgument(
    positions: TPositions,
    withEmpty = false,
  ): Wa_OfferPositionInputs[] {
    const pos: TPositions = withEmpty ? positions : positions.filter(({ quantity }) => quantity);

    return pos.map((position) => {
      const isHeaderPosition = position.virtualPositionId === C_Virtual_Position.VP3_GROUP_HEADER;
      const {
        articleId,
        quantity,
        price,
        total,
        discount,
        vatCode,
        virtualPositionId,
        indTextDeliveryNote,
        indTextProduction,
        description,
      } = position;

      return {
        quantity,
        articleId,
        price,
        total,
        discount,
        vatCode,
        virtualPositionId,
        indTextDeliveryNote: isHeaderPosition ? description : indTextDeliveryNote,
        indTextProduction,
      };
    });
  }

  private calculateTotalDependsOnSaleUnit({
    price,
    quantity,
    saleUnit,
  }: {
    price?: number | null;
    quantity?: number | null;
    saleUnit?: C_Sale_Unit | null;
  }): number {
    price = price ? price : 0;
    quantity = quantity ? quantity : 0;
    const kiloAmountRepresentationId =
      companyConfigService.getCachedConfigs()?.kiloAmountRepresentationId;
    if (saleUnit === C_Sale_Unit.SU2_100G) {
      return this.defaultRound((quantity / 100) * price);
    }
    if (
      saleUnit === C_Sale_Unit.SU3_1000G &&
      kiloAmountRepresentationId === C_Kg_Representation.KGR2_1000_IS_1
    ) {
      return this.defaultRound((quantity / 1000) * price);
    }
    return this.defaultRound(quantity * price);
  }

  private preUpdateCell(position: IOfferDetailsState['selectedPos']) {
    const state = this.stream$.getValue();
    if (position && state.offerData.positions) {
      state.offerData.positions = state.offerData.positions?.map((el) =>
        el.id === position.id ? position : el,
      );
      this.stream$.next({ ...state, action: 'updateState' });
    }
  }

  private addVirtualPosition(args: {
    state: IOfferDetailsState;
    virtualPositionId: C_Virtual_Position.VP1_PAGE_BREAK | C_Virtual_Position.VP3_GROUP_HEADER;
  }): Observable<IOfferDetailsState> {
    const updatedState = structuredClone(args.state);
    const { virtualPositionId } = args;

    const newVirtualPosition = {
      virtualPositionId,
      quantity: 1,
      vatCode: C_Vat_Code.VT5_ZERO,
      description:
        virtualPositionId === C_Virtual_Position.VP1_PAGE_BREAK ? i18n.t('offer.page_break') : null,
      price: 0,
      total: 0,
      weight: 0,
      id: Date.now(),
      __reorder__: i18n.t('offer.new_position'),
    } as IPosition;

    const insertIndex = updatedState.selectedPos
      ? (updatedState.offerData.positions || [])?.findIndex(
          (pos) => pos.id === updatedState.selectedPos?.id,
        ) + (virtualPositionId === C_Virtual_Position.VP1_PAGE_BREAK ? 1 : 0)
      : updatedState.offerData.positions?.length || 0;

    updatedState.offerData.positions?.splice(insertIndex, 0, newVirtualPosition);
    updatedState.selectedPos = newVirtualPosition;

    return of(updatedState);
  }

  /* UTILITY end */
}

interface IRunSaveDraftOfferArgs {
  id: string;
  customerId: string;
  draftOfferId?: string | null;
  offerForDate: CreateWa_DraftOfferMutationVariables['draftOfferProps']['offerForDate'];
}

type TPositions = NonNullable<IOfferDetailsState['offerData']['positions']>;

type ISharedValues = {
  offerData: IOfferDetailsState['offerData'];
};
