import { LoaderFunction } from 'react-router-dom';
import {
  productionStatisticService,
  GetProductionStatisticDataRes,
} from '../services/productionStatistic.service.ts';
import { zipWith, take, map } from 'rxjs';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { getFirstAndLastDateOfCurrentMonth } from '../../../shared/helpers/utils/utils.helper.ts';
import { storageHelper } from '../../../shared/helpers/storage';
import { ISelectOption } from '../../../shared/components/form/fields/select.component.tsx';
import {
  IItem as IFilterDictItem,
  IItem,
} from '../../../shared/components/filterTableWithModal/filterTableWithModal.component.tsx';
import {
  createOrderService,
  DictOneTimeCustomersQueryRes,
} from '../../order/services/createOrder.service.ts';
import { customerService, ListCustomersRes } from '../../customer/services/customer.service.ts';
import { articleService, ArticlesListRes } from '../../article/services/article.service.ts';

export const initProductionStatisticLoader: IProductionStatisticLoader = {
  gridData: [],
  summaryData: {},
  chartsDataset: {
    keys: [],
    values: [],
  },
  datePeriod: {
    ...getFirstAndLastDateOfCurrentMonth(),
  },
  profileId: undefined,
  profileOptions: [],
  filter: {
    customerList: [],
    articleList: [],
  },
  gridGrouping: [],
  filterDictionaries: {
    oneTimeCustomers: [],
    customers: [],
    articles: [],
  },
};

export const productionStatisticLoader: LoaderFunction =
  async (): Promise<IProductionStatisticLoader> => {
    const initValues = structuredClone(initProductionStatisticLoader);
    const sessionDatePeriod = storageHelper.session.getItem('productionStatistic.datePeriod');
    initValues.profileOptions = storageHelper.local.getItem('productionStatistic.profiles') || [];
    initValues.profileId =
      storageHelper.local.getItem('productionStatistic.selectedProfileId') || undefined;
    if (sessionDatePeriod) {
      initValues.datePeriod = sessionDatePeriod;
    }
    if (initValues.profileId) {
      const sessionProfile = initValues.profileOptions.find((el) => el.id === initValues.profileId);
      if (sessionProfile) {
        initValues.filter = sessionProfile.settings.filter;
        initValues.gridGrouping = sessionProfile.settings.gridGrouping || [];
      }
    }
    const filterDictionaries = await productionStatisticService.globHelpers.streamToPromise(
      createOrderService.sub.oneTimeCustomerList().pipe(
        zipWith(customerService.sub.listGridCustomers(), articleService.sub.articleList()),
        responseHandler<[DictOneTimeCustomersQueryRes, ListCustomersRes, ArticlesListRes]>({
          errorReturnType: [[], [], []],
        }),
        take(1),
        map(([oneTimeCustomersRaw, customersRaw, articlesRaw]) => {
          const oneTimeCustomers = oneTimeCustomersRaw.map(({ id, label, customerNo }) => ({
            id,
            number: customerNo,
            description: label ?? '',
          }));

          const customers = customersRaw.map(({ id, customerNo, internalOrFullName }) => ({
            id,
            number: customerNo,
            description: internalOrFullName ?? '',
          }));

          const articles = articlesRaw.map(({ id, articleNo, description }) => ({
            id,
            number: articleNo,
            description: description ?? '',
          }));
          return {
            filterDictionaries: {
              oneTimeCustomers,
              customers,
              articles,
            },
          };
        }),
      ),
      () => {
        createOrderService.pub.oneTimeCustomerList();
        customerService.pub.listGridCustomers({ filter: { isActive: true }, cache: false });
        articleService.pub.articlesList({ filter: { isActive: true }, cache: false });
      },
    );
    return { ...initValues, ...filterDictionaries };
  };

export interface IProductionStatisticLoader {
  gridData: GetProductionStatisticDataRes['gridData'];
  chartsDataset: GetProductionStatisticDataRes['chartsDataset'];
  summaryData: GetProductionStatisticDataRes['summaryData'];
  datePeriod: {
    fromDate: string;
    toDate: string;
  };
  profileOptions: Array<IProdExtendedOptions>;
  profileId: string | undefined;
  filter: {
    customerList: IItem[];
    articleList: IItem[];
  };
  gridGrouping?: Array<string>;
  filterDictionaries: {
    oneTimeCustomers: IFilterDictItem[];
    customers: IFilterDictItem[];
    articles: IFilterDictItem[];
  };
}

export interface IProdExtendedOptions extends ISelectOption {
  settings: Pick<IProductionStatisticLoader, 'filter' | 'gridGrouping'>;
}
