import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { debounceTime, filter, map, Subscription } from 'rxjs';

import { Content } from '../../../../../shared/components/content/content.component';
import { CustomerInfo } from '../../../common/customerInfo/customerInfo.component';
import { SideControls } from './sideControls/sideControls.component';
import { OrderGrid } from './orderGrid/orderGrid.component';
import {
  IOrderDetailsState,
  orderDetailsState,
} from '../../states/orderDetails/orderDetails.state.ts';
import { IEditOrderLoader } from '../../../loaders/editOrder.loader';
import {
  formatDeliveryTime,
  getDeliveryTime,
} from '../../../../../shared/helpers/utils/utils.helper';
import { ArticlesForOrderPositionListRes } from '../../../../../shared/services/configsData/configsData.service';
import { TaxesByDateRes } from '../../../services/order.service';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { IInfoBlockCommon } from '../../../../../shared/components/infoBlock/infoBlock.components.tsx';
import { localeFormatterHelper } from '../../../../../shared/helpers/formatter/localeFormatter.helper.ts';
import { C_Email_Msg_Doctype } from '../../../../../graphql/generatedModel.ts';
import { orderListState } from '../../states/orderList.state.ts';
import { CreatedOrdersListRes } from '../../../services/editOrder.service.ts';

// eslint-disable-next-line react-refresh/only-export-components
export let additionOptionsSubEdit: Subscription;

export const OrderDetails: FC<IOrderDetails> = ({
  initData: {
    initState,
    linkTextOptions,
    customerList,
    additionalOptions,
    rowReordering,
    deliveryFunctionActive,
  },
  initOrderId,
}) => {
  const { t } = useTranslation();
  const { transportSector, ...customerInfo } = initState.customerInfo;
  const [orderDetails, setOrderDetails] = useState<TOrderDetails>({
    customerInfo,
    orderData: initState?.orderData || {},
    noData: initState.noData,
    selectedPos: initState.selectedPos,
    dirty: false,
  });
  const [orderId, setOrderId] = useState(initOrderId);
  const isEditable = orderDetails?.orderData?.isEditable;
  const [isPriceRateFixed, setIsPriceRateFixed] = useState(!!transportSector?.isPriceRateFixed);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderGridOptions, setOrderGridOptions] = useState<IOrderGridOptions>({
    productList: additionalOptions.productData?.productList,
    taxes: additionalOptions.taxes,
  });

  const deliveryTime = useMemo(() => {
    return getDeliveryTime(orderDetails.orderData?.deliveryTime);
  }, [orderDetails.orderData?.deliveryTime]);
  const caDeliveryTime = useMemo(() => {
    const time = orderDetails.orderData?.caInfo?.deliveryTime;
    return time ?? getDeliveryTime(time);
  }, [orderDetails.orderData?.caInfo?.deliveryTime]);
  const date = orderDetails.orderData?.orderDate;

  const formMethods = useForm({
    values: {
      orderDate: date ? new Date(date) : null,
      customerId: orderDetails.orderData?.customerId,
      caCashRegisterNo: orderDetails.orderData?.caInfo?.cashRegisterNo,
      caReceiptNo: orderDetails.orderData?.caInfo?.receiptNo,
      caDeliveryTime,
      caDeliveryMethod: orderDetails.orderData?.caInfo?.deliveryMethod,
      caUserName: orderDetails.orderData?.caInfo?.userName,
      note: orderDetails.orderData.note,
      note2: orderDetails.orderData.note2,
      deliveryTime,
      referenceOrderNo: orderDetails.orderData?.referenceOrderNo,
      specialAddress:
        orderDetails.orderData?.specialAddress || orderDetails.customerInfo?.deliveryAddress,
      linkText: orderDetails.orderData.linkText,
    },
  });

  useEffect(() => {
    orderDetailsState.pub.init(initState);
    orderDetailsState.pub.initOptions(additionalOptions);
    const orderListStateSub = orderListState.sub
      .state()
      .pipe(
        filter(({ action }) => action !== 'init'),
        map((data) => data.selectedOrder?.orderId),
      )
      .subscribe(setOrderId);
    const subOrderDetailsState = orderDetailsState.sub
      .state()
      .pipe(filter(({ action }) => action !== 'init'))
      .subscribe(
        ({
          customerInfo: { transportSector, ...customerInfoRest },
          orderData,
          noData,
          selectedPos,
          dirty,
        }) => {
          setOrderDetails({
            customerInfo: customerInfoRest,
            orderData,
            noData,
            selectedPos,
            dirty,
          });
          setIsPriceRateFixed(!!transportSector?.isPriceRateFixed);
        },
      );

    const subAdditionalOptions = orderDetailsState.sub.additionalOptions().subscribe((value) => {
      const {
        productData: { productList },
        taxes,
      } = value;
      setOrderGridOptions({ productList, taxes });
    });
    additionOptionsSubEdit = subAdditionalOptions;
    const unsubLoading = orderDetailsState.sub
      .loading()
      .pipe(debounceTime(0.5))
      .subscribe(setLoading);

    return () => {
      subOrderDetailsState.unsubscribe();
      subAdditionalOptions.unsubscribe();
      unsubLoading.unsubscribe();
      orderListStateSub.unsubscribe();
      window.removeEventListener('beforeunload', pageLeaveHandle.current);
    };
  }, []);

  const pageLeaveHandle = useRef((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  });
  /* Show default browser alert window before leave or reload page if there are unsaved changes */
  useEffect(() => {
    if (orderDetails.dirty) {
      window.addEventListener('beforeunload', pageLeaveHandle.current);
    } else {
      window.removeEventListener('beforeunload', pageLeaveHandle.current);
    }
  }, [orderDetails.dirty]);

  useEffect(() => {
    const formStateSub = formMethods.watch(
      ({
        caCashRegisterNo,
        caReceiptNo,
        caDeliveryTime,
        caDeliveryMethod,
        caUserName,
        orderDate,
        deliveryTime,
        ...fields
      }) => {
        orderDetailsState.pub.updateOrderData({
          ...orderDetails.orderData,
          ...fields,
          deliveryTime: formatDeliveryTime(deliveryTime),
          orderDate: orderDate instanceof Date ? format(orderDate, 'yyyy-MM-dd') : orderDate,
          caInfo: {
            cashRegisterNo: caCashRegisterNo,
            receiptNo: caReceiptNo,
            deliveryTime: caDeliveryTime,
            deliveryMethod: caDeliveryMethod,
            userName: caUserName,
          },
        } as IOrderDetailsState['orderData']);
      },
    );

    return () => formStateSub.unsubscribe();
  }, [formMethods.watch, orderDetails.orderData]);

  const emailTip: IInfoBlockCommon['emailTip'] = useMemo(() => {
    const datetimeOfLastEmail = orderDetails.orderData.datetimeOfLastEmail;
    if (!!datetimeOfLastEmail && orderId) {
      const date = localeFormatterHelper.formatDate(
        format(new Date(datetimeOfLastEmail), 'yyyy-MM-dd'),
      );
      const time = localeFormatterHelper.formatDate(
        new Date(datetimeOfLastEmail),
        {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        },
        true,
      );
      return {
        message: t('order.datetime_of_last_email_message', { date, time }),
        params: {
          id: orderId,
          docType: C_Email_Msg_Doctype.EML_ORDER,
        },
      };
    }
  }, [orderId, orderDetails.orderData.datetimeOfLastEmail, t]);

  return (
    <FormProvider {...formMethods}>
      <Content
        type='paper'
        emptyData={{ value: orderDetails.noData }}
        paperProps={{
          variant: 'outlined',
          sx: { flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' },
        }}
        loading={loading}
      >
        <CustomerInfo
          orderType={orderDetails?.orderData?.orderTypeDescription}
          readOnlyAddress={!isEditable}
          {...orderDetails.customerInfo}
          {...{ emailTip }}
        />
        <Box sx={{ p: 2, flexGrow: 1 }}>
          <SideControls
            errorText={
              !deliveryTime && typeof orderDetails.orderData?.deliveryTime === 'string'
                ? orderDetails.orderData?.deliveryTime
                : undefined
            }
            linkTextOptions={linkTextOptions}
            customerList={customerList}
            orderType={orderDetails.orderData.orderType}
            isEditable={isEditable}
          />
          <OrderGrid
            data={structuredClone(orderDetails)}
            {...{
              orderGridOptions,
              rowReordering,
              isEditable,
              deliveryFunctionActive,
              isPriceRateFixed,
            }}
          />
        </Box>
      </Content>
    </FormProvider>
  );
};

export type TOrderDetails = Pick<
  IOrderDetailsState,
  'customerInfo' | 'orderData' | 'noData' | 'selectedPos' | 'dirty'
>;

export interface IOrderGridOptions {
  productList: ArticlesForOrderPositionListRes;
  taxes: TaxesByDateRes;
}

interface IOrderDetails {
  initData: IEditOrderLoader['initOrderDetailsData'];
  initOrderId?: CreatedOrdersListRes[number]['orderId'];
}
