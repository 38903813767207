import { FC, useEffect, useMemo, useState } from 'react';
import { Content as Wrapper } from '../../../../../../../../../shared/components/content/content.component.tsx';
import { FormProvider, useForm } from 'react-hook-form';
import { FormTextEditor, FormTextField } from '../../../../../../../../../shared/components/form';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fontHelper } from '../../../../../../../../../shared/helpers/font/font.helper.ts';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import {
  dictionaryAdditionalService,
  GetDictDebtReminderRes,
} from '../../../../../../../../../shared/components/dictionary/services/dictionaryAdditional.service.ts';
import { responseHandler } from '../../../../../../../../../shared/responseHandler/responseHandler.ts';
import { take } from 'rxjs';
import { dictionaryState } from '../../../../../../../../../shared/components/dictionary/states/dictionary.state.ts';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export const Content: FC<IContent> = ({ stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState<string>('');
  const defaultFontSize = useMemo(() => fontHelper.getDefaultFont(['fontSize']), []);

  useEffect(() => {
    const dictionaryAdditionalSub = dictionaryAdditionalService.sub
      .getDictDebtReminder()
      .pipe(
        responseHandler<GetDictDebtReminderRes>({
          errorReturnType: {},
        }),
      )
      .subscribe((formData) => {
        formMethods.reset(formData);
        setLoading(false);
      });

    stream
      .actionListener('open')
      .pipe(take(1))
      .subscribe(({ id }) => {
        dictionaryAdditionalService.pub.getDictDebtReminder({ id });
        setId(id);
      });
    return () => dictionaryAdditionalSub.unsubscribe();
  }, []);

  const formMethods = useForm<GetDictDebtReminderRes>({
    mode: 'onSubmit',
    defaultValues: {
      reminderText: '',
      description: '',
    },
  });

  const { dirtyFields, isDirty } = formMethods.formState;

  const onSubmit = (formData: GetDictDebtReminderRes) => {
    if (Object.keys(dirtyFields).length) {
      dictionaryState.pub.updateCell(
        {
          id,
          field: 'virtualField',
          value: formData,
        },
        true,
      );
    }
    stream.emit('close');
  };

  useBeforeClosePopup({
    stream,
    isDirty,
  });

  return (
    <Wrapper type='box' {...{ loading }} boxProps={{ sx: { minHeight: '298px' } }}>
      <FormProvider {...formMethods}>
        <form
          style={{ opacity: loading ? '0' : '1', transition: '0.3s' }}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          noValidate
        >
          {!loading && (
            <>
              <FormTextField
                inputProps={{ maxLength: 40 }}
                name='description'
                label={t('settings.report_header')}
                sx={{ mb: 3 }}
              />
              <FormTextEditor name='reminderText' defaultFormat={defaultFontSize} />
              <Stack mt={3} direction='row' justifyContent='flex-end' spacing={2}>
                <Button type='submit' variant='contained'>
                  {t('common.save')}
                </Button>
              </Stack>
            </>
          )}
        </form>
      </FormProvider>
    </Wrapper>
  );
};

interface IContent {
  stream: IPopupWrapper['stream'];
}
