import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'statistic'
  | 'order_statistic'
  | 'charts'
  | 'show_one_time_customers'
  | 'product_group'
  | 'marketing_group'
  | 'group_by'
  | 'order_type'
  | 'chart_type'
  | 'production_group'
  | 'production_statistic'
  | 'revenue'
  | 'hide_quantity_by_order_types'
  | 'show_quantity_by_order_types'
  | 'articles_sold'
  | 'top_article'
  | 'best_customer'
  | 'calculate'
  | 'profile'
  | 'add_and_customize'
  | 'profile_name'
  | 'gross_revenue'
  | 'produced_articles'
  | 'total_price';

const t = LocalizationHelper<Keys>('statistic');

export const statisticDe = t({
  statistic: 'Statistik',
  order_statistic: 'Bestellstatistik',
  product_group: 'Warengruppe',
  marketing_group: 'Marketing-Gruppe',
  production_group: 'Produktionsgruppe',
  production_statistic: 'Produktionsstatistik',
  group_by: 'Gruppiert nach',
  order_type: 'Bestelltyp',
  show_one_time_customers: 'Einmalkunden anzeigen',
  chart_type: 'Diagrammtyp',
  charts: 'Diagramme',
  revenue: 'Umsatz',
  hide_quantity_by_order_types: 'Menge nach Bestelltypen ausblenden',
  show_quantity_by_order_types: 'Menge nach Bestelltypen anzeigen',
  articles_sold: 'Verkaufte Artikel',
  top_article: 'Top-Artikel',
  best_customer: 'Bester Kunde',
  calculate: 'Berechnen',
  profile: 'Profil',
  add_and_customize: 'Hinzufügen & Anpassen',
  profile_name: 'Profilname',
  gross_revenue: 'Bruttoumsatz',
  produced_articles: 'Artikel hergestellt',
  total_price: 'Gesamtpreis',
});

export const statisticEn = t({
  statistic: 'Statistic',
  order_statistic: 'Order statistic',
  product_group: 'Product group',
  marketing_group: 'Marketing group',
  show_one_time_customers: 'Show one-time customers',
  production_group: 'Production group',
  production_statistic: 'Production statistic',
  group_by: 'Group by',
  order_type: 'Order type',
  chart_type: 'Chart type',
  charts: 'Charts',
  revenue: 'Revenue',
  hide_quantity_by_order_types: 'Hide quantity by order types',
  show_quantity_by_order_types: 'Show quantity by order types',
  articles_sold: 'Articles sold',
  top_article: 'Top article',
  best_customer: 'Best customer',
  calculate: 'Calculate',
  profile: 'Profile',
  add_and_customize: 'Add & Customize',
  profile_name: 'Profile name',
  gross_revenue: 'Gross revenue',
  produced_articles: 'Produced articles',
  total_price: 'Total price',
});

export const statisticFr = t({
  statistic: 'Statistique',
  order_statistic: 'Statistique des commandes',
  product_group: 'Groupe de produits',
  show_one_time_customers: 'Afficher les clients uniques',
  marketing_group: 'Groupe marketing',
  production_group: 'Groupe de production',
  production_statistic: 'Statistique de production',
  group_by: 'Grouper par',
  order_type: 'Type de commande',
  chart_type: 'Type de graphique',
  charts: 'Graphiques',
  hide_quantity_by_order_types: 'Masquer la quantité par types de commandes',
  show_quantity_by_order_types: 'Afficher la quantité par types de commandes',
  revenue: "Chiffre d'affaires",
  articles_sold: 'Articles vendus',
  top_article: 'Article phare',
  best_customer: 'Meilleur client',
  calculate: 'Calculer',
  profile: 'Profil',
  add_and_customize: 'Ajouter & Personnaliser',
  profile_name: 'Nom du profil',
  gross_revenue: 'Revenu brut',
  produced_articles: 'Articles produits',
  total_price: 'Prix total',
});

export const statisticIt = t({
  statistic: 'Statistica',
  order_statistic: 'Statistica degli ordini',
  product_group: 'Gruppo di prodotti',
  marketing_group: 'Gruppo marketing',
  production_group: 'Gruppo di produzione',
  show_one_time_customers: 'Mostra clienti una tantum',
  production_statistic: 'Statistica di produzione',
  group_by: 'Raggruppa per',
  order_type: 'Tipo di ordine',
  chart_type: 'Tipo di grafico',
  charts: 'Grafici',
  revenue: 'Fatturato',
  hide_quantity_by_order_types: 'Nascondi la quantità per tipi di ordini',
  show_quantity_by_order_types: 'Mostra la quantità per tipi di ordini',
  articles_sold: 'Articoli venduti',
  top_article: 'Articolo di punta',
  best_customer: 'Miglior cliente',
  calculate: 'Calcolare',
  profile: 'Profilo',
  add_and_customize: 'Aggiungi & Personalizza',
  profile_name: 'Nome profilo',
  gross_revenue: 'Ricavo lordo',
  produced_articles: 'Articoli prodotti',
  total_price: 'Prezzo totale',
});
