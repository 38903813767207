import { FC, useEffect, useState } from 'react';
import { Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FormSelect } from '../../../../../../../../../shared/components/form';
import { configsData } from '../../../../../../../../../shared/services/configsData/configsData.service.ts';
import { Content } from '../../../../../../../../../shared/components/content/content.component.tsx';
import { ISelectOption } from '../../../../../../../../../shared/components/form/fields/select.component.tsx';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { defaultOrderState } from '../../../../defaultOrder.state.ts';

export const CopyDefaultOrder: FC<ICopyDefaultOrder> = ({ currentCustomerId, stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<ExtendedSelectOption[]>([]);
  const { watch, handleSubmit } = useFormContext<{ customerId: string }>();

  useEffect(() => {
    const dictCustomersSub = configsData.sub.dictCustomers().subscribe((customers) => {
      setCustomers(
        customers.reduce<ExtendedSelectOption[]>((acc, item) => {
          if (item.id !== currentCustomerId) {
            acc.push({
              ...item,
              label: item?.internalOrFullName ?? '',
              customerNo: item?.customerNo ?? '',
            });
          }
          return acc;
        }, []),
      );
      setLoading(false);
    });
    configsData.pub.common(['dictCustomers']);
    return () => dictCustomersSub.unsubscribe();
  }, []);

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        ((option?.customerNo ? `${option?.customerNo} ` : '') + (option?.label || ''))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) as boolean,
    );

  useBeforeClosePopup({
    stream,
    isDirty: Boolean(watch('customerId')),
  });

  const onSubmit = ({ customerId }: { customerId: string }) => {
    defaultOrderState.pub.copyPositions(customerId);
    stream.emit('close');
  };

  return (
    <Content type='box' lsx={{ bottom: -5, right: -5, top: -5, left: -5 }} {...{ loading }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormSelect
          label={t('customer.customers')}
          width='100%'
          name='customerId'
          options={customers as []}
          datasetattribute='customerOptions'
          getOptionLabel={(params) => {
            const { customerNo, label } = params as ExtendedSelectOption;
            return `${customerNo} ${label}`;
          }}
          {...{ filterOptions }}
        />
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button disabled={!watch('customerId')} variant='contained' type='submit'>
            {t('article.copy')}
          </Button>
        </Stack>
      </form>
    </Content>
  );
};

interface ICopyDefaultOrder {
  currentCustomerId: string;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  customerNo?: string;
}
