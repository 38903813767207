import { Observable, filter, map, ReplaySubject } from 'rxjs';

type Actions = 'off';
class Pub {
  constructor(private stream$: ReplaySubject<Actions>) {}
  off() {
    this.stream$.next('off');
  }
}
class Sub {
  constructor(private stream$: ReplaySubject<Actions>) {}
  off(): Observable<boolean> {
    return this.stream$.pipe(
      filter((action) => action === 'off'),
      map(() => false),
    );
  }
}
class SplashScreenService {
  private stream$ = new ReplaySubject<Actions>(1);
  pub = new Pub(this.stream$);
  sub = new Sub(this.stream$);
}
export const splashScreenService = new SplashScreenService();
