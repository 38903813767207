import { gql } from 'graphql-request';

export const initOrderStatisticCalc = gql`
  mutation WA_initOrderStatisticCalc($orderStatisticProps: WA_initOrderStatisticInputs!) {
    wawiAssist {
      WA_initOrderStatisticCalc(orderStatisticProps: $orderStatisticProps) {
        jobId
      }
    }
  }
`;

export const getStatisticData = gql`
  query getStatisticData($jobId: String!) {
    wawiAssist {
      getWA_statisticData(jobId: $jobId) {
        status
        data {
          ... on WA_OrderStatisticData {
            items {
              articleId
              totalQuantity
              articleNo
              articleDescription
              customerId
              customerNo
              customerName
              netSales
              netSalesByPeriods
              quantityByPeriods
              periodPartsCount
              periodPartsType
              date
              quantityByOrderTypes {
                orderType
                quantity
              }
              id
              discountGroup
              marketingGroup
              productionGroup
            }
            summaryData {
              topArticle
              topCustomer
              totalQuantity
              totalNetSales
            }
            presentedOrderTypes
            chartsDataset {
              values
              keys
            }
          }
        }
      }
    }
  }
`;
