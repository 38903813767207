import { AlternateEmail, Fax, Language, Phone } from '@mui/icons-material';
import { Box, Stack, styled } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { C_Country } from '../../../../../../graphql/generatedModel.ts';
import {
  FieldsRow,
  FieldsSection,
  FormSelect,
  FormTextField,
} from '../../../../../../shared/components/form/index.ts';
import { companyTabState } from '../../../states/company.state.ts';

export const CompanyForm: FC<ICompanyForm> = ({ formMethods }) => {
  const { t } = useTranslation();
  const countryOptions = useMemo(() => {
    return Object.keys(C_Country).map((item) => {
      return { id: item, label: t(`enums.${item}`) };
    });
  }, [C_Country, t]);
  const {
    watch,
    formState: { errors, dirtyFields },
  } = formMethods;

  const countryId = watch('countryId');
  // VAT number setting visible for European countries
  const vatNumberVisibility = countryId !== C_Country.CO4_MALDIVES;
  // Bank account settings visible for European countries except Switzerland (it has BESR banks settings)
  const bankVisibility =
    countryId !== C_Country.CO1_SWITZERLAND && countryId !== C_Country.CO4_MALDIVES;

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!errors[e.target.name] && !!dirtyFields[e.target.name]) {
      companyTabState.pub.saveData();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <SForm noValidate onBlur={onSubmit}>
        <FieldsSection
          titleBoxSx={{ pb: 2, pt: 0 }}
          nomt
          title={t('settings.company.companySection')}
          className='company'
        >
          <Stack spacing={3}>
            <FormTextField
              inputProps={{ maxLength: 50 }}
              width='100%'
              name='companySalutation'
              label={t('settings.company.company.label')}
            />
            <FormTextField
              inputProps={{ maxLength: 50 }}
              width='100%'
              name='companyName'
              label={t('settings.company.companyName.label')}
            />
            <FormTextField
              inputProps={{ maxLength: 50 }}
              width='100%'
              name='companyAddition'
              label={t('settings.company.addition.label')}
            />
            <FormTextField
              inputProps={{ maxLength: 50 }}
              width='100%'
              name='companyStreet'
              label={t('settings.company.street.label')}
            />
            <FieldsRow>
              <FormTextField
                inputProps={{ maxLength: 10 }}
                width='100%'
                name='companyZipCode'
                label={t('settings.company.zip.label')}
              />
              <FormTextField
                inputProps={{ maxLength: 50 }}
                width='100%'
                name='companyCity'
                label={t('settings.company.city.label')}
              />
            </FieldsRow>
          </Stack>
        </FieldsSection>
        <FieldsSection titleBoxSx={{ pb: 2, pt: 0 }} nomt title={' '} className='company2'>
          <Stack spacing={3}>
            <FormTextField
              inputProps={{ maxLength: 50 }}
              name='companyPhone'
              label={t('settings.company.phone.label')}
              width='100%'
              InputProps={{ endAdornment: <Phone sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />

            <FormTextField
              inputProps={{ maxLength: 50 }}
              name='companyFax'
              label={t('settings.company.fax.label')}
              width='100%'
              InputProps={{ endAdornment: <Fax sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />

            <FormTextField
              inputProps={{ maxLength: 50 }}
              name='companyEmail'
              label={t('common.email')}
              width='100%'
              InputProps={{
                endAdornment: <AlternateEmail sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
              }}
              error={Boolean(errors['companyEmail'])}
              helperText={errors['companyEmail'] ? t(String(errors['companyEmail'].message)) : ''}
            />

            <FormTextField
              inputProps={{ maxLength: 50 }}
              name='companyWebsite'
              label={t('settings.company.website.label')}
              width='100%'
              InputProps={{ endAdornment: <Language sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> }}
            />
            <FormTextField
              inputProps={{ maxLength: 13 }}
              width='100%'
              name='companyGLN'
              label={t('settings.company.gln.label')}
            />
            <Box sx={{ minHeight: '40px' }}>
              {vatNumberVisibility && (
                <FormTextField
                  inputProps={{ maxLength: 100 }}
                  width='100%'
                  name='companyVatNo'
                  label={t(
                    countryId === C_Country.CO1_SWITZERLAND
                      ? 'settings.company.vat.label'
                      : 'settings.company.uid.label',
                  )}
                />
              )}
            </Box>
          </Stack>
        </FieldsSection>

        <FieldsSection
          titleBoxSx={{ pb: 2, pt: 0 }}
          nomt
          title={t('settings.company.countrySection')}
          className='country'
        >
          <FormSelect
            name='countryId'
            width='100%'
            options={countryOptions}
            disableClearable
            disableTextInput
          />
        </FieldsSection>
        {bankVisibility && (
          <>
            <FieldsSection
              titleBoxSx={{ pb: 2, pt: 0 }}
              nomt
              title={t('settings.company.bankSection')}
              className='bank'
            >
              <Stack spacing={3}>
                <FormTextField
                  inputProps={{ maxLength: 32 }}
                  width='100%'
                  name='bankName'
                  label={t('settings.company.bankName.label')}
                />
                <FormTextField
                  inputProps={{ maxLength: 16 }}
                  width='100%'
                  name='bankBIN'
                  label={t('settings.company.bin.label')}
                />
                <FormTextField
                  inputProps={{ maxLength: 32 }}
                  width='100%'
                  name='bankAccountNo'
                  label={t('settings.company.accountNo.label')}
                />
              </Stack>
            </FieldsSection>
            <FieldsSection titleBoxSx={{ pb: 2, pt: 0 }} nomt title={''} className='bank2'>
              <Stack spacing={3}>
                <FormTextField
                  inputProps={{ maxLength: 32 }}
                  width='100%'
                  name='bankIBAN'
                  label={t('settings.company.iban.label')}
                />
                <FormTextField
                  inputProps={{ maxLength: 40 }}
                  width='100%'
                  name='creditorIdNumber'
                  label={t('settings.company.cin.label')}
                />
                <FormTextField
                  inputProps={{ maxLength: 32 }}
                  width='100%'
                  name='bankBIC'
                  label={t('settings.company.bic.label')}
                />
              </Stack>
            </FieldsSection>
          </>
        )}
      </SForm>
    </FormProvider>
  );
};
interface ICompanyForm {
  formMethods: UseFormReturn<any>;
}
const SForm = styled('form')({
  display: 'grid',
  gridTemplateColumns: 'minmax(300px, 360px) minmax(300px, 360px)',
  gridTemplateRows: 'repeat(2, auto)',
  gap: '16px 54px',
  '& .company': {
    gridArea: 'company',
  },
  '& .company2': {
    gridArea: 'company2',
    marginTop: '31px',
  },
  '& .country': {
    gridArea: 'country',
  },
  '& .bank': {
    gridArea: 'bank',
  },
  '& .bank2': {
    gridArea: 'bank2',
    marginTop: '31px',
  },

  '@media(min-width: 1365px)': {
    gridTemplateAreas: `"company company2"
    "country ..."
    "bank bank2"`,
  },
  '@media(max-width: 1365px)': {
    '& .company2': {
      marginTop: '0',
    },
    '& .bank2': {
      marginTop: '0',
    },
    gridTemplateAreas: `"company ..."
    "company2 ..."
    "country ..."
    "bank ..."
    "bank2 ..."`,
  },
});
