import { ITabState } from '../states/tabState.model.ts';
import { articleService, GetDefaultOrderTabDataResExtended } from '../services/article.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';
import { zipWith, take } from 'rxjs';
import {
  configsData,
  DictCustomersRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import { initDefaultOrderState } from '../components/tabs/defaultOrder/defaultOrder.state.ts';

export const defaultOrderDataByArticle: GetDefaultOrderTabDataResExtended = {
  id: '',
  weeklyDefaultOrderByArticle: [],
};

export async function resolveDefaultOrderTab(data: ITabState): Promise<ITabState> {
  if (data.articleId) {
    const [
      { weeklyDefaultOrderByArticle: defaultOrderGridData = [], id: articleId },
      customerList,
    ] = await articleService.globHelpers.streamToPromise(
      articleService.sub.defaultOrderTabData().pipe(
        zipWith(configsData.sub.dictCustomers()),
        responseHandler<DefaultOrderData>({
          errorReturnType: [defaultOrderDataByArticle, []],
        }),
        take(1),
      ),
      () => {
        articleService.pub.defaultOrderTabData({ id: data.articleId });
        configsData.pub.common(['dictCustomers']);
      },
    );

    data.defaultOrderTabData = {
      ...initDefaultOrderState,
      defaultOrderGridData,
      defaultOrderGridDataBackup: structuredClone(defaultOrderGridData),
      articleId,
    };
    data.defaultOrderTabOptions = {
      customerList: customerList,
    };
  }
  return data;
}

export type DefaultOrderData = [GetDefaultOrderTabDataResExtended, DictCustomersRes];
