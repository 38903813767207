/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { BehaviorSubject } from 'rxjs';
import { Pub, State } from '../../../../../shared/state/state.abstract.ts';
import { IFilteredProductDataRes, TaxesByDateRes } from '../../../services/order.service.ts';
import {
  CreatedOrdersListRes,
  CustomerInfoRes,
  IOrderDataRes,
  IPosition,
  TOrderData,
} from '../../../services/editOrder.service.ts';
import i18n from 'i18next';
import {
  SaveOrderMutationVariables,
  Wa_OrderModificationOptions,
} from '../../../../../graphql/generatedModel.ts';
import { Theme as theme } from '../../../../../shared/styles/theme/theme.ts';
import { orderListRow } from '../../components/orderList/orderList.row.ts';
import { GridRowClassNameParams } from '@mui/x-data-grid-premium';
import { IOrderGrid } from '../../components/orderDetails/orderGrid/orderGrid.component.tsx';
import { SubImpl } from './sub.state.ts';

export const initOrderDetailsState: IOrderDetailsState = {
  action: undefined,
  orderData: {},
  customerInfo: {},
  dirty: false,
  orderDataBackup: {},
  noData: true,
  selectedPos: null,
};
export const additionalOptions: IAdditionalOptions = {
  productData: {
    productList: [],
    listSpecialAvailabilityStateForArticles: undefined,
    listSpecialAssortmentGroups: undefined,
  },
  taxes: [],
};
export const additionalOptions$ = new BehaviorSubject<IAdditionalOptions>(additionalOptions);

class PubImpl extends Pub<IOrderDetailsState> {
  init(params: IOrderDetailsState) {
    this.emit('init', params);
  }
  initOptions(params: IAdditionalOptions) {
    additionalOptions$.next(params);
  }
  updateOrderData(orderData: IOrderDetailsState['orderData']) {
    this.emit('updateOrderData', { orderData });
  }
  selectPosition(selectedPos: IOrderDetailsState['selectedPos']) {
    this.emit('selectPosition', { selectedPos });
  }
  updatePosition(position?: IOrderDetailsState['selectedPos']) {
    this.emit('updatePosition', { selectedPos: position });
  }
  updateCell(selectedPos: IOrderDetailsState['selectedPos'], type: TypeCell) {
    this.emit(type, { selectedPos });
  }
  addPosition(args: AddPositionArgs) {
    this.emit(args.type, args.type === 'addPosition' ? { selectedPos: args.position } : {});
  }
  addPositionByBarcode(articleInfo: TArticleFromProductList, quantity: number, price: number) {
    this.emit('addPositionByBarcode', { params: { articleInfo, quantity, price } });
  }
  mergePositions() {
    this.emit('mergePositions', {});
  }
  deletePosition(args: DeletePositionArgs) {
    const { selectedPos, orderData } = this.stream$.getValue();
    if (args.type === 'deletePosition' && args.position?.id !== selectedPos?.id) {
      const positions = orderData.positions?.filter((el) => el?.id !== args.position?.id);
      this.emit('updatePositions', { orderData: { ...orderData, positions } });
    } else {
      this.emit(args.type, args.type === 'deletePosition' ? { selectedPos: args.position } : {});
    }
  }
  resetOrderDataChanges() {
    this.emit('resetOrderDataChanges', {});
  }
  saveOrder(args?: SaveOrderMutationVariables['orderProps']['modificationOptions']) {
    this.emit('saveOrder', { params: args });
  }
  updateLastEmailDetails(
    datetimeOfLastEmail: IOrderData['datetimeOfLastEmail'],
    dataRecordId: string,
  ) {
    this.emit('updateLastEmailDetails', { params: { datetimeOfLastEmail, dataRecordId } });
  }
}

class OrderDetailsState extends State<IOrderDetailsState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}
export const orderDetailsState = new OrderDetailsState(initOrderDetailsState);

export interface IOrderDetailsState {
  action:
    | 'init'
    | 'updateState'
    | 'queryData'
    | 'emptyData'
    | 'errorData'
    | 'internalUpdate'
    | 'saveOrder'
    | 'selectPosition'
    | 'addPositionByBarcode'
    | 'mergePositions'
    | 'updatePosition'
    | 'updatePositions'
    | 'updateOrderData'
    | 'resetOrderDataChanges'
    | 'updateLastEmailDetails'
    | 'loader'
    | TypeAddPosition
    | TypeDeletePosition
    | TypeCell
    | undefined;
  customerInfo: ICustomerInfo;
  orderData: IOrderData | Record<string, never>;
  dirty: boolean;
  orderDataBackup: IOrderData | Record<string, never>;
  selectedPos: IPosition | null;
  noData: boolean;
  params?: any;
}

export const getOrderTypeDescription = (
  selectedOrder: CreatedOrdersListRes[number],
): IOrderData['orderTypeDescription'] => {
  const caOrderType = selectedOrder?.caOrderType;
  const orderType = selectedOrder?.orderType;
  const key = orderListRow?.action({ row: selectedOrder } as GridRowClassNameParams<any>);
  const { bgColor } =
    orderListRow?.styles?.find?.(({ className }) => className === (key as string)) || {};
  return {
    description: caOrderType ? caOrderType : i18n.t(`enums.${orderType}`),
    color: bgColor || theme.palette.grey[300],
  };
};

// example: we have barcode 1234567895419. In return we will have 89541
export const getQuantityOrPriceFromBarcode = (barcode: string): number => {
  const digitsPartWithoutChecksum = barcode.slice(0, -1);
  const lastFiveDigits = digitsPartWithoutChecksum.slice(-5);
  const lastFiveDigitsNumber = Number(lastFiveDigits);
  const isNumber = !isNaN(lastFiveDigitsNumber);
  return isNumber ? lastFiveDigitsNumber : 0;
};

export interface IAdditionalOptions {
  productData: IFilteredProductDataRes;
  taxes: TaxesByDateRes;
}

export interface IOrderData extends Omit<IOrderDataRes, 'deliveryTime'> {
  deliveryTime?: TOrderData['deliveryTime'] | Date;
  orderTypeDescription?: IOrderTypeDescription;
}

export interface IOrderTypeDescription {
  description: string;
  color: string;
}

export interface IPrepareArgsToSave {
  state: IOrderDetailsState;
  orderId: string;
  modificationOptions?: Wa_OrderModificationOptions;
}

export interface ICustomerInfo extends Partial<Pick<CustomerInfoRes, TSeparateCustomerInfoFields>> {
  contacts?: Omit<CustomerInfoRes, TSeparateCustomerInfoFields>;
  transportSector?: CustomerInfoRes['transportSector'];
  transportSectorId?: CustomerInfoRes['transportSectorId'];
}

type TSeparateCustomerInfoFields =
  | 'customerNo'
  | 'internalOrFullName'
  | 'informationTip'
  | 'deliveryAddress'
  | 'discountKindId'
  | 'isDeliverySplitting'
  | 'deliverySplittingPartsCount'
  | 'id';
type TypeCell =
  | 'priceCell'
  | 'descriptionCell'
  | 'vatCodeCell'
  | 'discountCell'
  | 'quantityCell'
  | 'splittingCell';

export type TArticleFromProductList = IOrderGrid['orderGridOptions']['productList'][number];
export interface IMergePosition {
  mergedPositions: IPosition[];
  isChanged: boolean;
}

/* TYPES TO ADD POSITION start */
type TypeAddPosition = IAddPosition['type'] | IAddDeliveryPosition['type'];
interface IAddPosition {
  type: 'addPosition';
  position?: IOrderDetailsState['selectedPos'];
}
interface IAddDeliveryPosition {
  type: 'addDeliveryPosition';
  position?: never;
}
type AddPositionArgs = IAddPosition | IAddDeliveryPosition;
/* TYPES TO ADD POSITION end */
/* TYPES TO DELETE POSITION start */
type TypeDeletePosition = IDeletePosition['type'] | IDeleteDeliveryPosition['type'];
interface IDeletePosition {
  type: 'deletePosition';
  position: IOrderDetailsState['selectedPos'];
}
interface IDeleteDeliveryPosition {
  type: 'deleteDeliveryPosition';
  position?: never;
}
type DeletePositionArgs = IDeletePosition | IDeleteDeliveryPosition;
/* TYPES TO DELETE POSITION end */
