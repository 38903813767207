import { Pub, Sub, Service } from '../../../shared/services/service.abstract.ts';
import { Observable, switchMap, map, delay, repeat, takeWhile, filter, take } from 'rxjs';
import {
  InitProdStatisticCalcMutationVariables,
  GetProdStatisticDataQuery,
  InitProdStatisticCalcMutation,
  C_Statistic_Calc_Status,
} from '../../../graphql/generatedModel.ts';
import { gqlClient } from '../../../graphql/graphqlRequest.ts';
import { initProdStatisticCalc, getProdStatisticData } from './gql/productionStatistic.gql.ts';

type Actions = 'getProductionStatisticData' | undefined;

class PubImpl extends Pub<Actions> {
  getProductionStatisticData(params: InitProdStatisticCalcMutationVariables): void {
    this.emit('getProductionStatisticData', params);
  }
}

class SubImpl extends Sub<Actions> {
  getProductionStatisticData(): Observable<GetProductionStatisticDataRes> {
    return this.actionListener('getProductionStatisticData').pipe(
      switchMap(({ params }) => {
        return gqlClient(
          initProdStatisticCalc,
          params,
        ) as Observable<InitProdStatisticCalcMutation>;
      }),
      delay(1300),
      switchMap((res) => {
        const maxPollingAttempts = 50;
        const jobId = res?.wawiAssist?.initWA_ProdStatisticCalc.jobId;
        return (
          gqlClient(getProdStatisticData, { jobId }) as Observable<GetProdStatisticDataQuery>
        ).pipe(
          repeat({ delay: 1000 }),
          map((data) => {
            return data.wawiAssist?.getWA_statisticData;
          }),
          takeWhile((data) => {
            return data?.status === C_Statistic_Calc_Status.SCS_LOADING;
          }, true),
          filter((data, index) => {
            const isInProgress = data?.status === C_Statistic_Calc_Status.SCS_LOADING;
            const maxAttemptsReached = index + 1 > maxPollingAttempts;
            return !isInProgress || maxAttemptsReached;
          }),
          take(1),
        );
      }),
      map((res) => {
        if (!res?.data && res?.status === C_Statistic_Calc_Status.SCS_LOADING) {
          throw new Error();
        }
        return res?.data as GetProductionStatisticDataRes;
      }),
    );
  }
}

class ProductionStatisticService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const productionStatisticService = new ProductionStatisticService();

export type GetProductionStatisticDataRowRes = NonNullable<
  NonNullable<GetProdStatisticDataQuery['wawiAssist']>['getWA_statisticData']
>;
export type GetProductionStatisticDataRes = Extract<
  NonNullable<GetProductionStatisticDataRowRes['data']>,
  { __typename?: 'WA_ProductionStatisticData' }
>;
