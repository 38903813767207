import { Alert, AlertTitle, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorIcon from '@mui/icons-material/Error';
import { Table } from '../table/table.component';
import { FieldsRow, TextField } from '../form';
import { Column } from '../table/components/common.components';
import { DataForMultiEmailsRes } from '../../services/reports/reports.service';

export const PopupMarkup: FC<IPopupMarkupProps> = ({
  loading,
  onCancel,
  onConfirm,
  tableData,
  defaultEmailText,
}) => {
  const [emailText, setEmailText] = useState<string>(defaultEmailText || '');
  const { t } = useTranslation();

  const columns = [
    {
      field: 'customerNo',
      headerName: t('common.customer_no'),
    },
    {
      field: 'internalOrFullName',
      headerName: t('common.name'),
    },
    {
      field: 'email',
      headerName: t('common.email'),
    },
  ];

  return loading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Stack direction='column' spacing={2}>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <ErrorIcon color='primary' style={{ height: 60, width: 60 }} />
        <Typography width='481px' marginLeft='16px' style={{ opacity: 0.7 }}>
          {t('order.emails_with_attached_reports_message')}
        </Typography>
      </Stack>
      <Stack minHeight={120}>
        <Table
          replacedId='documentId'
          style={{ minHeight: 120 }}
          heightOffset={550}
          data={tableData as []}
          rowSelection={false}
        >
          {
            columns?.map((params, i) => (
              <Column key={i} sortable={false} resizable={false} flex={1} {...params} />
            )) as any
          }
        </Table>
      </Stack>

      <FieldsRow>
        <TextField
          multiline
          InputProps={{
            readOnly: true,
          }}
          rows={6}
          width='100%'
          label={t('common.message')}
          value={emailText}
          onChange={(v) => setEmailText(v.target.value)}
        />
      </FieldsRow>
      <Alert severity='info'>
        <AlertTitle></AlertTitle>
        {t('common.mailing_message')}
      </Alert>
      <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
        <Button variant='outlined' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='contained' disabled={!tableData.length} onClick={onConfirm}>
          {t('common.confirm')}
        </Button>
      </Stack>
    </Stack>
  );
};

export interface IPopupMarkupProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  tableData: DataForMultiEmailsRes;
  defaultEmailText?: string;
}
