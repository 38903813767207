import { FC } from 'react';
import {
  FieldsRow,
  FieldsSection,
  FormTextField,
} from '../../../../../../../../../../../../shared/components/form';
import { useTranslation } from 'react-i18next';

export const DebtorAccount: FC = () => {
  const { t } = useTranslation();

  return (
    <FieldsSection
      titleProps={{ variant: 'body1' }}
      titleBoxSx={{ pt: 4 }}
      nomt
      title={t('customer.debtorAccount')}
    >
      <FieldsRow>
        <FormTextField width='192px' name='debtorAccount' label={t('customer.debtorAccount')} />
        <FormTextField
          inputProps={{ maxLength: 32 }}
          width='192px'
          label={t('customer.slipReference')}
          name='slipReferenceNo'
        />
      </FieldsRow>
    </FieldsSection>
  );
};
