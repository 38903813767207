import { FC, useState, useEffect } from 'react';
import { DefaultOrderGrid } from './components/defaultOrderGrid/defaultOrderGrid.tsx';
import { defaultOrderState, IDefaultOrderState } from './defaultOrder.state.ts';
import { useLoaderData } from 'react-router-dom';
import { ITabState } from '../../../states/tabState.model.ts';
import { Content } from '../../../../../shared/components/content/content.component.tsx';
import { useTranslation } from 'react-i18next';

export const DefaultOrderTab: FC<IDefaultOrderTab> = () => {
  const { defaultOrderTabData: initData, defaultOrderTabOptions } = useLoaderData() as ITabState;
  const { t } = useTranslation();
  const [data, setData] = useState(initData as IDefaultOrderState);
  useEffect(() => {
    defaultOrderState.pub.init(initData as IDefaultOrderState);
    const defaultOrderStateSub = defaultOrderState.sub.state().subscribe(setData);
    return () => {
      defaultOrderStateSub.unsubscribe();
    };
  }, []);
  return (
    <>
      {data.articleId ? (
        <DefaultOrderGrid {...{ data, defaultOrderTabOptions }} />
      ) : (
        <Content
          type='box'
          boxProps={{
            sx: {
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              height: '100%',
            },
          }}
          emptyData={{
            value: true,
            title: t('common.no_data'),
          }}
        />
      )}
    </>
  );
};

interface IDefaultOrderTab {}
