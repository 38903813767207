import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';

import { TCompanyConfigs } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import {
  FieldsSection,
  FormCheckbox,
  FormNumericField,
  FormTextField,
} from '../../../../../../shared/components/form/index.ts';
import { configurationTabState } from '../../../states/configuration.state.ts';
import { DictionaryPopup } from '../../../../../../shared/components/dictionary/dictionary.popup.tsx';
import { TextEditCellDict } from '../../../../../../shared/components/dictionary/cells/textEditDict.cell.tsx';
import { NameDictCell } from './popups/defaultSettings/cells/nameDict.cell.tsx';
import { DefaultSettingsPopup } from './popups/defaultSettings/defaultSettings.popup.tsx';
import {
  DictBanksRes,
  DictDiscountGroupsRes,
  DictPriceCategoriesRes,
  DictQuantityDiscountsRes,
} from '../../../../../../shared/services/configsData/configsData.service.ts';

export const CustomerTab: FC<ICustomerTab> = ({ params: defaultValuesAndOptions }) => {
  const { t } = useTranslation();
  const {
    banksDictOptions,
    dictPriceCategories,
    discountGroups,
    quantityDiscounts,
    ...defaultValues
  } = defaultValuesAndOptions || {};
  const dictionaryPopup = useMemo(() => new DictionaryPopup(), []);
  const defaultSettingsPopup = useMemo(() => new DefaultSettingsPopup(), []);
  const formMethods = useForm({ defaultValues, mode: 'onChange' });
  const {
    resetField,
    formState: { dirtyFields },
  } = formMethods;

  useEffect(() => {
    const formStateSub = formMethods.watch((data: Record<string, any>, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        configurationTabState.pub.recordData({ [fieldName]: data[fieldName] });
      }
    });

    const configurationTabStateSub = configurationTabState.sub
      .state()
      .subscribe(({ dataToSave }) => {
        const name = Object.keys(dataToSave)[0] as keyof typeof dataToSave;
        resetField(name, {
          defaultValue: dataToSave[name],
          keepDirty: false,
        });
      });

    return () => {
      formStateSub.unsubscribe();
      configurationTabStateSub.unsubscribe();
      dictionaryPopup.stream.unsubscribe();
    };
  }, []);

  const customerListsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.customer_lists',
        dictionaryType: 'dictCustomerLists',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!!dirtyFields[e.target.name as keyof typeof dirtyFields]) {
      configurationTabState.pub.saveData();
    }
  };

  const deliveryNoteGroupsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.delivery_note_groups',
        width: 500,
        limit: 10,
        validateFields: ['name'],
        dictionaryType: 'dictDeliveryNoteGroups',
        columns: [
          {
            field: 'name',
            headerName: t('common.delivery_note'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };
  const customerGroupsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.customer_groups',
        dictionaryType: 'dictCustomerGroups',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('common.description'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };
  const tourplansHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.tourplans',
        dictionaryType: 'dictTourplans',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('settings.tourplan'),
            renderEditCell: (params) => <TextEditCellDict {...params} />,
            editable: true,
          },
        ],
      },
    });
  };

  const optionsForDefaultCustomerSettings: IOptionsForDefaultCustomerSettings = {
    banksDictOptions,
    dictPriceCategories,
    discountGroups,
    quantityDiscounts,
  };

  const defaultSettingsHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.default_settings',
        dictionaryType: 'dictCustomerProfiles',
        validateFields: ['name'],
        columns: [
          {
            field: 'name',
            headerName: t('settings.name'),
            renderEditCell: (params) => (
              <TextEditCellDict
                rightButton={{
                  title: t('settings.default_settings'),
                  handler: () => {
                    defaultSettingsPopup.stream.emit('open', {
                      values: {
                        id: String(params.id),
                        title: params.row?.name,
                        optionsForDefaultCustomerSettings,
                      },
                    });
                  },
                }}
                {...params}
              />
            ),
            renderCell: (params) => (
              <NameDictCell
                popupStream={defaultSettingsPopup.stream}
                optionsForDefaultCustomerSettings={optionsForDefaultCustomerSettings}
                {...params}
              />
            ),
            editable: true,
          },
        ],
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <FieldsSection width={456} titleBoxSx={{ pt: 0 }} nomt title={t('common.customer_no')}>
        <form noValidate onBlur={onSubmit}>
          <Stack direction='column' spacing={3} minWidth='300px'>
            <FormCheckbox
              name='isAutoCustomerNo'
              label={t('settings.assign_customer_no_automatically')}
            />
            {formMethods.watch('isAutoCustomerNo') && (
              <>
                <FormTextField
                  inputProps={{ maxLength: 16 }}
                  name='autoCustomerNoPrefix'
                  label={t('settings.part_of_customer_no')}
                />
                <FormNumericField
                  name='autoCustomerNoCounter'
                  precision={0}
                  label={t('settings.ongoing_value')}
                />
              </>
            )}
          </Stack>
        </form>
      </FieldsSection>
      <FieldsSection title={t('settings.related_directories')} nomt>
        <Stack alignItems='start' spacing={1} width='fit-content'>
          <Button
            onClick={customerListsHandler}
            fullWidth
            size='medium'
            variant='outlined'
            color='secondary'
          >
            {t('settings.customer_lists_settings')}
          </Button>
          <Button
            onClick={customerGroupsHandler}
            fullWidth
            size='medium'
            variant='outlined'
            color='secondary'
          >
            {t('settings.customer_groups_settings')}
          </Button>
          <Button
            onClick={deliveryNoteGroupsHandler}
            fullWidth
            size='medium'
            variant='outlined'
            color='secondary'
          >
            {t('settings.delivery_note_groups_settings')}
          </Button>
          <Button
            onClick={tourplansHandler}
            fullWidth
            size='medium'
            variant='outlined'
            color='secondary'
          >
            {t('settings.tourplans_settings')}
          </Button>
          <Button
            onClick={defaultSettingsHandler}
            fullWidth
            size='medium'
            variant='outlined'
            color='secondary'
          >
            {t('settings.default_settings')}
          </Button>
        </Stack>
      </FieldsSection>
      <dictionaryPopup.Component />
      <defaultSettingsPopup.Component />
    </FormProvider>
  );
};

export interface IOptionsForDefaultCustomerSettings {
  banksDictOptions: DictBanksRes;
  dictPriceCategories: DictPriceCategoriesRes;
  discountGroups: DictDiscountGroupsRes;
  quantityDiscounts: DictQuantityDiscountsRes;
}

export type TCompanyConfigsForCustomer = TCompanyConfigs & IOptionsForDefaultCustomerSettings;

interface ICustomerTab {
  params: TCompanyConfigsForCustomer;
}
