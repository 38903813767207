import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled, Tab, Tabs } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  generalTabState,
  IGeneralTabState,
  initSpecialPricePosition,
} from '../../generalTab.state';
import { IconButton } from '../../../../../../order/common/iconButton/iconButton.component';
import { Table } from '../../../../../../../shared/components/table/table.component';
import { storageHelper } from '../../../../../../../shared/helpers/storage';
import { Column } from '../../../../../../../shared/components/table/components/common.components';
import { localeFormatterHelper } from '../../../../../../../shared/helpers/formatter/localeFormatter.helper';
import { SpecialPricesPopup } from './popups/specialPrices/specialPrices.popup.tsx';
import { viewRows } from './views/viewRows';
import { TMode } from '../../../../../../../shared/services/mode/mode.service';
import { ArticleGeneralTabDataRes } from '../../../../../services/article.service';
import { ITabState } from '../../../../../states/tabState.model';
import { CopySpecialPricesPopup } from './popups/copySpecialPrices/copySpecialPrices.popup.tsx';
import { articleListState } from '../../../../../states/articleList.state.ts';
import { TabPanel } from '../../../../../../../shared/components/tabs/tabPanel.component.tsx';
import { SCellCheckboxContainer } from '../../../../../../customer/components/tabs/conditions/components/sections/individualPrices/individualPrices.component.tsx';
import { Checkbox } from '../../../../../../../shared/components/form/index.ts';
import { CustomerPricesPopup } from './popups/customerPrices/customerPrices.popup.tsx';

export const SpecialPrices: FC<ISpecialPrices> = ({
  articleSpecialPrices,
  specialPriceCategoriesColumns,
  createSpecialPriceCategoriesInputs,
  customerPricesPopup,
  articlePricesByCustomers,
  mode,
}) => {
  const isEditMode = mode === 'edit';
  const { t } = useTranslation();
  const [specialPricesData, setSpecialPricesData] =
    useState<ITabState['articleSpecialPrices']>(articleSpecialPrices);
  const [selectPos, setSelectPos] = useState<IGeneralTabState['selectedSpecialPricesPos']>(
    specialPricesData?.[0],
  );
  const [pricesByCustomerData, setPricesByCustomerData] =
    useState<IGeneralTabState['articlePricesByCustomers']>(articlePricesByCustomers);
  const [selectedPriceByCustomer, setSelectedPriceByCustomer] = useState<
    IGeneralTabState['selectedPricesByCustomerPos']
  >(pricesByCustomerData?.[0]);
  const [currentArticleId, setCurrentArticleId] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [pricesActiveTab, setPricesActiveTab] = useState(SpecialPricesTabs.categories);
  const { getValues, setValue } = useFormContext();
  const specialPricesPopup = useMemo(
    () =>
      new SpecialPricesPopup({
        createSpecialPriceCategoriesInputs:
          createSpecialPriceCategoriesInputs as ITabState['createSpecialPriceCategoriesInputs'],
      }),
    [],
  );
  const copySpecialPricesPopup = useMemo(() => new CopySpecialPricesPopup(), []);

  useEffect(() => {
    // on create/copy mode here we provide data to form
    if (!isEditMode) {
      const specialPricesForSave = specialPricesData
        ?.filter(({ fromDate, toDate }) => fromDate || toDate)
        ?.map(({ id: _id, isActual: _, ...rest }) => ({
          ...rest,
        })) as ArticleGeneralTabDataRes['articleSpecialPrices'];
      setValue('articleSpecialPrices', specialPricesForSave);
    }
  }, [specialPricesData, isEditMode]);
  useEffect(() => {
    // on create/copy mode here we provide data to form
    if (!isEditMode) {
      const articlePricesByCustomersForSave = pricesByCustomerData?.map(
        ({ id: _id, customerName: _, customerNo: __, ...rest }) => ({
          ...rest,
        }),
      );
      setValue('articlePricesByCustomers', articlePricesByCustomersForSave);
    }
  }, [pricesByCustomerData, isEditMode]);

  useEffect(() => {
    generalTabState.pub.initArticleSpecialPrices(specialPricesData);
    generalTabState.pub.initPricesByCustomer(pricesByCustomerData);
    const unsubState = generalTabState.sub
      .state()
      .subscribe(({ action, articleSpecialPrices, selectedSpecialPricesPos, ...rest }) => {
        setSpecialPricesData(articleSpecialPrices);
        setSelectPos(selectedSpecialPricesPos);
        setPricesByCustomerData(rest.articlePricesByCustomers);
        setSelectedPriceByCustomer(rest.selectedPricesByCustomerPos);
        if (
          (action === 'updateSpecialPricesPos' ||
            action === 'deleteSpecialPricesPos' ||
            action === 'copySpecialPrices') &&
          mode === 'edit'
        ) {
          generalTabState.pub.saveSpecialPricesGrid(getValues('id'));
        }
        if (
          (action === 'updatePricesByCustomerPos' ||
            action === 'deletePricesByCustomerPos' ||
            action === 'copyPricesByCustomer') &&
          mode === 'edit'
        ) {
          generalTabState.pub.savePricesByCustomerGrid(getValues('id'));
        }
      });
    const articleListSub = articleListState.sub.state().subscribe(({ selectedArticle }) => {
      setCurrentArticleId(selectedArticle?.id ?? '');
    });
    const subLoadingGrid = generalTabState.sub.specialPricesGridLoading().subscribe(setLoading);
    return () => {
      unsubState.unsubscribe();
      specialPricesPopup.stream.unsubscribe();
      articleListSub.unsubscribe();
      subLoadingGrid.unsubscribe();
    };
  }, []);

  const buttonList: IButtonList[] = useMemo(
    () => [
      {
        title: t('article.addNewRecord'),
        icon: <AddIcon />,
        onClick: () =>
          specialPricesPopup.stream.emit('open', {
            values: {
              row: {
                ...initSpecialPricePosition,
                id: `new_${Date.now()}`,
              },
              field: 'fromDate',
              allPricesData: specialPricesData,
            },
          }),
      },
      {
        title: t('article.deleteRecord'),
        icon: <RemoveIcon />,
        onClick: () => generalTabState.pub.deleteSpecialPricesPos(),
        disabled: !specialPricesData.length || !selectPos,
      },
      {
        title: t('article.copy_special_prices'),
        icon: <ContentCopyIcon />,
        onClick: () =>
          copySpecialPricesPopup.stream.emit('open', { currentArticleId, type: 'specialPrices' }),
      },
    ],
    [t, specialPricesData, selectPos, currentArticleId],
  );
  const priceArray = generatePriceArray();

  return (
    <>
      <Tabs value={pricesActiveTab} onChange={(_, tab) => setPricesActiveTab(tab)}>
        <Tab label={t('article.price_categories')} value={SpecialPricesTabs.categories} />
        <Tab label={t('article.customer_prices')} value={SpecialPricesTabs.customers} />
      </Tabs>
      <Box mt={1}>
        <TabPanel value={SpecialPricesTabs.categories} activeValue={pricesActiveTab}>
          <Stack direction='row' spacing={2} mb={1}>
            {buttonList.map(({ icon, ...props }, i) => (
              <IconButton key={i} color='primary' size='large' {...props}>
                {icon}
              </IconButton>
            ))}
          </Stack>
          <STable
            data={specialPricesData}
            initialState={{
              columns: storageHelper.local.getItem('article.specialPricesColumnModel'),
            }}
            onChangeColumnModel={(v) =>
              storageHelper.local.setItem('article.specialPricesColumnModel', v)
            }
            rowSelectionModel={selectPos?.id || undefined}
            onRowClick={(v, e) => {
              generalTabState.pub.selectSpecialPricesPos(v.row);
              specialPricesPopup.stream.emit('open', {
                values: {
                  row: v.row,
                  field: (e.target as any).parentElement?.dataset?.field,
                  allPricesData: specialPricesData,
                },
              });
            }}
            focusTabProps={{
              enabled: true,
              enabledFields: ['description', 'fromDate', 'isActual', 'toDate', ...priceArray],
            }}
            onCellKeyDown={({ row, field }, { code }) => {
              if (code === 'Enter') {
                generalTabState.pub.selectSpecialPricesPos(row);
                specialPricesPopup.stream.emit('open', {
                  values: { row, field, allPricesData: specialPricesData },
                });
              }
            }}
            height={400}
            {...{ viewRows, loading }}
          >
            <Column
              field='fromDate'
              headerName={t('common.from_date')}
              valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
              sortable={false}
              width={105}
            />
            <Column
              field='toDate'
              headerName={t('common.to_date')}
              valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
              sortable={false}
              width={105}
            />
            {specialPriceCategoriesColumns}
            <Column
              field='description'
              headerName={t('common.note')}
              sortable={false}
              width={200}
            />
          </STable>
          <specialPricesPopup.Component />
        </TabPanel>
        <TabPanel value={SpecialPricesTabs.customers} activeValue={pricesActiveTab}>
          <Stack direction='row' spacing={2} mb={1}>
            <IconButton
              onClick={() =>
                customerPricesPopup.stream.emit('open', {
                  values: {
                    row: {
                      id: `new_${Date.now()}`,
                      specialIsNoDiscount: false,
                    },
                  },
                })
              }
              color='primary'
              size='large'
              title={t('article.addNewRecord')}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={() => generalTabState.pub.deletePricesByCustomerPos()}
              color='primary'
              size='large'
              title={t('article.deleteRecord')}
              disabled={!selectedPriceByCustomer}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                copySpecialPricesPopup.stream.emit('open', {
                  currentArticleId,
                  type: 'customerPrices',
                });
              }}
              color='primary'
              size='large'
              title={t('article.copy_special_prices')}
            >
              <ContentCopyIcon />
            </IconButton>
          </Stack>
          <STable
            data={pricesByCustomerData}
            rowSelectionModel={selectedPriceByCustomer?.id}
            onRowClick={(v, e) => {
              generalTabState.pub.selectPricesByCustomerPos(v.row);
              customerPricesPopup.stream.emit('open', {
                values: {
                  row: v.row,
                  field: (e.target as any).parentElement?.dataset?.field,
                },
              });
            }}
            initialState={{
              columns: storageHelper.local.getItem('article.pricesByCustomerColumnModel'),
            }}
            onChangeColumnModel={(v) =>
              storageHelper.local.setItem('article.pricesByCustomerColumnModel', v)
            }
            height={400}
          >
            <Column
              field='fromDate'
              headerName={t('common.from_date')}
              valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
              sortable={false}
              width={100}
            />
            <Column
              field='toDate'
              headerName={t('common.to_date')}
              valueGetter={({ value }) => (value ? localeFormatterHelper.formatDate(value) : '')}
              sortable={false}
              width={100}
            />
            <Column
              field='customerName'
              headerName={t('common.customer')}
              valueGetter={({ value, row }) => row.customerNo && `${row.customerNo} ${value}`}
              width={200}
            />
            <Column
              field='specialPrice'
              headerName={t('common.price')}
              align='right'
              headerAlign='right'
              sortable={false}
              width={100}
              valueGetter={({ value }) =>
                value !== undefined && value !== null
                  ? localeFormatterHelper.formatNumber(value, {
                      returnZero: false,
                    })
                  : value
              }
            />
            <Column
              field='specialDiscount'
              headerName={t('common.discount')}
              align='right'
              headerAlign='right'
              sortable={false}
              width={90}
              valueGetter={({ value }) =>
                value !== undefined && value !== null
                  ? `${localeFormatterHelper.formatNumber(value, {
                      precision: 2,
                      noTrailingZeros: true,
                    })}%`
                  : value
              }
            />
            <Column
              field='specialIsNoDiscount'
              headerName={t('customer.noDiscount')}
              sortable={false}
              align='center'
              headerAlign='center'
              renderCell={({ value }) => (
                <SCellCheckboxContainer data-field='specialIsNoDiscount'>
                  <Checkbox disabled size='small' checked={value} />
                </SCellCheckboxContainer>
              )}
              width={110}
            />
            <Column
              field='fromQuantity'
              headerName={t('customer.fromQuantity')}
              align='right'
              headerAlign='right'
              sortable={false}
              valueGetter={({ value }) =>
                value
                  ? localeFormatterHelper.formatNumber(value, {
                      precision: 3,
                      noTrailingZeros: true,
                    })
                  : ''
              }
              width={100}
            />
            <Column
              field='specialPriceType'
              headerName={t('customer.kind')}
              sortable={false}
              valueGetter={({ value }) => value && t(`enums.${value}`)}
              width={200}
            />
            <Column
              field='comment'
              headerName={t('customer.comment')}
              sortable={false}
              width={150}
            />
          </STable>
        </TabPanel>
        <copySpecialPricesPopup.Component />
      </Box>
    </>
  );
};

function generatePriceArray() {
  const prices = [];
  for (let i = 1; i <= 10; i++) {
    prices.push(`price${i}`);
  }
  return prices;
}

const STable = styled(Table)({
  '& .MuiDataGrid-cell': {
    padding: 0,
  },
  '& .MuiDataGrid-cellContent': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'inherit',
  },
});

export type TArticleSpecialPrices = ITabState['articleSpecialPrices'];
enum SpecialPricesTabs {
  categories,
  customers,
}

interface ISpecialPrices {
  articleSpecialPrices: TArticleSpecialPrices;
  specialPriceCategoriesColumns: ITabState['specialPriceCategoriesColumns'];
  createSpecialPriceCategoriesInputs?: ITabState['createSpecialPriceCategoriesInputs'];
  customerPricesPopup: CustomerPricesPopup;
  articlePricesByCustomers: ITabState['articlePricesByCustomers'];
  mode: TMode;
}

interface IButtonList {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}
