import { FC, useMemo } from 'react';
import {
  FieldsRow,
  FormSelect,
  FormTextField,
  FormTimePicker,
  SelectMenuWithFooter,
} from '../../../../../../shared/components/form';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NoteButton } from '../../../../../../shared/components/form/buttons/noteButton.component.tsx';
import { NotePopup } from '../../../../common/popups/note.popup.tsx';
import { valueHelper } from '../../../../../../shared/helpers/valueProcessor/value.helper.ts';
import { useVisibilityGUID } from '../../../../../../shared/hooks/useVisibilityGUID.ts';
import { additionalNoteCompanies } from '../../../../../customer/components/tabs/forms/components/formsForm.component.tsx';
import { DictTextBlocksRes } from '../../../../../../shared/services/configsData/configsData.service.ts';

export const SideControls: FC<ISideControls> = ({ linkTextOptions, errorText }) => {
  const { t } = useTranslation();
  const showAdditionalNote = useVisibilityGUID(additionalNoteCompanies);
  const { getValues } = useFormContext();
  const notePopup = useMemo(() => new NotePopup({ showAdditionalNote }), [showAdditionalNote]);
  const isNote = useMemo(
    () =>
      valueHelper.checkValueInHtml(getValues('note')) ||
      (showAdditionalNote && valueHelper.checkValueInHtml(getValues('note2'))),
    [getValues(), showAdditionalNote],
  );
  return (
    <FieldsRow direction={'row'} spacing={2} alignItems='start'>
      <FormTextField
        inputProps={{ maxLength: 32 }}
        name='referenceOrderNo'
        width='220px'
        label={t('order.reference')}
      />
      <FormTimePicker
        name='deliveryTime'
        label={t('order.delivery_time')}
        width={154}
        fieldProps={{ ...(errorText && { color: 'error' }) }}
        errorText={errorText && `${t('order.invalid_value')}: ${errorText}`}
      />
      <FormSelect
        name='linkText'
        options={linkTextOptions as []}
        label={t('order.text')}
        width='220px'
        PaperComponent={(props) => (
          <SelectMenuWithFooter {...props} href='/settings/print-forms/general' />
        )}
      />
      <NoteButton
        title={t('common.note')}
        view='small'
        badged={isNote}
        onClick={() =>
          notePopup.stream.emit('open', {
            values: {
              main: getValues('note'),
              additional: getValues('note2'),
            },
          })
        }
      />
      <notePopup.Component />
    </FieldsRow>
  );
};

interface ISideControls {
  errorText: undefined | string;
  linkTextOptions: DictTextBlocksRes;
}
