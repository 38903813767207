import { Observable, map, of, switchMap, take, zip } from 'rxjs';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  GetCustomerDetailsQuery,
  GetCustomersDetailsQuery,
  GetCustomersDetailsQueryVariables,
  GetOrderPositionsQuery,
  GetOrderPositionsQueryVariables,
  GetOrdersQuery,
  GetCustomerListQuery as InvoiceListQuery,
  GetCustomerListQueryVariables as InvoiceListVariables,
} from '../../../graphql/generatedModel';
import { gqlClient } from '../../../graphql/graphqlRequest';
import {
  createInvoice,
  getCustomerDetails,
  getCustomerList,
  getCustomersDetails,
  getOrderPositions,
  getOrders,
} from './gql/createInvoice.gql';
import { IInvoiceDetailsState } from '../create/states/invoiceDetails.state';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import { GridSortModel } from '@mui/x-data-grid-premium';
import { addTotalWeight } from '../loaders/createInvoice.loader';

type Actions =
  | 'customerList'
  | 'positions'
  | 'getCustomersDetails'
  | 'queryData'
  | 'createInvoice'
  | 'createPartialInvoice'
  | undefined;

class PubImpl extends Pub<Actions> {
  clearStream() {
    this.emit(undefined, {});
  }
  customerList(params: InvoiceListVariables) {
    this.emit('customerList', params);
  }
  positions(params: GetOrderPositionsQueryVariables) {
    this.emit('positions', params);
  }
  getCustomersDetails(params: GetCustomersDetailsQueryVariables) {
    this.emit('getCustomersDetails', params);
  }
  queryData(params: QueryProps) {
    this.emit('queryData', params);
  }
  createInvoice(params: CreateInvoiceMutationVariables) {
    this.emit('createInvoice', params);
  }
  createPartialInvoice(params: CreatePartialInvoiceVariables) {
    this.emit('createPartialInvoice', params);
  }
}

class SubImpl extends Sub<Actions> {
  customerList(): Observable<CustomerListRes> {
    return this.actionListener('customerList').pipe(
      switchMap(({ params }) => {
        return gqlClient(getCustomerList, params) as Observable<InvoiceListQuery>;
      }),
      map((data: InvoiceListQuery) => {
        return (data.wawiAssist?.listWA_CustomersForCreatingNewInvoices || []) as CustomerListRes;
      }),
    );
  }

  positions(): Observable<PositionsRes> {
    return this.actionListener('positions').pipe(
      switchMap(({ params }) => {
        return gqlClient(getOrderPositions, params) as Observable<GetOrderPositionsQuery>;
      }),
      map((data: GetOrderPositionsQuery) => {
        return addTotalWeight(
          (data.wawiAssist?.listWA_OrderPositionsForNewInvoice || []) as PositionsRes,
        );
      }),
    );
  }

  getCustomersDetails(): Observable<GetCustomersDetailsRes> {
    return this.actionListener('getCustomersDetails').pipe(
      switchMap(({ params }) => {
        return gqlClient(getCustomersDetails, params) as Observable<GetCustomersDetailsQuery>;
      }),
      map((data: GetCustomersDetailsQuery) => {
        return (data.wawiAssist?.listWA_CustomersDataForCreatingMultipleInvoices ||
          []) as GetCustomersDetailsRes;
      }),
    );
  }

  createPartialInvoice(): Observable<CreatePartialInvoiceRes> {
    return this.actionListener('createPartialInvoice').pipe(
      switchMap(({ params }) => {
        const { createParams, positionParams } = (params || {}) as CreatePartialInvoiceVariables;
        return zip(
          gqlClient(createInvoice, createParams) as Observable<CreateInvoiceMutation>,
          gqlClient(getOrderPositions, positionParams) as Observable<GetOrderPositionsQuery>,
        );
      }),
      map(([createdInvoiceInfo, positions]) => {
        return {
          invoiceId: createdInvoiceInfo.wawiAssist?.createWA_Invoice?.invoiceId,
          positions: positions.wawiAssist?.listWA_OrderPositionsForNewInvoice,
        } as CreatePartialInvoiceRes;
      }),
    );
  }

  queryData(): Observable<QueryDataRes> {
    return this.actionListener('queryData').pipe(
      switchMap(({ params }) => {
        const { id, datePeriod, orderSort } = params || {};
        return zip(
          gqlClient(getCustomerDetails, {
            getCustomerDataId: id,
          }) as Observable<GetCustomerDetailsQuery>,
          gqlClient(getOrders, {
            customerId: id,
            ordersStartDate: datePeriod?.fromDate,
            ordersEndDate: datePeriod?.toDate,
          }) as Observable<GetOrdersQuery>,
          of(orderSort),
          of(id),
        );
      }),
      map(([customerInfo, orders, orderSort, selectedCustomerId]) => {
        const sortModel = orderSort as GridSortModel;
        const sortedOrders = dataHelper
          .data(orders.wawiAssist?.listWA_OrdersForNewInvoice as [])
          .sort({ sortModel })
          .result();
        const { isMultipayCustomer, transformedOrders } = transformOrderDataIfMultipayCustomer({
          id: selectedCustomerId,
          orders: sortedOrders as OrdersRes,
        });

        const selectedOrder = transformedOrders?.[0];
        return {
          customerInfo: (customerInfo.wawiAssist?.getCustomerData || {}) as CustomerDataRes,
          orders: transformedOrders as IInvoiceDetailsState['orders'],
          selectedOrder,
          orderPositions: [] as PositionsRes,
          isMultipayCustomer,
        } as QueryDataRes;
      }),
      switchMap((data) => {
        const { orders, selectedOrder } = data;
        if (!orders?.length || !selectedOrder) {
          return zip(of(data));
        }

        return zip(
          of(data),
          gqlClient(getOrderPositions, {
            orderId: selectedOrder?.orderId,
          }) as Observable<GetOrderPositionsQuery>,
        );
      }),
      map(([data, orderPositionsRes]) => {
        if (!orderPositionsRes) {
          return data as QueryDataRes;
        }
        data.orderPositions = addTotalWeight(
          orderPositionsRes.wawiAssist?.listWA_OrderPositionsForNewInvoice || [],
        ) as PositionsRes;
        return data as QueryDataRes;
      }),
    );
  }

  createInvoice(): Observable<CreateInvoiceRes> {
    return this.actionListener('createInvoice').pipe(
      take(1),
      switchMap(({ params }) => {
        return gqlClient(createInvoice, params) as Observable<CreateInvoiceMutation>;
      }),
      map((data: CreateInvoiceMutation) => {
        return data?.wawiAssist?.createWA_Invoice as CreateInvoiceRes;
      }),
    );
  }
}

class CreateInvoiceService extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const createInvoiceService = new CreateInvoiceService();

export const transformOrderDataIfMultipayCustomer = (props: TransformProps): TransformRes => {
  const { orders } = props || {};
  const isMultipayCustomer = isMultipay(props);
  const simpleCustomer = !isMultipayCustomer;
  if (simpleCustomer || !orders?.length) {
    return {
      isMultipayCustomer: false,
      transformedOrders: orders || [],
    };
  }
  const transformedOrders = transformOrdersForMultipayCustomer(orders);
  return {
    isMultipayCustomer: true,
    transformedOrders,
  };
};

const isMultipay = (props: TransformProps): boolean => {
  const { orders, id } = props || {};
  return !orders?.every((order) => {
    const { customerId } = order || {};
    return customerId === id;
  });
};

const transformOrdersForMultipayCustomer = (orders: OrdersRes): OrdersForMultipay => {
  return orders?.map((order) => {
    const { customerNo, internalOrFullName } = order || {};
    return {
      ...order,
      customerNumberAndName: `${customerNo} ${internalOrFullName}`,
    };
  }) as OrdersForMultipay;
};

export type OrdersForMultipay = OrderForMultipay[];

type OrderType = OrdersRes[number];

type OrderForMultipay = OrderType & {
  customerNumberAndName: string;
};

interface TransformProps {
  orders: OrdersRes;
  id: IInvoiceDetailsState['customerId'];
}

interface TransformRes {
  isMultipayCustomer: boolean;
  transformedOrders: OrdersRes | OrdersForMultipay;
}

interface QueryProps {
  id: IInvoiceDetailsState['customerId'];
  datePeriod: IInvoiceDetailsState['datePeriod'];
  orderSort: GridSortModel;
}

export type QueryDataRes = Required<
  Pick<
    IInvoiceDetailsState,
    | 'customerInfo'
    | 'orders'
    | 'selectedOrder'
    | 'linkText'
    | 'orderPositions'
    | 'isMultipayCustomer'
  >
>;

export interface CreatePartialInvoiceVariables {
  createParams: CreateInvoiceMutationVariables;
  positionParams: GetOrderPositionsQueryVariables;
}

export interface CreatePartialInvoiceRes {
  positions: PositionsRes;
  invoiceId: CreateInvoiceRes['invoiceId'];
}

export type CustomerListRes = NonNullable<
  NonNullable<InvoiceListQuery['wawiAssist']>['listWA_CustomersForCreatingNewInvoices']
>;

export type CustomerDataRes = NonNullable<
  NonNullable<GetCustomerDetailsQuery['wawiAssist']>['getCustomerData']
>;

export type OrdersRes = NonNullable<
  NonNullable<GetOrdersQuery['wawiAssist']>['listWA_OrdersForNewInvoice']
>;

export type PositionsRes = NonNullable<
  NonNullable<GetOrderPositionsQuery['wawiAssist']>['listWA_OrderPositionsForNewInvoice']
>;

export type GetCustomersDetailsRes = NonNullable<
  NonNullable<
    GetCustomersDetailsQuery['wawiAssist']
  >['listWA_CustomersDataForCreatingMultipleInvoices']
>;

export type CreateInvoiceRes = NonNullable<
  NonNullable<CreateInvoiceMutation['wawiAssist']>['createWA_Invoice']
>;
