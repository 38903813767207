import { Observable, filter, finalize, map, merge, of, switchMap, take } from 'rxjs';
import {
  C_Save_Operation_Status,
  SaveCustomerDataMutationVariables,
} from '../../../../../graphql/generatedModel';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import { Pub, State, Sub } from '../../../../../shared/state/state.abstract';
import {
  CustomerFormsDataRes,
  customerService,
  SaveCustomerDataRes,
} from '../../../services/customer.service';
import { ITabState } from '../../../states/tabState.model';
import { customersListState } from '../../../states/customersList.state';
import { customerTabLoadingService } from '../customerTabLoading.service';
import { defaultCustomerFormsData } from '../../../loaders/formsTab.resolver';

class PubImpl extends Pub<IFormsTabState> {
  recordData(dataToSave: IFormsTabState['dataToSave']) {
    this.emit('recordData', { dataToSave });
  }
  save() {
    this.emit('save', {});
  }
  clearStream() {
    this.emit(undefined, {});
  }
}
class SubImpl extends Sub<IFormsTabState> {
  protected actionHandlers(): Observable<IFormsTabState> {
    return merge(this.selectedCustomer(), this.save());
  }
  private save(): Observable<IFormsTabState> {
    return this.actionListener('save').pipe(
      filter(({ dataToSave }) => {
        return typeof dataToSave.id === 'string';
      }),
      switchMap((data) => {
        const { dataToSave } = data;
        customerService.sub
          .editCustomerData()
          .pipe(
            responseHandler<SaveCustomerDataRes | undefined>({
              success: () => 'customer.customer_saved',
              customErrorHandler: () => 'common.error_chnages_not_saved',
            }),
            filter((v) => v !== undefined),
            take(1),
          )
          .subscribe((res) => {
            if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
              const { updatedGridItem } = res;
              customersListState.pub.updateCustomer(updatedGridItem!);
            }
          });
        customerService.pub.editCustomerData({
          data: dataToSave,
        } as SaveCustomerDataMutationVariables);

        return of(data);
      }),
      finalize(() => formsTabState.pub.clearStream()),
    );
  }

  private selectedCustomer(): Observable<IFormsTabState> {
    return customersListState.sub.state().pipe(
      filter(({ action }) => action === 'selectRecord'),
      filter(({ selectedRecord }) => typeof selectedRecord?.id === 'string'),
      switchMap(({ selectedRecord }) => {
        customerTabLoadingService.pub.loading(true);
        const id = selectedRecord!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedRecord';
        const details = customerService.sub.customerFormsData().pipe(
          responseHandler<CustomerFormsDataRes>({ errorReturnType: defaultCustomerFormsData }),
          map((data) => {
            state.defaultValues = data;
            customerTabLoadingService.pub.loading(false);
            customersListState.pub.updateInfoTip(data.informationTip);
            return state;
          }),
        );
        customerService.pub.customerFormsData({ id });

        return details;
      }),
    );
  }
}

class FormsTabState extends State<IFormsTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const formsTabState = new FormsTabState({
  action: undefined,
  defaultValues: {},
  dataToSave: {},
});

export interface IFormsTabState extends Pick<ITabState, 'defaultValues'> {
  action: undefined | 'list.selectedRecord' | 'recordData' | 'save';
  dataToSave: CustomerFormsDataRes | Record<string, any>;
}
