import { ICustomerListState, initCustomerListState } from '../create/states/customerList.state.ts';
import { companyConfigService } from '../../../shared/services/companyConfig/companyConfig.service.ts';
import { storageHelper } from '../../../shared/helpers/storage';
import {
  createOrderService,
  INewOrderDataRes,
  NewOrderDataExtendedRes,
  OrderDetailsDataRes,
} from '../services/createOrder.service.ts';
import { take, zipWith } from 'rxjs';
import { dataHelper } from '../../../shared/helpers/data/data.helper.ts';
import { LoaderFunction } from 'react-router-dom';
import { ISelectOption } from '../../../shared/components/form/fields/select.component.tsx';
import {
  additionalOptions,
  IAdditionalOptions,
  initOrderDetailsState,
  IOrderData,
  IOrderDetailsState,
} from '../create/states/orderDetails/orderDetails.state.ts';
import {
  configsData,
  DictCustomersRes,
  DictDeliveryNoteGroupsRes,
  DictTextBlocksRes,
} from '../../../shared/services/configsData/configsData.service.ts';
import {
  IFilteredProductDataRes,
  orderService,
  TaxesByDateRes,
} from '../services/order.service.ts';
import { C_Order_Positions_Sorting_Kind } from '../../../graphql/generatedModel.ts';
import { additionOptionsSubEdit } from '../edit/components/orderDetails/orderDetails.component.tsx';
import { responseHandler } from '../../../shared/responseHandler/responseHandler.ts';

export const createOrderLoader: LoaderFunction = async (): Promise<ICreateOrderLoader> => {
  if (additionOptionsSubEdit) additionOptionsSubEdit.unsubscribe();
  let initCustomerGridData = {
    initState: structuredClone(initCustomerListState),
    filterOptions: {} as IFilterOptions,
  };
  let initOrderGridData: ICreateOrderLoader['initOrderGridData'] = {
    initState: structuredClone({ ...initOrderDetailsState, orderData: [] } as IOrderGridInitState),
    additionalOptions: structuredClone(additionalOptions),
    linkTextOptions: [],
    rowReordering: true,
    deliveryFunctionActive: false,
  };
  initCustomerGridData = await resolveCustomerGridData(initCustomerGridData);
  initOrderGridData = await resolveOrderGridData(initOrderGridData, initCustomerGridData.initState);
  return { initCustomerGridData, initOrderGridData } as ICreateOrderLoader;
};

const resolveCustomerGridData = async (
  data: ICreateOrderLoader['initCustomerGridData'],
): Promise<ICreateOrderLoader['initCustomerGridData']> => {
  data.initState.action = 'loader';

  const filter = data.initState.filter;
  if (!filter.date) filter.date = companyConfigService.getCachedConfigs()?.nextDeliveryDate;
  let [customerList, customerListForFilter = [], deliveryNoteGroups = []] =
    await createOrderService.globHelpers.streamToPromise(
      createOrderService.sub.customerList().pipe(
        zipWith(configsData.sub.dictCustomers(), configsData.sub.dictDeliveryNoteGroups()),
        responseHandler<
          [ICustomerListState['customerList'], DictCustomersRes, DictDeliveryNoteGroupsRes]
        >({
          errorReturnType: [[], [], []],
        }),
        take(1),
      ),
      () => {
        createOrderService.pub.customerList({ filter });
        configsData.pub.common(['dictCustomers', 'dictDeliveryNoteGroups']);
      },
    );

  /* InitState */
  const sortModel =
    storageHelper.local.getItem('createOrder.customerList.sortModel') || data.initState.sortModel;

  if (customerList.length) {
    customerList = dataHelper
      .data(customerList as [])
      .sort({ sortModel })
      .result() as ICustomerListState['customerList'];
  }

  const selectedCustomerStorage = storageHelper.session.getItem('createOrder.selectedCustomer');
  const foundSelectedRecord =
    selectedCustomerStorage &&
    customerList.find((item) => item?.id === selectedCustomerStorage?.id);
  const selectedCustomer = foundSelectedRecord || customerList[0];
  const allCustomerListLength = customerList.length;

  /* FilterOptions */
  const deliveryGroupOptions: ISelectOption[] = deliveryNoteGroups.map((item) => {
    return { id: item.id, label: item.label! };
  });

  const customerListOptions = customerListForFilter.reduce((outArr: ISelectOption[], customer) => {
    const id = customer?.id && customer.id.toString();
    const internalOrFullName = customer?.internalOrFullName;
    if (id && internalOrFullName)
      outArr.push({ id, label: `${customer?.customerNo} ${internalOrFullName}` });
    return outArr;
  }, []);

  return {
    initState: {
      ...data.initState,
      filter,
      customerList,
      sortModel,
      selectedCustomer,
      allCustomerListLength,
    },
    filterOptions: { deliveryGroupOptions, customerListOptions },
  };
};

const resolveOrderGridData = async (
  data: ICreateOrderLoader['initOrderGridData'],
  customerListData: ICustomerListState,
): Promise<ICreateOrderLoader['initOrderGridData']> => {
  data.initState.action = 'loader';
  data.initState.orderData = [];
  if (customerListData.selectedCustomer) {
    const commonParams = {
      date: customerListData.filter.date,
      orderType: customerListData.filter.orderType,
    };
    const [orderDetailsData, linkTextOptions, productData, taxes] =
      await createOrderService.globHelpers.streamToPromise(
        createOrderService.sub.orderDetailsData().pipe(
          zipWith(
            configsData.sub.dictTextBlocks(),
            orderService.sub.filteredProductData(),
            orderService.sub.taxes(),
          ),
          responseHandler<
            [IOrderDetailsDataRes, DictTextBlocksRes, IFilteredProductDataRes, TaxesByDateRes]
          >({
            errorReturnType: [
              {
                customerInfo: {},
                orderData: [],
              },
              [],
              {
                productList: [],
              },
              [],
            ],
          }),
          take(1),
        ),
        () => {
          createOrderService.pub.orderDetailsData({
            customer: customerListData.selectedCustomer!,
            ...commonParams,
          });
          configsData.pub.common(['dictTextBlocks']);
          orderService.pub.filteredProductData({
            customerId: customerListData.selectedCustomer?.customerId,
            ...commonParams,
          });
          orderService.pub.taxes({
            date: customerListData.filter.date,
          });
        },
      );
    const { customerInfo, orderData } = orderDetailsData;
    if (!Object.keys(customerInfo).length || !orderData.length) {
      // in case of error
      const emptyState = structuredClone({ ...initOrderDetailsState, orderData: [] });
      emptyState.action = 'errorData';
      emptyState.orderDataBackup = emptyState.orderData as unknown as IOrderData;
      data.initState = emptyState;
    } else {
      const {
        customerNo,
        internalOrFullName,
        informationTip,
        deliveryAddress,
        discountKindId,
        isDeliverySplitting,
        deliverySplittingPartsCount,
        transportSectorId,
        transportSector,
        id,
        ...contacts
      } = customerInfo;

      data.initState.customerInfo = {
        customerNo,
        internalOrFullName,
        informationTip,
        deliveryAddress,
        discountKindId,
        isDeliverySplitting,
        deliverySplittingPartsCount,
        transportSector,
        id,
        contacts,
      };
      data.initState.orderData = addTotalWeight(orderData || []);
      data.initState.orderDataBackup = structuredClone(orderData?.[0] || {});
      data.initState.noData = false;
      data.rowReordering =
        companyConfigService.getCachedConfigs()?.orderPositionsSortingKindId ===
        C_Order_Positions_Sorting_Kind.OPS3_ORIGINAL;
      data.deliveryFunctionActive =
        !!companyConfigService.getCachedConfigs()?.isShippingFunctionActivated;
    }

    data.linkTextOptions = linkTextOptions;
    data.additionalOptions = { productData, taxes };
  } else {
    const [taxes, linkTextOptions] = await orderService.globHelpers.streamToPromise(
      orderService.sub.taxes().pipe(
        zipWith(configsData.sub.dictTextBlocks()),
        responseHandler<[TaxesByDateRes, DictTextBlocksRes]>({
          errorReturnType: [[], []],
        }),
        take(1),
      ),
      () => {
        configsData.pub.common(['dictTextBlocks']);
        orderService.pub.taxes({
          date: customerListData.filter.date,
        });
      },
    );
    data.linkTextOptions = linkTextOptions;
    data.additionalOptions.taxes = taxes;
  }
  return { ...data };
};

export const getTotalWeight = (data: INewOrderDataRes['positions']): number => {
  return (
    data?.reduce((acc, item) => {
      if (
        !item?.virtualPositionId &&
        item?.weight &&
        item?.quantity &&
        !isNaN(item.weight) &&
        !isNaN(item.quantity)
      ) {
        acc += item.weight * item.quantity;
      }
      return acc;
    }, 0) || 0
  );
};

export const addTotalWeight = (data: IOrderGridInitState['orderData']) => {
  return data.map((el) => {
    if (el?.positions) {
      const deliveryCost = el.positions.find((item) => item?.virtualPositionId);
      if (deliveryCost) {
        el.positions = el.positions.filter((el) => !el?.virtualPositionId);
        el.positions.push({ ...deliveryCost, weight: getTotalWeight(el.positions) });
      }
    }
    return el;
  });
};

export interface ICreateOrderLoader {
  initCustomerGridData: {
    initState: ICustomerListState;
    filterOptions: IFilterOptions;
  };
  initOrderGridData: {
    initState: IOrderGridInitState;
    linkTextOptions: DictTextBlocksRes;
    additionalOptions: IAdditionalOptions;
    rowReordering: boolean;
    deliveryFunctionActive: boolean;
  };
}

interface IOrderGridInitState extends Omit<IOrderDetailsState, 'orderData'> {
  orderData: NewOrderDataExtendedRes;
}

export type IFilterOptions = {
  [key in typeKeyOption]: ISelectOption[];
};
type typeKeyOption = 'deliveryGroupOptions' | 'customerListOptions';

export interface IOrderDetailsDataRes extends Pick<OrderDetailsDataRes, 'orderData'> {
  customerInfo: Partial<OrderDetailsDataRes['customerInfo']>;
}
