/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoaderFunction } from 'react-router-dom';
import { dataHelper } from '../../../shared/helpers/data/data.helper';
import {
  initCustomerListState,
  ICustomerListState,
  customerListState,
} from '../states/customerList.state';
import { format } from 'date-fns';
import {
  ListPotentialAndCreatedOffersRes,
  offerService,
  OfferDetailsRes,
  INewOfferDataExtendedRes,
  IFilteredOfferProductDataRes,
  TaxesByDateRes,
} from '../services/offer.service.ts';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';
import { take, zipWith } from 'rxjs';
import { storageHelper } from '../../../shared/helpers/storage';
import {
  IOfferDetailsState,
  initOfferDetailsState,
  additionalOptions,
  IAdditionalOptions,
} from '../states/offerDetails/offerDetails.state.ts';
import { companyConfigService } from '../../../shared/services/companyConfig/companyConfig.service.ts';
import { C_Order_Positions_Sorting_Kind } from '../../../graphql/generatedModel.ts';
import {
  DictTextBlocksRes,
  configsData,
} from '../../../shared/services/configsData/configsData.service.ts';

export const offerLoader: LoaderFunction = async (): Promise<IOfferLoader> => {
  let initCustomerList = structuredClone(initCustomerListState);
  let initOfferRightSideData: IOfferLoader['initOfferRightSideData'] = {
    initDetailsState: { ...structuredClone(initOfferDetailsState), offerData: [] },
    additionalOptions: structuredClone(additionalOptions),
    rowReordering: true,
    linkTextOptions: [],
  };
  initCustomerList = await resolverCustomerListData(initCustomerList);
  initOfferRightSideData = await resolverOfferRightSideData(
    initOfferRightSideData,
    initCustomerList,
  );
  return {
    initCustomerList,
    initOfferRightSideData,
  };
};

const resolverCustomerListData = async (data: ICustomerListState): Promise<ICustomerListState> => {
  const defaultData = format(new Date(), 'yyyy-MM-dd');
  const filter = data.filter;
  data.action = 'loader';
  filter.date = defaultData;
  filter.dateTo = defaultData;

  let customerList = await offerService.globHelpers.streamToPromise(
    offerService.sub
      .getListPotentialAndCreatedOffers()
      .pipe(responseHandler<ListPotentialAndCreatedOffersRes>({ errorReturnType: [] }), take(1)),
    () => offerService.pub.getListPotentialAndCreatedOffers({ filter }),
  );

  const sortModel = storageHelper.local.getItem('offer.customerList.sortModel') || data.sortModel;
  if (customerList.length) {
    customerList = dataHelper
      .data(customerList as [])
      .sort({ sortModel })
      .result() as ICustomerListState['customerList'];
  }
  let selectedCustomer = storageHelper.session.getItem('offer.selectedCustomer');
  const isSelectedCustomerInList =
    selectedCustomer && !!customerList?.find((item) => item?.id === selectedCustomer?.id);
  selectedCustomer = isSelectedCustomerInList ? selectedCustomer : customerList?.[0];
  const customerListLength = customerList?.length;
  const isCreatedOffersFilter = customerListState.sub.isCreatedOffersFilter(filter);

  return {
    ...data,
    customerList,
    selectedCustomer,
    customerListLength,
    filter,
    sortModel,
    isCreatedOffersFilter,
  };
};

const resolverOfferRightSideData = async (
  data: IOfferLoader['initOfferRightSideData'],
  customerList: IOfferLoader['initCustomerList'],
): Promise<IOfferLoader['initOfferRightSideData']> => {
  data.initDetailsState.action = 'loader';
  data.initDetailsState.offerData = [];
  if (customerList.selectedCustomer) {
    const [{ customerInfo, offerData }, linkTextOptions, productData, taxes] =
      await offerService.globHelpers.streamToPromise(
        offerService.sub.offerDetails().pipe(
          zipWith(
            configsData.sub.dictTextBlocks(),
            offerService.sub.filteredOfferProductData(),
            offerService.sub.taxes(),
          ),
          responseHandler<
            [OfferDetailsRes, DictTextBlocksRes, IFilteredOfferProductDataRes, TaxesByDateRes]
          >({
            errorReturnType: [
              {
                offerData: [],
                customerInfo: {},
              },
              [],
              { productList: [] },
              [],
            ],
          }),
          take(1),
        ),
        () => {
          offerService.pub.offerDetails({
            ...customerList.selectedCustomer!,
            offerForDate: customerList.filter.date,
          });
          configsData.pub.common(['dictTextBlocks']);
          offerService.pub.filteredOfferProductData({
            customerId: customerList.selectedCustomer?.customerId,
            date: customerList.filter.date,
          });
          offerService.pub.taxes({
            date: customerList.filter.date,
          });
        },
      );
    if (!Object.keys(customerInfo).length || !offerData.length) {
      // in case of error
      const emptyState = structuredClone({
        ...structuredClone(initOfferDetailsState),
        offerData: [],
      });
      emptyState.action = 'errorData';
      emptyState.offerDataBackup = emptyState.offerData?.[0] || {};
      data.initDetailsState = emptyState;
    } else {
      const {
        customerNo,
        internalOrFullName,
        informationTip,
        deliveryAddress,
        discountKindId,
        isDeliverySplitting,
        deliverySplittingPartsCount,
        transportSectorId,
        transportSector,
        id,
        ...contacts
      } = customerInfo;

      data.initDetailsState.customerInfo = {
        customerNo,
        internalOrFullName,
        informationTip,
        deliveryAddress,
        discountKindId,
        isDeliverySplitting,
        deliverySplittingPartsCount,
        transportSector,
        id,
        contacts,
      };
      data.initDetailsState.offerData = offerData;
      data.initDetailsState.offerDataBackup = structuredClone(offerData?.[0] || {});
      data.initDetailsState.noData = false;
      data.rowReordering =
        companyConfigService.getCachedConfigs()?.orderPositionsSortingKindId ===
        C_Order_Positions_Sorting_Kind.OPS3_ORIGINAL;
      data.additionalOptions = { productData, taxes };
    }
    data.linkTextOptions = linkTextOptions;
    return { ...data };
  }
  // in case when we have no selectedCustomer
  const linkTextOptions = await offerService.globHelpers.streamToPromise(
    configsData.sub.dictTextBlocks().pipe(
      responseHandler<DictTextBlocksRes>({
        errorReturnType: [],
      }),
    ),
    () => {
      configsData.pub.common(['dictTextBlocks']);
    },
  );
  data.linkTextOptions = linkTextOptions;
  return { ...data };
};

export interface IOfferLoader {
  initCustomerList: ICustomerListState;
  initOfferRightSideData: {
    initDetailsState: IInitDetailsLoader;
    additionalOptions: IAdditionalOptions;
    rowReordering: boolean;
    linkTextOptions: DictTextBlocksRes;
  };
}

interface IInitDetailsLoader extends Omit<IOfferDetailsState, 'offerData'> {
  offerData: INewOfferDataExtendedRes;
}
