/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { TPopupDefaultActions } from '../../../../../../../../../../shared/components/popup/services/popup.service.ts';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { IndividualPricesForm } from './individualPrices.form.tsx';
import { useFormContext } from 'react-hook-form';
import { ITabState } from '../../../../../../../../states/tabState.model.ts';
import { IIndividualPrices } from '../../individualPrices.component.tsx';
import { C_Special_Price_Type } from '../../../../../../../../../../graphql/generatedModel.ts';

export class IndividualPricesPopup extends Popup<IIndividualPricesPopup> {
  Component: FC<TPopupComponent>;
  productListOptions: ITabState['conditionsTabOptions']['productList'];
  constructor(params: { productListOptions: IIndividualPrices['productListOptions'] }) {
    super();
    const stream = this.innerStream;
    this.productListOptions = params.productListOptions;
    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 700 },
        }));
      }, []);
      const { getValues } = useFormContext();
      const [defaultValues, setDefaultValues] = useState<TDefaultValues>({
        id: '',
        articleId: getValues().id,
        specialPriceType: null,
        specialIsNoDiscount: false,
      });
      const [field, setField] = useState('');
      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          if (values?.row) {
            const { fromDate, toDate, ...rest } = values.row as TDefaultValues;
            setDefaultValues({
              ...rest,
              fromDate: fromDate && new Date(fromDate),
              toDate: toDate && new Date(toDate),
            });
          }
          if (values?.field) setField(values.field);
        });
        stream.actionListener('close').subscribe(() => {
          setDefaultValues({
            id: '',
            articleId: getValues().id,
            specialPriceType: null,
            specialIsNoDiscount: false,
          });
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('customer.individualPrices')}>
          <IndividualPricesForm
            productListOptions={this.productListOptions}
            priceCategory={Number(getValues().priceCategoryId)}
            customerId={getValues().id}
            defaultValues={defaultValues}
            {...{ field, stream }}
          />
        </SWrapper>
      );
    };
  }
}

export const getSpecialPriceType = (data: IDataForSpecialPriceType) => {
  if (!data.fromQuantity) {
    if (data.specialDiscount || data.specialIsNoDiscount) {
      if (!data.specialPrice) {
        return C_Special_Price_Type.SP2_DISCOUNT;
      } else {
        return C_Special_Price_Type.SP3_PRICE_AND_DISCOUNT;
      }
    } else {
      if (data.specialPrice) {
        return C_Special_Price_Type.SP1_PRICE;
      } else {
        return null;
      }
    }
  } else {
    if (data.specialDiscount || data.specialIsNoDiscount) {
      if (!data.specialPrice) {
        return C_Special_Price_Type.SP5_DISCOUNT_IF_QUANTITY_MORE;
      } else {
        return C_Special_Price_Type.SP6_PRICE_AND_DISCOUNT_IF_QUANTITY_MORE;
      }
    } else {
      if (data.specialPrice) {
        return C_Special_Price_Type.SP4_PRICE_IF_QUANTITY_MORE;
      } else {
        return null;
      }
    }
  }
};

interface IDataForSpecialPriceType {
  fromQuantity?: TDefaultValues['fromQuantity'];
  specialDiscount?: TDefaultValues['specialDiscount'];
  specialIsNoDiscount?: TDefaultValues['specialIsNoDiscount'];
  specialPrice?: TDefaultValues['specialPrice'];
}
export interface IIndividualPricesPopup {
  action: 'save' | TPopupDefaultActions;
  values?: { row: GridRowParams['row']; field?: string };
}

export type TDefaultValues = ITabState['customerSpecialPrices'][number] & {
  specialPriceTypeText?: string;
};
