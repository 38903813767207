/* eslint-disable react-hooks/rules-of-hooks */

import { GridRowParams } from '@mui/x-data-grid-premium';
import {
  Popup,
  TPopupComponent,
} from '../../../../../../../../../shared/components/popup/popup.abstract.tsx';
import { TPopupDefaultActions } from '../../../../../../../../../shared/components/popup/services/popup.service.ts';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { ITabState } from '../../../../../../../states/tabState.model.ts';
import { CustomerPricesForm } from './customerPrices.form.tsx';
import { C_Special_Price_Type } from '../../../../../../../../../graphql/generatedModel.ts';
import { GeneralTabOptionsRes } from '../../../../../../../services/article.service.ts';

export class CustomerPricesPopup extends Popup<ICustomerPricesPopup> {
  Component: FC<TPopupComponent>;
  customersList: GeneralTabOptionsRes['customersList'];

  constructor(params: { customersList: GeneralTabOptionsRes['customersList'] }) {
    super();
    const stream = this.innerStream;
    this.customersList = params.customersList;
    this.Component = () => {
      const { t } = useTranslation();
      const SWrapper = useMemo(() => {
        return styled(this.Wrapper)(() => ({
          '& > div > div': { maxWidth: 700 },
        }));
      }, []);
      const [autoFocusField, setAutoFocusField] = useState('');
      const [defaultValues, setDefaultValues] = useState<TDefaultValues>({
        id: '',
        customerId: '',
        specialIsNoDiscount: false,
      });

      useEffect(() => {
        stream.actionListener('open').subscribe(({ values }) => {
          if (values?.row) {
            const { fromDate, toDate, ...rest } = values.row as TDefaultValues;
            setDefaultValues({
              ...rest,
              fromDate: fromDate && new Date(fromDate),
              toDate: toDate && new Date(toDate),
            });
          }
          if (values?.field) setAutoFocusField(values.field);
        });
        stream.actionListener('close').subscribe(() => {
          setDefaultValues({
            id: '',
            customerId: '',
            specialIsNoDiscount: false,
          });
          setAutoFocusField('');
          stream.emit('clear');
        });
      }, []);

      return (
        <SWrapper fullWidth stream={stream} popupTitle={t('article.customer_prices')}>
          <CustomerPricesForm
            defaultValues={defaultValues}
            autoFocusField={autoFocusField}
            articleId=''
            customersList={this.customersList}
            stream={stream}
          />
        </SWrapper>
      );
    };
  }
}

export interface ICustomerPricesPopup {
  action: 'save' | TPopupDefaultActions;
  values: {
    row: GridRowParams['row'];
    field?: string;
  };
}

export type TDefaultValues = Omit<
  ITabState['articlePricesByCustomers'][number],
  'specialPriceType'
> & {
  specialPriceTypeText?: string;
  specialPriceType?: C_Special_Price_Type | null;
};
