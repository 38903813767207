import { UseFormReturn } from 'react-hook-form';
import { Pub, State, Sub } from '../../../../../shared/state/state.abstract';
import { ITabState, tabStateModel } from '../../../states/tabState.model';
import {
  customerService,
  CustomerAvailabilityRes,
  SaveCustomerDataRes,
} from '../../../services/customer.service';
import { Observable, filter, map, merge, switchMap, take, tap } from 'rxjs';
import { responseHandler } from '../../../../../shared/responseHandler/responseHandler';
import { C_Save_Operation_Status } from '../../../../../graphql/generatedModel';
import { customersListState } from '../../../states/customersList.state';
import { customerTabLoadingService } from '../customerTabLoading.service';
import { defaultCustomerAvailabilityData } from '../../../loaders/availabilityTab.resolver';

class PubImpl extends Pub<IAvailabilityTabState> {
  form(form: IAvailabilityTabState['form']) {
    this.emit('form', { form });
  }
  save() {
    this.emit('save', {});
  }
}

class SubImpl extends Sub<IAvailabilityTabState> {
  protected actionHandlers(): Observable<IAvailabilityTabState> {
    return merge(this.selectedCustomer(), this.save()).pipe(
      filter(({ action }) => action !== 'form'),
      filter(({ action }) => action !== 'save'),
    );
  }

  private save(): Observable<IAvailabilityTabState> {
    return this.actionListener('save').pipe(
      tap(({ form }) => {
        if (form) {
          const {
            reset,
            formState: { isValid, dirtyFields },
          } = form;
          const isDirty = Object.keys(dirtyFields).length;
          if (isDirty && isValid) {
            customerService.sub
              .editCustomerData()
              .pipe(
                responseHandler<SaveCustomerDataRes | undefined>({
                  success: () => 'customer.customer_saved',
                  customErrorHandler: () => 'common.error_chnages_not_saved',
                }),
                filter((v) => v !== undefined),
                take(1),
              )
              .subscribe((res) => {
                if (res?.status === C_Save_Operation_Status.SOS1_DATA_CHANGED) {
                  const { updatedGridItem } = res;
                  customersListState.pub.updateCustomer(updatedGridItem!);
                }
              });

            const formValues = form.getValues() || {};
            const {
              id,
              customerAssortimentGroupsCheckedState,
              periods,
              periodsWithCustomWeekDays,
              vacations,
              weekDays,
            } = formValues;
            const isDeliveryPeriodsChanged =
              dirtyFields?.weekDays ||
              dirtyFields?.periods ||
              dirtyFields?.periodsWithCustomWeekDays ||
              dirtyFields?.vacations;

            customerService.pub.editCustomerData({
              data: {
                id,
                ...(dirtyFields?.customerAssortimentGroupsCheckedState && {
                  customerAssortimentGroupsCheckedState: customerAssortimentGroupsCheckedState?.map(
                    ({ id, checked, label }) => ({
                      id,
                      checked,
                      name: label,
                    }),
                  ),
                }),
                ...(isDeliveryPeriodsChanged && {
                  customerDeliveryPeriodsSettings: {
                    customerRegularDeliveryPeriods: periods?.map(({ id: _, ...v }) => v),
                    customerCustomWeekDaysAndDeliveryPeriods: periodsWithCustomWeekDays.map(
                      ({ period, weekDays, description }) => ({
                        description,
                        fromDate: period?.fromDate,
                        toDate: period?.toDate,
                        isRepeatYearly: period?.isRepeatYearly,
                        ...weekDays,
                      }),
                    ),
                    customerVacations: vacations?.map(({ id: _, ...v }) => v),
                  },
                  ...weekDays,
                }),
              },
            });
            reset(formValues);
          }
        }
      }),
    );
  }
  private selectedCustomer(): Observable<IAvailabilityTabState> {
    return customersListState.sub.state().pipe(
      filter(({ action }) => action === 'selectRecord'),
      filter(({ selectedRecord }) => typeof selectedRecord?.id === 'string'),
      switchMap(({ selectedRecord }) => {
        customerTabLoadingService.pub.loading(true);
        const id = selectedRecord!.id;
        const state = this.stream$.getValue();
        state.action = 'list.selectedRecord';
        return this.details(state, id);
      }),
    );
  }
  private details(state: IAvailabilityTabState, id: string): Observable<IAvailabilityTabState> {
    const details = customerService.sub.customerAvailabilityData().pipe(
      responseHandler<CustomerAvailabilityRes>({
        errorReturnType: defaultCustomerAvailabilityData,
      }),
      map((data) => {
        state.defaultValues = data;
        customerTabLoadingService.pub.loading(false);
        customersListState.pub.updateInfoTip(data.informationTip);
        return state;
      }),
    );
    customerService.pub.customerAvailabilityData({ id });
    return details;
  }
}

class AvailabilityTabState extends State<IAvailabilityTabState> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const availabilityTabState = new AvailabilityTabState({
  ...tabStateModel,
  action: undefined,
  form: undefined,
});

export interface IAvailabilityTabState extends Pick<ITabState, 'defaultValues'> {
  action: undefined | 'list.selectedRecord' | 'form' | 'save';
  form: undefined | UseFormReturn<CustomerAvailabilityRes>;
}
