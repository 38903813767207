import { gql } from 'graphql-request';

export const getPrintJobsProductionList = gql`
  query listWA_ProductionPrintJobs($filter: WA_ProductionPrintJobsGridFilter!) {
    wawiAssist {
      listWA_ProductionPrintJobs(filter: $filter) {
        id
        name
        isActive
        addDaysForPreProduction
        reportId
        reportGroupId
      }
    }
  }
`;

export const getPrintJobsOrdersCount = gql`
  query getPrintJobsOrdersCount($fromDate: Date!, $toDate: Date!) {
    wawiAssist {
      getWA_OrdersCountForPrintJobs(fromDate: $fromDate, toDate: $toDate)
    }
  }
`;

export const getPrintJobsPreProductionOrdersCount = gql`
  query getPrintJobsPreProductionOrdersCount($onDate: Date!) {
    wawiAssist {
      getWA_PreProductionOrdersCountForPrintJobs(onDate: $onDate)
    }
  }
`;

export const getPrintJobsProductionPrintDeliveryNotes = gql`
  query listWA_ProductionDeliveryNotes($fromDate: Date!, $toDate: Date!) {
    wawiAssist {
      listWA_ProductionDeliveryNotes(fromDate: $fromDate, toDate: $toDate) {
        id
        customerNo
        internalOrFullName
        customerDeliveryNoteGroup
        orderNo
        orderDate
        orderType
        isPrinted
        printedDateTime
      }
    }
  }
`;

export const getIncomingOrdersStatus = gql`
  query getIncomingOrdersAcceptanceStatus(
    $externalSourceKind: C_INCOMING_ORDERS_SOURCE!
    $onDate: Date!
  ) {
    wawiAssist {
      getWA_IncomingOrdersAcceptanceStatus(externalSourceKind: $externalSourceKind, onDate: $onDate)
    }
  }
`;

export const changeIncomingOrdersStatus = gql`
  mutation changeIncomingOrdersStatus(
    $externalSourceKind: C_INCOMING_ORDERS_SOURCE!
    $onDate: Date!
    $isAccepted: Boolean!
  ) {
    wawiAssist {
      saveIncomingOrdersAcceptanceStatus(
        externalSourceKind: $externalSourceKind
        onDate: $onDate
        isAccepted: $isAccepted
      )
    }
  }
`;

export const getProductionReportsVersions = gql`
  query GetProductionReportsVersions($onDate: Date!) {
    wawiAssist {
      listWA_ProductionReportsVersions(onDate: $onDate) {
        id
        versionNo
        createdDateTime
      }
    }
  }
`;

export const getProducedArticlesQuantity = gql`
  query GetProducedArticlesQuantity($onDate: Date!, $productionPrintListId: ID!) {
    wawiAssist {
      listWA_ProducedArticlesQuantity(
        onDate: $onDate
        productionPrintListId: $productionPrintListId
      ) {
        articleNo
        description
        producedQuantity
        recordId
      }
    }
  }
`;

export const doughSplittingInfoGridItem = gql`
  fragment DoughSplittingInfoGridItem on WA_DoughSplittingInfoGridItem {
    id
    roundTo
    doughNo
    doughName
    productionGroupId
    kilogramDoughRequired
    literDoughRequired
    isDoughSplitting
    resPart1Amount
    resPart1LtAmount
    resPart2Amount
    resPart2LtAmount
    resPart3Amount
    resPart3LtAmount
    resPart4Amount
    resPart4LtAmount
    resPart5Amount
    resPart5LtAmount
    diff
    diffLt
    isSplitted
    part1Def
    part2Def
    faktorLtKg
  }
`;

export const getDoughsSplittingInformation = gql`
  query GetDoughsSplittingInformation($onDate: Date!) {
    wawiAssist {
      listWA_DoughsSplittingInfoOnDate(onDate: $onDate) {
        ...DoughSplittingInfoGridItem
      }
    }
  }
  ${doughSplittingInfoGridItem}
`;

export const saveDoughSplittingInformation = gql`
  mutation SaveDoughSplittingInformation($dataToSave: WA_SaveDoughSplittingInfoOnDateInput!) {
    wawiAssist {
      saveWA_DoughSplittingInfoOnDate(dataToSave: $dataToSave) {
        status
        updatedGridItem {
          ...DoughSplittingInfoGridItem
        }
      }
    }
  }
  ${doughSplittingInfoGridItem}
`;

export const deleteDoughSplittingInformation = gql`
  mutation DeleteDoughSplittingInformation($id: ID!, $onDate: Date!) {
    wawiAssist {
      deleteWA_DoughSplittingInfoOnDate(id: $id, onDate: $onDate) {
        status
        updatedGridItem {
          ...DoughSplittingInfoGridItem
        }
      }
    }
  }
  ${doughSplittingInfoGridItem}
`;

export const saveProducedArticlesQuantity = gql`
  mutation SaveProducedArticlesQuantity($records: [WA_ProducedArticlesQuantityInput!]!) {
    wawiAssist {
      saveWA_ProducedArticlesQuantity(records: $records)
    }
  }
`;

export const createProductionReportsVersion = gql`
  mutation CreateProductionReportsVersion($onDate: Date!) {
    wawiAssist {
      createWA_ProductionReportsVersion(onDate: $onDate) {
        id
        versionNo
        createdDateTime
      }
    }
  }
`;

export const deleteProductionReportsVersion = gql`
  mutation DeleteProductionReportsVersion($id: ID!) {
    wawiAssist {
      deleteWA_ProductionReportsVersion(id: $id)
    }
  }
`;

export const r1FromVersion = gql`
  mutation create_r1_BakingList2ColumnsReportFromVersion(
    $params: WA_ProductionReportFromVersionInput!
  ) {
    wawiAssist {
      create_r1_BakingList2ColumnsReportFromVersion(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r14FromVersion = gql`
  mutation create_r14_BakingListWithRecipe2ColumnsReportFromVersion(
    $params: WA_ProductionReportFromVersionInput!
  ) {
    wawiAssist {
      create_r14_BakingListWithRecipe2ColumnsReportFromVersion(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r26FromVersion = gql`
  mutation create_r26_BakingListWithRecipeA4ReportFromVersion(
    $params: WA_ProductionReportFromVersionInput!
  ) {
    wawiAssist {
      create_r26_BakingListWithRecipeA4ReportFromVersion(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r27FromVersion = gql`
  mutation create_r27_BakingListWithRecipeA4ReportFromVersion(
    $params: WA_ProductionReportFromVersionInput!
  ) {
    wawiAssist {
      create_r27_BakingListWithRecipeA4ReportFromVersion(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r34FromVersion = gql`
  mutation create_r34_BakingListA4ReportFromVersion($params: WA_ProductionReportFromVersionInput!) {
    wawiAssist {
      create_r34_BakingListA4ReportFromVersion(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r41FromVersion = gql`
  mutation create_r41_BakingListA4DetailedReportFromVersion(
    $params: WA_ProductionReportFromVersionInput!
  ) {
    wawiAssist {
      create_r41_BakingListA4DetailedReportFromVersion(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r57FromVersion = gql`
  mutation create_r57_BakingListRawmaterials2ColumnsReportFromVersion(
    $params: WA_ProductionReportFromVersionInput!
  ) {
    wawiAssist {
      create_r57_BakingListRawmaterials2ColumnsReportFromVersion(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r58VersionsDifferenceWithRecipe = gql`
  mutation create_r58_BakingListVersionsDifferenceWithRecipe(
    $params: WA_ProductionReportDiffVersionsInput!
  ) {
    wawiAssist {
      create_r58_BakingListVersionsDifferenceWithRecipe(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r68VersionsDifference = gql`
  mutation create_r68_ForwardingListArticleCustomersVersionsDifference(
    $params: WA_ProductionReportDiffVersionsInput!
  ) {
    wawiAssist {
      create_r68_ForwardingListArticleCustomersVersionsDifference(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r56VersionsDifference = gql`
  mutation create_r56_BakingListVersionsDifference($params: WA_ProductionReportDiffVersionsInput!) {
    wawiAssist {
      create_r56_BakingListVersionsDifference(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;

export const r56VersionsDifferenceFromLiveData = gql`
  mutation create_r56_BakingListVersionsDifferenceFromLiveData(
    $params: WA_ProductionReportDiffOrderedAndProducedInput!
  ) {
    wawiAssist {
      create_r56_BakingListVersionsDifferenceFromLiveData(params: $params) {
        taskId
        url
        s3Key
      }
    }
  }
`;
