import { FC, useCallback, useMemo } from 'react';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component.tsx';
import { UseFormReturn } from 'react-hook-form';
import { ITabState } from '../../../../../../../states/tabState.model.ts';
import { useTranslation } from 'react-i18next';
import { TDefaultValues } from './specialPrices.popup.tsx';
import { format } from 'date-fns';
import { generalTabState } from '../../../../generalTab.state.ts';
import { Button, Stack } from '@mui/material';
import {
  FieldsRow,
  FormDatePicker,
  FormTextField,
} from '../../../../../../../../../shared/components/form';
import { resetTimeInDate } from '../../../../../../../../../shared/helpers/utils/utils.helper.ts';
import { Theme as theme } from '../../../../../../../../../shared/styles/theme/theme.ts';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup.tsx';

export const SpecialPricesForm: FC<ISpecialPricesForm> = ({
  field,
  disabledFromDate,
  formMethods,
  stream,
  createSpecialPriceCategoriesInputs,
}) => {
  const { t } = useTranslation();
  const { formState, trigger } = formMethods || {};
  const { errors, isDirty, dirtyFields, isValid } = formState;

  const handleClick = useCallback(() => {
    if (isDirty && isValid) {
      const values = formMethods.getValues() as TDefaultValues;
      if (values.fromDate) values.fromDate = format(values.fromDate, 'yyyy-MM-dd');
      if (values.toDate) values.toDate = format(values.toDate, 'yyyy-MM-dd');
      generalTabState.pub.updateSpecialPricesPos(values);
    }
    if (isValid) {
      stream.emit('close');
    } else {
      trigger('fromDate');
      trigger('price1');
    }
  }, [isDirty, dirtyFields, isValid]);

  // in Special prices table we need unique fromDate field in every row
  const shouldDisableDate = useCallback(
    (date: Date) => {
      return disabledFromDate?.some(
        (d) => resetTimeInDate(new Date(d))?.getTime() === resetTimeInDate(date)?.getTime(),
      );
    },
    [disabledFromDate],
  );

  const pricesInputs = useMemo(
    () => createSpecialPriceCategoriesInputs(field, errors),
    [field, Boolean(errors['price1'])],
  );

  const priceErrorMessage = errors['price1']
    ? t('article.at_least_one_price_category_should_be_not_empty')
    : '';

  useBeforeClosePopup({
    stream,
    isDirty: !!Object.keys(dirtyFields).length,
  });

  return (
    <>
      <Stack spacing={2}>
        <FieldsRow>
          <FieldsRow width='100%' justifyContent='space-between'>
            <FormDatePicker
              width='318px'
              returnEmpty
              fieldProps={{ label: t('common.from_date'), autoFocus: 'fromDate' === field }}
              navigation={false}
              name='fromDate'
              error={Boolean(errors['fromDate'])}
              helperText={errors['fromDate'] ? t(String(errors['fromDate'].message)) : ''}
              calendarProps={{ shouldDisableDate }}
            />
            <FormDatePicker
              width='318px'
              returnEmpty
              fieldProps={{ label: t('common.to_date'), autoFocus: 'toDate' === field }}
              navigation={false}
              name='toDate'
              error={Boolean(errors['fromDate'])}
              controllerProps={{
                rules: {
                  deps: ['fromDate'],
                },
              }}
            />
          </FieldsRow>
        </FieldsRow>
        <FieldsRow>{pricesInputs}</FieldsRow>
      </Stack>
      {priceErrorMessage && (
        <Stack
          mt='4px'
          color={theme.palette.error.main}
          sx={{
            fontSize: '12px',
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {priceErrorMessage}
        </Stack>
      )}
      <FieldsRow>
        <FormTextField
          inputProps={{ maxLength: 128 }}
          width='100%'
          label={t('common.note')}
          name='description'
          autoFocus={'description' === field}
        />
      </FieldsRow>

      <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
        <Button disabled={!!Object.keys(errors)?.length} variant='contained' onClick={handleClick}>
          {t('common.save')}
        </Button>
      </Stack>
    </>
  );
};

interface ISpecialPricesForm {
  createSpecialPriceCategoriesInputs: ITabState['createSpecialPriceCategoriesInputs'];
  disabledFromDate: Date[];
  field: string;
  stream: IPopupWrapper['stream'];
  formMethods: UseFormReturn<ITabState['articleSpecialPrices'][number]>;
}
