import { Chip, Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { TypeGroupTable } from '../groupTable.component';
import { GridColDef, GridRowGroupingModel } from '@mui/x-data-grid-premium';
import { storageHelper } from '../../../helpers/storage';

export const GroupButtons: FC<IGroupButtons> = (props) => {
  const {
    initButtonsSchema,
    columns,
    rowGroupingModel,
    setRowGroupingModel,
    groupLocalStorageKey,
    generateButtons,
    sxButtons,
    isActive,
    onGroup,
  } = props;

  const buttonsSchema = initButtonsSchema ?? columns;
  let buttons: JSX.Element[] = [];
  if (buttonsSchema?.length) {
    buttons = buttonsSchema.map(({ field, headerName }) => {
      const isActive = rowGroupingModel?.includes(field);
      const toggleMode = () => {
        setRowGroupingModel((prev) => {
          if (isActive) {
            const newGroupingModel = prev?.filter((fieldName) => fieldName !== field);
            storageHelper.local.setItem(groupLocalStorageKey, newGroupingModel);
            onGroup?.(newGroupingModel);
            return newGroupingModel;
          }
          const newGroupingModel = [...prev, field];
          storageHelper.local.setItem(groupLocalStorageKey, newGroupingModel);
          onGroup?.(newGroupingModel);
          return newGroupingModel;
        });
      };

      if (generateButtons) {
        return generateButtons({ label: headerName, isActive, onClick: toggleMode });
      }

      return (
        <Chip
          color={isActive ? 'primary' : 'default'}
          key={headerName}
          label={headerName}
          sx={{ ...sxButtons }}
          {...(!isActive && {
            onClick: toggleMode,
          })}
          {...(isActive && {
            onDelete: toggleMode,
          })}
        />
      );
    });
  }

  const isButtons = isActive && !!buttons?.length;

  return (
    <>
      {isButtons && (
        <Stack
          display='flex'
          flexDirection='row'
          gap={2}
          mb={2}
          sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
        >
          {buttons}
        </Stack>
      )}
    </>
  );
};

interface IGroupButtons {
  initButtonsSchema?: TypeGroupTable['initButtonsSchema'];
  columns: GridColDef[];
  rowGroupingModel: GridRowGroupingModel;
  setRowGroupingModel: Dispatch<SetStateAction<GridRowGroupingModel>>;
  groupLocalStorageKey: TypeGroupTable['groupLocalStorageKey'];
  generateButtons?: TypeGroupTable['generateButtons'];
  sxButtons?: TypeGroupTable['sxButtons'];
  isActive?: TypeGroupTable['isActive'];
  onGroup?: (activeGroup: string[]) => void;
}
