import {
  Observable,
  delay,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  repeat,
  switchMap,
  take,
  takeWhile,
  tap,
  zip,
  zipWith,
} from 'rxjs';
import {
  C_History_Pdf_Doctype,
  C_Win_Report_Task_Status,
  Create_R10_CustomersDebtNotifierMutation,
  Create_R10_CustomersDebtNotifierMutationVariables,
  Create_R24_PaymentJournalA4Mutation,
  Create_R24_PaymentJournalA4MutationVariables,
  Create_R35_ExtendedPaymentJournalMutation,
  Create_R35_ExtendedPaymentJournalMutationVariables,
  GetTasksStatusQuery,
  ListWa_PdfHistoryReportsQueryVariables,
  C_Report,
} from '../../../graphql/generatedModel';
import { Pub, Service, Sub } from '../../../shared/services/service.abstract';
import { gqlClient } from '../../../graphql/graphqlRequest';
import { r10, r24, r35 } from './gql/debtReports.gql';
import { getTasksStatus } from '../../production/services/gql/winReports.gql';
import {
  ListWAPdfHistoryReportRes,
  reportsService,
} from '../../../shared/services/reports/reports.service';
import { responseHandler } from '../../../shared/responseHandler/responseHandler';

class PubImpl extends Pub<Actions> {
  getR10NotifierFile(params: Create_R10_CustomersDebtNotifierMutationVariables) {
    this.emit('getR10NotifierFile', params);
  }
  getMultipleR10NotifierFiles(params: Array<Create_R10_CustomersDebtNotifierMutationVariables>) {
    this.emit('getMultipleR10NotifierFiles', params);
  }
  getR64NotifierFiles(params: IGetR64NotifierFilesParams) {
    this.emit('getR64NotifierFiles', params);
  }
  getMultipleR64NotifierFiles(params: IGetMultipleR64NotifierFilesParams) {
    this.emit('getMultipleR64NotifierFiles', params);
  }
  getR24PaymentJournal(params: Create_R24_PaymentJournalA4MutationVariables) {
    this.emit('getR24PaymentJournal', params);
  }
  getR35ExtendedPaymentJournal(params: Create_R35_ExtendedPaymentJournalMutationVariables) {
    this.emit('getR35ExtendedPaymentJournal', params);
  }
  clearStream() {
    this.emit(undefined, {});
  }
}

class SubImpl extends Sub<Actions> {
  getR10NotifierFile(): Observable<IGetR10NotifierFileRes> {
    return this.actionListener('getR10NotifierFile').pipe(
      this._queryR10Notifier(),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getMultipleR10NotifierFiles(): Observable<IGetMultipleR10NotifierFilesRes> {
    return this.actionListener('getMultipleR10NotifierFiles').pipe(
      switchMap(({ params }) => {
        const requests: Array<Observable<GetR10NotifierRequestRes & { customerId: string }>> =
          params.map((gqlVariables: Create_R10_CustomersDebtNotifierMutationVariables) =>
            gqlClient(r10, gqlVariables).pipe(
              map(
                (res: Create_R10_CustomersDebtNotifierMutation) =>
                  res.wawiAssist?.create_r10_CustomersDebtNotifier,
              ),
              map((res) => ({ ...res, customerId: gqlVariables.params.customerId })),
            ),
          );
        return forkJoin(requests);
      }),
      delay(5000),
      switchMap((res) => {
        const tasksId = res.map((item) => item.taskId);

        return this._taskStatusPolling({ tasksId, maxPollingAttempts: 20 }).pipe(
          map((v) => {
            const items = v.map((item) => {
              const isReady: boolean = item.status === C_Win_Report_Task_Status.WRTS2_READY;
              const generateResItem = res.find((it) => it.taskId === item.taskId);
              if (!isReady || !generateResItem) {
                throw new Error();
              }
              return generateResItem;
            });

            return items;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getR64NotifierFiles(): Observable<IGetR64NotifierFilesRes> {
    return this.actionListener('getR64NotifierFiles').pipe(
      map((v) => {
        const { r10NotifierParams, listPdfHistoryReportParams } =
          v.params as IGetR64NotifierFilesParams;
        reportsService.pub.listPdfHistoryReports(listPdfHistoryReportParams);
        v.params = r10NotifierParams;
        return v;
      }),
      this._queryR10Notifier(),
      zipWith(
        reportsService.sub
          .waitAndListPdfHistoryReports()
          .pipe(responseHandler<ListWAPdfHistoryReportRes>({ errorReturnType: { reports: [] } })),
      ),
      map((v) => {
        const notifierPdfUrl = v?.[0]?.url!;
        const notifierPdfS3Key = v?.[0]?.s3Key;
        const invoicesPdfs = v?.[1]?.reports || [];
        if (!notifierPdfS3Key || !invoicesPdfs.length) {
          throw new Error();
        }
        const resInvoices = invoicesPdfs.filter(
          (invoiceReport) =>
            invoiceReport.reportId !== C_Report.R17_INVOICE_SUMMARY &&
            invoiceReport.reportId !== C_Report.R52_DELIVERY_OVERVIEW,
        );

        return {
          notifierPdfUrl,
          notifierPdfS3Key,
          invoicesPdfs: resInvoices,
        };
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getMultipleR64NotifierFiles(): Observable<IGetMultipleR64NotifierFilesRes> {
    return this.actionListener('getMultipleR64NotifierFiles').pipe(
      tap((v) => {
        const params: IGetMultipleR64NotifierFilesParams = v.params;
        const invoicesId = Object.values(params).reduce<Array<string>>((outArr, item) => {
          return [...outArr, ...item.invoiceIds];
        }, []);
        reportsService.pub.listPdfHistoryReports({
          params: {
            docsType: C_History_Pdf_Doctype.PDF_INVOICE,
            documentsIds: invoicesId,
          },
        });
      }),
      switchMap((v) => {
        const params = v.params as IGetMultipleR64NotifierFilesParams;
        const r10Requests = Object.values(params).map(({ r10NotifierParams }) =>
          gqlClient(r10, r10NotifierParams).pipe(
            map(
              (res: Create_R10_CustomersDebtNotifierMutation) =>
                res.wawiAssist?.create_r10_CustomersDebtNotifier as GetR10NotifierRequestRes,
            ),
            map((res) => ({ ...res, customerId: r10NotifierParams.params.customerId })),
          ),
        );
        return zip(
          forkJoin(r10Requests),
          reportsService.sub
            .waitAndListPdfHistoryReports()
            .pipe(responseHandler<ListWAPdfHistoryReportRes>({ errorReturnType: { reports: [] } })),
          of(params),
        );
      }),
      delay(5000),
      switchMap((res) => {
        const [notifiersRes, invoicesRes, params] = res || [];
        const tasksId = notifiersRes?.map((item) => item.taskId);

        return this._taskStatusPolling({ tasksId, maxPollingAttempts: 20 }).pipe(
          map((v) => {
            const items = v.map((item) => {
              const isReady: boolean = item.status === C_Win_Report_Task_Status.WRTS2_READY;
              const generateResItem = res?.[0]?.find((it) => it.taskId === item.taskId);

              if (!isReady || !generateResItem) {
                throw new Error();
              }
              const { url, s3Key, customerId } = generateResItem || {};
              const customerInvoicesId = params[customerId]?.invoiceIds || [];
              const invoicesPdfs =
                invoicesRes.reports.reduce(
                  (outArr: IGetR64NotifierFilesRes['invoicesPdfs'], invoiceReport) => {
                    const isInvoiceOfCustomer = !!customerInvoicesId.find(
                      (invoiceId) => invoiceId === invoiceReport.documentId,
                    );
                    const isInvoiceSummaryReport =
                      invoiceReport.reportId === C_Report.R17_INVOICE_SUMMARY ||
                      invoiceReport.reportId === C_Report.R52_DELIVERY_OVERVIEW;
                    if (isInvoiceOfCustomer && !isInvoiceSummaryReport) {
                      outArr.push(invoiceReport);
                    }
                    return outArr;
                  },
                  [],
                ) || [];

              return {
                notifierPdfUrl: url,
                notifierPdfS3Key: s3Key,
                invoicesPdfs,
                customerId,
              };
            });

            return items;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getR24PaymentJournal(): Observable<IGetR24PaymentJournalRes> {
    return this.actionListener('getR24PaymentJournal').pipe(
      switchMap(
        ({ params }) => gqlClient(r24, params) as Observable<Create_R24_PaymentJournalA4Mutation>,
      ),
      delay(2000),
      switchMap((res) => {
        const resData = res.wawiAssist?.create_r24_PaymentJournalA4;
        const { taskId } = resData || {};
        const nonSuccessRes = {
          taskId,
          url: null,
          s3Key: null,
        };
        if (!taskId) {
          return of(nonSuccessRes);
        }
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((v) => {
            if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
              return nonSuccessRes;
            }
            return resData as IGetR24PaymentJournalRes;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  getR35ExtendedPaymentJournal(): Observable<IGetR35ExtendedPaymentJournalRes> {
    return this.actionListener('getR35ExtendedPaymentJournal').pipe(
      switchMap(
        ({ params }) =>
          gqlClient(r35, params) as Observable<Create_R35_ExtendedPaymentJournalMutation>,
      ),
      delay(2000),
      switchMap((res) => {
        const resData = res.wawiAssist?.create_r35_ExtendedPaymentJournal;
        const { taskId } = resData || {};
        const nonSuccessRes = {
          taskId,
          url: null,
          s3Key: null,
        };
        if (!taskId) {
          return of(nonSuccessRes);
        }
        return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
          map((v) => {
            if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
              return nonSuccessRes;
            }
            return resData as IGetR35ExtendedPaymentJournalRes;
          }),
        );
      }),
      finalize(() => debtReports.pub.clearStream()),
    );
  }

  private _queryR10Notifier() {
    return (stream: Observable<any>): Observable<IGetR10NotifierFileRes> =>
      stream.pipe(
        switchMap(
          ({ params }) =>
            gqlClient(r10, params) as Observable<Create_R10_CustomersDebtNotifierMutation>,
        ),
        delay(2000),
        switchMap((res) => {
          const resData = res.wawiAssist
            ?.create_r10_CustomersDebtNotifier as IGetR10NotifierFileRes;
          const { taskId } = resData || {};
          const nonSuccessRes = {
            taskId,
            url: null,
            s3Key: null,
          };
          if (!taskId) {
            return of(nonSuccessRes);
          }
          return this._taskStatusPolling({ tasksId: [taskId] }).pipe(
            map((v) => {
              if (v?.[0].status !== C_Win_Report_Task_Status.WRTS2_READY) {
                return nonSuccessRes;
              }
              return resData;
            }),
          );
        }),
      );
  }

  private _taskStatusPolling({
    tasksId,
    maxPollingAttempts = 10,
  }: {
    tasksId: Array<number>;
    maxPollingAttempts?: number;
  }): Observable<GetWinReportsStatusRes> {
    return (gqlClient(getTasksStatus, { tasksId }) as Observable<GetTasksStatusQuery>).pipe(
      repeat({ delay: 2000 }),
      map((v) => v.wawiAssist?.getWinReportTasksStatuses as GetWinReportsStatusRes),
      takeWhile((v) => {
        return (
          v?.every(
            (item) =>
              item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
              item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
          ),
          true
        );
      }),
      filter((v, ind) => {
        const isInProcess = v?.some(
          (item) =>
            item.status === C_Win_Report_Task_Status.WRTS5_IN_PROGRESS ||
            item.status === C_Win_Report_Task_Status.WRTS1_NOT_STARTED,
        );
        const maxAttemptsReached = ind + 1 > maxPollingAttempts;
        if (!isInProcess) {
          return true;
        }
        // Limit polling by maximum number of attempts
        return maxAttemptsReached;
      }),
      take(1),
    );
  }
}

class DebtReports extends Service<Actions> {
  pub = new PubImpl(this.stream$);
  sub = new SubImpl(this.stream$);
}

export const debtReports = new DebtReports();

type Actions =
  | 'getR10NotifierFile'
  | 'getMultipleR10NotifierFiles'
  | 'getR64NotifierFiles'
  | 'getMultipleR64NotifierFiles'
  | 'getR24PaymentJournal'
  | 'getR35ExtendedPaymentJournal'
  | undefined;

type GetR10NotifierRequestRes = NonNullable<
  NonNullable<
    Create_R10_CustomersDebtNotifierMutation['wawiAssist']
  >['create_r10_CustomersDebtNotifier']
>;
export interface IGetR10NotifierFileRes extends Omit<GetR10NotifierRequestRes, 'url' | 's3Key'> {
  url: string | null;
  s3Key: string | null;
}
export type IGetMultipleR10NotifierFilesRes = Array<
  { customerId: string } & IGetR10NotifierFileRes
>;
type GetWinReportsStatusRes = NonNullable<
  NonNullable<GetTasksStatusQuery['wawiAssist']>['getWinReportTasksStatuses']
>;
interface IGetR64NotifierFilesParams {
  r10NotifierParams: Create_R10_CustomersDebtNotifierMutationVariables;
  listPdfHistoryReportParams: ListWa_PdfHistoryReportsQueryVariables;
}
export interface IGetR64NotifierFilesRes {
  notifierPdfUrl: string;
  notifierPdfS3Key: string;
  invoicesPdfs: ListWAPdfHistoryReportRes['reports'];
}
export interface IGetMultipleR64NotifierFilesParams {
  [customerId: string]: {
    r10NotifierParams: Create_R10_CustomersDebtNotifierMutationVariables;
    invoiceIds: Array<string>;
  };
}
export type IGetMultipleR64NotifierFilesRes = Array<
  { customerId: string } & IGetR64NotifierFilesRes
>;

export interface IGetR24PaymentJournalRes
  extends Omit<Create_R24_PaymentJournalA4Mutation, 'url' | 's3Key'> {
  url: string | null;
  s3Key: string | null;
}
export interface IGetR35ExtendedPaymentJournalRes
  extends Omit<Create_R35_ExtendedPaymentJournalMutation, 'url' | 's3Key'> {
  url: string | null;
  s3Key: string | null;
}
