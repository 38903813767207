import { LocalizationHelper } from '../../shared/helpers/localization/localization.helper';

type Keys =
  | 'print_jobs'
  | 'production'
  | 'print_delivery_notes'
  | 'print_list'
  | 'print_lists'
  | 'orders'
  | 'pre_production_orders'
  | 'all_print_lists'
  | 'active_print_lists'
  | 'inactive_print_lists'
  | 'edit_print_list'
  | 'forwarding_list'
  | 'cross_table_report'
  | 'labels'
  | 'dough_list'
  | 'orders_over_bakery2b'
  | 'orders_over_cashassist'
  | 'report_data_was_exported_to_LabelAssist'
  | 'report_data_was_exported_to_SmartScale'
  | 'activated'
  | 'order_type'
  | 'delivery_note_group'
  | 'printed'
  | 'date_time'
  | 'print_list_not_loaded'
  | 'deleting_print_list'
  | 'print_list_successfully_deleted'
  | 'delete_print_list_confirmation'
  | 'tab_not_loaded'
  | 'set_up'
  | 'production_report_type'
  | 'labels_labelAssist'
  | 'dough_list_smartScale'
  | 'form_type'
  | 'form'
  | 'partial_delivery'
  | 'show_production_description'
  | 'automatic_mode_of_cross_table_report'
  | 'show_individual_texts'
  | 'consider_only_order_positions_with_individual_text'
  | 'display_linked_articles_with_indentation'
  | 'do_default_dough_splitting'
  | 'display_delivery_splitting_parts'
  | 'sorting_type'
  | 'pre_production_in_days'
  | 'show_semi_products_details'
  | 'tour'
  | 'tour_type'
  | 'need_signature'
  | 'standard_tour'
  | 'sat_tour'
  | 'sun_tour'
  | 'quantity_beginning_of_the_day'
  | 'print_all_delivery_notes_of_this_tour'
  | 'customer_sorting'
  | 'article_sorting'
  | 'print_list_settings'
  | 'additional_settings'
  | 'print_list_availability'
  | 'filters_setup'
  | 'edit_print_list_saved'
  | 'edit_print_list_not_saved'
  | 'request_error_message'
  | 'try_preview_again'
  | 'try_again'
  | 'cross_table_setup'
  | 'cross_table_config_edit'
  | 'tour_list_setup'
  | 'tour_list_config_edit'
  | 'article_list_filter'
  | 'customer_list_filter'
  | 'create_print_list'
  | 'copy_print_list'
  | 'no_data_no_preview'
  | 'stock_of_freezer'
  | 'article'
  | 'article_other'
  | 'legend_over_stock'
  | 'legend_normal_stock'
  | 'legend_under_stock'
  | 'active'
  | 'type_of_goods'
  | 'storage_locations'
  | 'stock_min_qty'
  | 'stock_max_qty'
  | 'stock_actual_qty'
  | 'stock_qty'
  | 'set_quantity'
  | 'stock_of_freezer_saved'
  | 'stock_of_freezer_saved_not_saved'
  | 'group_name'
  | 'split_no'
  | 'customer'
  | 'customer_list'
  | 'customer_or_customer_list'
  | 'copy_settings_from'
  | 'copy'
  | 'filter_by'
  | 'manage_versions'
  | 'display_selected_version'
  | 'compare_selected_versions'
  | 'product_no'
  | 'products'
  | 'manage_quantity_of_produced_articles'
  | 'charge_splitting'
  | 'dough_splitting'
  | 'splitted'
  | 'diff'
  | 'can_not_edit_splitting_parts_in_liters'
  | 'split_in_report'
  | 'part_n_m'
  | 'difference'
  | 'dough_splitting_information_was_saved'
  | 'for_dough_splitting_you_need_to_have_more_then_one_not_empty_splitting_parts';

const t = LocalizationHelper<Keys>('production');

export const productionDe = t({
  print_jobs: 'Druckaufträge',
  production: 'Produktion',
  print_delivery_notes: 'Lieferscheine drucken',
  print_list: 'Druckliste',
  print_lists: 'Drucklisten',
  orders: 'Bestellungen',
  quantity_beginning_of_the_day: 'Menge (am Anfang des Tages)',
  legend_over_stock: 'Maximum überschritten',
  legend_normal_stock: 'Bestand normal',
  legend_under_stock: 'Minimum unterschritten',
  pre_production_orders: 'Bestellungen für Vorproduktion',
  all_print_lists: 'Alle Drucklisten',
  active_print_lists: 'Aktive Drucklisten',
  inactive_print_lists: 'Inaktive Drucklisten',
  edit_print_list: 'Drucklisten bearbeiten',
  stock_of_freezer: 'TK-Bestand',
  forwarding_list: 'Versand Spediliste',
  cross_table_report: 'Versand Cross Bericht',
  labels: 'Etiketten (LabelAssist)',
  dough_list: 'Teiglisten (smartScale)',
  orders_over_bakery2b: 'Bestellungen über bakery2b.com',
  orders_over_cashassist: 'Bestellungen über CashAssist (DirectAccess)',
  report_data_was_exported_to_LabelAssist: 'Report-Daten wurden exportiert LabelAssist',
  report_data_was_exported_to_SmartScale: 'Report-Daten wurden exportiert Smartscale',
  activated: 'Aktiviert',
  order_type: 'Bestelltyp',
  article_list_filter: 'Artikel Filter',
  customer_list_filter: 'Kunden Filter',
  delivery_note_group: 'Lieferscheingruppe',
  printed: 'Gedruckt',
  date_time: 'Datum/Zeit',
  print_list_not_loaded: 'Druckliste nicht geladen',
  deleting_print_list: 'Druckliste wird gelöscht...',
  print_list_successfully_deleted: 'Druckliste erfolgreich gelöscht',
  delete_print_list_confirmation: 'Sind Sie sicher, dass Sie die Druckliste löschen möchten?',
  tab_not_loaded: 'Daten für Tab nicht geladen!',
  set_up: 'Aufstellen',
  labels_labelAssist: 'Etiketten (LabelAssist)',
  dough_list_smartScale: 'Teiglisten (smartScale)',
  production_report_type: 'Art des Produktionsberichts',
  form_type: 'Formulartyp',
  form: 'Formular',
  partial_delivery: 'Anfahrt',
  show_production_description: 'Herstellungsbeschrieb anzeigen',
  automatic_mode_of_cross_table_report: 'Automatikbetrieb des Kreuztabellenberichts',
  show_individual_texts: 'Individueller Text anzeigen',
  consider_only_order_positions_with_individual_text: 'Nur Artikel mit individuellen Text',
  display_linked_articles_with_indentation: 'Verknüpfte Artikel mit Einzug (->) darstellen',
  do_default_dough_splitting: 'Automatisches Teigsplitting',
  display_delivery_splitting_parts: 'Teillieferungen anzeigen',
  sorting_type: 'Sortiertyp',
  pre_production_in_days: 'Vorproduktion (Tage)',
  show_semi_products_details: 'Halbfabrikate detailiert zeigen',
  tour: 'Tour',
  tour_type: 'Tourtyp',
  need_signature: 'Mit Unterschrift',
  standard_tour: 'Standard Tour',
  print_all_delivery_notes_of_this_tour: 'Alle Lieferschein dieses Tour ausdrucken',
  customer_sorting: 'Sortierung Kunde',
  sat_tour: 'Sa. Tour',
  sun_tour: 'So. Tour',
  article_sorting: 'Sortierung Artikel',
  print_list_settings: 'Druckliste Einstellungen',
  additional_settings: 'Zusätzliche Einstellungen',
  print_list_availability: 'Druckliste Verfügbarkeit',
  filters_setup: 'Filterkonfiguration',
  edit_print_list_saved: 'Druckliste bearbeiten gespeichert',
  edit_print_list_not_saved: 'Druckliste bearbeiten nicht gespeichert',
  request_error_message: 'Leider ist bei der Bearbeitung Ihrer Anfrage ein Fehler aufgetreten.',
  try_preview_again: 'Bitte versuchen Sie erneut, eine Vorschau des Dokuments anzeigen.',
  try_again: 'Versuchen Sie es erneut',
  cross_table_setup: 'Kreuztabelle Einstellung',
  cross_table_config_edit: 'Kreuztabellen-Konfiguration bearbeiten',
  tour_list_setup: 'Tourenliste Einstellung',
  tour_list_config_edit: 'Tourenlisten-Konfiguration bearbeiten',
  create_print_list: 'Druckliste erstellen',
  copy_print_list: 'Druckliste kopieren',
  no_data_no_preview: 'Vorschau ist nicht verfügbar. Keine Daten für den Bericht.',
  article: 'Artikel',
  article_other: 'Artikel',
  active: 'Aktiv',
  type_of_goods: 'Warentyp',
  storage_locations: 'Lagerorte',
  stock_min_qty: 'Min. Bestand',
  stock_max_qty: 'Max. Bestand',
  stock_actual_qty: 'Aktueller Bestand',
  stock_qty: 'Bestand',
  set_quantity: 'Menge einstellen',
  stock_of_freezer_saved: 'TK-Bestand bearbeiten gespeichert',
  stock_of_freezer_saved_not_saved: 'TK-Bestand bearbeiten nicht gespeichert',
  group_name: 'Gruppenname',
  split_no: 'Split-Nr.',
  customer: 'Kunde',
  customer_list: 'Kundenliste',
  customer_or_customer_list: 'Kunde / Kundenliste',
  copy_settings_from: 'Einstellungen kopieren von',
  copy: 'Kopieren',
  filter_by: 'Filtern nach',
  manage_versions: 'Versionen verwalten',
  display_selected_version: 'Ausgewählte Version anzeigen',
  compare_selected_versions: 'Zwei Versionen vergleichen (Backzettel mit Differenz anzeigen)',
  product_no: 'Produkt-Nr.',
  products: 'Produkte',
  manage_quantity_of_produced_articles: 'Menge der produzierten Artikel verwalten',
  charge_splitting: 'Charge splitting',
  dough_splitting: 'Teig Splitting',
  splitted: 'Splitted',
  diff: 'Diff',
  can_not_edit_splitting_parts_in_liters:
    'Sie können den Teig nicht in Liter splitten. Das Feld "Literanzahl Teig (g)" ist leer.',
  split_in_report: 'Splitting auf Produktionsliste',
  part_n_m: 'Teil {{number}} ({{measurement}})',
  difference: 'Differenz',
  dough_splitting_information_was_saved: 'Die Informationen zur Teigteilung wurden gespeichert',
  for_dough_splitting_you_need_to_have_more_then_one_not_empty_splitting_parts:
    'Für das Teig-Splitting geben Sie bitte mehr als einen Splitting-Teil an',
});

export const productionFr = t({
  print_jobs: 'Imprimer les tâches',
  production: 'Production',
  print_delivery_notes: 'Imprimer les bons de livraison',
  print_list: 'Imprimer la liste',
  print_lists: 'Imprimer les listes',
  orders: 'Commandes',
  quantity_beginning_of_the_day: 'Quantité (début de la journée)',
  legend_over_stock: 'Surstock',
  legend_normal_stock: 'Stock normal',
  legend_under_stock: 'Sous-stock',
  pre_production_orders: 'Commandes pour la pré-production',
  all_print_lists: 'Toutes les listes d’impression',
  active_print_lists: 'Listes d’impression actives',
  inactive_print_lists: 'Listes d’impression inactives',
  edit_print_list: 'Modifier la liste d’impression',
  stock_of_freezer: 'Stock du congélateur',
  forwarding_list: 'Liste de distribution',
  cross_table_report: 'Rapport croisé d’expédition',
  labels: 'Étiquettes (LabelAssist)',
  dough_list: 'Liste des pâtes (smartScale)',
  orders_over_bakery2b: 'Commandes via bakery2b.com',
  orders_over_cashassist: 'Commandes via CashAssist (DirectAccess)',
  report_data_was_exported_to_LabelAssist:
    'Les données du rapport ont été exportées vers LabelAssist',
  report_data_was_exported_to_SmartScale:
    'Les données du rapport ont été exportées vers smartScale',
  activated: 'Activé',
  order_type: 'Type de commande',
  article_list_filter: 'Filtre des articles',
  customer_list_filter: 'Filtre des clients',
  delivery_note_group: 'Groupe de bons de livraison',
  printed: 'Imprimé',
  date_time: 'Date/Heure',
  print_list_not_loaded: 'Liste d’impression non chargée',
  deleting_print_list: 'Suppression de la liste d’impression...',
  print_list_successfully_deleted: 'Liste d’impression supprimée avec succès',
  delete_print_list_confirmation: 'Êtes-vous sûr de vouloir supprimer la liste d’impression ?',
  tab_not_loaded: 'Données pour l’onglet non chargées !',
  set_up: 'Configurer',
  labels_labelAssist: 'Étiquettes (LabelAssist)',
  dough_list_smartScale: 'Liste des pâtes (smartScale)',
  production_report_type: 'Type de rapport de production',
  form_type: 'Type de formulaire',
  form: 'Formulaire',
  partial_delivery: 'Livraison partielle',
  show_production_description: 'Afficher la description de production',
  automatic_mode_of_cross_table_report: 'Mode automatique du rapport croisé',
  show_individual_texts: 'Afficher les descriptions individuelles',
  consider_only_order_positions_with_individual_text:
    'Uniquement les articles avec une description individuelle',
  display_linked_articles_with_indentation: 'Afficher l’indentation (->) pour les articles liés',
  do_default_dough_splitting: 'Effectuer une répartition par défaut des pâtes',
  display_delivery_splitting_parts: 'Afficher les parties de répartition de livraison',
  sorting_type: 'Type de tri',
  pre_production_in_days: 'Pré-production (jours)',
  show_semi_products_details: 'Afficher les détails des semi-produits',
  tour: 'Tournée',
  tour_type: 'Type de tournée',
  need_signature: 'Signature requise',
  standard_tour: 'Tournée standard',
  print_all_delivery_notes_of_this_tour: 'Imprimer tous les bons de livraison de cette tournée',
  customer_sorting: 'Tri des clients',
  sat_tour: 'Tournée du samedi',
  sun_tour: 'Tournée du dimanche',
  article_sorting: 'Tri des articles',
  print_list_settings: 'Paramètres de la liste d’impression',
  additional_settings: 'Paramètres supplémentaires',
  print_list_availability: 'Disponibilité de la liste d’impression',
  filters_setup: 'Configuration des filtres',
  edit_print_list_saved: 'Modification de la liste d’impression enregistrée',
  edit_print_list_not_saved: 'Modification de la liste d’impression non enregistrée',
  request_error_message:
    'Désolé, nous avons rencontré une erreur lors du traitement de votre demande.',
  try_preview_again: 'Veuillez réessayer d’apercevoir le document.',
  try_again: 'Réessayer',
  cross_table_setup: 'Configuration du tableau croisé',
  cross_table_config_edit: 'Modifier la configuration du tableau croisé',
  tour_list_setup: 'Configuration de la liste des tournées',
  tour_list_config_edit: 'Modifier la configuration de la liste des tournées',
  create_print_list: 'Créer une liste d’impression',
  copy_print_list: 'Copier la liste d’impression',
  no_data_no_preview: 'Aperçu non disponible. Pas de données pour le rapport.',
  article: 'Article',
  article_other: 'Articles',
  active: 'Actif',
  type_of_goods: 'Type de marchandises',
  storage_locations: 'Emplacements de stockage',
  stock_min_qty: 'Quantité minimale en stock',
  stock_max_qty: 'Quantité maximale en stock',
  stock_actual_qty: 'Quantité actuelle en stock',
  stock_qty: 'Quantité en stock',
  set_quantity: 'Définir la quantité',
  stock_of_freezer_saved: 'Stock du congélateur enregistré',
  stock_of_freezer_saved_not_saved: 'Stock du congélateur non enregistré',
  group_name: 'Nom du groupe',
  split_no: 'Numéro de fractionnement',
  customer: 'Client',
  customer_list: 'Liste des clients',
  customer_or_customer_list: 'Client / Liste des clients',
  copy_settings_from: 'Copier les paramètres de',
  copy: 'Copier',
  filter_by: 'Filtrer par',
  manage_versions: 'Gérer les versions',
  display_selected_version: 'Afficher la version sélectionnée',
  compare_selected_versions: 'Comparer deux versions (afficher la liste avec les différences)',
  product_no: 'N° de produit',
  products: 'Produits',
  manage_quantity_of_produced_articles: 'Gérer la quantité d’articles produits',
  charge_splitting: 'Répartition des charges',
  dough_splitting: 'Spliiting de pâte',
  splitted: 'Frazionata',
  diff: 'Diff',
  can_not_edit_splitting_parts_in_liters:
    'Vous ne pouvez pas modifier les pièces fractionnement en litres. Champ "Pate litrs" est vide pour cette pâte.',
  split_in_report: 'Splitting sur la liste de production',
  part_n_m: 'Partie {{number}} ({{measurement}})',
  difference: 'Différence',
  dough_splitting_information_was_saved:
    'Les informations sur le découpage de la pâte ont été enregistrées',
  for_dough_splitting_you_need_to_have_more_then_one_not_empty_splitting_parts:
    'Pour le fractionnement de la pâte vous devez avoir plus d’une pièce fractionnée plaine',
});

export const productionIt = t({
  print_jobs: 'Stampa lavori',
  production: 'Produzione',
  print_delivery_notes: 'Stampa note di consegna',
  print_list: 'Stampa elenco',
  print_lists: 'Stampa elenchi',
  orders: 'Ordini',
  quantity_beginning_of_the_day: 'Quantità (inizio giornata)',
  legend_over_stock: 'Sovra stock',
  legend_normal_stock: 'Stock normale',
  legend_under_stock: 'Sotto stock',
  pre_production_orders: 'Ordini per pre-produzione',
  all_print_lists: 'Tutti gli elenchi di stampa',
  active_print_lists: 'Elenchi di stampa attivi',
  inactive_print_lists: 'Elenchi di stampa inattivi',
  edit_print_list: 'Modifica elenco di stampa',
  stock_of_freezer: 'Stock del congelatore',
  forwarding_list: 'Elenco spedizioni',
  cross_table_report: 'Rapporto incrociato spedizioni',
  labels: 'Etichette (LabelAssist)',
  dough_list: 'Elenco impasti (smartScale)',
  orders_over_bakery2b: 'Ordini tramite bakery2b.com',
  orders_over_cashassist: 'Ordini tramite CashAssist (DirectAccess)',
  report_data_was_exported_to_LabelAssist: 'Dati del rapporto esportati in LabelAssist',
  report_data_was_exported_to_SmartScale: 'Dati del rapporto esportati in smartScale',
  activated: 'Attivato',
  order_type: 'Tipo di ordine',
  article_list_filter: 'Filtro articoli',
  customer_list_filter: 'Filtro clienti',
  delivery_note_group: 'Gruppo note di consegna',
  printed: 'Stampato',
  date_time: 'Data/Ora',
  print_list_not_loaded: 'Elenco di stampa non caricato',
  deleting_print_list: 'Eliminazione elenco di stampa in corso...',
  print_list_successfully_deleted: 'Elenco di stampa eliminato con successo',
  delete_print_list_confirmation: "Sei sicuro di voler eliminare l'elenco di stampa?",
  tab_not_loaded: 'Dati per la scheda non caricati!',
  set_up: 'Configurazione',
  labels_labelAssist: 'Etichette (LabelAssist)',
  dough_list_smartScale: 'Elenco impasti (smartScale)',
  production_report_type: 'Tipo di rapporto di produzione',
  form_type: 'Tipo di modulo',
  form: 'Modulo',
  partial_delivery: 'Consegna parziale',
  show_production_description: 'Mostra descrizione della produzione',
  automatic_mode_of_cross_table_report: 'Modalità automatica del rapporto incrociato',
  show_individual_texts: 'Mostra descrizioni individuali',
  consider_only_order_positions_with_individual_text: 'Solo articoli con descrizione individuale',
  display_linked_articles_with_indentation: 'Visualizza articoli collegati con rientro (->)',
  do_default_dough_splitting: 'Effettua divisione impasto predefinita',
  display_delivery_splitting_parts: 'Mostra parti di divisione consegna',
  sorting_type: 'Tipo di ordinamento',
  pre_production_in_days: 'Pre-produzione (giorni)',
  show_semi_products_details: 'Mostra dettagli dei semilavorati',
  tour: 'Giro',
  tour_type: 'Tipo di giro',
  need_signature: 'Richiede firma',
  standard_tour: 'Giro standard',
  print_all_delivery_notes_of_this_tour: 'Stampa tutte le note di consegna di questo giro',
  customer_sorting: 'Ordinamento clienti',
  sat_tour: 'Giro di sabato',
  sun_tour: 'Giro di domenica',
  article_sorting: 'Ordinamento articoli',
  print_list_settings: 'Impostazioni elenco di stampa',
  additional_settings: 'Impostazioni aggiuntive',
  print_list_availability: 'Disponibilità elenco di stampa',
  filters_setup: 'Impostazione filtri',
  edit_print_list_saved: 'Modifica elenco di stampa salvata',
  edit_print_list_not_saved: 'Modifica elenco di stampa non salvata',
  request_error_message:
    "Ci dispiace, si è verificato un errore durante l'elaborazione della tua richiesta.",
  try_preview_again: 'Per favore prova a visualizzare nuovamente il documento.',
  try_again: 'Riprova',
  cross_table_setup: 'Impostazione rapporto incrociato',
  cross_table_config_edit: 'Modifica configurazione rapporto incrociato',
  tour_list_setup: 'Impostazione elenco giri',
  tour_list_config_edit: 'Modifica configurazione elenco giri',
  create_print_list: 'Crea elenco di stampa',
  copy_print_list: 'Copia elenco di stampa',
  no_data_no_preview: 'Anteprima non disponibile. Nessun dato per il rapporto.',
  article: 'Articolo',
  article_other: 'Articoli',
  active: 'Attivo',
  type_of_goods: 'Tipo di beni',
  storage_locations: 'Posizioni di stoccaggio',
  stock_min_qty: 'Quantità minima di stock',
  stock_max_qty: 'Quantità massima di stock',
  stock_actual_qty: 'Quantità attuale di stock',
  stock_qty: 'Quantità di stock',
  set_quantity: 'Imposta quantità',
  stock_of_freezer_saved: 'Stock del congelatore salvato',
  stock_of_freezer_saved_not_saved: 'Stock del congelatore non salvato',
  group_name: 'Nome del gruppo',
  split_no: 'Numero divisione',
  customer: 'Cliente',
  customer_list: 'Elenco clienti',
  customer_or_customer_list: 'Cliente / Elenco clienti',
  copy_settings_from: 'Copia impostazioni da',
  copy: 'Copia',
  filter_by: 'Filtra per',
  manage_versions: 'Gestisci versioni',
  display_selected_version: 'Visualizza versione selezionata',
  compare_selected_versions: 'Confronta due versioni (mostra elenco con differenze)',
  product_no: 'N° prodotto',
  products: 'Prodotti',
  manage_quantity_of_produced_articles: 'Gestisci quantità di articoli prodotti',
  charge_splitting: 'Frazionamento della carica',
  dough_splitting: 'Frazionamento della pasta',
  splitted: 'Frazionata',
  diff: 'Diff',
  can_not_edit_splitting_parts_in_liters:
    'Non è possibile modificare le parti suddivise in litri. Il campo “Pate litrs” è vuoto per questo impasto.',
  split_in_report: 'Frazionamento in report',
  part_n_m: 'Parte {{number}} ({{measurement}})',
  difference: 'Differenza',
  dough_splitting_information_was_saved:
    'Le informazioni sulla divisione dell’impasto sono state salvate',
  for_dough_splitting_you_need_to_have_more_then_one_not_empty_splitting_parts:
    'Per il frazionamento della pasta è necessario avere più di una parte riempita',
});

export const productionEn = t({
  print_jobs: 'Print jobs',
  production: 'Production',
  print_delivery_notes: 'Print delivery notes',
  print_list: 'Print list',
  print_lists: 'Print lists',
  orders: 'Orders',
  quantity_beginning_of_the_day: 'Quantity (beginning of the day)',
  legend_over_stock: 'Over stock',
  legend_normal_stock: 'Normal stock',
  legend_under_stock: 'Under stock',
  pre_production_orders: 'Orders for pre-production',
  all_print_lists: 'All print lists',
  active_print_lists: 'Active print lists',
  inactive_print_lists: 'Inactive print lists',
  edit_print_list: 'Edit print list',
  stock_of_freezer: 'Stock of freezer',
  forwarding_list: 'Forwarding list',
  cross_table_report: 'Shipping cross report',
  labels: 'Labels (LabelAssist)',
  dough_list: 'Dough list (smartScale)',
  orders_over_bakery2b: 'Orders over bakery2b.com',
  orders_over_cashassist: 'Orders over CashAssist (DirectAccess)',
  report_data_was_exported_to_LabelAssist: 'Report data was exported to LabelAssist',
  report_data_was_exported_to_SmartScale: 'Report data was exported to smartScale',
  activated: 'Activated',
  order_type: 'Order type',
  article_list_filter: 'Article filter',
  customer_list_filter: 'Customer filter',
  delivery_note_group: 'Delivery note group',
  printed: 'Printed',
  date_time: 'Date/Time',
  print_list_not_loaded: 'Print list not loaded',
  deleting_print_list: 'Deleting print list...',
  print_list_successfully_deleted: 'Print list successfully deleted',
  delete_print_list_confirmation: 'Are you sure you want to delete the print list?',
  tab_not_loaded: 'Data for tab not loaded!',
  set_up: 'Set up',
  labels_labelAssist: 'Labels (LabelAssist)',
  dough_list_smartScale: 'Dough list (smartScale)',
  production_report_type: 'Production report type',
  form_type: 'Form type',
  form: 'Form',
  partial_delivery: 'Partial delivery',
  show_production_description: 'Display production description',
  automatic_mode_of_cross_table_report: 'Automatic mode of cross-table report',
  show_individual_texts: 'Display individual descriptions',
  consider_only_order_positions_with_individual_text: 'Only articles with individual description',
  display_linked_articles_with_indentation: 'Make identation (->) for linked articles',
  do_default_dough_splitting: 'Do default dough splitting',
  display_delivery_splitting_parts: 'Display delivery splitting parts',
  sorting_type: 'Sorting type',
  pre_production_in_days: 'Pre-production (days)',
  show_semi_products_details: 'Display semi-products details',
  tour: 'Tour',
  tour_type: 'Tour type',
  need_signature: 'Need signature',
  standard_tour: 'Standard tour',
  print_all_delivery_notes_of_this_tour: 'Print all delivery notes of this tour',
  customer_sorting: 'Customer sorting',
  sat_tour: 'Sat.tour',
  sun_tour: 'Sun. tour',
  article_sorting: 'Article sorting',
  print_list_settings: 'Print list settings',
  additional_settings: 'Additional settings',
  print_list_availability: 'Print list availability',
  filters_setup: 'Filters setup',
  edit_print_list_saved: 'Edit print list saved',
  edit_print_list_not_saved: 'Edit print list not saved',
  request_error_message: 'Sorry, we encountered an error while processing your request.',
  try_preview_again: 'Please try to preview the document again.',
  try_again: 'Try again',
  cross_table_setup: 'Cross-table setup',
  cross_table_config_edit: 'Edit cross-table configuration',
  tour_list_setup: 'Tour list setup',
  tour_list_config_edit: 'Edit tour list configuration',
  create_print_list: 'Create print list',
  copy_print_list: 'Copy print list',
  no_data_no_preview: 'Preview is not available. No data for report.',
  article: 'Article',
  article_other: 'Articles',
  active: 'Active',
  type_of_goods: 'Type of goods',
  storage_locations: 'Storage locations',
  stock_min_qty: 'Min. stock quantity',
  stock_max_qty: ' Max. stock quantity',
  stock_actual_qty: 'Current stock quantity',
  stock_qty: 'Stock quantity',
  set_quantity: 'Set quantity',
  stock_of_freezer_saved: 'Stock of freezer saved',
  stock_of_freezer_saved_not_saved: 'Stock of freezer not saved',
  group_name: 'Group name',
  split_no: 'Split No.',
  customer: 'Customer',
  customer_list: 'Customer list',
  customer_or_customer_list: 'Customer / Customer list',
  copy_settings_from: 'Copy settings from',
  copy: 'Copy',
  filter_by: 'Filter by',
  manage_versions: 'Manage versions',
  display_selected_version: 'Display selected version',
  compare_selected_versions: 'Compare two versions (display backing list with difference)',
  product_no: 'Product-No.',
  products: 'Products',
  manage_quantity_of_produced_articles: 'Manage quantity of produced articles',
  charge_splitting: 'Charge splitting',
  dough_splitting: 'Dough splitting',
  splitted: 'Splitted',
  diff: 'Diff',
  can_not_edit_splitting_parts_in_liters:
    'Can not edit splitting parts in liters. Field "Weight of liter of dough (g)" is empty for this dough.',
  split_in_report: 'Split in report',
  part_n_m: 'Part {{number}} ({{measurement}})',
  difference: 'Difference',
  dough_splitting_information_was_saved: 'Dough splitting information was saved',
  for_dough_splitting_you_need_to_have_more_then_one_not_empty_splitting_parts:
    'For dough splitting you need to have more than one not empty splitting parts',
});
