import { FC, useEffect, useState } from 'react';
import { IPopupWrapper } from '../../../../../../../../../../shared/components/popup/components/popup.component';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { configsData } from '../../../../../../../../../../shared/services/configsData/configsData.service';
import { ISelectOption } from '../../../../../../../../../../shared/components/form/fields/select.component';
import { useBeforeClosePopup } from '../../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup';
import { Content } from '../../../../../../../../../../shared/components/content/content.component';
import { conditionsTabState } from '../../../../../conditions.state';
import { FormSelect } from '../../../../../../../../../../shared/components/form';
import { Button, Stack } from '@mui/material';

export const CopyIndividualPrices: FC<ICopyIndividualPrices> = ({ currentCustomerId, stream }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState<ExtendedSelectOption[]>([]);
  const { watch, handleSubmit } = useFormContext<{ customerId: string }>();

  useEffect(() => {
    const dictCustomersSub = configsData.sub.dictCustomers().subscribe((customers) => {
      setCustomers(
        customers
          .filter(({ id }) => id !== currentCustomerId)
          .map((customer) => ({
            ...customer,
            label: customer?.internalOrFullName ?? '',
            customerNo: customer?.customerNo ?? '',
          })),
      );
      setLoading(false);
    });
    configsData.pub.common(['dictCustomers']);
    return () => dictCustomersSub.unsubscribe();
  }, []);

  const filterOptions = (
    options: ExtendedSelectOption[],
    { inputValue }: { inputValue: string },
  ): ExtendedSelectOption[] =>
    options.filter(
      (option) =>
        ((option?.customerNo ? `${option?.customerNo} ` : '') + (option?.label || ''))
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) as boolean,
    );

  useBeforeClosePopup({
    stream,
    isDirty: Boolean(watch('customerId')),
  });

  const onSubmit = ({ customerId }: { customerId: string }) => {
    conditionsTabState.pub.copyIndividualPrices(customerId);
    stream.emit('close');
  };

  return (
    <Content type='box' lsx={{ bottom: -5, right: -5, top: -5, left: -5 }} {...{ loading }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormSelect
          label={t('customer.customers')}
          width='100%'
          name='customerId'
          options={customers as []}
          datasetattribute='customerOptions'
          {...{ filterOptions }}
        />
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button disabled={!watch('customerId')} variant='contained' type='submit'>
            {t('customer.copy')}
          </Button>
        </Stack>
      </form>
    </Content>
  );
};

interface ICopyIndividualPrices {
  currentCustomerId: string;
  stream: IPopupWrapper['stream'];
}

interface ExtendedSelectOption extends ISelectOption {
  customerNo?: string;
}
