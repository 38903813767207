import { GridColumnsInitialState } from '@mui/x-data-grid-premium';
import { GridRowModel } from '@mui/x-data-grid/models/gridRows';
import { ExtendedDefaultOrderGridDataPos } from '../../../../../../../services/article.service.ts';

export const attachMinMaxToQuantity = (model?: GridColumnsInitialState) => {
  if (model?.orderedFields) {
    const keys = {
      'mondaySettings.quantity': [
        'limit.mondaySettings.quantityMin',
        'limit.mondaySettings.quantityMax',
      ],
      'tuesdaySettings.quantity': [
        'limit.tuesdaySettings.quantityMin',
        'limit.tuesdaySettings.quantityMax',
      ],
      'wednesdaySettings.quantity': [
        'limit.wednesdaySettings.quantityMin',
        'limit.wednesdaySettings.quantityMax',
      ],
      'thursdaySettings.quantity': [
        'limit.thursdaySettings.quantityMin',
        'limit.thursdaySettings.quantityMax',
      ],
      'fridaySettings.quantity': [
        'limit.fridaySettings.quantityMin',
        'limit.fridaySettings.quantityMax',
      ],
      'saturdaySettings.quantity': [
        'limit.saturdaySettings.quantityMin',
        'limit.saturdaySettings.quantityMax',
      ],
      'sundaySettings.quantity': [
        'limit.sundaySettings.quantityMin',
        'limit.sundaySettings.quantityMax',
      ],
    };
    model.orderedFields = model?.orderedFields
      .filter((el: string) => !el.includes('limit'))
      .reduce((acc: string[], el: string) => {
        if (keys[el as keyof typeof keys]) {
          acc.push(el, ...keys[el as keyof typeof keys]);
        } else acc.push(el);
        return acc;
      }, []);
  }
  return model;
};

export const clearRowFromVirtualKeys = (
  row: GridRowModel<ExtendedDefaultOrderGridDataPos>,
): GridRowModel<ExtendedDefaultOrderGridDataPos> => {
  const copyRow = structuredClone(row);
  for (const rowKey in copyRow) {
    if (rowKey.includes('.')) {
      delete copyRow[rowKey as keyof ExtendedDefaultOrderGridDataPos];
    }
  }
  return copyRow;
};
