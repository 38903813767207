import { FC } from 'react';
import { IPopupWrapper } from '../../../../../../../../../shared/components/popup/components/popup.component';
import { ISelectOption } from '../../../../../../../../../shared/components/form/fields/select.component';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useBeforeClosePopup } from '../../../../../../../../../shared/components/popup/hooks/useBeforeClosePopup';
import { Content } from '../../../../../../../../../shared/components/content/content.component';
import { FormSelect } from '../../../../../../../../../shared/components/form';
import { Button, Stack } from '@mui/material';

export const CopyCrossTable: FC<ICopyCrossTable> = ({ options, stream }) => {
  const { t } = useTranslation();
  const { watch, handleSubmit } = useFormContext<{ printListId: string }>();

  useBeforeClosePopup({
    stream,
    isDirty: Boolean(watch('printListId')),
  });

  const onSubmit = ({ printListId }: { printListId: string }) => {
    stream.emit('save', { idForCopy: printListId });
    stream.emit('close');
  };

  return (
    <Content type='box' lsx={{ bottom: -5, right: -5, top: -5, left: -5 }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormSelect
          label={t('production.copy_settings_from')}
          width='100%'
          name='printListId'
          options={options as []}
        />
        <Stack marginTop='34px' direction='row' spacing={2} justifyContent='flex-end'>
          <Button disabled={!watch('printListId')} variant='contained' type='submit'>
            {t('customer.copy')}
          </Button>
        </Stack>
      </form>
    </Content>
  );
};

interface ICopyCrossTable {
  stream: IPopupWrapper['stream'];
  options: ISelectOption[];
}
