import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { FieldsSection, FormTextField } from '../../../../../../shared/components/form';
import { printFormsTabState } from '../../../states/printForms.state.ts';
import { TCompanyConfigs } from '../../../../../../shared/services/companyConfig/companyConfig.service.ts';
import { createValidationSchema } from './general.schema.ts';
import { DictionaryPopup } from '../../../../../../shared/components/dictionary/dictionary.popup.tsx';
import { TextPopup } from './dictionaries/textBlockDict/popup/text.popup.tsx';
import { TextEditCellDict } from '../../../../../../shared/components/dictionary/cells/textEditDict.cell.tsx';
import { DescriptionTextBlockCell } from './dictionaries/textBlockDict/descriptionTextBlock.cell.tsx';
import { CheckboxDictCell } from '../../../../../../shared/components/dictionary/cells/checkboxDict.cell.tsx';

const validationSchema = createValidationSchema();

export const GeneralTab: FC<IGeneralTab> = ({ params }) => {
  const { t } = useTranslation();
  const dictionaryPopup = useMemo(() => new DictionaryPopup(), []);
  const textPopup = useMemo(() => new TextPopup(), []);
  const formMethods = useForm({
    defaultValues: params,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    resetField,
    trigger,
    formState: { errors, dirtyFields },
  } = formMethods;

  const triggerValidate = useCallback(() => {
    trigger('companyNameInReports');
  }, []);

  useEffect(() => {
    triggerValidate();
    const formStateSub = formMethods.watch((data, reason) => {
      if (reason.type === 'change' && reason.name) {
        const fieldName = reason.name;
        const dataToSave = { [fieldName]: data[fieldName] };
        if (fieldName === 'companyNameInReports') triggerValidate();
        printFormsTabState.pub.recordData(dataToSave);
      }
    });
    const printFormsTabStateSub = printFormsTabState.sub.state().subscribe(({ dataToSave }) => {
      for (const valuesKey in dataToSave) {
        if (valuesKey && dataToSave) {
          resetField(valuesKey as keyof TCompanyConfigs, {
            defaultValue: dataToSave[valuesKey as keyof typeof dataToSave],
            keepDirty: false,
          });
        }
      }
    });

    return () => {
      formStateSub.unsubscribe();
      printFormsTabStateSub.unsubscribe();
      textPopup.stream.unsubscribe();
      dictionaryPopup.stream.unsubscribe();
    };
  }, []);

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    if (!errors[e.target.name] && !!dirtyFields[e.target.name]) {
      printFormsTabState.pub.saveData();
    }
  };

  const textBlocksHandler = () => {
    dictionaryPopup.stream.emit('open', {
      params: {
        popupTitle: 'settings.text_blocks',
        dictionaryType: 'dictTextBlocks',
        validateFields: ['description'],
        nameAlias: 'description',
        width: 888,
        emptyRow: {
          description: null,
          isDefaultForOrder: false,
          isDefaultForOffer: false,
          isDefaultForInvoice: false,
        },
        columns: [
          {
            field: 'description',
            headerName: t('common.description'),
            renderEditCell: (params) => (
              <TextEditCellDict
                rightButton={{
                  title: t('settings.text_block'),
                  handler: () =>
                    textPopup.stream.emit('open', {
                      id: String(params.id),
                      title: params.row.description,
                    }),
                }}
                {...params}
              />
            ),
            renderCell: (params) => (
              <DescriptionTextBlockCell popupStream={textPopup.stream} {...params} />
            ),
            editable: true,
            resizable: true,
          },
          {
            field: 'isDefaultForOrder',
            headerAlign: 'center',
            align: 'center',
            width: 170,
            flex: 0,
            renderCell: (params) => <CheckboxDictCell {...params} />,
            headerName: t('settings.standard_order'),
          },
          {
            field: 'isDefaultForOffer',
            headerAlign: 'center',
            align: 'center',
            width: 170,
            flex: 0,
            renderCell: (params) => <CheckboxDictCell {...params} />,
            headerName: t('settings.standard_offer'),
          },
          {
            field: 'isDefaultForInvoice',
            headerAlign: 'center',
            align: 'center',
            width: 170,
            flex: 0,
            renderCell: (params) => <CheckboxDictCell {...params} />,
            headerName: t('settings.standard_invoice'),
          },
        ],
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form noValidate onBlur={onSubmit}>
        <Stack direction='column' pt={1}>
          <FormTextField
            inputProps={{ maxLength: 50 }}
            required
            name='companyNameInReports'
            label={t('settings.company_name_in_reports')}
            error={Boolean(errors['companyNameInReports'])}
            helperText={
              errors['companyNameInReports']
                ? t(String(errors['companyNameInReports'].message))
                : ''
            }
          />
        </Stack>
      </form>
      <FieldsSection title={t('settings.related_directories')} nomt>
        <Stack alignItems='start' spacing={1} width='fit-content'>
          <Button
            onClick={textBlocksHandler}
            size='medium'
            variant='outlined'
            color='secondary'
            fullWidth
          >
            {t('settings.text_blocks_settings')}
          </Button>
        </Stack>
      </FieldsSection>
      <dictionaryPopup.Component />
      <textPopup.Component />
    </FormProvider>
  );
};

interface IGeneralTab {
  params: TCompanyConfigs;
}
