import { Stack } from '@mui/material';
import { FC, useMemo, useEffect } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FieldsRow,
  FormCheckbox,
  FormNumericField,
  FormSelect,
  FormTextField,
  SelectMenuWithFooter,
} from '../../../../../../shared/components/form';
import { FormDeliveryTimePicker } from './formDeliveryTimePicker.component.tsx';
import { NoteButton } from '../../../../../../shared/components/form/buttons/noteButton.component.tsx';
import { valueHelper } from '../../../../../../shared/helpers/valueProcessor/value.helper';
import { CustomerTourDataRes } from '../../../../services/customer.service';
import { ITourTabOptions } from '../../../../states/tabState.model';
import { tourTabState } from '../tour.state';
import { NotePopup } from '../popups/note.popup';
import { formatDeliveryTime } from '../../../../../../shared/helpers/utils/utils.helper.ts';

export const TourForm: FC<ITourForm> = ({ formMethods, tourTabOptions }) => {
  const { t } = useTranslation();

  const notePopup = useMemo(() => new NotePopup(), []);

  const {
    formState: { errors, dirtyFields },
    getValues,
  } = formMethods;

  const onSubmit = (e: React.FocusEvent<HTMLFormElement>) => {
    const fieldName = e.target.name as keyof CustomerTourDataRes;

    if (
      !errors[fieldName] &&
      !!dirtyFields[fieldName] &&
      fieldName !== 'tourDepartureTime' &&
      fieldName !== 'tourSatDepartureTime' &&
      fieldName !== 'tourSunDepartureTime'
    ) {
      const values = getValues();
      tourTabState.pub.save({ data: { id: values.id, [fieldName]: values[fieldName] } });
    }
  };

  useEffect(() => {
    notePopup.stream.state().subscribe(({ action }) => {
      if (action === 'save') {
        const values = getValues();
        tourTabState.pub.save({ data: { id: values.id, tourplanNote: values['tourplanNote'] } });
      }
    });
    return () => notePopup.stream.unsubscribe();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onBlur={onSubmit}>
        <Stack marginTop='10px'>
          <FieldsRow>
            <FormTextField
              inputProps={{ maxLength: 16 }}
              label={t('customer.setUpStation')}
              width='220px'
              name='tourPreparationArea'
            />
            <FormSelect
              label={t('customer.standardTour')}
              width='220px'
              name='tourplanId'
              options={(tourTabOptions?.tourplanList as { id: string; label: string }[]) || []}
              PaperComponent={(props) => (
                <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
              )}
            />
            <FormNumericField
              label={t('customer.drivingPos')}
              width='120px'
              name='tourPosition'
              precision={0}
            />
            <FormDeliveryTimePicker
              onBlur={(date) => {
                tourTabState.pub.save({
                  data: { id: getValues().id, tourDepartureTime: formatDeliveryTime(date) },
                });
              }}
              label={t('customer.deliveryTime')}
              width='130px'
              name='tourDepartureTime'
            />
          </FieldsRow>
          <FieldsRow>
            <FormTextField
              inputProps={{ maxLength: 16 }}
              label={t('customer.setUpStationSat')}
              width='220px'
              name='tourSatPreparationArea'
            />
            <FormSelect
              label={t('customer.saturdayTour')}
              width='220px'
              name='tourplanSatId'
              options={(tourTabOptions?.tourplanList as { id: string; label: string }[]) || []}
              PaperComponent={(props) => (
                <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
              )}
            />
            <FormNumericField
              label={t('customer.drivingPos')}
              width='120px'
              name='tourSatPosition'
              precision={0}
            />
            <FormDeliveryTimePicker
              onBlur={(date) => {
                tourTabState.pub.save({
                  data: { id: getValues().id, tourSatDepartureTime: formatDeliveryTime(date) },
                });
              }}
              label={t('customer.deliveryTime')}
              width='130px'
              name='tourSatDepartureTime'
            />
          </FieldsRow>
          <FieldsRow>
            <FormTextField
              inputProps={{ maxLength: 16 }}
              label={t('customer.setUpStationSun')}
              width='220px'
              name='tourSunPreparationArea'
            />
            <FormSelect
              label={t('customer.sundayTour')}
              width='220px'
              name='tourplanSunId'
              options={(tourTabOptions?.tourplanList as { id: string; label: string }[]) || []}
              PaperComponent={(props) => (
                <SelectMenuWithFooter {...props} href='/settings/configuration/customer' />
              )}
            />
            <FormNumericField
              label={t('customer.drivingPos')}
              width='120px'
              name='tourSunPosition'
              precision={0}
            />
            <FormDeliveryTimePicker
              onBlur={(date) => {
                tourTabState.pub.save({
                  data: { id: getValues().id, tourSunDepartureTime: formatDeliveryTime(date) },
                });
              }}
              label={t('customer.deliveryTime')}
              width='130px'
              name='tourSunDepartureTime'
            />
          </FieldsRow>
          <FieldsRow>
            <NoteButton
              badged={valueHelper.checkValueInHtml(getValues().tourplanNote)}
              onClick={() =>
                notePopup.stream.emit('open', {
                  values: {
                    tourplanNote: getValues().tourplanNote,
                  },
                })
              }
            >
              {t('customer.tourNote')}
            </NoteButton>
            <FormCheckbox label={t('customer.addInTourIfNoDelivery')} name='isAlwaysInTourplan' />
          </FieldsRow>
          <FieldsRow>
            <FormSelect
              name='transportSectorId'
              options={
                (tourTabOptions.postSectorsList as Array<{ id: string; label: string }>) || []
              }
              label={t('customer.shipping_costs_sector')}
              PaperComponent={(props) => (
                <SelectMenuWithFooter {...props} href='/settings/configuration/post' />
              )}
              disableClearable
            />
          </FieldsRow>
        </Stack>
      </form>
      <notePopup.Component />
    </FormProvider>
  );
};

interface ITourForm {
  formMethods: UseFormReturn<CustomerTourDataRes>;
  tourTabOptions: ITourTabOptions;
}
