import { gql } from 'graphql-request';

export const emailTrackingByDocument = gql`
  query EmailTrackingByDocument($docType: C_EMAIL_MSG_DOCTYPE!, $id: ID!) {
    wawiAssist {
      getWA_EmailTrackingByDocument(docType: $docType, id: $id) {
        messageId
        senderAddress
        receiverAddress
        messageSubject
        timestamp
        events {
          timestamp
          type
          diagnosticInfo
        }
      }
    }
  }
`;
