import { gql } from 'graphql-request';

export const initProdStatisticCalc = gql`
  mutation initProdStatisticCalc($prodStatisticProps: WA_InitProdStatisticInputs!) {
    wawiAssist {
      initWA_ProdStatisticCalc(prodStatisticProps: $prodStatisticProps) {
        jobId
      }
    }
  }
`;

export const getProdStatisticData = gql`
  query getProdStatisticData($jobId: String!) {
    wawiAssist {
      getWA_statisticData(jobId: $jobId) {
        data {
          ... on WA_ProductionStatisticData {
            gridData: items {
              id
              articleNo
              articleDescription
              quantity
              discountGroup
              productionGroup
              grossSales
            }
            summaryData {
              topArticle
              totalQuantity
              totalGrossSales
            }
            chartsDataset {
              values
              keys
            }
          }
        }
        status
      }
    }
  }
`;
