import { TypeTable } from '../../../../../../../../shared/components/table/table.component';
import { Theme } from '../../../../../../../../shared/styles/theme/theme.ts';

const getFieldStile = (field: any) => {
  if (field.row.isSplitted) {
    if (field.row.diff) {
      return field.row.isLoading ? 'splitted-not-ok-loading' : 'splitted-not-ok';
    } else {
      return field.row.isLoading ? 'splitted-ok-loading' : 'splitted-ok';
    }
  } else {
    return '';
  }
};

const getDiffFieldStile = (field: any) => {
  if (field.row.isSplitted) {
    if (field.row.diff) {
      return field.row.isLoading ? 'splitted-not-ok-diff-loading' : 'splitted-not-ok-diff';
    } else {
      return field.row.isLoading ? 'splitted-ok-loading' : 'splitted-ok';
    }
  } else {
    return '';
  }
};

const getIsSplittedFieldStile = (field: any) => {
  if (field.row.isSplitted) {
    if (field.row.diff) {
      return field.row.isLoading ? 'splitted-not-ok-issplitted-loading' : 'splitted-not-ok';
    } else {
      return field.row.isLoading ? 'splitted-ok-issplitted-loading' : 'splitted-ok';
    }
  } else {
    return '';
  }
};

export const viewCells: TypeTable['viewCells'] = {
  actions: {
    isSplitted: getIsSplittedFieldStile,
    doughNo: getFieldStile,
    doughName: getFieldStile,
    kilogramDoughRequired: getFieldStile,
    literDoughRequired: getFieldStile,
    resPart1Amount: getFieldStile,
    resPart1LtAmount: getFieldStile,
    resPart2Amount: getFieldStile,
    resPart2LtAmount: getFieldStile,
    resPart3Amount: getFieldStile,
    resPart3LtAmount: getFieldStile,
    resPart4Amount: getFieldStile,
    resPart4LtAmount: getFieldStile,
    resPart5Amount: getFieldStile,
    resPart5LtAmount: getFieldStile,
    diff: getDiffFieldStile,
    diffLt: getDiffFieldStile,
  },
  styles: [
    {
      className: 'splitted-ok',
      cellStyles: {
        backgroundColor: Theme.palette.green.light,
        '&:hover': {
          backgroundColor: Theme.palette.green.main,
        },
      },
    },
    {
      className: 'splitted-not-ok',
      cellStyles: {
        backgroundColor: Theme.palette.yellow.light,
        '&:hover': {
          backgroundColor: Theme.palette.yellow.main,
        },
      },
    },
    {
      className: 'splitted-not-ok-diff',
      cellStyles: {
        backgroundColor: Theme.palette.red.light,
        '&:hover': {
          backgroundColor: Theme.palette.red.main,
        },
      },
    },
    {
      className: 'splitted-ok-loading',
      cellStyles: {
        backgroundColor: Theme.palette.green.light,
        opacity: 0.4,
        '&:hover': {
          backgroundColor: Theme.palette.green.main,
        },
      },
    },
    {
      className: 'splitted-not-ok-loading',
      cellStyles: {
        backgroundColor: Theme.palette.yellow.light,
        opacity: 0.4,
        '&:hover': {
          backgroundColor: Theme.palette.yellow.main,
        },
      },
    },
    {
      className: 'splitted-not-ok-diff-loading',
      cellStyles: {
        backgroundColor: Theme.palette.red.light,
        opacity: 0.4,
        '&:hover': {
          backgroundColor: Theme.palette.red.main,
        },
      },
    },
    {
      className: 'splitted-not-ok-issplitted-loading',
      cellStyles: {
        backgroundColor: Theme.palette.yellow.light,
        opacity: 0.4,
        '&:hover': {
          backgroundColor: Theme.palette.yellow.main,
        },
      },
    },
    {
      className: 'splitted-ok-issplitted-loading',
      cellStyles: {
        backgroundColor: Theme.palette.green.light,
        opacity: 0.4,
        '&:hover': {
          backgroundColor: Theme.palette.green.main,
        },
      },
    },
  ],
};
